var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showOrganigram && !_vm.isMobile && !_vm.mini
        ? _c(
            "v-expansion-panels",
            {
              staticClass: "extensionPanelsORga",
              attrs: {
                flat: "",
                value: _vm.state.searchTerm ? "0" : "" || _vm.isActive
              }
            },
            [
              _c(
                "v-expansion-panel",
                {
                  on: {
                    click: function($event) {
                      return _vm.setList("organisation")
                    }
                  }
                },
                [
                  _c(
                    "v-expansion-panel-header",
                    {
                      staticStyle: {
                        "max-height": "48px",
                        "min-height": "unset"
                      }
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "d-flex" },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _vm.currentPage("/organisation")
                                ? _c("font-awesome-icon", {
                                    staticClass: "primary--text",
                                    attrs: { icon: ["fas", "sitemap"] }
                                  })
                                : _c("font-awesome-icon", {
                                    staticClass: "primary--text",
                                    attrs: { icon: ["fal", "sitemap"] }
                                  })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-title",
                            {
                              class:
                                "" +
                                (_vm.getRoute === "/organisation"
                                  ? "font-weight-bold"
                                  : ""),
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.handleOrganisation()
                                }
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("generics.organisation")))
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            !_vm.isMobile &&
                                            _vm.amIAdmin &&
                                            !_vm.isBigOrganisation
                                              ? _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "btnAddGroup",
                                                      attrs: {
                                                        icon: "",
                                                        disabled: false
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          $event.preventDefault()
                                                          return _vm.showEditableView()
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c("font-awesome-icon", {
                                                      staticClass:
                                                        "primary--text",
                                                      style: {
                                                        fontSize: "20px"
                                                      },
                                                      attrs: {
                                                        icon: [
                                                          "fal",
                                                          "plus-circle"
                                                        ]
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1077843587
                                  )
                                },
                                [
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.state.user.organisationEditable
                                          ? _vm.$t(
                                              "components.sidebar.cancelEditOrga"
                                            )
                                          : _vm.$t(
                                              "components.sidebar.organigramPlusTT"
                                            )
                                      )
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-expansion-panel-content",
                    [
                      !_vm.isGuest &&
                      (_vm.state.namespaceSettings.processedOrganisation || {})
                        .sections &&
                      Object.keys(
                        _vm.state.namespaceSettings.processedOrganisation
                          .sections
                      ).length > 0
                        ? _c("Sections", {
                            attrs: {
                              setList: _vm.setList,
                              active: _vm.active,
                              data: _vm.separatedPeople.sections,
                              setActiveOrganisationSection:
                                _vm.setActiveOrganisationSection,
                              searchFoundSomethingSection:
                                _vm.searchFoundSomethingSection
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isGuest &&
                      (_vm.state.namespaceSettings.processedOrganisation || {})
                        .departments &&
                      Object.keys(
                        _vm.state.namespaceSettings.processedOrganisation
                          .departments
                      ).length > 0
                        ? _c("Departments", {
                            attrs: {
                              setList: _vm.setList,
                              active: _vm.active,
                              data: _vm.separatedPeople.departments,
                              setActiveOrganisationSection:
                                _vm.setActiveOrganisationSection,
                              searchFoundSomethingDepartment:
                                _vm.searchFoundSomethingDepartment
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isGuest &&
                      (_vm.state.namespaceSettings.processedOrganisation || {})
                        .teams &&
                      Object.keys(
                        _vm.state.namespaceSettings.processedOrganisation.teams
                      ).length > 0
                        ? _c("Teams", {
                            attrs: {
                              setList: _vm.setList,
                              active: _vm.active,
                              data: _vm.separatedPeople.teams,
                              setActiveOrganisationSection:
                                _vm.setActiveOrganisationSection,
                              totalTeams: _vm.getTotalTeams,
                              searchFoundSomethingTeam:
                                _vm.searchFoundSomethingTeam,
                              isMobile: _vm.isMobile
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showOrganigram && !_vm.isMobile && _vm.mini
        ? _c(
            "v-tooltip",
            {
              attrs: { right: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-list-item",
                          _vm._g({ staticClass: "cursorPointer" }, on),
                          [
                            _c(
                              "v-list-item-icon",
                              {
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.handlerClickOrgaMini.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [
                                _vm.currentPage("/organisation")
                                  ? _c("font-awesome-icon", {
                                      staticClass: "primary--text",
                                      attrs: { icon: ["fas", "sitemap"] }
                                    })
                                  : _c("font-awesome-icon", {
                                      staticClass: "primary--text",
                                      attrs: { icon: ["fal", "sitemap"] }
                                    })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3501255994
              )
            },
            [
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$t("generics.organisation")))])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }