var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto", attrs: { flat: "", width: "100%" } },
    [
      _c("Favorites", {
        attrs: {
          setList: _vm.setList,
          active: _vm.active,
          setCurrentContentVisile: _vm.setCurrentContentVisile,
          isGuest: _vm.isGuest,
          sortedFavoriteUsers: _vm.sortedFavoriteUsers,
          totalUnfilteredUsers: _vm.totalUnfilteredUsers,
          isMobile: _vm.isMobile,
          mini: _vm.mini
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }