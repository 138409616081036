var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.userExists
    ? _c(
        "div",
        [
          _vm.isCardStyle
            ? [
                _c(
                  "v-card",
                  {
                    staticStyle: {
                      height: "100%, position: relative , background: #888888"
                    },
                    style: _vm.getBorderByStatusFull(
                      _vm.getPerson(_vm.user.uuid)
                    )
                  },
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "div",
                                    _vm._g({}, on),
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "topbar" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "userIconInfo" },
                                            [
                                              _vm.user.isModerator
                                                ? _c("font-awesome-icon", {
                                                    staticClass: "mr-2",
                                                    style: { fontSize: "20px" },
                                                    attrs: {
                                                      icon: [
                                                        "fal",
                                                        "users-crown"
                                                      ]
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !_vm.user.isGuest &&
                                              !_vm.user.isModerator
                                                ? _c("font-awesome-icon", {
                                                    staticClass: "mr-2",
                                                    style: { fontSize: "20px" },
                                                    attrs: {
                                                      icon: ["fal", "user"]
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.user.isGuest
                                                ? _c("font-awesome-icon", {
                                                    staticClass: "mr-2",
                                                    style: { fontSize: "20px" },
                                                    attrs: {
                                                      icon: [
                                                        "fal",
                                                        "user-clock"
                                                      ]
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-card-subtitle",
                                            {
                                              staticClass:
                                                "\n                  callerName\n                  font-weight-light\n                  pt-0\n                  pb-0\n                  px-0\n                  text-truncate\n                ",
                                              staticStyle: {
                                                color: "#fff !important"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.getTitelForUuid(
                                                      _vm.user.uuid
                                                    )
                                                  ) +
                                                  "  " +
                                                  _vm._s(
                                                    _vm.getName(_vm.user)
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "frontButtons" },
                                            [
                                              _vm.canEdit &&
                                              _vm.user.uuid !==
                                                _vm.groupModerator
                                                ? _c(
                                                    "v-menu",
                                                    {
                                                      attrs: {
                                                        "offset-y": "",
                                                        scrollable: "",
                                                        "close-on-click": true
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _vm.canEdit
                                                                  ? _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "buttonCallFrontCard",
                                                                          staticStyle: {
                                                                            float:
                                                                              "right"
                                                                          },
                                                                          attrs: {
                                                                            icon:
                                                                              ""
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "font-awesome-icon",
                                                                          {
                                                                            style: {
                                                                              fontSize:
                                                                                "20px"
                                                                            },
                                                                            attrs: {
                                                                              color:
                                                                                "white",
                                                                              icon: [
                                                                                "fal",
                                                                                "ellipsis-v"
                                                                              ]
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e()
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-list",
                                                        [
                                                          _vm.isEligible
                                                            ? _c(
                                                                "v-list-item",
                                                                {
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.toggleModerator(
                                                                        _vm.user
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  !_vm.user
                                                                    .isModerator
                                                                    ? _c(
                                                                        "v-list-item-title",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "components.conferenceForm.makeModerator"
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _vm.user
                                                                    .isModerator
                                                                    ? _c(
                                                                        "v-list-item-title",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "components.conferenceForm.removeModerator"
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _vm.amIAdmin()
                                                            ? _c(
                                                                "v-list-item",
                                                                {
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.resendInvite(
                                                                        _vm.user
                                                                          .uuid
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "components.inviteVisitor.resendInvLink"
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          !_vm.user.isGroup &&
                                                          !_vm.user.isTeam &&
                                                          !_vm.hasPrivilege(
                                                            _vm.user.uuid
                                                          )
                                                            ? _c(
                                                                "v-list-item",
                                                                {
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.copyLink(
                                                                        $event,
                                                                        _vm.user
                                                                          .uuid
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "components.inviteVisitor.copyLink"
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          !_vm.user.isGroup &&
                                                          !_vm.user.isTeam
                                                            ? _c(
                                                                "v-list-item",
                                                                {
                                                                  attrs: {
                                                                    disabled: !_vm.isEditingConference
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.checkSendMessage(
                                                                        _vm.user
                                                                          .uuid
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "components.userProfile.sendAMessage"
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _vm.user.uuid !==
                                                          _vm.groupModerator
                                                            ? _c(
                                                                "v-list-item",
                                                                {
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.preDeleteUser(
                                                                        $event,
                                                                        _vm.user
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        top: ""
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              return [
                                                                                _c(
                                                                                  "v-list-item-title",
                                                                                  _vm._g(
                                                                                    {
                                                                                      class: !_vm.deleteActive
                                                                                        ? "deleteBasketButtonNormal"
                                                                                        : "deleteBasketButtonActive"
                                                                                    },
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "components.conferenceForm.delete"
                                                                                        )
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      )
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "components.conferenceForm.confirmRemove"
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-img",
                                        {
                                          staticClass:
                                            "white--text align-end mx-auto",
                                          attrs: {
                                            src: _vm.getAvatarByType(
                                              _vm.user.uuid
                                            ),
                                            height: "100%",
                                            width: "256px"
                                          }
                                        },
                                        [
                                          _vm.user.isGuest &&
                                          !_vm.user.isModerator &&
                                          _vm.userHasEmailError
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "badMailDiv red"
                                                },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: {
                                                        top: "",
                                                        "max-width": "300"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var onTTBadMail =
                                                                ref.on
                                                              return [
                                                                _c(
                                                                  "font-awesome-icon",
                                                                  _vm._g(
                                                                    {
                                                                      style: {
                                                                        fontSize:
                                                                          "18px"
                                                                      },
                                                                      attrs: {
                                                                        color:
                                                                          "white",
                                                                        icon: [
                                                                          "fas",
                                                                          "envelope"
                                                                        ]
                                                                      }
                                                                    },
                                                                    onTTBadMail
                                                                  )
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "components.inviteVisitor.badEmail"
                                                              )
                                                            )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.userHasAcceptedEvent === false &&
                                          !_vm.user.isTeam &&
                                          !_vm.user.isGroup &&
                                          !_vm.user.isModerator
                                            ? _c(
                                                "div",
                                                { staticClass: "rsvpDiv red" },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var onTTRejected =
                                                                ref.on
                                                              return [
                                                                _c(
                                                                  "font-awesome-icon",
                                                                  _vm._g(
                                                                    {
                                                                      style: {
                                                                        fontSize:
                                                                          "18px"
                                                                      },
                                                                      attrs: {
                                                                        color:
                                                                          "white",
                                                                        icon: [
                                                                          "fal",
                                                                          "times"
                                                                        ]
                                                                      }
                                                                    },
                                                                    onTTRejected
                                                                  )
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.user.isGuest
                                                                ? _vm.$t(
                                                                    "components.conferenceForm.rejectedGuest"
                                                                  )
                                                                : _vm.$t(
                                                                    "components.conferenceForm.rejectedParticipant"
                                                                  )
                                                            )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.userHasAcceptedEvent === true &&
                                          !_vm.user.isTeam &&
                                          !_vm.user.isGroup &&
                                          !_vm.user.isModerator
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "rsvpDiv green"
                                                },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var onTTAccepted =
                                                                ref.on
                                                              return [
                                                                _c(
                                                                  "font-awesome-icon",
                                                                  _vm._g(
                                                                    {
                                                                      style: {
                                                                        fontSize:
                                                                          "18px"
                                                                      },
                                                                      attrs: {
                                                                        color:
                                                                          "white",
                                                                        icon: [
                                                                          "fal",
                                                                          "check"
                                                                        ]
                                                                      }
                                                                    },
                                                                    onTTAccepted
                                                                  )
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.user.isGuest
                                                                ? _vm.$t(
                                                                    "components.conferenceForm.acceptedGuest"
                                                                  )
                                                                : _vm.$t(
                                                                    "components.conferenceForm.acceptedParticipant"
                                                                  )
                                                            )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1707523071
                        )
                      },
                      [
                        _vm._v(" "),
                        _c("UserInfoTooltip", {
                          attrs: { person: _vm.getPerson(_vm.user.uuid) }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : [
                _c(
                  "div",
                  { staticStyle: { position: "relative" } },
                  [
                    _vm.user.isModerator
                      ? _c("font-awesome-icon", {
                          staticStyle: { "z-index": "9" },
                          style: {
                            fontSize: "18px",
                            position: "absolute",
                            top: -10,
                            left: -11,
                            transform: "rotate(-20deg)"
                          },
                          attrs: { color: "#02889D", icon: ["fas", "crown"] }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.user.isGuest &&
                    !_vm.user.isModerator &&
                    _vm.userHasEmailError
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { top: "", "max-width": "300" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "font-awesome-icon",
                                        _vm._g(
                                          {
                                            staticStyle: { "z-index": "9" },
                                            style: {
                                              fontSize: "18px",
                                              position: "absolute",
                                              top: -10,
                                              left: -11,
                                              transform: "rotate(-20deg)"
                                            },
                                            attrs: {
                                              color: "#ff0000",
                                              icon: ["fas", "envelope"]
                                            }
                                          },
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3339851814
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("components.inviteVisitor.badEmail")
                                )
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("v-img", {
                      staticClass: "userAvatar",
                      style: _vm.getBorderByStatusFull(
                        _vm.getPerson(_vm.user.uuid),
                        "3px"
                      ),
                      attrs: {
                        contain: "",
                        src: _vm.user.isGroup
                          ? _vm.user.groupAvatar
                          : _vm.getAvatarByType(_vm.user.uuid)
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "v-card-subtitle",
                      {
                        staticClass:
                          "text-truncate ml-2 px-0 py-0 userName font-weight-medium"
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.getTitelForUuid(_vm.user.uuid)) +
                            " " +
                            _vm._s(_vm.getName(_vm.user)) +
                            "\n        "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.user.isModerator
                                ? _vm.$t("components.conferenceForm.moderator")
                                : _vm.user.isGuest
                                ? _vm.$t("components.conferenceForm.guest")
                                : _vm.$t("components.conferenceForm.user")
                            ) +
                            "\n      "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.userHasAcceptedEvent === false &&
                    !_vm.user.isTeam &&
                    !_vm.user.isGroup &&
                    !_vm.user.isModerator
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "font-awesome-icon",
                                        _vm._g(
                                          {
                                            style: {
                                              fontSize: "20px",
                                              marginLeft: 5
                                            },
                                            attrs: {
                                              color: "red",
                                              icon: ["fal", "times"]
                                            }
                                          },
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3355934839
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.user.isGuest
                                      ? _vm.$t(
                                          "components.conferenceForm.rejectedGuest"
                                        )
                                      : _vm.$t(
                                          "components.conferenceForm.rejectedParticipant"
                                        )
                                  )
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.userHasAcceptedEvent === true &&
                    !_vm.user.isTeam &&
                    !_vm.user.isGroup &&
                    !_vm.user.isModerator
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "font-awesome-icon",
                                        _vm._g(
                                          {
                                            style: {
                                              fontSize: "20px",
                                              marginLeft: 5
                                            },
                                            attrs: {
                                              color: "green",
                                              icon: ["fal", "check"]
                                            }
                                          },
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3276688479
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.user.isGuest
                                      ? _vm.$t(
                                          "components.conferenceForm.acceptedGuest"
                                        )
                                      : _vm.$t(
                                          "components.conferenceForm.acceptedParticipant"
                                        )
                                  )
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.canEdit && _vm.user.uuid !== _vm.groupModerator
                      ? _c(
                          "v-menu",
                          {
                            attrs: {
                              "offset-y": "",
                              scrollable: "",
                              "close-on-click": true
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _vm.canEdit
                                        ? _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticStyle: { float: "right" },
                                                attrs: { icon: "" }
                                              },
                                              on
                                            ),
                                            [
                                              _c("font-awesome-icon", {
                                                style: { fontSize: "20px" },
                                                attrs: {
                                                  icon: ["fal", "ellipsis-v"]
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1720550229
                            )
                          },
                          [
                            _vm._v(" "),
                            _c(
                              "v-list",
                              [
                                _vm.isEligible
                                  ? _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.toggleModerator(_vm.user)
                                          }
                                        }
                                      },
                                      [
                                        !_vm.user.isModerator
                                          ? _c("v-list-item-title", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.conferenceForm.makeModerator"
                                                  )
                                                )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.user.isModerator
                                          ? _c("v-list-item-title", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.conferenceForm.removeModerator"
                                                  )
                                                )
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.amIAdmin()
                                  ? _c(
                                      "v-list-item",
                                      {
                                        attrs: {
                                          disabled: !_vm.isEditingConference
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.resendInvite(
                                              _vm.user.uuid
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "components.inviteVisitor.resendInvLink"
                                              )
                                            )
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.user.isGroup &&
                                !_vm.user.isTeam &&
                                !_vm.hasPrivilege(_vm.user.uuid)
                                  ? _c(
                                      "v-list-item",
                                      {
                                        attrs: {
                                          disabled: !_vm.isEditingConference
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.copyLink(
                                              $event,
                                              _vm.user.uuid
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "components.inviteVisitor.copyLink"
                                              )
                                            )
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.user.isGroup && !_vm.user.isTeam
                                  ? _c(
                                      "v-list-item",
                                      {
                                        attrs: {
                                          disabled: !_vm.isEditingConference
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.checkSendMessage(
                                              _vm.user.uuid
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "components.userProfile.sendAMessage"
                                              )
                                            )
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.user.uuid !== _vm.groupModerator
                                  ? _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.preDeleteUser(
                                              $event,
                                              _vm.user
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-list-item-title",
                                                        _vm._g(
                                                          {
                                                            class: !_vm.deleteActive
                                                              ? "deleteBasketButtonNormal"
                                                              : "deleteBasketButtonActive"
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "components.conferenceForm.delete"
                                                              )
                                                            ) +
                                                              "\n                  "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              2603229952
                                            )
                                          },
                                          [
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.conferenceForm.confirmRemove"
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }