var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "570", height: "329" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showSendSMSModal,
        callback: function($$v) {
          _vm.showSendSMSModal = $$v
        },
        expression: "showSendSMSModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-row",
            {
              staticClass: "w-100 mx-0 my-0 justify-space-between",
              attrs: { flex: "" }
            },
            [
              _c(
                "v-col",
                { staticClass: "px-0 py-0", attrs: { align: "left" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "headline" },
                    [
                      _c("v-img", {
                        attrs: {
                          src: "assets/icon.png",
                          "max-height": "30",
                          "max-width": "30",
                          contain: ""
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-medium pl-4 spanModalTitle"
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("components.2faRecovery.recoveryTitle")
                            )
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider")
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-card-subtitle", { staticClass: "pt-2 heightContent" }, [
            _c("div", { staticClass: "py-2" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("components.2faRecovery.recoveryText")))
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "v-card",
            {
              staticClass: "py-2 pl-4 mt-0 pr-2 footerModal",
              attrs: { color: "primary" }
            },
            [
              _c(
                "v-row",
                { staticClass: "px-2 btns mx-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "px-0 py-0",
                      attrs: { cols: "12", align: "right" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            loading: _vm.requestingSms
                          },
                          on: { click: _vm.sendSMS }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("components.2faSms.sendSMS")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticStyle: {
                            "border-radius": "4px",
                            "min-width": "45px"
                          },
                          attrs: {
                            icon: "",
                            tile: "",
                            outlined: "",
                            color: "white"
                          },
                          on: { click: _vm.cancelBtn }
                        },
                        [
                          _c("font-awesome-icon", {
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "times"] }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }