var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-footer", { attrs: { fixed: "" } }, [
    _c(
      "div",
      { staticClass: "d-flex justify-space-between align-center w100" },
      [
        _vm.mini
          ? _c("v-img", {
              staticClass: "cursorPointer",
              class: {
                "ml-3": _vm.isMobile && !_vm.mini,
                "ml-6 mr-0": !_vm.isMobile && !_vm.mini,
                "ml-0": _vm.mini
              },
              staticStyle: { display: "inline-flex" },
              attrs: { "max-width": "25", src: "assets/icon.png", contain: "" },
              on: {
                click: function($event) {
                  _vm.sheet = !_vm.sheet
                }
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.dirty &&
        !_vm.callInfoStateStore.callActive &&
        !_vm.inBridgeCall &&
        !_vm.mini
          ? _c(
              "v-tooltip",
              {
                attrs: { top: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "buttonReload",
                                attrs: {
                                  id: "updateIcon",
                                  icon: "",
                                  color: "red"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.reloadPage()
                                  }
                                }
                              },
                              on
                            ),
                            [
                              _c("font-awesome-icon", {
                                attrs: {
                                  id: "updateFont",
                                  icon: ["far", "repeat"]
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  65753262
                )
              },
              [
                _vm._v(" "),
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("components.footer.newUpdate")))
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.mini
          ? _c(
              "v-btn",
              {
                staticClass: "w100",
                style: { textTransform: "none" },
                attrs: { text: "" },
                on: {
                  click: function($event) {
                    _vm.sheet = !_vm.sheet
                  }
                }
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "primary--text",
                    staticStyle: { "font-size": "15px" }
                  },
                  [_vm._v("\n        vOffice ©")]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "v-bottom-sheet",
          {
            attrs: { padless: "", persistent: "" },
            model: {
              value: _vm.sheet,
              callback: function($$v) {
                _vm.sheet = $$v
              },
              expression: "sheet"
            }
          },
          [
            _c(
              "v-sheet",
              { staticClass: "text-center", attrs: { "min-height": "200px" } },
              [
                _c(
                  "v-row",
                  {
                    staticClass: "px-5 mx-0",
                    style: { width: "100%", margin: 0 }
                  },
                  [
                    _c(
                      "v-col",
                      { staticClass: "px-0", attrs: { cols: "12" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mt-6",
                            attrs: { text: "", color: "red" },
                            on: {
                              click: function($event) {
                                return _vm.closeFooter()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("components.footer.close")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                [
                  _vm.activeTab === "ContactUs" ? _c("Contact") : _vm._e(),
                  _vm._v(" "),
                  _vm.activeTab === "AboutUs" ? _c("About") : _vm._e()
                ],
                _vm._v(" "),
                _c(
                  "v-row",
                  {
                    staticClass: "py-8",
                    attrs: { justify: "center", "no-gutters": "" }
                  },
                  [
                    _vm._l(_vm.links, function(link) {
                      return _c(
                        "v-btn",
                        {
                          key: link,
                          staticClass: "my-2",
                          attrs: { text: "", rounded: "" },
                          on: {
                            click: function($event) {
                              return _vm.changeTab(link)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("literals." + link)))]
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      {
                        staticClass: "primary py-2 text-center white--text",
                        attrs: { cols: "12" }
                      },
                      [
                        _c("strong", [_vm._v("RA-MICRO Software AG")]),
                        _vm._v(
                          "\n            © " +
                            _vm._s(new Date().getFullYear()) +
                            "\n          "
                        )
                      ]
                    )
                  ],
                  2
                )
              ],
              2
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }