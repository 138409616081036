var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "flex-grow-1 flex-shrink-0", attrs: { cols: "auto" } },
        [
          _c(
            "v-card",
            {
              staticClass: "d-flex flex-column fill-height",
              attrs: { flat: "" }
            },
            [
              _c(
                "v-card-text",
                { staticClass: "flex-grow-1 overflow-y-auto" },
                [
                  _vm._l(_vm.messages, function(message, name, index) {
                    return [
                      _c(
                        "div",
                        {
                          key: index,
                          class: {
                            "d-flex flex-row-reverse":
                              message.creatorUUID === _vm.ownUUID
                          }
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "pa-3 mb-2",
                              staticStyle: {
                                height: "auto",
                                "word-break": "break-word",
                                "max-width": "85%"
                              },
                              attrs: {
                                color:
                                  message.creatorUUID === _vm.ownUUID
                                    ? "#38a2b84d"
                                    : "#bbc9cc4d",
                                label: ""
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "white-space": "pre-line" } },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$sanitize(
                                          _vm.checkForUrls(
                                            _vm.getMessageBody(message)
                                          )
                                        )
                                      )
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                [
                                  _vm.showBtnConferences(message)
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "pb-0",
                                          attrs: { cols: "8" }
                                        },
                                        [
                                          _vm.getStatusConference(message) ===
                                          "pending"
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.acceptConference(
                                                        message
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "components.meetingLineModal.accept"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.getStatusConference(message) ===
                                          "pending"
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.cancelConference(
                                                        message
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "components.meetingLineModal.reject"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.getStatusConference(message) ===
                                            "accepted" ||
                                          _vm.getStatusConference(message) ===
                                            "rejected"
                                            ? _c("p", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getStatusConference(
                                                      message
                                                    ) === "accepted"
                                                      ? _vm.$t(
                                                          "components.conferenceForm.acceptedConference"
                                                        )
                                                      : _vm.$t(
                                                          "components.conferenceForm.rejectedConference"
                                                        )
                                                  )
                                                )
                                              ])
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "pb-0 text-right align-self-end",
                                      attrs: {
                                        cols: _vm.showBtnConferences(message)
                                          ? "4"
                                          : "12"
                                      }
                                    },
                                    [
                                      _c("span", { staticClass: "caption" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.dateFormatted(
                                              message.creationServerTs ||
                                                message.date
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }