var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "max-width": "650",
        attach: _vm.getAttach,
        "hide-overlay": _vm.getAttach ? true : false
      },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t("components.conferenceForm.titleInviteUser"),
              closeModalFunction: _vm.closeModal
            }
          }),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-combobox", {
                staticClass: "mt-3",
                attrs: {
                  items: _vm.dataCombobox.users,
                  "search-input": _vm.searchUsers,
                  multiple: "",
                  "item-text": "name",
                  "item-value": "uuid",
                  label: _vm.$t("components.conferenceForm.vOfficeUsers"),
                  dense: "",
                  outlined: "",
                  "hide-details": "",
                  autofocus: ""
                },
                on: {
                  "update:searchInput": function($event) {
                    _vm.searchUsers = $event
                  },
                  "update:search-input": function($event) {
                    _vm.searchUsers = $event
                  },
                  change: _vm.handleChangeCombo
                },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function(data) {
                      return [
                        _c("div", [
                          _c("span", {
                            staticClass: "statusSpan",
                            style: { background: data.item.statusColor }
                          }),
                          _vm._v(" "),
                          _c("span", {
                            style: { marginLeft: "25px" },
                            domProps: { textContent: _vm._s(data.item.name) }
                          })
                        ])
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.selectedUser,
                  callback: function($$v) {
                    _vm.selectedUser = $$v
                  },
                  expression: "selectedUser"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "FooterModal",
            { attrs: { closeModalFunction: _vm.closeModal } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-1 callButtonUserInvite",
                  staticStyle: { "border-radius": "4px", "min-width": "45px" },
                  attrs: {
                    tile: "",
                    outlined: "",
                    color: "white",
                    icon: "",
                    disabled: _vm.disableCallBtn
                  },
                  on: {
                    click: function($event) {
                      return _vm.callUser()
                    }
                  }
                },
                [
                  _c("font-awesome-icon", {
                    style: { fontSize: "20px" },
                    attrs: { icon: ["fal", "phone"] }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-spacer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }