var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isMobile && !_vm.sidebar
        ? _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              attrs: {
                                icon: "",
                                color: _vm.wholeBarWithColors()
                                  ? "white"
                                  : "black",
                                disabled:
                                  _vm.userInCall || _vm.startingConference
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.startInstaConference()
                                }
                              }
                            },
                            on
                          ),
                          [
                            _c("font-awesome-icon", {
                              style: { fontSize: "20px" },
                              attrs: { icon: ["fal", "bolt"] }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                1472046612
              )
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t("components.conferenceForm.startInstantConference")
                  )
                )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.sidebar && !_vm.mini
        ? _c(
            "v-list",
            {
              attrs: { "max-width": "320" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                }
              }
            },
            [
              !_vm.isGuest && !_vm.mini
                ? _c(
                    "v-list-item",
                    {
                      attrs: {
                        disabled: _vm.userInCall || _vm.startingConference
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.startInstaConference()
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("font-awesome-icon", {
                            staticClass: "primary--text",
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "bolt"] }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c("v-list-item-title", _vm._g({}, on), [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t(
                                              "components.conferenceForm.startInstantConference"
                                            )
                                          ) +
                                          "\n            "
                                      )
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2885093407
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.conferenceForm.startInstantConference"
                                )
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isGuest && _vm.mini
        ? _c(
            "v-tooltip",
            {
              attrs: { right: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-list-item",
                          _vm._g(
                            {
                              staticClass: "cursorPointer",
                              attrs: {
                                disabled:
                                  _vm.userInCall || _vm.startingConference
                              }
                            },
                            on
                          ),
                          [
                            _c(
                              "v-list-item-icon",
                              {
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.startInstaConference()
                                  }
                                }
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "primary--text",
                                  style: { fontSize: "20px" },
                                  attrs: { icon: ["fal", "bolt"] }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3593184107
              )
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t("components.conferenceForm.startInstantConference")
                  )
                )
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }