var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    { attrs: { "max-width": "320" } },
    [
      !_vm.mini
        ? _c(
            "v-list-group",
            {
              attrs: {
                value: _vm.isActive,
                appendIcon: _vm.sortedGroups.length === 0 ? null : "$expand",
                "active-class": "listColor"
              },
              on: {
                click: function($event) {
                  return _vm.handlerClickConference($event)
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function() {
                      return [
                        _c(
                          "v-list-item-icon",
                          {
                            class:
                              "" +
                              (_vm.getRoute === "/newconference"
                                ? "font-weight-bold primary--text"
                                : "")
                          },
                          [
                            _vm.getRoute === "/newconference"
                              ? _c("font-awesome-icon", {
                                  staticClass: "primary--text",
                                  attrs: { icon: ["fas", "users"] }
                                })
                              : _c("font-awesome-icon", {
                                  staticClass: "primary--text",
                                  attrs: { icon: ["fal", "users"] }
                                })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-item-title",
                          {
                            staticClass: "text-truncate",
                            class:
                              "" +
                              (_vm.getRoute === "/newconference"
                                ? "font-weight-bold"
                                : ""),
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.gotoMainConference()
                              }
                            }
                          },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass: "btnAddGroup",
                                                attrs: { icon: "" },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.handleAddCircle.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  }
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c("font-awesome-icon", {
                                                staticClass: "primary--text",
                                                style: {
                                                  fontSize: "20px"
                                                },
                                                attrs: {
                                                  icon: ["fal", "plus-circle"]
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2010673363
                                )
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("components.sidebar.conferenceTT")
                                    )
                                  )
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c("span", _vm._g({}, on), [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.groups.konferenzen"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3108672799
                                )
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("components.sidebar.conferencesTT")
                                    )
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.showCustomTT
                          ? _c("CustomTT", {
                              attrs: {
                                text:
                                  _vm.$t("components.hotkeys.controlKey") +
                                  " + K",
                                position: "bottom",
                                extraclass: "ctrk"
                              }
                            })
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3489034592
              )
            },
            [
              _vm.renderParadox
                ? _c("span", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false"
                      }
                    ]
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._v(" "),
              _c("TemplateList", {
                attrs: {
                  selectTemplate: _vm.selectTemplate,
                  groups: _vm.sortedGroups,
                  section: "myTemplates"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.mini
        ? _c(
            "v-tooltip",
            {
              attrs: { right: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-list-item",
                          _vm._g({ staticClass: "cursorPointer" }, on),
                          [
                            _c(
                              "v-list-item-icon",
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "primary--text cursorPointer",
                                  attrs: { icon: ["fal", "users"] },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.handlerClickConferenceMini()
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2531696559
              )
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("components.sidebar.conferencesTT")))
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }