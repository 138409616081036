var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-content",
        {
          class:
            "px-0 py-0 " +
            (_vm.removeGeneralWrapper ? null : "contentGridWrapper") +
            " " +
            (_vm.isMobile ? "contentGridWrapperMobile" : "contentGridWrapper"),
          nativeOn: {
            scroll: function($event) {
              return _vm.handleScroll.apply(null, arguments)
            }
          }
        },
        [
          _vm.state.user.readGridMessage
            ? [
                _vm.showUserProfile
                  ? _c("UserProfle", {
                      attrs: {
                        showUserProfile: _vm.showUserProfile,
                        toggleUserProfile: _vm.toggleUserProfile,
                        enableCam: true
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                (_vm.canISeeUser && !_vm.isMobile) ||
                (_vm.canISeeUser &&
                  _vm.isMobile &&
                  (_vm.typeData !== "favorites" ||
                    _vm.state.user.userSettings.favoritesViewMobile ===
                      "grid") &&
                  _vm.typeData !== "coffee-break")
                  ? _c(
                      "v-row",
                      { staticClass: "mx-auto" },
                      [
                        (_vm.$route.path === "/guest" ||
                          _vm.$route.path === "/invites") &&
                        !_vm.isMobile
                          ? _c(
                              "v-col",
                              {
                                staticClass: "custom6cols pb-1 px-2",
                                attrs: { cols: "6" }
                              },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "cardWidth cursorPointer",
                                    style: { border: "1px solid #efefef" },
                                    on: { click: _vm.clickOnAddCard }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          height: "180px",
                                          display: "table",
                                          width: "100%"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "divAdd" },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "buttonAdd",
                                              attrs: { icon: ["fal", "plus"] }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              { staticClass: "mb-0 mt-2" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "generics.addUserCard"
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.$route.path === "/users" &&
                        _vm.amIAdmin &&
                        !_vm.showSidebar &&
                        !_vm.isMobile
                          ? _c(
                              "v-col",
                              {
                                staticClass: "custom6cols pb-1 px-2",
                                attrs: { cols: "6" }
                              },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "cardWidth cursorPointer",
                                    style: { border: "1px solid #efefef" },
                                    on: { click: _vm.openUserInvite }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          height: "180px",
                                          display: "table",
                                          width: "100%"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "divAdd" },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "buttonAdd",
                                              attrs: {
                                                icon: ["fal", "plus-circle"]
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              { staticClass: "mb-0 mt-2" },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "components.footernav.inviteUser"
                                                      )
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "mt-3",
                                                attrs: {
                                                  color: "primary",
                                                  outlined: "",
                                                  dense: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    $event.stopPropagation()
                                                    return _vm.gotoInviteUserList()
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "components.inviteGuestPanel.inviteList"
                                                      )
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.slicedPersons, function(person, index) {
                          return _c(
                            "v-col",
                            {
                              key: index,
                              staticClass: "custom6cols pb-1 px-2",
                              attrs: { cols: "6" }
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "cardWidth",
                                  style: _vm.getBorderByStatusFull(person),
                                  on: {
                                    mouseover: function($event) {
                                      return _vm.selectIndex(index)
                                    },
                                    mouseout: function($event) {
                                      _vm.ItemIndex = null
                                    },
                                    contextmenu: function($event) {
                                      return _vm.openMenu($event, person.uuid)
                                    },
                                    click: function($event) {
                                      return _vm.handleClicks(
                                        $event,
                                        person,
                                        "flipCard-" + index
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "div",
                                                  _vm._g({}, on),
                                                  [
                                                    _c(
                                                      "v-img",
                                                      {
                                                        staticClass:
                                                          "white--text align-end",
                                                        attrs: {
                                                          src: _vm.getAvatarByType(
                                                            person.uuid
                                                          ),
                                                          height: "180px"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "gradientBg"
                                                          },
                                                          [
                                                            _c(
                                                              "v-card-subtitle",
                                                              {
                                                                staticClass:
                                                                  "titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate",
                                                                staticStyle: {
                                                                  color:
                                                                    "#fff !important"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        " +
                                                                    _vm._s(
                                                                      _vm.titelPerson(
                                                                        person
                                                                      )
                                                                    ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      _vm.namePerson(
                                                                        person
                                                                      )
                                                                    ) +
                                                                    "\n                      "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            person.user &&
                                                            _vm.hasRepresentative(
                                                              person.uuid
                                                            ) &&
                                                            person.user
                                                              .representative &&
                                                            _vm.state.group &&
                                                            person.user
                                                              .representative
                                                              .value in
                                                              _vm.state.group
                                                              ? _c(
                                                                  "v-card-subtitle",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex titleCard colorWhite py-0 px-0 text-truncate justify-space-between",
                                                                    style: _vm.getBorderByStatusFull(
                                                                      _vm.getPersonByUuid(
                                                                        person
                                                                          .user
                                                                          .representative
                                                                          .value
                                                                      )
                                                                    ),
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        $event.preventDefault()
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "representative-subtitle text-truncate"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "CallIcon",
                                                                          {
                                                                            staticClass:
                                                                              "mr-1",
                                                                            attrs: {
                                                                              person: _vm.getPersonByUuid(
                                                                                person
                                                                                  .user
                                                                                  .representative
                                                                                  .value
                                                                              ),
                                                                              isRegularGrid: true
                                                                            }
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "colorWhite"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                            " +
                                                                                _vm._s(
                                                                                  _vm.getRepresentativeName(
                                                                                    person
                                                                                  )
                                                                                ) +
                                                                                "\n                          "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "ButtonsFrontCard",
                                                                      {
                                                                        staticClass:
                                                                          "pt-1",
                                                                        staticStyle: {
                                                                          position:
                                                                            "inherit !important"
                                                                        },
                                                                        attrs: {
                                                                          person: person,
                                                                          uuid:
                                                                            person.uuid,
                                                                          sectionName:
                                                                            "members",
                                                                          flipCard:
                                                                            _vm.flipCard,
                                                                          index: index,
                                                                          typeData:
                                                                            _vm.typeData
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            !_vm.hasRepresentative(
                                                              person.uuid
                                                            ) ||
                                                            !(
                                                              person.user
                                                                .representative &&
                                                              _vm.state.group &&
                                                              person.user
                                                                .representative
                                                                .value in
                                                                _vm.state.group
                                                            )
                                                              ? _c(
                                                                  "ButtonsFrontCard",
                                                                  {
                                                                    attrs: {
                                                                      person: person,
                                                                      uuid:
                                                                        person.uuid,
                                                                      sectionName:
                                                                        "members",
                                                                      flipCard:
                                                                        _vm.flipCard,
                                                                      index: index,
                                                                      typeData:
                                                                        _vm.typeData
                                                                    }
                                                                  }
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "ButtonsCardWrapper",
                                                          {
                                                            attrs: {
                                                              person: person,
                                                              isRegularGrid: true,
                                                              showMultiUserCallIcon: true
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("UserInfoTooltip", {
                                        attrs: { person: person }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "infinite-loading",
                          {
                            attrs: { distance: 5 },
                            on: { infinite: _vm.infiniteHandler }
                          },
                          [
                            _c("div", {
                              attrs: { slot: "no-results" },
                              slot: "no-results"
                            }),
                            _vm._v(" "),
                            _c("div", {
                              attrs: { slot: "no-more" },
                              slot: "no-more"
                            })
                          ]
                        )
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.canISeeUser &&
                _vm.isMobile &&
                _vm.typeData == "favorites" &&
                (_vm.state.user.userSettings.favoritesViewMobile === "swipe" ||
                  !_vm.state.user.userSettings.favoritesViewMobile)
                  ? _c(
                      "div",
                      [
                        _c(
                          "swiper",
                          {
                            ref: "swiperComponent",
                            attrs: { options: _vm.swiperOptions }
                          },
                          _vm._l(_vm.slicedPersons, function(person, index) {
                            return _c(
                              "swiper-slide",
                              { key: index },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "cardWidth",
                                    style: _vm.getBorderByStatusFull(person),
                                    attrs: {
                                      height: "90%",
                                      width: "100%",
                                      "max-width": "95%"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleClicks(
                                          $event,
                                          person,
                                          "flipCard-" + index
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "div",
                                                    _vm._g({}, on),
                                                    [
                                                      _c(
                                                        "v-img",
                                                        {
                                                          staticClass:
                                                            "white--text align-end",
                                                          attrs: {
                                                            src: _vm.getAvatarByType(
                                                              person.uuid
                                                            ),
                                                            height: "100%"
                                                          }
                                                        },
                                                        [
                                                          !_vm.disableCallBtn(
                                                            person
                                                          )
                                                            ? _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  staticClass:
                                                                    "buttonCallSwiper primary--text",
                                                                  style: {
                                                                    fontSize:
                                                                      "30px"
                                                                  },
                                                                  attrs: {
                                                                    icon: [
                                                                      "fas",
                                                                      "phone"
                                                                    ]
                                                                  }
                                                                }
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "gradientBg"
                                                            },
                                                            [
                                                              _c(
                                                                "v-card-subtitle",
                                                                {
                                                                  staticClass:
                                                                    "titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate",
                                                                  staticStyle: {
                                                                    color:
                                                                      "#fff !important"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.titelPerson(
                                                                          person
                                                                        )
                                                                      ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        _vm.namePerson(
                                                                          person
                                                                        )
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              person.user &&
                                                              _vm.hasRepresentative(
                                                                person.uuid
                                                              ) &&
                                                              person.user
                                                                .representative &&
                                                              _vm.state.group &&
                                                              person.user
                                                                .representative
                                                                .value in
                                                                _vm.state.group
                                                                ? _c(
                                                                    "v-card-subtitle",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex titleCard colorWhite py-0 px-0 text-truncate justify-space-between",
                                                                      style: _vm.getBorderByStatusFull(
                                                                        _vm.getPersonByUuid(
                                                                          person
                                                                            .user
                                                                            .representative
                                                                            .value
                                                                        )
                                                                      ),
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          $event.preventDefault()
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "representative-subtitle text-truncate"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "CallIcon",
                                                                            {
                                                                              staticClass:
                                                                                "mr-1",
                                                                              attrs: {
                                                                                person: _vm.getPersonByUuid(
                                                                                  person
                                                                                    .user
                                                                                    .representative
                                                                                    .value
                                                                                ),
                                                                                isRegularGrid: true,
                                                                                ownUUID:
                                                                                  _vm.ownUUID
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "colorWhite"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                              " +
                                                                                  _vm._s(
                                                                                    _vm.getRepresentativeName(
                                                                                      person
                                                                                    )
                                                                                  ) +
                                                                                  "\n                            "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "ButtonsFrontCard",
                                                                        {
                                                                          staticClass:
                                                                            "pt-1",
                                                                          staticStyle: {
                                                                            position:
                                                                              "inherit !important"
                                                                          },
                                                                          attrs: {
                                                                            person: person,
                                                                            uuid:
                                                                              person.uuid,
                                                                            sectionName:
                                                                              "members",
                                                                            flipCard:
                                                                              _vm.flipCard,
                                                                            index: index,
                                                                            typeData:
                                                                              _vm.typeData
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              !_vm.hasRepresentative(
                                                                person.uuid
                                                              ) ||
                                                              !(
                                                                person.user
                                                                  .representative &&
                                                                _vm.state
                                                                  .group &&
                                                                person.user
                                                                  .representative
                                                                  .value in
                                                                  _vm.state
                                                                    .group
                                                              )
                                                                ? _c(
                                                                    "ButtonsFrontCard",
                                                                    {
                                                                      attrs: {
                                                                        person: person,
                                                                        uuid:
                                                                          person.uuid,
                                                                        sectionName:
                                                                          "members",
                                                                        flipCard:
                                                                          _vm.flipCard,
                                                                        index: index,
                                                                        typeData:
                                                                          _vm.typeData
                                                                      }
                                                                    }
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "ButtonsCardWrapper",
                                                            {
                                                              attrs: {
                                                                person: person,
                                                                isRegularGrid: true,
                                                                showMultiUserCallIcon: true
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("UserInfoTooltip", {
                                          attrs: { person: person }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.amIGuest
                  ? [
                      _c("RemoveFromFavoriteModal", {
                        attrs: {
                          person: _vm.deleteFavorite.person,
                          showModal: _vm.deleteFavorite.show,
                          closeModal: _vm.closeDeleteFavoriteModal
                        }
                      })
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.changeUserFunction.show
                  ? [
                      _c("ChangeFunctionModal", {
                        attrs: {
                          modalData: _vm.changeUserFunction,
                          cancelChangeFunction: _vm.cancelChangeFunction
                        }
                      })
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.showInvitationModal
                  ? [
                      _c("SendInvitationModal", {
                        attrs: {
                          closeShowInviteInfo: _vm.closeShowInviteInfo,
                          showInviteInfo: _vm.showInvitationModal
                        }
                      })
                    ]
                  : _vm._e()
              ]
            : [_c("GridUserMessage")]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }