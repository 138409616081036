import { render, staticRenderFns } from "./footernav.vue?vue&type=template&id=556a5b3a&scoped=true"
import script from "./footernav.vue?vue&type=script&lang=js"
export * from "./footernav.vue?vue&type=script&lang=js"
import style0 from "./footernav.vue?vue&type=style&index=0&id=556a5b3a&scoped=true&lang=scss"
import style1 from "./footernav.vue?vue&type=style&index=1&id=556a5b3a&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "556a5b3a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/voffice-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('556a5b3a')) {
      api.createRecord('556a5b3a', component.options)
    } else {
      api.reload('556a5b3a', component.options)
    }
    module.hot.accept("./footernav.vue?vue&type=template&id=556a5b3a&scoped=true", function () {
      api.rerender('556a5b3a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "websrc/components/mobile/footernav.vue"
export default component.exports