var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "950", fullscreen: _vm.isMobile },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModalFunction.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t("components.userSettings.userSettings"),
              closeModalFunction: _vm.closeModalFunction
            }
          }),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mx-3 mt-3" },
            [
              _c(
                "v-col",
                {
                  staticClass: "col-12 py-0 px-0",
                  class: { h78vh: _vm.isMobile }
                },
                [
                  _c(
                    "div",
                    {
                      class: {
                        rowSettingsMobile: _vm.isMobile,
                        rowSettings: !_vm.isMobile
                      }
                    },
                    [
                      _c(
                        "v-expansion-panels",
                        {
                          attrs: {
                            accordion: false,
                            popout: false,
                            inset: false,
                            multiple: false,
                            focusable: false,
                            disabled: false,
                            readonly: false,
                            flat: false,
                            hover: false,
                            tile: false
                          },
                          model: {
                            value: _vm.panel,
                            callback: function($$v) {
                              _vm.panel = $$v
                            },
                            expression: "panel"
                          }
                        },
                        _vm._l(_vm.getExpansionPanels, function(item, i) {
                          return _c(
                            "v-expansion-panel",
                            {
                              key: i,
                              style: [
                                (item.index === 11 &&
                                  !(
                                    _vm.state.nerd &&
                                    _vm.canShowSpeechliveByDomain
                                  )) ||
                                (item.index === 10 && !_vm.state.nerd) ||
                                (item.index == 17 &&
                                  !_vm.state.user.enabledBeacon)
                                  ? { display: "none" }
                                  : { display: "block" }
                              ]
                            },
                            [
                              _c(
                                "v-expansion-panel-header",
                                { staticClass: "px-3" },
                                [
                                  _c(
                                    "v-list-item-title",
                                    { staticClass: "subtitle-2" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(item.name) +
                                          "\n                  "
                                      ),
                                      item.index == 15 ||
                                      item.index == 16 ||
                                      item.index == 5 ||
                                      item.index == 18
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                top: "",
                                                "content-class": "videoButtonTT"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "font-awesome-icon",
                                                          _vm._g(
                                                            {
                                                              style: {
                                                                fontSize:
                                                                  "17px",
                                                                position:
                                                                  "relative",
                                                                top: "2px"
                                                              },
                                                              attrs: {
                                                                color:
                                                                  "#02889d",
                                                                icon: [
                                                                  "fal",
                                                                  "info-circle"
                                                                ]
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.stopPropagation()
                                                                  $event.preventDefault()
                                                                  return _vm.openInfoModalMobile(
                                                                    item.index
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            on
                                                          )
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      item.index == 16
                                                        ? _vm.$t(
                                                            "components.userSettings.screensaverTT"
                                                          )
                                                        : item.index == 5
                                                        ? _vm.$t(
                                                            "components.userSettings.startviewTT"
                                                          )
                                                        : item.index == 15
                                                        ? _vm.$t(
                                                            "components.userSettings.waitingroomTT"
                                                          )
                                                        : item.index == 18
                                                        ? _vm.$t(
                                                            "components.userSettings.calendarInfoTT"
                                                          )
                                                        : ""
                                                    ) +
                                                    "\n                    "
                                                )
                                              ])
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("br")
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              item.index == 0
                                ? _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-list-item",
                                                { staticClass: "py-0 px-0" },
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass:
                                                        "py-1 pl-3 my-0 px-2"
                                                    },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "v-switch",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "pb-1 mt-0",
                                                                          attrs: {
                                                                            "hide-details":
                                                                              "",
                                                                            label: _vm.$t(
                                                                              "components.userSettings.showHidePlanerLine"
                                                                            )
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.showHideSection()
                                                                            }
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.showVideoCallPlanner,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.showVideoCallPlanner = $$v
                                                                            },
                                                                            expression:
                                                                              "showVideoCallPlanner"
                                                                          }
                                                                        },
                                                                        on
                                                                      )
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              "\n                            " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "components.userSettings.showPlannerTT"
                                                                  )
                                                                ) +
                                                                "\n                          "
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-list-item",
                                                { staticClass: "py-0 px-0" },
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass:
                                                        "py-1 pl-3 my-0 px-2"
                                                    },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "v-switch",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "pb-1 mt-0",
                                                                          attrs: {
                                                                            "hide-details":
                                                                              "",
                                                                            label: _vm.$t(
                                                                              "components.userSettings.showStatusNumber"
                                                                            )
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.changeStatusNumber()
                                                                            }
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.showStatusNumber,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.showStatusNumber = $$v
                                                                            },
                                                                            expression:
                                                                              "showStatusNumber"
                                                                          }
                                                                        },
                                                                        on
                                                                      )
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              "\n                            " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "components.userSettings.showStatusNumberTT"
                                                                  )
                                                                ) +
                                                                "\n                          "
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-list-item",
                                                { staticClass: "py-0 px-0" },
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass:
                                                        "py-1 my-0 pl-3 px-2"
                                                    },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "v-switch",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "pb-1 mt-0",
                                                                          attrs: {
                                                                            "hide-details":
                                                                              "",
                                                                            label: _vm.$t(
                                                                              "components.userSettings.displayStatusColor"
                                                                            )
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.changeColor(
                                                                                $event,
                                                                                "whole"
                                                                              )
                                                                            }
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .state
                                                                                .user
                                                                                .colorsettings
                                                                                .wholeBar,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .state
                                                                                  .user
                                                                                  .colorsettings,
                                                                                "wholeBar",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "state.user.colorsettings.wholeBar"
                                                                          }
                                                                        },
                                                                        on
                                                                      )
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              "\n                            " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "components.userSettings.displayStatusColorTT"
                                                                  )
                                                                ) +
                                                                "\n                          "
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.index == 1
                                ? _c("v-expansion-panel-content", [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-list-item",
                                          { staticClass: "py-0 switchWrapper" },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              { staticClass: "py-1 my-0 px-2" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-center",
                                                    class: _vm.isMobile
                                                      ? "flex-column"
                                                      : ""
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        class: !_vm.isMobile
                                                          ? "mw-445"
                                                          : ""
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "components.userSettings.2faPin"
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "ml-2",
                                                        class: _vm.isMobile
                                                          ? "mt-2 mb-2"
                                                          : "",
                                                        attrs: {
                                                          color: "primary"
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.toggle2faSettings
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "generics.display"
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-list-item",
                                          { staticClass: "py-0 switchWrapper" },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              { staticClass: "py-1 my-0 px-2" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-center",
                                                    class: _vm.isMobile
                                                      ? "flex-column"
                                                      : ""
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        class: !_vm.isMobile
                                                          ? "mw-445"
                                                          : ""
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "components.2faModal.twoFaModalNumberTitle"
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "ml-2",
                                                        class: _vm.isMobile
                                                          ? "mt-2"
                                                          : "",
                                                        attrs: {
                                                          color: "primary"
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.toggle2faActivationModal
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "generics.display"
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              item.index == 2
                                ? _c("v-expansion-panel-content", [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-list-item",
                                          { staticClass: "py-0 switchWrapper" },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              { staticClass: "py-1 my-0 px-2" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-center",
                                                    class: _vm.isMobile
                                                      ? "flex-column"
                                                      : ""
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.holidays.setYourHolidays"
                                                            )
                                                          )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "ml-2",
                                                        class: _vm.isMobile
                                                          ? "mt-2"
                                                          : "",
                                                        attrs: {
                                                          color: "primary"
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.toggleShowHolidaysModal
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "generics.manage"
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              item.index == 3
                                ? _c("v-expansion-panel-content", [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-list-item",
                                          {
                                            staticClass:
                                              "pl-0 py-0 switchWrapper"
                                          },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              { staticClass: "py-1 my-0 px-2" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-center"
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "ml-2",
                                                        attrs: {
                                                          color: "primary"
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.showProtocol
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "generics.display"
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              item.index == 4
                                ? _c("v-expansion-panel-content", [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-list-item",
                                          { staticClass: "py-0 switchWrapper" },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              {
                                                staticClass:
                                                  "pl-0 py-1 my-0 px-2"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-center"
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "ml-2",
                                                        attrs: {
                                                          color: "primary"
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.showDialUpTime
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "generics.display"
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              item.index == 5
                                ? _c("v-expansion-panel-content", [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-list-item",
                                          { staticClass: "py-0 switchWrapper" },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              { staticClass: "py-1 my-0 px-2" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-center",
                                                    class: _vm.isMobile
                                                      ? "flex-column"
                                                      : ""
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.userSettings.startView"
                                                            )
                                                          )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "ml-2",
                                                        class: _vm.isMobile
                                                          ? "mt-2 mb-2"
                                                          : "",
                                                        attrs: {
                                                          color: "primary"
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.showStartView
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "generics.display"
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      { staticClass: "mx-5" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.userSettings.selectedSetting"
                                                            )
                                                          ) +
                                                            "\n                          " +
                                                            _vm._s(
                                                              _vm.getDefaultPageText()
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              item.index == 7
                                ? _c("v-expansion-panel-content", [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-list-item",
                                          { staticClass: "py-0 switchWrapper" },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              { staticClass: "py-1 my-0 px-2" },
                                              [
                                                _c(
                                                  "v-radio-group",
                                                  {
                                                    staticClass: "ml-2 mt-0",
                                                    attrs: {
                                                      row: "",
                                                      mandatory: false
                                                    },
                                                    on: {
                                                      change: _vm.changeLanguage
                                                    },
                                                    model: {
                                                      value: _vm.radiosLanguage,
                                                      callback: function($$v) {
                                                        _vm.radiosLanguage = $$v
                                                      },
                                                      expression:
                                                        "radiosLanguage"
                                                    }
                                                  },
                                                  [
                                                    _c("v-radio", {
                                                      attrs: { value: "DE" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "label",
                                                            fn: function() {
                                                              return [
                                                                _c(
                                                                  "v-list-item",
                                                                  [
                                                                    _c(
                                                                      "v-list-item-avatar",
                                                                      {
                                                                        attrs: {
                                                                          tile:
                                                                            "",
                                                                          width:
                                                                            "23",
                                                                          height:
                                                                            "17",
                                                                          "min-width":
                                                                            "23",
                                                                          "max-height":
                                                                            "17",
                                                                          "max-width":
                                                                            "23"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-img",
                                                                          {
                                                                            attrs: {
                                                                              src:
                                                                                "img/flags/DE.png"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      {
                                                                        staticClass:
                                                                          "notranslate primary--text"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "DE"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            },
                                                            proxy: true
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    }),
                                                    _vm._v(" "),
                                                    _c("v-radio", {
                                                      attrs: { value: "EN" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "label",
                                                            fn: function() {
                                                              return [
                                                                _c(
                                                                  "v-list-item",
                                                                  [
                                                                    _c(
                                                                      "v-list-item-avatar",
                                                                      {
                                                                        attrs: {
                                                                          tile:
                                                                            "",
                                                                          width:
                                                                            "23",
                                                                          height:
                                                                            "17",
                                                                          "min-width":
                                                                            "23",
                                                                          "max-height":
                                                                            "17",
                                                                          "max-width":
                                                                            "23"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-img",
                                                                          {
                                                                            attrs: {
                                                                              src:
                                                                                "img/flags/EN.png"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      {
                                                                        staticClass:
                                                                          "notranslate primary--text"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "EN"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            },
                                                            proxy: true
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    }),
                                                    _vm._v(" "),
                                                    _c("v-radio", {
                                                      attrs: { value: "ES" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "label",
                                                            fn: function() {
                                                              return [
                                                                _c(
                                                                  "v-list-item",
                                                                  [
                                                                    _c(
                                                                      "v-list-item-avatar",
                                                                      {
                                                                        attrs: {
                                                                          tile:
                                                                            "",
                                                                          width:
                                                                            "23",
                                                                          height:
                                                                            "17",
                                                                          "min-width":
                                                                            "23",
                                                                          "max-height":
                                                                            "17",
                                                                          "max-width":
                                                                            "23"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-img",
                                                                          {
                                                                            attrs: {
                                                                              src:
                                                                                "img/flags/ES.png"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      {
                                                                        staticClass:
                                                                          "notranslate primary--text"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "ES"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            },
                                                            proxy: true
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              item.index == 8
                                ? _c("v-expansion-panel-content", [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-list-item",
                                          { staticClass: "py-0 switchWrapper" },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              { staticClass: "py-1 my-0 px-2" },
                                              [
                                                _c(
                                                  "div",
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "components.userSettings.sortFavoritesList"
                                                          )
                                                        )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-radio-group",
                                                      {
                                                        staticClass:
                                                          "ml-2 mt-0",
                                                        attrs: {
                                                          column: _vm.isMobile,
                                                          row: !_vm.isMobile,
                                                          "hide-details": "",
                                                          mandatory: false
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.changefavoritesSort
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.favoritesSort,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.favoritesSort = $$v
                                                          },
                                                          expression:
                                                            "favoritesSort"
                                                        }
                                                      },
                                                      [
                                                        _c("v-radio", {
                                                          attrs: {
                                                            value: "lastName"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "label",
                                                                fn: function() {
                                                                  return [
                                                                    _c(
                                                                      "v-list-item",
                                                                      [
                                                                        _c(
                                                                          "v-list-item-title",
                                                                          {
                                                                            staticClass:
                                                                              "notranslate primary--text"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "components.userSettings.sortByLastName"
                                                                                )
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                },
                                                                proxy: true
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        }),
                                                        _vm._v(" "),
                                                        _c("v-radio", {
                                                          attrs: {
                                                            value:
                                                              "alphabetical"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "label",
                                                                fn: function() {
                                                                  return [
                                                                    _c(
                                                                      "v-list-item",
                                                                      [
                                                                        _c(
                                                                          "v-list-item-title",
                                                                          {
                                                                            staticClass:
                                                                              "notranslate primary--text"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "components.userSettings.sortByFirstName"
                                                                                )
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                },
                                                                proxy: true
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        }),
                                                        _vm._v(" "),
                                                        _c("v-radio", {
                                                          attrs: {
                                                            value: "statusColor"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "label",
                                                                fn: function() {
                                                                  return [
                                                                    _c(
                                                                      "v-list-item",
                                                                      [
                                                                        _c(
                                                                          "v-list-item-title",
                                                                          {
                                                                            staticClass:
                                                                              "notranslate primary--text"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "components.userSettings.sortByStatusColor"
                                                                                )
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                },
                                                                proxy: true
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "components.userSettings.favoritesViewOnMobile"
                                                          )
                                                        )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-radio-group",
                                                      {
                                                        staticClass:
                                                          "ml-2 mt-0",
                                                        attrs: {
                                                          column: _vm.isMobile,
                                                          row: !_vm.isMobile,
                                                          "hide-details": "",
                                                          mandatory: false
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.changefavoritesViewMobile
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.favoritesViewMobile,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.favoritesViewMobile = $$v
                                                          },
                                                          expression:
                                                            "favoritesViewMobile"
                                                        }
                                                      },
                                                      [
                                                        _c("v-radio", {
                                                          attrs: {
                                                            value: "grid"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "label",
                                                                fn: function() {
                                                                  return [
                                                                    _c(
                                                                      "v-list-item",
                                                                      [
                                                                        _c(
                                                                          "v-list-item-title",
                                                                          {
                                                                            staticClass:
                                                                              "notranslate primary--text"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "generics.grid"
                                                                                )
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                },
                                                                proxy: true
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        }),
                                                        _vm._v(" "),
                                                        _c("v-radio", {
                                                          attrs: {
                                                            value: "swipe"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "label",
                                                                fn: function() {
                                                                  return [
                                                                    _c(
                                                                      "v-list-item",
                                                                      [
                                                                        _c(
                                                                          "v-list-item-title",
                                                                          {
                                                                            staticClass:
                                                                              "notranslate primary--text"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "generics.swipe"
                                                                                )
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                },
                                                                proxy: true
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              item.index == 9
                                ? _c("v-expansion-panel-content", [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-list-item",
                                          { staticClass: "py-0 switchWrapper" },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              { staticClass: "py-1 my-0 px-2" },
                                              [
                                                _c(
                                                  "v-radio-group",
                                                  {
                                                    staticClass: "ml-2 mt-0",
                                                    attrs: {
                                                      row: "",
                                                      mandatory: false
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.changeUsersSort
                                                    },
                                                    model: {
                                                      value: _vm.usersSort,
                                                      callback: function($$v) {
                                                        _vm.usersSort = $$v
                                                      },
                                                      expression: "usersSort"
                                                    }
                                                  },
                                                  [
                                                    _c("v-radio", {
                                                      attrs: {
                                                        value: "lastName"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "label",
                                                            fn: function() {
                                                              return [
                                                                _c(
                                                                  "v-list-item",
                                                                  [
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      {
                                                                        staticClass:
                                                                          "notranslate primary--text"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "components.userSettings.sortByLastName"
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            },
                                                            proxy: true
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    }),
                                                    _vm._v(" "),
                                                    _c("v-radio", {
                                                      attrs: {
                                                        value: "alphabetical"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "label",
                                                            fn: function() {
                                                              return [
                                                                _c(
                                                                  "v-list-item",
                                                                  [
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      {
                                                                        staticClass:
                                                                          "notranslate primary--text"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "components.userSettings.sortByFirstName"
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            },
                                                            proxy: true
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    }),
                                                    _vm._v(" "),
                                                    _c("v-radio", {
                                                      attrs: {
                                                        value: "statusColor"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "label",
                                                            fn: function() {
                                                              return [
                                                                _c(
                                                                  "v-list-item",
                                                                  [
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      {
                                                                        staticClass:
                                                                          "notranslate primary--text"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "components.userSettings.sortByStatusColor"
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            },
                                                            proxy: true
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              item.index == 11
                                ? _c("v-expansion-panel-content", [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-list-item",
                                          { staticClass: "py-0 switchWrapper" },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              { staticClass: "py-1 my-0 px-2" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-center",
                                                    class: _vm.isMobile
                                                      ? "flex-column"
                                                      : ""
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.userSettings.speechLive"
                                                            )
                                                          )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "ml-2",
                                                        class: _vm.isMobile
                                                          ? "mt-2"
                                                          : "",
                                                        attrs: {
                                                          color: "primary"
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.toggleSpeechlive
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "generics.manage"
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              item.index == 13
                                ? _c("v-expansion-panel-content", [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-list-item",
                                          { staticClass: "py-0 switchWrapper" },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              { staticClass: "py-1 my-0 px-2" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-center",
                                                    class: _vm.isMobile
                                                      ? "flex-column"
                                                      : ""
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "ml-2",
                                                                        class: _vm.isMobile
                                                                          ? "mb-2"
                                                                          : "",
                                                                        attrs: {
                                                                          color:
                                                                            "primary"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.goToStats(
                                                                              "stats"
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "components.stats.status"
                                                                            )
                                                                          )
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "components.userSettings.sortedUserStatusTT"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "ml-2",
                                                                        attrs: {
                                                                          color:
                                                                            "primary"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.goToStats(
                                                                              "conference"
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "components.stats.conferenceStats"
                                                                            )
                                                                          )
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "components.userSettings.conferenceStatsTT"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              item.index == 14
                                ? _c("v-expansion-panel-content", [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-list-item",
                                          {
                                            staticClass:
                                              "pl-0 py-0 switchWrapper"
                                          },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              {
                                                staticClass:
                                                  "pl-0 py-1 my-0 px-2"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-center"
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          top: "",
                                                          "max-width": "300"
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "ml-2",
                                                                        attrs: {
                                                                          color:
                                                                            "primary"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.setShowModalSendInvitation(
                                                                              _vm
                                                                                .state
                                                                                .ownUUID,
                                                                              _vm
                                                                                .state
                                                                                .user
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "generics.send"
                                                                            )
                                                                          )
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "components.userSettings.sendEmailTT"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              item.index == 15
                                ? _c("v-expansion-panel-content", [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-list-item",
                                          { staticClass: "py-0 switchWrapper" },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              { staticClass: "py-1 my-0 px-2" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-center",
                                                    attrs: {
                                                      id:
                                                        "combobox_waitingRoomAssistants"
                                                    }
                                                  },
                                                  [
                                                    _c("v-combobox", {
                                                      staticClass: "mb-5",
                                                      attrs: {
                                                        outlined: "",
                                                        items:
                                                          _vm.dataComboboxAllUsers,
                                                        "search-input":
                                                          _vm.searchUsers,
                                                        select: "",
                                                        label: _vm.$t(
                                                          "components.waitingRoom.waitingroomAssistants"
                                                        ),
                                                        multiple: "",
                                                        "hide-details": "",
                                                        dense: "",
                                                        "item-value": "value",
                                                        "item-text": "text",
                                                        "return-object": true,
                                                        attach:
                                                          "#combobox_waitingRoomAssistants"
                                                      },
                                                      on: {
                                                        "update:searchInput": function(
                                                          $event
                                                        ) {
                                                          _vm.searchUsers = $event
                                                        },
                                                        "update:search-input": function(
                                                          $event
                                                        ) {
                                                          _vm.searchUsers = $event
                                                        },
                                                        change: function(
                                                          $event
                                                        ) {
                                                          _vm.searchUsers = ""
                                                          _vm.saveWaitingRoomAssistants()
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.waitingRoomAssistants,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.waitingRoomAssistants = $$v
                                                        },
                                                        expression:
                                                          "waitingRoomAssistants"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              item.index == 16
                                ? _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c(
                                        "v-list-item",
                                        { staticClass: "py-0 switchWrapper" },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            { staticClass: "py-1 my-0 px-2" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-center",
                                                  attrs: {
                                                    id: "select_screenSaver"
                                                  }
                                                },
                                                [
                                                  _c("v-select", {
                                                    staticClass: "mb-5",
                                                    attrs: {
                                                      items:
                                                        _vm.dataComboBoxScreensaver,
                                                      label: _vm.$t(
                                                        "components.userSettings.screenSaverLabel"
                                                      ),
                                                      "hide-details": "",
                                                      outlined: "",
                                                      dense: "",
                                                      "item-value": "value",
                                                      "item-text": "text",
                                                      "return-object": "",
                                                      attach:
                                                        "#select_screenSaver"
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.saveScreenSaver()
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.screensaverMinutes,
                                                      callback: function($$v) {
                                                        _vm.screensaverMinutes = $$v
                                                      },
                                                      expression:
                                                        "screensaverMinutes"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.index == 17
                                ? _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c(
                                        "v-list-item",
                                        { staticClass: "py-0 switchWrapper" },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            { staticClass: "py-1 my-0 px-2" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-center"
                                                },
                                                [
                                                  _c("v-checkbox", {
                                                    staticClass: "mt-0",
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "generics.active"
                                                      ),
                                                      "hide-details": ""
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.changeBeaconSetting()
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.enabledBeaconUserSetting,
                                                      callback: function($$v) {
                                                        _vm.enabledBeaconUserSetting = $$v
                                                      },
                                                      expression:
                                                        "enabledBeaconUserSetting"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        { staticClass: "pt-2 switchWrapper" },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            { staticClass: "py-1 my-0 px-2" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "align-center" },
                                                [
                                                  _c(
                                                    "v-radio-group",
                                                    {
                                                      staticClass: "ml-0 mt-2",
                                                      attrs: { row: "" },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.changeBeaconCall()
                                                        }
                                                      },
                                                      model: {
                                                        value: _vm.beaconCall,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.beaconCall = $$v
                                                        },
                                                        expression: "beaconCall"
                                                      }
                                                    },
                                                    [
                                                      _c("v-radio", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "components.userSettings.directCall"
                                                          ),
                                                          value: "directCall"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("v-radio", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "components.userSettings.waitingCall"
                                                          ),
                                                          value: "waitingCall"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        { staticClass: "pt-0 switchWrapper" },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            { staticClass: "py-1 my-0 px-2" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "align-center" },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "components.userSettings.beaconInCall"
                                                          )
                                                        )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-radio-group",
                                                    {
                                                      staticClass: "mt-2",
                                                      attrs: { row: "" },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.changeBeaconInCall()
                                                        }
                                                      },
                                                      model: {
                                                        value: _vm.beaconInCall,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.beaconInCall = $$v
                                                        },
                                                        expression:
                                                          "beaconInCall"
                                                      }
                                                    },
                                                    [
                                                      _c("v-radio", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "components.userSettings.beaconInCallActive"
                                                          ),
                                                          value: "active"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("v-radio", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "components.userSettings.beaconInCallInactive"
                                                          ),
                                                          value: "inactive"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.index == 18
                                ? _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c(
                                        "v-list-item",
                                        { staticClass: "py-0 switchWrapper" },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            { staticClass: "py-1 my-0 px-2" },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "pl-2" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pl-0 pt-0",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "components.userSettings.plannerExport"
                                                          )
                                                        ) +
                                                          "\n                        "
                                                      ),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            top: "",
                                                            "max-width": "450"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "font-awesome-icon",
                                                                      _vm._g(
                                                                        {
                                                                          style: {
                                                                            fontSize:
                                                                              "17px",
                                                                            position:
                                                                              "relative",
                                                                            top:
                                                                              "2px"
                                                                          },
                                                                          attrs: {
                                                                            color:
                                                                              "#02889d",
                                                                            icon: [
                                                                              "fal",
                                                                              "info-circle"
                                                                            ]
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              _vm.isMobile
                                                                                ? _vm.openInfoModal(
                                                                                    _vm.$t(
                                                                                      "generics.info"
                                                                                    ),
                                                                                    _vm.$t(
                                                                                      "components.userSettings.plannerExportTT"
                                                                                    )
                                                                                  )
                                                                                : null
                                                                            }
                                                                          }
                                                                        },
                                                                        on
                                                                      )
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "components.userSettings.plannerExportTT"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      class: _vm.isMobile
                                                        ? "py-2 pl-2"
                                                        : "py-0 px-0",
                                                      attrs: {
                                                        cols: _vm.isMobile
                                                          ? "12"
                                                          : "10"
                                                      }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        ref:
                                                          "calendarExportURLRef",
                                                        refInFor: true,
                                                        attrs: {
                                                          autocomplete: "off",
                                                          value:
                                                            _vm.calendarExportURL,
                                                          "hide-details": "",
                                                          label: _vm.$t(
                                                            "components.userSettings.calendarExport"
                                                          ),
                                                          outlined: "",
                                                          dense: "",
                                                          readonly: "readonly"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0 px-0",
                                                      attrs: {
                                                        cols: _vm.isMobile
                                                          ? "12"
                                                          : "2"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "font-awesome-icon",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "outlookSave",
                                                                          class: _vm.isMobile
                                                                            ? "mb-0"
                                                                            : "",
                                                                          style: {
                                                                            fontSize:
                                                                              "20px",
                                                                            color:
                                                                              _vm.colorIconCopycalendarExportURL
                                                                          },
                                                                          attrs: {
                                                                            icon: [
                                                                              "fal",
                                                                              "copy"
                                                                            ]
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.copycalendarExportURL()
                                                                            }
                                                                          }
                                                                        },
                                                                        on
                                                                      )
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "generics.copy"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        { staticClass: "py-0 switchWrapper" },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            { staticClass: "py-1 my-0 px-2" },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "pl-2" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pl-0 pt-0",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "components.userSettings.outlookCalendar"
                                                          )
                                                        ) +
                                                          "\n                        "
                                                      ),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            top: "",
                                                            "max-width": "450"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "font-awesome-icon",
                                                                      _vm._g(
                                                                        {
                                                                          style: {
                                                                            fontSize:
                                                                              "17px",
                                                                            position:
                                                                              "relative",
                                                                            top:
                                                                              "2px"
                                                                          },
                                                                          attrs: {
                                                                            color:
                                                                              "#02889d",
                                                                            icon: [
                                                                              "fal",
                                                                              "info-circle"
                                                                            ]
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              _vm.isMobile
                                                                                ? _vm.openInfoModal(
                                                                                    _vm.$t(
                                                                                      "generics.info"
                                                                                    ),
                                                                                    _vm.$t(
                                                                                      "components.userSettings.outlookCalendarTT"
                                                                                    )
                                                                                  )
                                                                                : null
                                                                            }
                                                                          }
                                                                        },
                                                                        on
                                                                      )
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "components.userSettings.outlookCalendarTT"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      class: _vm.isMobile
                                                        ? "py-2 pl-2"
                                                        : "py-0 px-0",
                                                      attrs: {
                                                        cols: _vm.isMobile
                                                          ? "12"
                                                          : "10"
                                                      }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          autocomplete: "off",
                                                          "hide-details": "",
                                                          label: _vm.$t(
                                                            "components.userSettings.outlookCalendarExport"
                                                          ),
                                                          outlined: "",
                                                          dense: ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.outlookCalendarImportURL,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.outlookCalendarImportURL = $$v
                                                          },
                                                          expression:
                                                            "outlookCalendarImportURL"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0 px-0",
                                                      attrs: {
                                                        cols: _vm.isMobile
                                                          ? "12"
                                                          : "2"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "font-awesome-icon",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "primary--text outlookSave",
                                                                          class: _vm.isMobile
                                                                            ? "mb-0"
                                                                            : "",
                                                                          style: {
                                                                            fontSize:
                                                                              "20px"
                                                                          },
                                                                          attrs: {
                                                                            icon: [
                                                                              "fal",
                                                                              "save"
                                                                            ]
                                                                          },
                                                                          on: {
                                                                            click:
                                                                              _vm.saveOutlookImportUrl
                                                                          }
                                                                        },
                                                                        on
                                                                      )
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "generics.save"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "font-awesome-icon",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "primary--text outlookSave",
                                                                          class: _vm.isMobile
                                                                            ? "mb-0"
                                                                            : "",
                                                                          style: {
                                                                            fontSize:
                                                                              "20px"
                                                                          },
                                                                          attrs: {
                                                                            icon: [
                                                                              "fal",
                                                                              "trash"
                                                                            ]
                                                                          },
                                                                          on: {
                                                                            click:
                                                                              _vm.deleteOutlookImportUrl
                                                                          }
                                                                        },
                                                                        on
                                                                      )
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "generics.remove"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        { staticClass: "py-0 switchWrapper" },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            { staticClass: "py-1 my-0 pl-2" },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "pl-2" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pl-0 pt-0",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "components.userSettings.appleCalendar"
                                                          )
                                                        ) +
                                                          "\n                        "
                                                      ),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            top: "",
                                                            "max-width": "450"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "font-awesome-icon",
                                                                      _vm._g(
                                                                        {
                                                                          style: {
                                                                            fontSize:
                                                                              "17px",
                                                                            position:
                                                                              "relative",
                                                                            top:
                                                                              "2px"
                                                                          },
                                                                          attrs: {
                                                                            color:
                                                                              "#02889d",
                                                                            icon: [
                                                                              "fal",
                                                                              "info-circle"
                                                                            ]
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              _vm.isMobile
                                                                                ? _vm.openInfoModal(
                                                                                    _vm.$t(
                                                                                      "generics.info"
                                                                                    ),
                                                                                    _vm.$t(
                                                                                      "components.userSettings.appleCalendarTT"
                                                                                    )
                                                                                  )
                                                                                : null
                                                                            }
                                                                          }
                                                                        },
                                                                        on
                                                                      )
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "components.userSettings.appleCalendarTT"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      class: _vm.isMobile
                                                        ? "py-2 pl-2"
                                                        : "py-0 px-0",
                                                      attrs: {
                                                        cols: _vm.isMobile
                                                          ? "12"
                                                          : "10"
                                                      }
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          autocomplete: "off",
                                                          "hide-details": "",
                                                          label: _vm.$t(
                                                            "components.userSettings.appleCalendarExport"
                                                          ),
                                                          outlined: "",
                                                          dense: ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.appleCalendarImportURL,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.appleCalendarImportURL = $$v
                                                          },
                                                          expression:
                                                            "appleCalendarImportURL"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "py-0 px-0",
                                                      attrs: {
                                                        cols: _vm.isMobile
                                                          ? "12"
                                                          : "2"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "font-awesome-icon",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "primary--text outlookSave",
                                                                          class: _vm.isMobile
                                                                            ? "mb-0"
                                                                            : "",
                                                                          style: {
                                                                            fontSize:
                                                                              "20px"
                                                                          },
                                                                          attrs: {
                                                                            icon: [
                                                                              "fal",
                                                                              "save"
                                                                            ]
                                                                          },
                                                                          on: {
                                                                            click:
                                                                              _vm.saveAppleImportUrl
                                                                          }
                                                                        },
                                                                        on
                                                                      )
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "generics.save"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "font-awesome-icon",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "primary--text outlookSave",
                                                                          class: _vm.isMobile
                                                                            ? "mb-0"
                                                                            : "",
                                                                          style: {
                                                                            fontSize:
                                                                              "20px"
                                                                          },
                                                                          attrs: {
                                                                            icon: [
                                                                              "fal",
                                                                              "trash"
                                                                            ]
                                                                          },
                                                                          on: {
                                                                            click:
                                                                              _vm.deleteAppleImportUrl
                                                                          }
                                                                        },
                                                                        on
                                                                      )
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "generics.remove"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.index == 19
                                ? _c(
                                    "v-expansion-panel-content",
                                    { staticClass: "additionalInfo" },
                                    [
                                      _c(
                                        "v-list-item",
                                        { staticClass: "py-0 switchWrapper" },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            { staticClass: "py-1 my-0 pl-2" },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "pl-2" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "pl-0 pt-0 font-weight-medium",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "components.userSettings.educationBackground"
                                                          )
                                                        ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      class: _vm.isMobile
                                                        ? "py-2 pl-2"
                                                        : "pl-0 py-0",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _c("v-textarea", {
                                                        attrs: {
                                                          autocomplete: "off",
                                                          maxlength: 300,
                                                          "auto-grow": "",
                                                          rows: "1",
                                                          outlined: "",
                                                          counter: "",
                                                          dense: "",
                                                          rules:
                                                            _vm.educationRules
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.educationBackground,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.educationBackground = $$v
                                                          },
                                                          expression:
                                                            "educationBackground"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        { staticClass: "py-0 switchWrapper" },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            {
                                              staticClass: "pt-0 pb-3 my-0 pl-2"
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "pl-2" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "pl-0 pt-0 font-weight-medium",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "components.userSettings.startDate"
                                                          )
                                                        ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      class: _vm.isMobile
                                                        ? "py-2 pl-2"
                                                        : "pl-0 py-0",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _c(
                                                        "v-menu",
                                                        {
                                                          ref: "startTeamDate",
                                                          refInFor: true,
                                                          attrs: {
                                                            id: "startTeamDate",
                                                            "close-on-content-click": false,
                                                            transition:
                                                              "scale-transition",
                                                            "offset-y": "",
                                                            "min-width": "290px"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-text-field",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            attrs: {
                                                                              readonly:
                                                                                "",
                                                                              dense:
                                                                                "",
                                                                              "hide-details":
                                                                                "",
                                                                              outlined:
                                                                                ""
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                _vm.startTeamDate,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.startTeamDate = $$v
                                                                              },
                                                                              expression:
                                                                                "startTeamDate"
                                                                            }
                                                                          },
                                                                          "v-text-field",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm.startTeamDate !=
                                                                        null
                                                                          ? _c(
                                                                              "template",
                                                                              {
                                                                                slot:
                                                                                  "append"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "DeleteBasket",
                                                                                  {
                                                                                    attrs: {
                                                                                      indexData:
                                                                                        "",
                                                                                      delFunction:
                                                                                        _vm.cleanDateTeamStart,
                                                                                      extraClasses:
                                                                                        "dateTeamStartBasket",
                                                                                      fontSize:
                                                                                        "18"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e()
                                                                      ],
                                                                      2
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                          model: {
                                                            value:
                                                              _vm.startDateMenu,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.startDateMenu = $$v
                                                            },
                                                            expression:
                                                              "startDateMenu"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("v-date-picker", {
                                                            attrs: {
                                                              "no-title": "",
                                                              scrollable: ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.additionalStartDate,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.additionalStartDate = $$v
                                                              },
                                                              expression:
                                                                "additionalStartDate"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        { staticClass: "py-0 switchWrapper" },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            { staticClass: "py-1 my-0 pl-2" },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "pl-2" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "pl-0 pt-0 font-weight-medium",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "components.userSettings.additionalKnowledge"
                                                          )
                                                        ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      class: _vm.isMobile
                                                        ? "py-2 pl-2"
                                                        : "pl-0 py-0",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _c("v-textarea", {
                                                        attrs: {
                                                          autocomplete: "off",
                                                          maxlength: "300",
                                                          "auto-grow": "",
                                                          rows: "1",
                                                          outlined: "",
                                                          counter: "",
                                                          dense: ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.additionalKnowledge,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.additionalKnowledge = $$v
                                                          },
                                                          expression:
                                                            "additionalKnowledge"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        { staticClass: "py-0 switchWrapper" },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            { staticClass: "py-1 my-0 pl-2" },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "pl-2" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "pl-0 pt-0 font-weight-medium",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "components.userSettings.briefDesc"
                                                          )
                                                        ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      class: _vm.isMobile
                                                        ? "py-2 pl-2"
                                                        : "pl-0 py-0",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _c("v-textarea", {
                                                        attrs: {
                                                          autocomplete: "off",
                                                          maxlength: "500",
                                                          "auto-grow": "",
                                                          rows: "1",
                                                          outlined: "",
                                                          counter: "",
                                                          dense: ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.additionalBriefDesc,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.additionalBriefDesc = $$v
                                                          },
                                                          expression:
                                                            "additionalBriefDesc"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        { staticClass: "py-0 switchWrapper" },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            { staticClass: "py-1 my-0 pl-2" },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "pl-2" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "pl-0 pt-0 font-weight-medium",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "components.userSettings.workExp"
                                                          )
                                                        ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      class: _vm.isMobile
                                                        ? "py-2 pl-2"
                                                        : "pl-0 py-0",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _c("v-textarea", {
                                                        attrs: {
                                                          autocomplete: "off",
                                                          maxlength: "300",
                                                          "auto-grow": "",
                                                          rows: "1",
                                                          outlined: "",
                                                          counter: "",
                                                          dense: ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.additionalWorkExp,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.additionalWorkExp = $$v
                                                          },
                                                          expression:
                                                            "additionalWorkExp"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        { staticClass: "py-0 switchWrapper" },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            { staticClass: "py-1 my-0 pl-2" },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: "pl-2" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "pl-0 pt-0 font-weight-medium",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "components.userSettings.interests"
                                                          )
                                                        ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      class: _vm.isMobile
                                                        ? "py-2 pl-2"
                                                        : "pl-0 py-0",
                                                      attrs: { cols: "12" }
                                                    },
                                                    [
                                                      _c("v-textarea", {
                                                        attrs: {
                                                          autocomplete: "off",
                                                          maxlength: "300",
                                                          "auto-grow": "",
                                                          rows: "1",
                                                          outlined: "",
                                                          counter: "",
                                                          dense: ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.additionalInterests,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.additionalInterests = $$v
                                                          },
                                                          expression:
                                                            "additionalInterests"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "pl-0 saveButton text-end mx-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "ml-2",
                                                              class: _vm.isMobile
                                                                ? "mt-2 mb-2"
                                                                : "",
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                disabled:
                                                                  _vm.educationBackground.trim()
                                                                    .length <= 0
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.saveAdditionalUserInfo()
                                                                }
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "generics.save"
                                                                  )
                                                                )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("generics.save")
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("FooterModal", {
            attrs: { closeModalFunction: _vm.closeModalFunction }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.showActivation
        ? _c("TwoFactorActivationModal", {
            attrs: {
              showActivation: _vm.showActivation,
              toggle2faActivationModal: _vm.toggle2faActivationModal,
              saveEntryNoPhone: _vm.saveEntryNoPhone
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showInvitationModal
        ? _c("SendInvitationModal", {
            attrs: {
              closeShowInviteInfo: _vm.closeShowInviteInfo,
              showInviteInfo: _vm.showInvitationModal
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }