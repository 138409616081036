var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    [
      _c(
        "v-card-title",
        [
          _vm._v("\n    Benutzer\n    "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("v-text-field", {
            attrs: {
              "append-icon": "mdi-magnify",
              label: "Suche",
              "single-line": "",
              "hide-details": ""
            },
            on: { change: _vm.search }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.visibleUsers,
          "item-key": "uuid",
          dense: "",
          "show-select": "",
          "show-expand": "",
          "single-expand": "",
          "fixed-header": "",
          loading: _vm.isLoading,
          "items-per-page": _vm.itemsPerPage,
          height: "calc(100% - 187px)"
        },
        scopedSlots: _vm._u([
          {
            key: "item.user.name",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("v-text-field", {
                  staticClass: "my-1",
                  attrs: {
                    solo: "",
                    flat: "",
                    rules: [_vm.rules.required],
                    dense: "",
                    "hide-details": "auto"
                  },
                  model: {
                    value: item.user.name,
                    callback: function($$v) {
                      _vm.$set(item.user, "name", $$v)
                    },
                    expression: "item.user.name"
                  }
                })
              ]
            }
          },
          {
            key: "item.user.email",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("v-text-field", {
                  staticClass: "my-1",
                  attrs: {
                    solo: "",
                    flat: "",
                    rules: [_vm.rules.required, _vm.rules.email],
                    dense: "",
                    "hide-details": "auto"
                  },
                  model: {
                    value: item.user.email,
                    callback: function($$v) {
                      _vm.$set(item.user, "email", $$v)
                    },
                    expression: "item.user.email"
                  }
                })
              ]
            }
          },
          {
            key: "item.user.position",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("v-text-field", {
                  staticClass: "my-1",
                  attrs: {
                    solo: "",
                    flat: "",
                    dense: "",
                    "hide-details": "auto"
                  },
                  model: {
                    value: item.user.position,
                    callback: function($$v) {
                      _vm.$set(item.user, "position", $$v)
                    },
                    expression: "item.user.position"
                  }
                })
              ]
            }
          },
          {
            key: "item.user.assistants",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("v-autocomplete", {
                  attrs: {
                    items: _vm.allUsers,
                    "item-text": "user.name",
                    "item-value": "uuid",
                    multiple: "",
                    "auto-select-first": "",
                    solo: "",
                    flat: "",
                    "hide-details": "auto"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "item",
                        fn: function(data) {
                          return [
                            _c("v-list-item-avatar", { attrs: { tile: "" } }, [
                              _c("img", {
                                attrs: { src: data.item.user.avatar }
                              })
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$sanitize(data.item.user.name)
                                    )
                                  }
                                }),
                                _vm._v(" "),
                                _c("v-list-item-subtitle", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$sanitize(data.item.user.position)
                                    )
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: item.user.assistants,
                    callback: function($$v) {
                      _vm.$set(item.user, "assistants", $$v)
                    },
                    expression: "item.user.assistants"
                  }
                })
              ]
            }
          },
          {
            key: "item.permissions",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("v-select", {
                  attrs: {
                    value: item.permissions,
                    items: _vm.permissions,
                    "item-text": "name",
                    "item-value": "value",
                    multiple: "",
                    "auto-select-first": "",
                    dense: "",
                    solo: "",
                    flat: "",
                    "hide-details": "auto",
                    disabled: ""
                  }
                })
              ]
            }
          },
          {
            key: "expanded-item",
            fn: function(ref) {
              var headers = ref.headers
              var item = ref.item
              return [
                _c(
                  "td",
                  {
                    staticStyle: { padding: "10px 16px" },
                    attrs: { colspan: headers.length }
                  },
                  [
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c(
                        "div",
                        { staticStyle: { width: "200px" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { sm: "12" } },
                                [
                                  _c(
                                    "v-avatar",
                                    {
                                      staticStyle: { "border-radius": "5px" },
                                      style: {
                                        border:
                                          "2px solid " +
                                          _vm.getStatusColorByUser(
                                            item.user,
                                            item.connected
                                          )
                                      },
                                      attrs: { size: "200" }
                                    },
                                    [
                                      _c("img", {
                                        attrs: { src: item.user.avatar }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: { width: "100%", "margin-left": "16px" }
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { sm: "4", xs: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Titel",
                                      placeholder: " ",
                                      dense: "",
                                      "hide-details": "auto"
                                    },
                                    model: {
                                      value: item.user.title,
                                      callback: function($$v) {
                                        _vm.$set(item.user, "title", $$v)
                                      },
                                      expression: "item.user.title"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { sm: "8", xs: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Name",
                                      placeholder: " ",
                                      dense: "",
                                      "hide-details": "auto"
                                    },
                                    model: {
                                      value: item.user.name,
                                      callback: function($$v) {
                                        _vm.$set(item.user, "name", $$v)
                                      },
                                      expression: "item.user.name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { sm: "4", xs: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Ort",
                                      placeholder: " ",
                                      dense: "",
                                      "hide-details": "auto"
                                    },
                                    model: {
                                      value: item.user.location,
                                      callback: function($$v) {
                                        _vm.$set(item.user, "location", $$v)
                                      },
                                      expression: "item.user.location"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { sm: "4", xs: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Wochenstunden (Homeoffice)",
                                      placeholder: " ",
                                      dense: "",
                                      "hide-details": "auto"
                                    },
                                    model: {
                                      value: item.user.homeHours,
                                      callback: function($$v) {
                                        _vm.$set(item.user, "homeHours", $$v)
                                      },
                                      expression: "item.user.homeHours"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { sm: "4", xs: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Wochenstunden (Realoffice)",
                                      placeholder: " ",
                                      dense: "",
                                      "hide-details": "auto"
                                    },
                                    model: {
                                      value: item.user.hours,
                                      callback: function($$v) {
                                        _vm.$set(item.user, "hours", $$v)
                                      },
                                      expression: "item.user.hours"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { sm: "4", xs: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Telefon",
                                      placeholder: " ",
                                      dense: "",
                                      "hide-details": "auto"
                                    },
                                    model: {
                                      value: item.user.phone,
                                      callback: function($$v) {
                                        _vm.$set(item.user, "phone", $$v)
                                      },
                                      expression: "item.user.phone"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { sm: "4", xs: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Durchwahl",
                                      placeholder: " ",
                                      dense: "",
                                      "hide-details": "auto"
                                    },
                                    model: {
                                      value: item.user.extension,
                                      callback: function($$v) {
                                        _vm.$set(item.user, "extension", $$v)
                                      },
                                      expression: "item.user.extension"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { sm: "4", xs: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Mobiltelefon",
                                      placeholder: " ",
                                      dense: "",
                                      "hide-details": "auto"
                                    },
                                    model: {
                                      value: item.user.mobilePhone,
                                      callback: function($$v) {
                                        _vm.$set(item.user, "mobilePhone", $$v)
                                      },
                                      expression: "item.user.mobilePhone"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      [
                        _c("v-col", { staticStyle: { display: "flex" } }, [
                          _c("div", [
                            _c("span", {
                              staticClass: "dot mr-2",
                              style: {
                                backgroundColor: _vm.getStatusColorByUser(
                                  item.user,
                                  item.connected
                                )
                              }
                            }),
                            _vm._v(
                              _vm._s(item.user.activity) + "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "px-2" }, [
                            _vm._v("\n              |\n            ")
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _vm.state.user.userLocation === "homeOffice"
                                ? _c("font-awesome-icon", {
                                    attrs: {
                                      icon: ["fal", "house-user"],
                                      color: "#02889d"
                                    }
                                  })
                                : _vm.state.user.userLocation === "outOfHouse"
                                ? _c("font-awesome-icon", {
                                    attrs: {
                                      icon: ["fal", "car-building"],
                                      color: "#02889d"
                                    }
                                  })
                                : _vm.state.user.userLocation === "realoOffice"
                                ? _c("font-awesome-icon", {
                                    attrs: {
                                      icon: ["fal", "building"],
                                      color: "#02889d"
                                    }
                                  })
                                : _vm.state.user.userLocation === "notSpecified"
                                ? _c("span", [_vm._v("--")])
                                : _c("font-awesome-icon", {
                                    attrs: { icon: ["fal", "location-arrow"] }
                                  }),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml-1" }, [
                                _vm._v(
                                  _vm._s(_vm.locationTranslated(item.user))
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "px-2" }, [
                            _vm._v("\n              |\n            ")
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("font-awesome-icon", {
                                attrs: {
                                  color: "#02889d",
                                  icon: ["fal", "arrow-alt-circle-up"]
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "ml-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatDateTime(item.user.presentFrom)
                                  )
                                )
                              ])
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          {
                            staticClass: "px-2 py-0",
                            attrs: { align: "right" }
                          },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              { attrs: { color: "primary" } },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                  Einladungslink senden\n                "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(" "),
                                _c("span", [_vm._v("In Vorbereitung")])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              { attrs: { color: "primary" } },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                  Löschen\n                "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(" "),
                                _c("span", [_vm._v("In Vorbereitung")])
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c(
        "v-card",
        {
          staticClass: "pl-4 pr-2 footerModal",
          staticStyle: { padding: "6px 0" },
          attrs: { color: "primary" }
        },
        [
          _c(
            "v-row",
            { staticClass: "btns mx-0", attrs: { flex: "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "px-0 py-0",
                  attrs: { cols: "8", align: "right" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", disabled: "" },
                      on: { click: _vm.save }
                    },
                    [
                      !_vm.isSaving
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.manageOrganisation.save")
                              )
                            )
                          ])
                        : _c("v-progress-circular", {
                            attrs: { indeterminate: "" }
                          })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", disabled: "" },
                      on: { click: _vm.saveAndClose }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("components.manageOrganisation.saveAndClose")
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    { attrs: { color: "primary" }, on: { click: _vm.close } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("generics.cancel")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }