var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "460" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c("v-img", {
                staticClass: "mr-1",
                attrs: {
                  src: "assets/icon.png",
                  "max-height": "30",
                  "max-width": "30",
                  contain: ""
                }
              }),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("components.2faSms.title")) +
                  "\n    "
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  !_vm.editingPhone
                    ? [
                        _c(
                          "div",
                          { staticClass: "tex0t-truncate body-1" },
                          [
                            _c(
                              "span",
                              {
                                style: _vm.phoneNumberValid
                                  ? {}
                                  : { color: "red" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("components.2faSms.phoneNumber")
                                  )
                                )
                              ]
                            ),
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.phoneNumber) +
                                "\n            "
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: { icon: "" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editPhone()
                                                  }
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c("font-awesome-icon", {
                                                style: { fontSize: "20px" },
                                                attrs: { icon: ["fal", "pen"] }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  4036333849
                                )
                              },
                              [
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.2faSms.editPhoneNumber"
                                      )
                                    )
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.editingPhone
                    ? [
                        _c("vue-tel-input", {
                          staticClass:
                            "form-control text-center input-sm inputPhone2FA",
                          class: { borderError: !_vm.phoneInputValid },
                          attrs: {
                            required: "",
                            defaultCountry: "DE",
                            enabledCountryCode: "",
                            name: "number",
                            id: "numDisplay",
                            value: "",
                            placeholder: "",
                            autocomplete: "off"
                          },
                          on: { onInput: _vm.onTelInput },
                          model: {
                            value: _vm.phoneNumberInput,
                            callback: function($$v) {
                              _vm.phoneNumberInput = $$v
                            },
                            expression: "phoneNumberInput"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "my-3",
                                            attrs: {
                                              icon: "",
                                              disabled: !_vm.phoneInputValid
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.savePhone()
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c("font-awesome-icon", {
                                            style: { fontSize: "20px" },
                                            attrs: { icon: ["fal", "check"] }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2419165192
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("generics.save")))
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "my-3",
                                            attrs: { icon: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.cancelPhone()
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c("font-awesome-icon", {
                                            style: { fontSize: "20px" },
                                            attrs: { icon: ["fal", "times"] }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              708696980
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("generics.cancel")))
                            ])
                          ]
                        )
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex mb-4" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        outlined: "",
                        dense: "",
                        "hide-details": "",
                        disabled:
                          _vm.requestSmsDone ||
                          _vm.editingPhone ||
                          !_vm.phoneNumberValid,
                        small: "",
                        color: "error"
                      },
                      on: {
                        click: function($event) {
                          return _vm.requestSms()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("components.2faSms.sendSMS")))]
                  ),
                  _vm._v(" "),
                  _vm.requestSmsDone
                    ? _c("span", { staticClass: "px-2 align-center d-flex" }, [
                        _vm._v(_vm._s(_vm.$t("components.2fa.sentSMSMsg")))
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm.errMsg && !_vm.requestSmsDone
                ? _c(
                    "v-alert",
                    { attrs: { type: "error", dismissible: true } },
                    [_vm._v(_vm._s(_vm.errMsg))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.requestSmsDone
                ? _c(
                    "div",
                    { staticClass: "mt-3" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          autocomplete: "off",
                          label: _vm.$t("components.2fa.enterSmsCode"),
                          outlined: "",
                          dense: "",
                          required: "",
                          autofocus: ""
                        },
                        on: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            _vm.smsCode && _vm.enable2faSms()
                          },
                          keydown: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "space", 32, $event.key, [
                                " ",
                                "Spacebar"
                              ])
                            ) {
                              return null
                            }
                            $event.preventDefault()
                          }
                        },
                        model: {
                          value: _vm.smsCode,
                          callback: function($$v) {
                            _vm.smsCode = $$v
                          },
                          expression: "smsCode"
                        }
                      }),
                      _vm._v(" "),
                      _vm.errMsg
                        ? _c(
                            "v-alert",
                            { attrs: { type: "error", dismissible: true } },
                            [_vm._v(_vm._s(_vm.errMsg))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-end mt-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                "hide-details": "",
                                small: "",
                                disabled: !_vm.smsCode
                              },
                              on: {
                                click: function($event) {
                                  return _vm.enable2faSms()
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("components.2faSms.btnEnable"))
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            {
              staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
              attrs: { color: "primary" }
            },
            [
              _c(
                "v-row",
                { staticClass: "px-2 btns mx-0", attrs: { flex: "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "px-0 py-0",
                      attrs: { cols: "8", align: "right" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.closeModal()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("generics.close")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }