var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "560" },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      [
        _c(
          "v-card",
          { staticClass: "mx-auto" },
          [
            _c("HeaderModal", {
              attrs: {
                titleModal: _vm.$t(
                  "components.conferenceForm.titleInviteVisitor"
                ),
                closeModalFunction: _vm.closeModal
              }
            }),
            _vm._v(" "),
            _c(
              "v-card-text",
              { staticClass: "px-2" },
              [
                _c(
                  "v-row",
                  { staticClass: "mx-0 pt-6" },
                  [
                    _c(
                      "v-col",
                      { staticClass: "py-0", attrs: { cols: "7" } },
                      [
                        _c("v-combobox", {
                          staticClass: "pb-6",
                          attrs: {
                            items: _vm.filteredInvitations,
                            outlined: "",
                            "item-text": "name",
                            "item-value": "email",
                            "hide-details": "auto",
                            label: _vm.$t("components.inviteVisitor.contacts"),
                            select: "",
                            dense: "",
                            "menu-props": { closeOnContentClick: true }
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "item",
                              fn: function(data) {
                                return [
                                  _c(
                                    "v-list-item",
                                    {
                                      staticStyle: { position: "relative" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleSelectContact(
                                            data.item
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", {
                                            staticClass: "listTitle",
                                            domProps: {
                                              textContent: _vm._s(
                                                data.item.name
                                              )
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-list-item-subtitle", {
                                            staticClass: "listSubtitle",
                                            domProps: {
                                              textContent: _vm._s(
                                                data.item.email
                                              )
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.contactSelected,
                            callback: function($$v) {
                              _vm.contactSelected = $$v
                            },
                            expression: "contactSelected"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { staticClass: "py-0", attrs: { cols: "5" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "cursorPointer heightBtn40w100",
                            attrs: {
                              align: "right",
                              color: "primary",
                              outlined: "",
                              disabled:
                                !_vm.name ||
                                _vm.name.length == 0 ||
                                _vm.isEmailInvalid
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.saveContact()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "components.inviteGuestPanel.saveContact"
                                  )
                                ) +
                                "\n            "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { staticClass: "py-0", attrs: { cols: "12" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            counter: "",
                            maxlength: "60",
                            required: "",
                            outlined: "",
                            rules: _vm.applyRules
                              ? _vm.userNameRules
                              : _vm.noRules,
                            label: _vm.$t("components.inviteVisitor.nameGuest"),
                            dense: ""
                          },
                          on: {
                            blur: function($event) {
                              _vm.applyRules = true
                            }
                          },
                          model: {
                            value: _vm.name,
                            callback: function($$v) {
                              _vm.name = $$v
                            },
                            expression: "name"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { staticClass: "py-0", attrs: { cols: "6" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            label: _vm.$t(
                              "components.inviteVisitor.invitationEmail"
                            ),
                            required: "",
                            outlined: "",
                            dense: "",
                            rules: _vm.emailRules
                          },
                          on: { keyup: _vm.changeEmail },
                          model: {
                            value: _vm.email,
                            callback: function($$v) {
                              _vm.email = $$v
                            },
                            expression: "email"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { staticClass: "py-0", attrs: { cols: "6" } },
                      [
                        _c("vue-tel-input", {
                          staticClass:
                            "\n                focusStyle\n                form-control\n                text-center\n                input-sm\n                inputPhone2FA\n              ",
                          class: { borderError: !_vm.phoneInputValid },
                          attrs: {
                            defaultCountry: "DE",
                            enabledCountryCode: "",
                            name: "number",
                            id: "numDisplay",
                            value: "",
                            placeholder: "",
                            autocomplete: "off"
                          },
                          on: { onInput: _vm.onTelInput },
                          model: {
                            value: _vm.telNumberModel,
                            callback: function($$v) {
                              _vm.telNumberModel = $$v
                            },
                            expression: "telNumberModel"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.isConference
                      ? _c(
                          "v-col",
                          { staticClass: "py-0", attrs: { cols: "12" } },
                          [
                            _c("v-checkbox", {
                              staticClass: "mt-0 fontSize14 mb-0",
                              attrs: {
                                label: _vm.$t(
                                  "components.conferenceForm.showVisitorName"
                                )
                              },
                              model: {
                                value: _vm.showUserName,
                                callback: function($$v) {
                                  _vm.showUserName = $$v
                                },
                                expression: "showUserName"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      {
                        staticClass: "py-0",
                        staticStyle: { position: "relative" },
                        attrs: { cols: "12" }
                      },
                      [
                        _c("v-text-field", {
                          staticClass: "functionFieldPadd",
                          attrs: {
                            outlined: "",
                            dense: "",
                            "hide-details": "auto",
                            label: _vm.$t("components.inviteVisitor.notiz")
                          },
                          model: {
                            value: _vm.userFunction,
                            callback: function($$v) {
                              _vm.userFunction = $$v
                            },
                            expression: "userFunction"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "FooterModal",
              { attrs: { hideDefaultButtons: true } },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-3",
                    staticStyle: { color: "#11889d !important" },
                    attrs: {
                      color: "white",
                      depressed: "",
                      disabled: !_vm.isNotAbleToAddUser
                    },
                    on: {
                      click: function($event) {
                        return _vm.saveMember(true)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("components.addGroupModal.add")) +
                        "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-1",
                    staticStyle: { color: "#11889d !important" },
                    attrs: {
                      color: "white",
                      depressed: "",
                      disabled: !_vm.isNotAbleToAddUser
                    },
                    on: {
                      click: function($event) {
                        return _vm.saveMember(false)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("components.addGroupModal.addplus")) +
                        "\n        "
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }