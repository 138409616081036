var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.isMobile ? "InviteFormContainerMobile" : "InviteFormContainer"
    },
    [
      _c(
        "v-card",
        { class: (!_vm.showTimeline ? "pt-2" : "pt-6") + " pl-10 pr-10" },
        [
          _c("div", { staticClass: "cols-12 textCenterd" }, [
            _vm._v(_vm._s(_vm.getHeaderTitle()))
          ]),
          _vm._v(" "),
          _c(
            "v-tabs",
            {
              staticClass: "tabInvite pb-5",
              attrs: {
                vertical: _vm.isMobile ? true : false,
                centered: _vm.isMobile ? false : true
              },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c("v-tab", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("components.inviteVisitor.title")) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("v-tab", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("components.inviteGuestPanel.inviteList")) +
                    "\n      "
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: _vm.isMobile ? 12 : 5 } },
                            [
                              _c("div", { staticClass: "d-flex" }, [
                                _c(
                                  "div",
                                  { staticClass: "contactStyle" },
                                  [
                                    _c("v-combobox", {
                                      staticClass: "pb-6",
                                      attrs: {
                                        "search-input": _vm.contactSearch,
                                        items: _vm.filteredInvitations,
                                        outlined: "",
                                        "item-text": "name",
                                        "item-value": "email",
                                        "hide-details": "auto",
                                        label: _vm.$t(
                                          "components.inviteVisitor.contacts"
                                        ),
                                        select: "",
                                        dense: "",
                                        "menu-props": {
                                          closeOnContentClick: true
                                        }
                                      },
                                      on: {
                                        "update:searchInput": function($event) {
                                          _vm.contactSearch = $event
                                        },
                                        "update:search-input": function(
                                          $event
                                        ) {
                                          _vm.contactSearch = $event
                                        }
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "item",
                                          fn: function(data) {
                                            return [
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticStyle: {
                                                    position: "relative",
                                                    "min-height": "25px"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.handleSelectEmail(
                                                        data.item
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-list-item-content", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "listTitle mb-1"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                            " +
                                                            _vm._s(
                                                              data.item.name
                                                            ) +
                                                            "\n                          "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "listSubtitle"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                            " +
                                                            _vm._s(
                                                              data.item.email
                                                            ) +
                                                            "\n                          "
                                                        )
                                                      ]
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("DeleteBasket", {
                                                    attrs: {
                                                      indexData: data.item,
                                                      delFunction:
                                                        _vm.removeUserFromList,
                                                      extraClasses:
                                                        "deleteContactBasket  cursorPointer",
                                                      fontSize: "20",
                                                      prevent: "true"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ]),
                                      model: {
                                        value: _vm.contactSelected,
                                        callback: function($$v) {
                                          _vm.contactSelected = $$v
                                        },
                                        expression: "contactSelected"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("v-text-field", {
                                class: { borderError: _vm.name == "" },
                                attrs: {
                                  counter: "",
                                  maxlength: "60",
                                  required: "",
                                  outlined: "",
                                  rules: _vm.applyRules
                                    ? _vm.userNameRules
                                    : _vm.noRules,
                                  label: _vm.$t(
                                    "components.inviteVisitor.nameGuest"
                                  ),
                                  dense: ""
                                },
                                on: {
                                  blur: function($event) {
                                    _vm.applyRules = true
                                  }
                                },
                                model: {
                                  value: _vm.name,
                                  callback: function($$v) {
                                    _vm.name = $$v
                                  },
                                  expression: "name"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        class: {
                                          borderError: _vm.isEmailInvalid
                                        },
                                        attrs: {
                                          label: _vm.$t(
                                            "components.inviteVisitor.invitationEmail"
                                          ),
                                          required: "",
                                          outlined: "",
                                          dense: "",
                                          rules: _vm.emailRules
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.checkEmailPreSend()
                                          },
                                          keyup: _vm.changeEmail
                                        },
                                        model: {
                                          value: _vm.email,
                                          callback: function($$v) {
                                            _vm.email = $$v
                                          },
                                          expression: "email"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              !_vm.reducedMode
                                ? [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "pt-0 positionRelative",
                                            attrs: { cols: "12" }
                                          },
                                          [
                                            _c("vue-tel-input", {
                                              staticClass:
                                                "\n                        focusStyle\n                        form-control\n                        text-center\n                        input-sm\n                        inputPhone2FA\n                      ",
                                              class: {
                                                borderError: !_vm.phoneInputValid
                                              },
                                              attrs: {
                                                defaultCountry: "DE",
                                                enabledCountryCode: "",
                                                name: "number",
                                                id: "numDisplay",
                                                value: "",
                                                placeholder: _vm.$t(
                                                  "components.inviteGuestPanel.telOptional"
                                                ),
                                                autocomplete: "off"
                                              },
                                              on: { onInput: _vm.onTelInput },
                                              model: {
                                                value: _vm.telNumberModel,
                                                callback: function($$v) {
                                                  _vm.telNumberModel = $$v
                                                },
                                                expression: "telNumberModel"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass: "customLabelTlf",
                                                class: _vm.isDarkMode
                                                  ? "bgBlack"
                                                  : "bgWhite"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "components.inviteGuestPanel.telOptional"
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-row",
                                      [
                                        _c("v-col", { staticClass: "py-0" }, [
                                          _c(
                                            "div",
                                            { staticClass: "d-flex" },
                                            [
                                              _c("v-checkbox", {
                                                staticClass: "mr-3",
                                                attrs: {
                                                  disabled:
                                                    _vm.getCheckboxDisabled,
                                                  label: _vm.$t(
                                                    "components.inviteVisitor.asap"
                                                  ),
                                                  value: "asap",
                                                  "hide-details": ""
                                                },
                                                on: {
                                                  change:
                                                    _vm.changeTypeInvitation
                                                },
                                                model: {
                                                  value: _vm.typeInvitation,
                                                  callback: function($$v) {
                                                    _vm.typeInvitation = $$v
                                                  },
                                                  expression: "typeInvitation"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("v-checkbox", {
                                                staticClass: "mr-3",
                                                attrs: {
                                                  disabled:
                                                    _vm.getCheckboxDisabled,
                                                  label: _vm.$t(
                                                    "components.inviteVisitor.soon"
                                                  ),
                                                  value: "soon",
                                                  "hide-details": ""
                                                },
                                                on: {
                                                  change:
                                                    _vm.changeTypeInvitation
                                                },
                                                model: {
                                                  value: _vm.typeInvitation,
                                                  callback: function($$v) {
                                                    _vm.typeInvitation = $$v
                                                  },
                                                  expression: "typeInvitation"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("v-checkbox", {
                                                staticClass: "mr-3",
                                                attrs: {
                                                  disabled:
                                                    _vm.getCheckboxDisabled,
                                                  label: _vm.$t(
                                                    "components.inviteVisitor.appointmentshort"
                                                  ),
                                                  value: "appointment"
                                                },
                                                on: {
                                                  change:
                                                    _vm.changeTypeInvitation
                                                },
                                                model: {
                                                  value: _vm.typeInvitation,
                                                  callback: function($$v) {
                                                    _vm.typeInvitation = $$v
                                                  },
                                                  expression: "typeInvitation"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "d-flex" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pt-0 selectDateWidthPadd"
                                        },
                                        [
                                          _vm.fixedDate
                                            ? _c(
                                                "v-menu",
                                                {
                                                  attrs: {
                                                    "close-on-content-click": false,
                                                    "nudge-right": 40,
                                                    transition:
                                                      "scale-transition",
                                                    "offset-y": ""
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-text-field",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    outlined:
                                                                      "",
                                                                    dense: "",
                                                                    readonly:
                                                                      "",
                                                                    label: _vm.$t(
                                                                      "components.inviteGuestPanel.selectDate"
                                                                    ),
                                                                    disabled:
                                                                      _vm.fixedDate ===
                                                                      false
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.linkDateTo,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.linkDateTo = $$v
                                                                    },
                                                                    expression:
                                                                      "linkDateTo"
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "font-awesome-icon",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "calendarIcon",
                                                                      attrs: {
                                                                        slot:
                                                                          "append",
                                                                        icon: [
                                                                          "fal",
                                                                          "calendar-alt"
                                                                        ]
                                                                      },
                                                                      slot:
                                                                        "append"
                                                                    },
                                                                    on
                                                                  )
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    2836324185
                                                  ),
                                                  model: {
                                                    value: _vm.startLinkDateTo,
                                                    callback: function($$v) {
                                                      _vm.startLinkDateTo = $$v
                                                    },
                                                    expression:
                                                      "startLinkDateTo"
                                                  }
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("v-date-picker", {
                                                    attrs: {
                                                      pickTime: true,
                                                      locale: this.$locale.current(),
                                                      disabled:
                                                        _vm.fixedDate === false,
                                                      "first-day-of-week": "1",
                                                      "allowed-dates":
                                                        _vm.allowedDates
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        _vm.tixdate = false
                                                      }
                                                    },
                                                    model: {
                                                      value: _vm.dateTo,
                                                      callback: function($$v) {
                                                        _vm.dateTo = $$v
                                                      },
                                                      expression: "dateTo"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "selectTimeWidthPadd pt-0"
                                        },
                                        [
                                          _vm.fixedDate
                                            ? _c(
                                                "v-menu",
                                                {
                                                  ref: "menutTo",
                                                  attrs: {
                                                    "close-on-content-click": false,
                                                    "nudge-left": 150,
                                                    "return-value": _vm.time,
                                                    transition:
                                                      "scale-transition",
                                                    "offset-y": ""
                                                  },
                                                  on: {
                                                    "update:returnValue": function(
                                                      $event
                                                    ) {
                                                      _vm.time = $event
                                                    },
                                                    "update:return-value": function(
                                                      $event
                                                    ) {
                                                      _vm.time = $event
                                                    }
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          var attrs = ref.attrs
                                                          return [
                                                            _c(
                                                              "v-text-field",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      readonly:
                                                                        "",
                                                                      outlined:
                                                                        "",
                                                                      dense: "",
                                                                      disabled:
                                                                        _vm.fixedDate ===
                                                                        false,
                                                                      label: _vm.$t(
                                                                        "components.conferenceForm.from"
                                                                      )
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.linkTimeTo,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.linkTimeTo = $$v
                                                                      },
                                                                      expression:
                                                                        "linkTimeTo"
                                                                    }
                                                                  },
                                                                  "v-text-field",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "font-awesome-icon",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "calendarIcon",
                                                                      attrs: {
                                                                        slot:
                                                                          "append",
                                                                        icon: [
                                                                          "far",
                                                                          "clock"
                                                                        ]
                                                                      },
                                                                      slot:
                                                                        "append"
                                                                    },
                                                                    on
                                                                  )
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    3661785837
                                                  ),
                                                  model: {
                                                    value: _vm.menutTo,
                                                    callback: function($$v) {
                                                      _vm.menutTo = $$v
                                                    },
                                                    expression: "menutTo"
                                                  }
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _vm.menutTo
                                                    ? _c("v-time-picker", {
                                                        attrs: {
                                                          format: "24hr",
                                                          disabled:
                                                            _vm.fixedDate ===
                                                            false,
                                                          min: "00:00"
                                                        },
                                                        on: {
                                                          "click:minute": function(
                                                            $event
                                                          ) {
                                                            return _vm.$refs.menutTo.save(
                                                              _vm.linkTimeTo
                                                            )
                                                          },
                                                          "click:hour":
                                                            _vm.autocompleteMinutes
                                                        },
                                                        model: {
                                                          value: _vm.linkTimeTo,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.linkTimeTo = $$v
                                                          },
                                                          expression:
                                                            "linkTimeTo"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "selectDurationWidthPadd pt-0"
                                        },
                                        [
                                          _vm.fixedDate
                                            ? _c(
                                                "v-menu",
                                                {
                                                  ref: "endTime",
                                                  attrs: {
                                                    "close-on-content-click": false,
                                                    "nudge-left": 150,
                                                    "return-value": _vm.time,
                                                    transition:
                                                      "scale-transition",
                                                    "offset-y": "",
                                                    disabled: !_vm.linkTimeTo
                                                  },
                                                  on: {
                                                    "update:returnValue": function(
                                                      $event
                                                    ) {
                                                      _vm.time = $event
                                                    },
                                                    "update:return-value": function(
                                                      $event
                                                    ) {
                                                      _vm.time = $event
                                                    }
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          var attrs = ref.attrs
                                                          return [
                                                            _c(
                                                              "v-text-field",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      readonly:
                                                                        "",
                                                                      outlined:
                                                                        "",
                                                                      dense: "",
                                                                      disabled:
                                                                        _vm.fixedDate ===
                                                                          false ||
                                                                        !_vm.linkTimeTo,
                                                                      label: _vm.$t(
                                                                        "components.conferenceForm.to"
                                                                      )
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.durationMin,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.durationMin = $$v
                                                                      },
                                                                      expression:
                                                                        "durationMin"
                                                                    }
                                                                  },
                                                                  "v-text-field",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "font-awesome-icon",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "calendarIcon",
                                                                      attrs: {
                                                                        slot:
                                                                          "append",
                                                                        icon: [
                                                                          "far",
                                                                          "clock"
                                                                        ]
                                                                      },
                                                                      slot:
                                                                        "append"
                                                                    },
                                                                    on
                                                                  )
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    953171893
                                                  ),
                                                  model: {
                                                    value: _vm.endTime,
                                                    callback: function($$v) {
                                                      _vm.endTime = $$v
                                                    },
                                                    expression: "endTime"
                                                  }
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _vm.endTime
                                                    ? _c("v-time-picker", {
                                                        attrs: {
                                                          format: "24hr",
                                                          disabled:
                                                            _vm.fixedDate ===
                                                              false ||
                                                            !_vm.linkTimeTo,
                                                          min: _vm.linkTimeTo
                                                        },
                                                        on: {
                                                          "click:minute": function(
                                                            $event
                                                          ) {
                                                            return _vm.$refs.endTime.save(
                                                              _vm.durationMin
                                                            )
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.durationMin,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.durationMin = $$v
                                                          },
                                                          expression:
                                                            "durationMin"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0",
                                            attrs: {
                                              cols: "12",
                                              md: "9",
                                              lg: "8"
                                            }
                                          },
                                          [
                                            _c("v-checkbox", {
                                              staticClass: "pt-0 mt-0",
                                              attrs: {
                                                label: _vm.$t(
                                                  "components.inviteGuestPanel.ccBenutzer",
                                                  [
                                                    _vm.getTitelForUuid(
                                                      _vm.state.ownUUID
                                                    ) +
                                                      " " +
                                                      _vm.getNameForUuid(
                                                        _vm.state.ownUUID
                                                      )
                                                  ]
                                                )
                                              },
                                              model: {
                                                value: _vm.carboncopy,
                                                callback: function($$v) {
                                                  _vm.carboncopy = $$v
                                                },
                                                expression: "carboncopy"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-row",
                                      [
                                        _vm.isPayAvailable
                                          ? _c(
                                              "v-col",
                                              { staticClass: "py-0" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "d-flex" },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass:
                                                        "mr-2 maxwidth150",
                                                      attrs: {
                                                        type: "number",
                                                        min: "1",
                                                        max: "10000",
                                                        "hide-details": "",
                                                        dense: "",
                                                        rules: _vm.amountRules
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.checkValuePayLink,
                                                        keyup:
                                                          _vm.checkValuePayLink
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "append",
                                                            fn: function() {
                                                              return [
                                                                _vm.state
                                                                  .namespaceSettings
                                                                  .currency ===
                                                                "EUR"
                                                                  ? _c(
                                                                      "font-awesome-icon",
                                                                      {
                                                                        staticClass:
                                                                          "mt-1",
                                                                        style: {
                                                                          fontSize:
                                                                            "15px"
                                                                        },
                                                                        attrs: {
                                                                          icon: [
                                                                            "fal",
                                                                            "euro-sign"
                                                                          ]
                                                                        }
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _vm.state
                                                                  .namespaceSettings
                                                                  .currency ===
                                                                "GBP"
                                                                  ? _c(
                                                                      "font-awesome-icon",
                                                                      {
                                                                        staticClass:
                                                                          "mt-1",
                                                                        style: {
                                                                          fontSize:
                                                                            "15px"
                                                                        },
                                                                        attrs: {
                                                                          icon: [
                                                                            "far",
                                                                            "pound-sign"
                                                                          ]
                                                                        }
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _vm.state
                                                                  .namespaceSettings
                                                                  .currency ===
                                                                "CHF"
                                                                  ? _c(
                                                                      "v-img",
                                                                      {
                                                                        attrs: {
                                                                          contain:
                                                                            "",
                                                                          height:
                                                                            "20",
                                                                          src:
                                                                            "img/paymentIcons/swiss-franc-black.png",
                                                                          "lazy-src":
                                                                            "img/paymentIcons/swiss-franc.png"
                                                                        }
                                                                      }
                                                                    )
                                                                  : _vm._e()
                                                              ]
                                                            },
                                                            proxy: true
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        145907837
                                                      ),
                                                      model: {
                                                        value:
                                                          _vm.amountPayLink,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.amountPayLink = $$v
                                                        },
                                                        expression:
                                                          "amountPayLink"
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c("p", [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.inviteVisitor.paylinkPayment"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-row",
                                      [
                                        _c("v-col", { staticClass: "py-0" }, [
                                          _c(
                                            "div",
                                            { staticClass: "d-flex" },
                                            [
                                              _c("v-text-field", {
                                                staticClass:
                                                  "mr-2 mt-0 pt-0 maxwidth150",
                                                attrs: {
                                                  autocomplete: "off",
                                                  dense: "",
                                                  maxlength: 6,
                                                  rules: [
                                                    function(v) {
                                                      return !!v || ""
                                                    },
                                                    function(v) {
                                                      return (
                                                        (v && v.length === 6) ||
                                                        ""
                                                      )
                                                    },
                                                    function(v) {
                                                      return (
                                                        /^\d{6}$/.test(v) ||
                                                        this$1.$t(
                                                          "components.2faPin.sixDigitsRequired"
                                                        )
                                                      )
                                                    }
                                                  ]
                                                },
                                                on: {
                                                  keydown: function($event) {
                                                    if (
                                                      !$event.type.indexOf(
                                                        "key"
                                                      ) &&
                                                      _vm._k(
                                                        $event.keyCode,
                                                        "space",
                                                        32,
                                                        $event.key,
                                                        [" ", "Spacebar"]
                                                      )
                                                    ) {
                                                      return null
                                                    }
                                                    $event.preventDefault()
                                                  },
                                                  blur: _vm.checkPin
                                                },
                                                model: {
                                                  value: _vm.pinCode,
                                                  callback: function($$v) {
                                                    _vm.pinCode = $$v
                                                  },
                                                  expression: "pinCode"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("p", [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "components.inviteVisitor.pinAccess"
                                                      )
                                                    ) +
                                                    "\n                      "
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.pinCode
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "body-2 noteStyle"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "components.inviteGuestPanel.pinGuestSenden"
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "py-0" },
                                          [
                                            _c("v-textarea", {
                                              attrs: {
                                                name: "subjectinput",
                                                "no-resize": true,
                                                outlined: "",
                                                dense: "",
                                                rows: 1,
                                                maxlength: "80",
                                                "auto-grow": "",
                                                label: _vm.$t(
                                                  "components.inviteVisitor.notiz"
                                                )
                                              },
                                              model: {
                                                value: _vm.noteForContact,
                                                callback: function($$v) {
                                                  _vm.noteForContact = $$v
                                                },
                                                expression: "noteForContact"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          ),
                          _vm._v(" "),
                          !_vm.reducedMode
                            ? [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pl-8",
                                    attrs: { cols: _vm.isMobile ? 12 : 7 },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                      }
                                    }
                                  },
                                  [
                                    _c("v-textarea", {
                                      staticClass: "inviteTextAreaClass",
                                      attrs: {
                                        spellcheck: "false",
                                        outlined: "",
                                        "no-resize": true,
                                        name: "noteinput",
                                        "auto-grow": "",
                                        rows: "1",
                                        label: _vm.$t(
                                          "components.inviteVisitor.invitationTextHeadline"
                                        ),
                                        maxlength: "2000",
                                        counter: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                        }
                                      },
                                      model: {
                                        value: _vm.inviteText,
                                        callback: function($$v) {
                                          _vm.inviteText = $$v
                                        },
                                        expression: "inviteText"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticStyle: { display: "none" } },
                                      [
                                        _vm._v(
                                          "Value: " + _vm._s(_vm.inviteText)
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      [
                                        _vm.getCustomInviteTexts.length > 0
                                          ? _c("v-autocomplete", {
                                              staticClass: "pb-6",
                                              attrs: {
                                                "search-input":
                                                  _vm.templateSearch,
                                                items: _vm.getCustomInviteTexts,
                                                outlined: "",
                                                "item-text": "title",
                                                "item-value": "title",
                                                "hide-details": "auto",
                                                label: _vm.$t(
                                                  "generics.templates"
                                                ),
                                                dense: "",
                                                clearable: "",
                                                "menu-props": {
                                                  closeOnContentClick: true
                                                }
                                              },
                                              on: {
                                                "update:searchInput": function(
                                                  $event
                                                ) {
                                                  _vm.templateSearch = $event
                                                },
                                                "update:search-input": function(
                                                  $event
                                                ) {
                                                  _vm.templateSearch = $event
                                                },
                                                "click:clear": function(
                                                  $event
                                                ) {
                                                  return _vm.resetCustomMessage()
                                                }
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "item",
                                                    fn: function(data) {
                                                      return [
                                                        _c(
                                                          "v-list-item",
                                                          {
                                                            staticStyle: {
                                                              position:
                                                                "relative",
                                                              "min-height":
                                                                "25px"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.selectText(
                                                                  data
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-content",
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "listTitle mb-1"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                            " +
                                                                        _vm._s(
                                                                          data
                                                                            .item
                                                                            .title
                                                                        ) +
                                                                        "\n                          "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _vm.amIAdmin
                                                              ? _c(
                                                                  "DeleteBasket",
                                                                  {
                                                                    attrs: {
                                                                      indexData:
                                                                        data.item,
                                                                      delFunction:
                                                                        _vm.removeCustomTextFromList,
                                                                      extraClasses:
                                                                        "deleteContactBasket  cursorPointer",
                                                                      fontSize:
                                                                        "20",
                                                                      prevent:
                                                                        "true"
                                                                    }
                                                                  }
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                1437582898
                                              ),
                                              model: {
                                                value: _vm.customTextSelected,
                                                callback: function($$v) {
                                                  _vm.customTextSelected = $$v
                                                },
                                                expression: "customTextSelected"
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.updateText
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "buttonsTextarea pt-6 px-0",
                                                attrs: { align: "right" }
                                              },
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      align:
                                                                        "right",
                                                                      color:
                                                                        "primary",
                                                                      outlined:
                                                                        "",
                                                                      dense: ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.resetCustomMessage(
                                                                          true
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      class: _vm.hideElements
                                                                        ? null
                                                                        : "mr-3",
                                                                      style: {
                                                                        fontSize:
                                                                          "18px"
                                                                      },
                                                                      attrs: {
                                                                        icon: [
                                                                          "fal",
                                                                          "undo"
                                                                        ]
                                                                      }
                                                                    }
                                                                  ),
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.hideElements
                                                                          ? null
                                                                          : _vm.$t(
                                                                              "components.inviteGuestPanel.resetCustomInviteText"
                                                                            )
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1133510750
                                                    )
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "components.inviteGuestPanel.resetCustomInviteText"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm.amIAdmin
                                                  ? [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        {
                                                                          attrs: {
                                                                            align:
                                                                              "right",
                                                                            color:
                                                                              "primary",
                                                                            outlined:
                                                                              "",
                                                                            dense:
                                                                              "",
                                                                            disabled:
                                                                              _vm.disabledSaveText
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              $event.preventDefault()
                                                                              return _vm.saveText()
                                                                            }
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "font-awesome-icon",
                                                                          {
                                                                            class: _vm.hideElements
                                                                              ? null
                                                                              : "mr-3",
                                                                            style: {
                                                                              fontSize:
                                                                                "18px"
                                                                            },
                                                                            attrs: {
                                                                              icon: [
                                                                                "fal",
                                                                                "save"
                                                                              ]
                                                                            }
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          "\n                            " +
                                                                            _vm._s(
                                                                              _vm.hideElements
                                                                                ? null
                                                                                : _vm.$t(
                                                                                    "components.inviteGuestPanel.saveText"
                                                                                  )
                                                                            ) +
                                                                            "\n                          "
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            false,
                                                            3441939991
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "components.inviteGuestPanel.saveText"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        {
                                                                          attrs: {
                                                                            disabled:
                                                                              _vm
                                                                                .getCustomInviteTexts
                                                                                .length >=
                                                                                20 ||
                                                                              _vm.isTextEmpty,
                                                                            align:
                                                                              "right",
                                                                            color:
                                                                              "primary",
                                                                            outlined:
                                                                              "",
                                                                            dense:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.saveTextAsModal()
                                                                            }
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "font-awesome-icon",
                                                                          {
                                                                            class: _vm.hideElements
                                                                              ? null
                                                                              : "mr-3",
                                                                            style: {
                                                                              fontSize:
                                                                                "18px"
                                                                            },
                                                                            attrs: {
                                                                              icon: [
                                                                                "fal",
                                                                                "save"
                                                                              ]
                                                                            }
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          "\n                            " +
                                                                            _vm._s(
                                                                              _vm.hideElements
                                                                                ? null
                                                                                : _vm.$t(
                                                                                    "components.inviteVisitor.saveTextAs"
                                                                                  )
                                                                            ) +
                                                                            "\n                          "
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            false,
                                                            483225378
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "components.inviteVisitor.saveTextAs"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0 d-flex justify-end",
                              attrs: { cols: _vm.isMobile ? 12 : 5 }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", outlined: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.reducedMode = !_vm.reducedMode
                                    }
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.reducedMode
                                            ? _vm.$t("generics.extend")
                                            : _vm.$t("generics.reduced")
                                        ) +
                                        "\n                "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _vm.reducedMode
                                    ? _c("font-awesome-icon", {
                                        staticClass: "ml-1",
                                        style: { fontSize: "16px" },
                                        attrs: { icon: ["fal", "arrow-down"] }
                                      })
                                    : _c("font-awesome-icon", {
                                        staticClass: "ml-1",
                                        style: { fontSize: "16px" },
                                        attrs: { icon: ["fal", "arrow-up"] }
                                      })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.errMssg
                        ? _c(
                            "v-row",
                            {
                              staticClass: "mx-0 py-2",
                              staticStyle: { width: "100%" }
                            },
                            [
                              _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticStyle: { color: "red" },
                                    attrs: { align: "left" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.emailExists
                                            ? _vm.$t(
                                                "components.inviteGuestPanel.sameEmail"
                                              )
                                            : ""
                                        )
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "mx-auto footerRow" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "px-0 pt-2", attrs: { cols: "12" } },
                            [_c("v-divider")],
                            1
                          ),
                          _vm._v(" "),
                          !_vm.hideNoteInviteGuest
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "px-0 pb-0 align-self-center",
                                  attrs: {
                                    cols: _vm.isMobile ? 12 : 4,
                                    md: _vm.isMobile ? 12 : 6,
                                    align: "right"
                                  }
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "mb-0 body-2 noteStyle",
                                      attrs: { align: "left" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-medium" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "components.inviteGuestPanel.note"
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "components.inviteGuestPanel.guestWaitingroom",
                                            [
                                              _vm.getTitelForUuid(
                                                _vm.isAssistsInviteForm
                                                  ? _vm.isAssistsInviteForm
                                                  : _vm.state.ownUUID
                                              ) +
                                                " " +
                                                _vm.getNameForUuid(
                                                  _vm.isAssistsInviteForm
                                                    ? _vm.isAssistsInviteForm
                                                    : _vm.state.ownUUID
                                                )
                                            ]
                                          )
                                        ) + "\n              "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  !_vm.hideNoteInviteGuest
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "px-0 pt-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "v-checkbox",
                                            {
                                              staticClass: "mt-0",
                                              attrs: {
                                                "hide-details": "",
                                                label: _vm.$t(
                                                  "components.meetingTime.dontShowAgain"
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.setHideNoteInviteGuest(
                                                    _vm.hideNoteInviteGuest
                                                  )
                                                }
                                              },
                                              model: {
                                                value: _vm.hideNoteInviteGuest,
                                                callback: function($$v) {
                                                  _vm.hideNoteInviteGuest = $$v
                                                },
                                                expression:
                                                  "hideNoteInviteGuest"
                                              }
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "label" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "mb-0 font-italic"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.meetingTime.dontShowAgain"
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "px-0",
                              attrs: {
                                cols:
                                  _vm.hideNoteInviteGuest || _vm.isMobile
                                    ? 12
                                    : 8,
                                md:
                                  _vm.hideNoteInviteGuest || _vm.isMobile
                                    ? 12
                                    : 6,
                                align: _vm.isMobile ? "center" : "right"
                              }
                            },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                class:
                                                  (_vm.isMobile
                                                    ? "mb-2"
                                                    : null) + " cursorPointer",
                                                attrs: {
                                                  align: "right",
                                                  color: "primary",
                                                  outlined: "",
                                                  disabled:
                                                    !_vm.name ||
                                                    _vm.name.length == 0 ||
                                                    _vm.isEmailInvalid
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.addUserToList()
                                                  }
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c("font-awesome-icon", {
                                                staticClass: "mr-3",
                                                style: { fontSize: "18px" },
                                                attrs: { icon: ["fal", "save"] }
                                              }),
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "components.inviteGuestPanel.saveContact"
                                                    )
                                                  ) +
                                                  "\n                  "
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.inviteVisitor.ttSaveDiskette"
                                        )
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-3",
                                  attrs: {
                                    disabled: _vm.disableSendButton,
                                    color: "primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.precheckNewVisitor.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.inviteGuestPanel.sendInvitation"
                                      )
                                    ) + "\n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-3 mr-2",
                                  staticStyle: {
                                    "border-radius": "4px",
                                    "min-width": "45px"
                                  },
                                  attrs: {
                                    icon: "",
                                    tile: "",
                                    outlined: "",
                                    color: "primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.closeInvitationForm()
                                    }
                                  }
                                },
                                [
                                  _c("font-awesome-icon", {
                                    style: { fontSize: "20px" },
                                    attrs: { icon: ["fal", "times"] }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-tab-item", [
                _vm.getVisitors.length > 0
                  ? _c(
                      "div",
                      { staticClass: "visitorListTable" },
                      _vm._l(_vm.getVisitors, function(invitation, index) {
                        return _c(
                          "div",
                          { key: invitation.uuid || index },
                          [
                            _c("div", { staticClass: "d-flex Table" }, [
                              _c("div", { staticClass: "ListAvatar" }, [
                                _c(
                                  "div",
                                  { staticClass: "AvatarImage" },
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-img",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          src:
                                                            _vm.getAvatarForUuid(
                                                              invitation.uuid
                                                            ) ||
                                                            "img/default_profile_picture.png",
                                                          "lazy-src":
                                                            "img/default_profile_picture.png"
                                                        }
                                                      },
                                                      on
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(invitation.noteForContact)
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "ListMessage" },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass: "mt-1 mx-2",
                                                      attrs: {
                                                        icon: "",
                                                        small: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.setShowModalSenMsg(
                                                            invitation.uuid
                                                          )
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c("font-awesome-icon", {
                                                      style: {
                                                        fontSize: "20px"
                                                      },
                                                      attrs: {
                                                        icon: [
                                                          "fal",
                                                          "paper-plane"
                                                        ]
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.groups.sendMessage"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "ListName pl-2" },
                                [
                                  _vm.editingName &&
                                  _vm.editingName == invitation.uuid
                                    ? [
                                        _c("v-text-field", {
                                          staticClass: "pr-5",
                                          attrs: {
                                            dense: "",
                                            clearable: "",
                                            type: "text",
                                            "hide-details": "",
                                            maxlength: "60",
                                            disabled: _vm.savingVisitorName
                                          },
                                          on: {
                                            "click:clear": function($event) {
                                              return _vm.editNameGuest(
                                                invitation
                                              )
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "append",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "pt-1",
                                                        attrs: {
                                                          icon: "",
                                                          small: "",
                                                          loading:
                                                            _vm.savingVisitorName,
                                                          disabled: !_vm.newVisitorName.trim()
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.saveNewVisitorName(
                                                              invitation
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            style: {
                                                              fontSize: "18px"
                                                            },
                                                            attrs: {
                                                              icon: [
                                                                "fal",
                                                                "save"
                                                              ]
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.newVisitorName,
                                            callback: function($$v) {
                                              _vm.newVisitorName = $$v
                                            },
                                            expression: "newVisitorName"
                                          }
                                        })
                                      ]
                                    : [
                                        _c("p", { staticClass: "mt-2" }, [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.getTitelForUuid(
                                                  invitation.uuid
                                                )
                                              ) +
                                              " " +
                                              _vm._s(invitation.name) +
                                              "\n                "
                                          )
                                        ])
                                      ]
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "ListEmail" }, [
                                _c(
                                  "p",
                                  { staticClass: "mt-2" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(invitation.email) +
                                        "\n                  "
                                    ),
                                    invitation.uuid
                                      ? _c("font-awesome-icon", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.hasUuidBeenOnPage(
                                                invitation.uuid
                                              ),
                                              expression:
                                                "hasUuidBeenOnPage(invitation.uuid)"
                                            }
                                          ],
                                          staticClass: "ml-2",
                                          attrs: {
                                            icon: ["fal", "check"],
                                            color: "primary"
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "listDate" }, [
                                _c("p", { staticClass: "mt-2" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.$t("generics.sentOn") +
                                          " " +
                                          _vm.formatDateToShow(
                                            invitation.created
                                          ) +
                                          " " +
                                          _vm.$t("generics.hours")
                                      ) +
                                      "\n                "
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _vm.getInviteType(invitation) &&
                              _vm.getInviteType(invitation).trim()
                                ? _c("div", { staticClass: "listDate" }, [
                                    _c("p", { staticClass: "mt-2" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.getInviteType(invitation)
                                          ) +
                                          "\n                "
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "ListPayLink" }, [
                                _vm.totalPaid[invitation.uuid] &&
                                _vm.totalPaid[invitation.uuid] != ""
                                  ? _c("img", {
                                      staticClass: "facepayImg mr-2",
                                      attrs: {
                                        src: "img/icons/facepay.svg",
                                        "lazy-src": "img/icons/facepay.svg"
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", { staticClass: "mt-1" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.totalPaid[invitation.uuid] || ""
                                      ) +
                                      "\n                "
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "ListResend" },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass: "mt-1",
                                                      attrs: {
                                                        icon: "",
                                                        small: "",
                                                        disabled:
                                                          _vm.savingVisitorName
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.editNameGuest(
                                                            invitation
                                                          )
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c("font-awesome-icon", {
                                                      style: {
                                                        fontSize: "20px"
                                                      },
                                                      attrs: {
                                                        icon: ["fal", "pen"]
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("generics.edit")))
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "ListResend" },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass: "mt-1",
                                                      attrs: {
                                                        icon: "",
                                                        small: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.SendLinkToVisitor(
                                                            invitation.uuid,
                                                            invitation
                                                          )
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c("font-awesome-icon", {
                                                      style: {
                                                        fontSize: "20px"
                                                      },
                                                      attrs: {
                                                        icon: ["fal", "redo"]
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.inviteVisitor.resendInvLink"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "ListCopy" },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass: "mt-1",
                                                      attrs: {
                                                        icon: "",
                                                        small: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.copyLink(
                                                            $event,
                                                            invitation
                                                          )
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c("font-awesome-icon", {
                                                      style: {
                                                        fontSize: "20px"
                                                      },
                                                      attrs: {
                                                        icon: ["fal", "copy"]
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.inviteGuestPanel.copyLink"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "ListDelete" },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass: "mt-1",
                                                      attrs: {
                                                        icon: "",
                                                        small: ""
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c("DeleteBasket", {
                                                      attrs: {
                                                        indexData:
                                                          invitation.uuid,
                                                        delFunction:
                                                          _vm.deleteVisitor,
                                                        extraClasses:
                                                          "deleteUserFromList",
                                                        fontSize: "20"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("generics.delete"))
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("v-divider", { staticClass: "mt-2 mb-2" })
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e()
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showInvitationModal
        ? [
            _c("SendInvitationModal", {
              attrs: {
                closeShowInviteInfo: _vm.closeShowInviteInfo,
                showInviteInfo: _vm.showInvitationModal
              }
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showSaveTextModal
        ? [
            _c("SaveCustomTextModal", {
              attrs: {
                showModal: _vm.showSaveTextModal,
                closeModal: _vm.openCloseSaveTextModal,
                saveTextAs: _vm.saveTextAs
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }