var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "700" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.closeModal.apply(null, arguments)
            }
          },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("HeaderModal", {
                attrs: {
                  titleModal: _vm.titleModal,
                  closeModalFunction: _vm.closeModal
                }
              }),
              _vm._v(" "),
              _c(
                "v-row",
                {
                  staticClass: "w-100 mx-0 my-0 px-3 pt-3",
                  class: _vm.isMobile ? "containerModal" : ""
                },
                [
                  _vm._l(2, function(n) {
                    return _c(
                      "v-col",
                      {
                        key: n,
                        staticClass: "my-0 py-0 px-0",
                        class: {
                          "text-center avatarContainerModal":
                            _vm.isMobile && n == 1
                        },
                        attrs: {
                          lg: _vm.cols[n - 1],
                          md: _vm.cols[n - 1],
                          sm: _vm.cols[n - 1],
                          xl: _vm.cols[n - 1]
                        }
                      },
                      [
                        n == 1
                          ? _c(
                              "div",
                              { staticClass: "pl-3" },
                              [
                                !_vm.multiUserCallQueueStore
                                  ? _c(
                                      "v-list-item-avatar",
                                      {
                                        staticClass: "borderRadius4",
                                        style: _vm.getBorderByStatusFull(
                                          _vm.state.group[
                                            _vm.state.showModalMeetingTime
                                          ]
                                        ),
                                        attrs: { tile: "", size: "150" },
                                        on: {
                                          dblclick: function($event) {
                                            return _vm.callWithoutTime(
                                              _vm.state.showModalMeetingTime
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm.getAvatarForUuid(
                                          _vm.state.showModalMeetingTime
                                        ) !== "img/default_profile_picture.png"
                                          ? _c("v-img", {
                                              staticClass: "borderRadius4",
                                              attrs: {
                                                "max-height": "150",
                                                "max-width": "150",
                                                contain: "",
                                                src: _vm.getAvatarForUuid(
                                                  _vm.state.showModalMeetingTime
                                                ),
                                                "lazy-src":
                                                  "img/default_profile_picture.png"
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.getAvatarForUuid(
                                          _vm.state.showModalMeetingTime
                                        ) === "img/default_profile_picture.png"
                                          ? _c("DefaultAvatar", {
                                              attrs: {
                                                size: 120,
                                                userData:
                                                  _vm.state.group[
                                                    _vm.state
                                                      .showModalMeetingTime
                                                  ].user
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _c(
                                          "v-list",
                                          {
                                            staticClass: "userQueueStyle pt-0"
                                          },
                                          _vm._l(
                                            _vm.multiUserCallQueueStore,
                                            function(uuid) {
                                              return _c(
                                                "v-list-item",
                                                {
                                                  key: uuid,
                                                  attrs: { dense: "" }
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-avatar",
                                                    {
                                                      attrs: {
                                                        "min-width": "25px",
                                                        "min-height": "25px",
                                                        width: "25px",
                                                        height: "25px",
                                                        "max-width": "25px",
                                                        "max-height": "25px"
                                                      }
                                                    },
                                                    [
                                                      _c("v-img", {
                                                        attrs: {
                                                          src: _vm.getAvatarForUuid(
                                                            uuid
                                                          )
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass:
                                                        "queueContent"
                                                    },
                                                    [
                                                      _c("v-list-item-title", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.$sanitize(
                                                              _vm.getNameForUuid(
                                                                uuid
                                                              )
                                                            )
                                                          )
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-list-item-subtitle",
                                                        {
                                                          staticClass:
                                                            "trashQueue"
                                                        },
                                                        [
                                                          uuid !=
                                                          _vm.state
                                                            .showModalMeetingTime
                                                            ? _c(
                                                                "DeleteBasket",
                                                                {
                                                                  attrs: {
                                                                    indexData: uuid,
                                                                    delFunction:
                                                                      _vm.removeUserFromMultiUserCalQueue,
                                                                    extraClasses:
                                                                      "pointer mb-1",
                                                                    fontSize:
                                                                      "16"
                                                                  }
                                                                }
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      ],
                                      1
                                    )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        n == 2
                          ? _c("div", { staticClass: "pt-3 pr-3" }, [
                              _vm.getTimelineSelection
                                ? _c(
                                    "div",
                                    { staticClass: "mb-0" },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "mt-0",
                                        attrs: {
                                          label: "Timeline",
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: _vm.showTimeLine,
                                          callback: function($$v) {
                                            _vm.showTimeLine = $$v
                                          },
                                          expression: "showTimeLine"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showDurationCall
                                ? _c("div", [
                                    _c("p", { staticClass: "pb-0 mt-4 mb-1" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "components.meetingLineModal.plannedDuration"
                                            )
                                          ) +
                                          "\n              "
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showDurationCall
                                ? _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c(
                                        "v-radio-group",
                                        {
                                          staticClass: "my-0",
                                          attrs: { row: "", dense: "" },
                                          on: {
                                            change: _vm.changeCallDuration
                                          },
                                          model: {
                                            value: _vm.select,
                                            callback: function($$v) {
                                              _vm.select = $$v
                                            },
                                            expression: "select"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            { staticClass: "my-0 mx-0" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  class: {
                                                    "px-0 py-1": !_vm.isMobile,
                                                    "pt-0 pb-4": _vm.isMobile
                                                  },
                                                  attrs: {
                                                    cols: !_vm.isMobile ? 3 : 6
                                                  }
                                                },
                                                [
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: "5 min",
                                                      value: "300000"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  class: {
                                                    "px-0 py-1": !_vm.isMobile,
                                                    "pt-0 pb-4": _vm.isMobile
                                                  },
                                                  attrs: {
                                                    cols: !_vm.isMobile ? 3 : 6
                                                  }
                                                },
                                                [
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: "10 min",
                                                      value: "600000"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  class: {
                                                    "px-0 py-1": !_vm.isMobile,
                                                    "pt-0 pb-4": _vm.isMobile
                                                  },
                                                  attrs: {
                                                    cols: !_vm.isMobile ? 3 : 6
                                                  }
                                                },
                                                [
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: "20 min",
                                                      value: "1200000"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  class: {
                                                    "px-0 py-1": !_vm.isMobile,
                                                    "pt-0 pb-4": _vm.isMobile
                                                  },
                                                  attrs: {
                                                    cols: !_vm.isMobile ? 3 : 6
                                                  }
                                                },
                                                [
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: "30 min",
                                                      value: "1800000"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  class: {
                                                    "px-0 py-1": !_vm.isMobile,
                                                    "pt-0 pb-4": _vm.isMobile
                                                  },
                                                  attrs: {
                                                    cols: !_vm.isMobile ? 3 : 6
                                                  }
                                                },
                                                [
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: "40 min",
                                                      value: "2400000"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  class: {
                                                    "px-0 py-1": !_vm.isMobile,
                                                    "pt-0 pb-4": _vm.isMobile
                                                  },
                                                  attrs: {
                                                    cols: !_vm.isMobile ? 3 : 6
                                                  }
                                                },
                                                [
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: "50 min",
                                                      value: "3000000"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  class: {
                                                    "px-0 py-1": !_vm.isMobile,
                                                    "pt-0 pb-4": _vm.isMobile
                                                  },
                                                  attrs: {
                                                    cols: !_vm.isMobile ? 3 : 6
                                                  }
                                                },
                                                [
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: "60 min",
                                                      value: "3600000"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  class: {
                                                    "px-0 py-1": !_vm.isMobile,
                                                    "pt-0 pb-4": _vm.isMobile
                                                  },
                                                  attrs: {
                                                    cols: !_vm.isMobile ? 3 : 6
                                                  }
                                                },
                                                [
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "components.meetingTime.unlimited"
                                                      ),
                                                      value: "86400000"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          : _vm._e()
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm.state.persisted.tmpMessages
                    .showMessageInMeetingTimeModal && !_vm.isMobile
                    ? _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("v-divider"),
                          _vm._v(" "),
                          _c("p", { staticClass: "pt-5" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.meetingTime.spaceBarTip")
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-checkbox", {
                            attrs: {
                              label: _vm.$t(
                                "components.meetingTime.dontShowAgain"
                              )
                            },
                            model: {
                              value: _vm.checkedMessage,
                              callback: function($$v) {
                                _vm.checkedMessage = $$v
                              },
                              expression: "checkedMessage"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "pb-5 pt-0 px-2", attrs: { cols: "12" } },
                    [
                      _c("CallModalCameraPreview", {
                        attrs: {
                          typeCall: "meeting",
                          height: "100%",
                          width: "100%"
                        }
                      })
                    ],
                    1
                  )
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "FooterModal",
                { attrs: { closeModalFunction: _vm.closeModal } },
                [
                  [
                    _vm.amIAdmin
                      ? _c(
                          "v-menu",
                          {
                            attrs: {
                              top: "",
                              "offset-y": "",
                              "close-on-click": true
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "ml-2 btnAbsolute",
                                              attrs: {
                                                icon: "",
                                                color: "white"
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("font-awesome-icon", {
                                            style: { fontSize: "30px" },
                                            attrs: {
                                              icon: ["fal", "ellipsis-v"]
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3582511441
                            )
                          },
                          [
                            _vm._v(" "),
                            _c(
                              "v-list",
                              [
                                _vm.amIAdmin
                                  ? _c(
                                      "v-list-item",
                                      { staticClass: "pointer" },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.toggleMeetingTimeGlobalDuration()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.meetingTime.timeSet"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass:
                                        "ml-3 buttonIconPrimaryFooter callButton px-2",
                                      attrs: {
                                        icon: "",
                                        tile: "",
                                        color: "primary"
                                      },
                                      on: { click: _vm.startCall }
                                    },
                                    on
                                  ),
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("generics.startCall")) +
                                        "\n              "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("generics.startCall")))
                        ])
                      ]
                    )
                  ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("MeetingTimeGlobalDuration", {
        attrs: {
          toggleMeetingTimeGlobalDuration: _vm.toggleMeetingTimeGlobalDuration,
          showModal: _vm.showMeetingTimeGlobalDuration
        }
      }),
      _vm._v(" "),
      _c("MeetingTimeTitle", {
        attrs: {
          toggleMeetingTimeTitle: _vm.toggleMeetingTimeTitle,
          showModal: _vm.showMeetingTimeTitle
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }