var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selectedUser
    ? _c(
        "div",
        { staticClass: "ml-2" },
        [
          _c("div", { staticClass: "backColor py-1 mx-0" }, [
            _c(
              "div",
              { staticClass: "avatarWrapper" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "userMgmtHeaderClose",
                    attrs: { icon: "", color: "gray" },
                    on: { click: _vm.closeCross }
                  },
                  [
                    _c("font-awesome-icon", {
                      style: { fontSize: "18px" },
                      attrs: { icon: ["fal", "times"] }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.isInsertingRecord && _vm.canDeleteUser
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { left: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "userMgmtHeaderDelete",
                                        attrs: { icon: "", color: "gray" },
                                        on: {
                                          click: function($event) {
                                            _vm.showDeleteUserModal = true
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c("font-awesome-icon", {
                                        style: { fontSize: "17px" },
                                        attrs: { icon: ["fal", "trash"] }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3450805432
                        )
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("generics.deleteUserTitle")))
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("img", {
                  staticClass: "userMgmtAvatar",
                  attrs: { src: _vm.getSelectedUserAvatar }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class:
                      "uploadAvatarButtons px-4 mx-auto " +
                      (_vm.disableAvatarBtn ? "d-none" : ""),
                    attrs: { disabled: _vm.disableAvatarBtn, flex: "" }
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        class:
                          "avatarChange mr-1 ml-1 " +
                          (_vm.disableAvatarBtn ? "d-none" : ""),
                        attrs: {
                          disabled: _vm.disableAvatarBtn,
                          depressed: "",
                          color: "primary",
                          isSmall: "true"
                        },
                        on: {
                          click: function($event) {
                            return _vm.changeAvatar()
                          }
                        }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.getSelectedUserAvatar ===
                                "img/default_profile_picture.png"
                                ? _vm.$t("generics.addImage")
                                : _vm.$t(
                                    "components.userManagement.changePicture"
                                  )
                            )
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mx-0 backColor2" },
            [
              _c("v-col", { staticStyle: { "text-align": "center" } }, [
                _c("span", [_vm._v(_vm._s(_vm.vofficeName))])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mx-0 backColor" },
            [
              _c(
                "v-col",
                { staticClass: "cols-12 col px-0 py-0" },
                [
                  _c(
                    "v-tabs",
                    {
                      staticClass: "tabInvite",
                      attrs: { centered: "", "background-color": "#e4f4f6" },
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c(
                        "v-tab",
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("components.userManagement.general")
                              ) +
                              "\n          "
                          ),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "font-awesome-icon",
                                          _vm._g(
                                            {
                                              style: {
                                                fontSize: "15px",
                                                position: "relative",
                                                marginLeft: "2px"
                                              },
                                              attrs: {
                                                color: "#02889d",
                                                icon: ["fal", "info-circle"]
                                              }
                                            },
                                            on
                                          )
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1498331409
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.userManagement.generalTT"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tab",
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "components.userManagement.administration"
                                )
                              ) +
                              "\n          "
                          ),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "font-awesome-icon",
                                          _vm._g(
                                            {
                                              style: {
                                                fontSize: "15px",
                                                position: "relative",
                                                marginLeft: "2px"
                                              },
                                              attrs: {
                                                color: "#02889d",
                                                icon: ["fal", "info-circle"]
                                              }
                                            },
                                            on
                                          )
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1498331409
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.userManagement.administrationTT"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tab",
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("components.userManagement.permissions")
                              ) +
                              "\n           "
                          ),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "font-awesome-icon",
                                          _vm._g(
                                            {
                                              style: {
                                                fontSize: "15px",
                                                position: "relative",
                                                marginLeft: "2px"
                                              },
                                              attrs: {
                                                color: "#02889d",
                                                icon: ["fal", "info-circle"]
                                              }
                                            },
                                            on
                                          )
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1498331409
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.userManagement.permissionsTabTT"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c(
                        "v-tab-item",
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "detailsCard px-5 pb-5",
                              attrs: { flat: "" }
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "userMgmtdetails mx-0" },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "col-12 px-1 py-1 d-flex" },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            top: "",
                                            "max-width": "400",
                                            disabled: !_vm.userIsConnected
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g({}, on),
                                                      [
                                                        _c("v-checkbox", {
                                                          attrs: {
                                                            label:
                                                              "Administrator",
                                                            disabled:
                                                              _vm.userIsConnected
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.changesInForm(
                                                                true
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value: _vm.isAdmin,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.isAdmin = $$v
                                                            },
                                                            expression:
                                                              "isAdmin"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2376110182
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.userManagement.noChange"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("v-checkbox", {
                                        staticClass: "ml-4",
                                        attrs: {
                                          disabled: _vm.noData,
                                          label:
                                            _vm.$t(
                                              "components.userManagement.RRHH"
                                            ) +
                                            " " +
                                            _vm.$t("generics.comingSoon")
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.changesInForm(true)
                                          }
                                        },
                                        model: {
                                          value: _vm.isHr,
                                          callback: function($$v) {
                                            _vm.isHr = $$v
                                          },
                                          expression: "isHr"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-checkbox", {
                                        staticClass: "ml-4",
                                        attrs: {
                                          label: _vm.$t(
                                            "components.userManagement.intern"
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.changesInForm(true)
                                          }
                                        },
                                        model: {
                                          value: _vm.isIntern,
                                          callback: function($$v) {
                                            _vm.isIntern = $$v
                                          },
                                          expression: "isIntern"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { staticClass: "col-12 px-1 py-1" },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "mb-5",
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          autocomplete: "off",
                                          label: _vm.$t(
                                            "components.userManagement.title"
                                          ),
                                          "hide-details": ""
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.changesInForm(true)
                                          }
                                        },
                                        model: {
                                          value: _vm.personTitle,
                                          callback: function($$v) {
                                            _vm.personTitle = $$v
                                          },
                                          expression: "personTitle"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        staticClass: "mb-5",
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          autocomplete: "off",
                                          label: _vm.$t(
                                            "components.userManagement.surname"
                                          ),
                                          "hide-details": ""
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.changesInForm(true)
                                          }
                                        },
                                        model: {
                                          value: _vm.lastName,
                                          callback: function($$v) {
                                            _vm.lastName = $$v
                                          },
                                          expression: "lastName"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        staticClass: "mb-5",
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          autocomplete: "off",
                                          label: _vm.$t(
                                            "components.userManagement.firstName"
                                          ),
                                          "hide-details": ""
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.changesInForm(true)
                                          }
                                        },
                                        model: {
                                          value: _vm.firstName,
                                          callback: function($$v) {
                                            _vm.firstName = $$v
                                          },
                                          expression: "firstName"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        staticClass: "mb-5",
                                        attrs: {
                                          outlined: "",
                                          rules: _vm.vofficeNameRules,
                                          dense: "",
                                          autocomplete: "off",
                                          label: _vm.$t(
                                            "components.userManagement.vOfficeUserName"
                                          ),
                                          "hide-details": ""
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.changesInForm(true)
                                          }
                                        },
                                        model: {
                                          value: _vm.vofficeName,
                                          callback: function($$v) {
                                            _vm.vofficeName = $$v
                                          },
                                          expression: "vofficeName"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        staticClass: "mb-5",
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          autocomplete: "off",
                                          disabled: !_vm.isInsertingRecord,
                                          label: _vm.$t(
                                            "components.userManagement.email"
                                          ),
                                          rules: _vm.emailRules,
                                          "hide-details": ""
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.changesInForm(true)
                                          }
                                        },
                                        model: {
                                          value: _vm.email,
                                          callback: function($$v) {
                                            _vm.email = $$v
                                          },
                                          expression: "email"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        staticClass: "mb-5",
                                        attrs: {
                                          dense: "",
                                          outlined: "",
                                          label: _vm.$t("excel.position"),
                                          "hide-details": ""
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.changesInForm(true)
                                          }
                                        },
                                        model: {
                                          value: _vm.position,
                                          callback: function($$v) {
                                            _vm.position = $$v
                                          },
                                          expression: "position"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-combobox", {
                                        staticClass: "mb-5",
                                        attrs: {
                                          outlined: "",
                                          items: _vm.dataComboboxAllUsers,
                                          "search-input": _vm.searchUsers,
                                          select: "",
                                          label: _vm.$t("excel.representative"),
                                          "hide-details": "",
                                          dense: "",
                                          "item-value": "value",
                                          "item-text": "text",
                                          "return-object": true
                                        },
                                        on: {
                                          "update:searchInput": function(
                                            $event
                                          ) {
                                            _vm.searchUsers = $event
                                          },
                                          "update:search-input": function(
                                            $event
                                          ) {
                                            _vm.searchUsers = $event
                                          },
                                          change: function($event) {
                                            _vm.searchUsers = ""
                                            _vm.changesInForm(true)
                                          }
                                        },
                                        model: {
                                          value: _vm.representative,
                                          callback: function($$v) {
                                            _vm.representative = $$v
                                          },
                                          expression: "representative"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tab-item",
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "detailsCard pa-5",
                              attrs: { flat: "" }
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "userMgmtdetails mx-0" },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "col-12 px-1 py-1" },
                                    [
                                      _c("v-combobox", {
                                        staticClass: "mb-5",
                                        attrs: {
                                          outlined: "",
                                          items: _vm.dataComboboxAllUsers,
                                          "search-input": _vm.searchUsers,
                                          select: "",
                                          label: _vm.$t("excel.assistants"),
                                          multiple: "",
                                          "hide-details": "",
                                          dense: "",
                                          "item-value": "value",
                                          "item-text": "text",
                                          "return-object": true
                                        },
                                        on: {
                                          "update:searchInput": function(
                                            $event
                                          ) {
                                            _vm.searchUsers = $event
                                          },
                                          "update:search-input": function(
                                            $event
                                          ) {
                                            _vm.searchUsers = $event
                                          },
                                          change: function($event) {
                                            _vm.searchUsers = ""
                                            _vm.changesInForm(true)
                                          }
                                        },
                                        model: {
                                          value: _vm.assistants,
                                          callback: function($$v) {
                                            _vm.assistants = $$v
                                          },
                                          expression: "assistants"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        staticClass: "mb-5",
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          autocomplete: "off",
                                          label: _vm.$t("excel.phone"),
                                          "hide-details": ""
                                        },
                                        on: {
                                          keypress: function($event) {
                                            return _vm.hasCorrectPhone(
                                              $event,
                                              _vm.phone
                                            )
                                          },
                                          change: function($event) {
                                            return _vm.changesInForm(true)
                                          }
                                        },
                                        model: {
                                          value: _vm.phone,
                                          callback: function($$v) {
                                            _vm.phone = $$v
                                          },
                                          expression: "phone"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        staticClass: "mb-5",
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          autocomplete: "off",
                                          label: _vm.$t("excel.extension"),
                                          "hide-details": ""
                                        },
                                        on: {
                                          keypress: function($event) {
                                            return _vm.isOnlyNumbers($event)
                                          },
                                          change: function($event) {
                                            return _vm.changesInForm(true)
                                          }
                                        },
                                        model: {
                                          value: _vm.extension,
                                          callback: function($$v) {
                                            _vm.extension = $$v
                                          },
                                          expression: "extension"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        staticClass: "mb-5",
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          autocomplete: "off",
                                          label: _vm.$t("excel.mobilePhone"),
                                          "hide-details": ""
                                        },
                                        on: {
                                          keypress: function($event) {
                                            return _vm.hasCorrectPhone(
                                              $event,
                                              _vm.mobilePhone
                                            )
                                          },
                                          change: function($event) {
                                            return _vm.changesInForm(true)
                                          }
                                        },
                                        model: {
                                          value: _vm.mobilePhone,
                                          callback: function($$v) {
                                            _vm.mobilePhone = $$v
                                          },
                                          expression: "mobilePhone"
                                        }
                                      }),
                                      _vm._v(" "),
                                      !_vm.editingPhone
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "tex0t-truncate body-2 containerWrapper mb-5"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    style: _vm.phoneNumberValid
                                                      ? {}
                                                      : { color: "red" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "components.2faSms.recoveryPhoneNumber"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(_vm.recoveryPhone) +
                                                    "\n                    "
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "d-flex" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          slot: "label"
                                                        },
                                                        slot: "label"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.2faSms.userPhoneNumberLabel"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("v-checkbox", {
                                                      staticClass:
                                                        "mt-0 py-0 px-1",
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.changesInForm(
                                                            true
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value: _vm.dontUsePhone,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.dontUsePhone = $$v
                                                        },
                                                        expression:
                                                          "dontUsePhone"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ]
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.editingPhone
                                        ? [
                                            _c("vue-tel-input", {
                                              staticClass:
                                                "\n                      focusStyle\n                      mb-5\n                      form-control\n                      text-center\n                      input-sm\n                      inputPhone2FA\n                    ",
                                              class: {
                                                borderError: !_vm.phoneInputValid
                                              },
                                              attrs: {
                                                required: "",
                                                defaultCountry: "DE",
                                                enabledCountryCode: "",
                                                name: "number",
                                                id: "numDisplay",
                                                value: "",
                                                autocomplete: "off",
                                                placeholder: _vm.$t(
                                                  "components.userManagement.recoverPinTT"
                                                )
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.changesInForm(true)
                                                },
                                                onInput: _vm.onTelInput
                                              },
                                              model: {
                                                value: _vm.recoveryPhone,
                                                callback: function($$v) {
                                                  _vm.recoveryPhone = $$v
                                                },
                                                expression: "recoveryPhone"
                                              }
                                            })
                                          ]
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "d-flex" },
                                        [
                                          _c("v-checkbox", {
                                            staticClass: "mt-0",
                                            attrs: {
                                              label: _vm.$t("excel.noSendEmail")
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.changesInForm(true)
                                              }
                                            },
                                            model: {
                                              value: _vm.noaccessmail,
                                              callback: function($$v) {
                                                _vm.noaccessmail = $$v
                                              },
                                              expression: "noaccessmail"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "font-awesome-icon",
                                                          _vm._g(
                                                            {
                                                              style: {
                                                                fontSize:
                                                                  "18px",
                                                                position:
                                                                  "relative",
                                                                marginLeft:
                                                                  "4px",
                                                                marginTop: "8px"
                                                              },
                                                              attrs: {
                                                                color:
                                                                  "#02889d",
                                                                icon: [
                                                                  "fal",
                                                                  "info-circle"
                                                                ]
                                                              }
                                                            },
                                                            on
                                                          )
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                3393792963
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  "\n                          " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "components.userManagement.sendInvitationTT"
                                                      )
                                                    ) +
                                                    "\n                        "
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.noaccessmail
                                        ? _c("p", { staticClass: "my-0" }, [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.userManagement.noInvitationTT"
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ])
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tab-item",
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "detailsCard pa-5",
                              attrs: { flat: "" }
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "userMgmtdetails mx-0" },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "col-12 px-1 py-1" },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            top: "",
                                            "max-width": "400"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-text-field",
                                                      _vm._g(
                                                        {
                                                          staticClass: "mb-5",
                                                          attrs: {
                                                            dense: "",
                                                            outlined: "",
                                                            label: _vm.$t(
                                                              "excel.rmoUserId"
                                                            ),
                                                            "hide-details": ""
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.changesInForm(
                                                                true
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.rmoUserId,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.rmoUserId = $$v
                                                            },
                                                            expression:
                                                              "rmoUserId"
                                                          }
                                                        },
                                                        on
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            3980329467
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.userManagement.customerNumberTT"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            top: "",
                                            "max-width": "400"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-text-field",
                                                      _vm._g(
                                                        {
                                                          staticClass: "mb-5",
                                                          attrs: {
                                                            dense: "",
                                                            outlined: "",
                                                            label: _vm.$t(
                                                              "excel.dasdUserId"
                                                            ),
                                                            "hide-details": ""
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              return _vm.changesInForm(
                                                                true
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.dasdUserId,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.dasdUserId = $$v
                                                            },
                                                            expression:
                                                              "dasdUserId"
                                                          }
                                                        },
                                                        on
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2670132795
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.userManagement.dasdUserNumberTT"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { staticClass: "col-12 px-1 py-1 d-flex" },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "mr-2 mt-0",
                                        attrs: {
                                          label: _vm.$t(
                                            "components.userManagement.beaconEnabled"
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.changesInForm(true)
                                          }
                                        },
                                        model: {
                                          value: _vm.enabledBeacon,
                                          callback: function($$v) {
                                            _vm.enabledBeacon = $$v
                                          },
                                          expression: "enabledBeacon"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            top: "",
                                            "max-width": "450"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "font-awesome-icon",
                                                      _vm._g(
                                                        {
                                                          style: {
                                                            fontSize: "17px",
                                                            top: "7px",
                                                            position: "relative"
                                                          },
                                                          attrs: {
                                                            color: "#02889d",
                                                            icon: [
                                                              "fal",
                                                              "info-circle"
                                                            ]
                                                          }
                                                        },
                                                        on
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            1857281784
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.userManagement.permissionsTT"
                                                  )
                                                ) +
                                                "\n                  "
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.errMsg
                    ? _c(
                        "v-alert",
                        { attrs: { type: "error", dismissible: true } },
                        [_vm._v(_vm._s(_vm.errMsg))]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("input", {
            ref: "fileInput",
            staticClass: "d-none",
            attrs: { type: "file" },
            on: {
              change: function($event) {
                return _vm.showCroppieModal($event)
              }
            }
          }),
          _vm._v(" "),
          _vm.showCloseDetailModal
            ? [
                _c("CloseManagementDetailModal", {
                  attrs: {
                    showModal: _vm.showCloseDetailModal,
                    closeModal: _vm.openCloseDetailModal,
                    acceptCloseModal: _vm.closeDetails
                  }
                })
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.showDeleteUserModal
            ? [
                _c("DeleteUserModal", {
                  attrs: {
                    showModal: _vm.showDeleteUserModal,
                    closeModal: _vm.closeDeleteUserModal,
                    deleteUser: _vm.deleteUser,
                    userId: _vm.userId
                  }
                })
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }