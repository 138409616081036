var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.viewingConferences
        ? _c(
            "v-content",
            {
              class:
                "px-0 py-0 " +
                (_vm.removeGeneralWrapper ? null : "contentGridWrapper"),
              nativeOn: {
                scroll: function($event) {
                  return _vm.handleScroll.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "v-row",
                { staticClass: "mx-auto" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "custom6cols pb-1 px-2",
                      attrs: { cols: "6" }
                    },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "cardWidth cursorPointer",
                          style: { border: "1px solid #efefef" },
                          on: {
                            click: function($event) {
                              return _vm.clickOnAddCard()
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                height: "180px",
                                display: "table",
                                width: "100%"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "divAdd" },
                                [
                                  _c("font-awesome-icon", {
                                    staticClass: "buttonAdd",
                                    attrs: { icon: ["fal", "plus"] }
                                  }),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "mb-0 mt-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.conferenceForm.createConferenceHall"
                                        )
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.getMyConferenceHallDetailsConfIsTemp, function(
                    conference,
                    index
                  ) {
                    return _c(
                      "v-col",
                      {
                        key: index,
                        staticClass: "custom6cols pb-1 px-2",
                        attrs: { cols: "6" }
                      },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "cardWidth",
                            staticStyle: { "background-color": "#eaeaea" },
                            on: {
                              click: function($event) {
                                return _vm.editConference(conference)
                              }
                            }
                          },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "", "max-width": "750" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "div",
                                            _vm._g({}, on),
                                            [
                                              _c(
                                                "v-img",
                                                {
                                                  staticClass:
                                                    "white--text align-end",
                                                  staticStyle: {
                                                    "border-radius": "4px"
                                                  },
                                                  attrs: {
                                                    src: conference.confAvatar,
                                                    height: "180px"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "ConferenceDateTimeOverlay",
                                                    {
                                                      attrs: {
                                                        conference: conference
                                                      }
                                                    }
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var onMenu =
                                                                ref.on
                                                              return [
                                                                conference.confIsReocurring
                                                                  ? _c(
                                                                      "font-awesome-icon",
                                                                      _vm._g(
                                                                        {
                                                                          style: {
                                                                            fontSize:
                                                                              "20px",
                                                                            position:
                                                                              "absolute",
                                                                            top: 45,
                                                                            right: 5
                                                                          },
                                                                          attrs: {
                                                                            icon: [
                                                                              "far",
                                                                              "repeat-alt"
                                                                            ],
                                                                            color:
                                                                              "orange"
                                                                          }
                                                                        },
                                                                        Object.assign(
                                                                          {},
                                                                          on,
                                                                          onMenu
                                                                        )
                                                                      )
                                                                    )
                                                                  : _vm._e()
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.conferenceForm.reocurringConf"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "gradientBg"
                                                    },
                                                    [
                                                      _c(
                                                        "v-card-subtitle",
                                                        {
                                                          staticClass:
                                                            "\n                        titleCard\n                        colorWhite\n                        pt-1\n                        pb-1\n                        px-2\n                        text-truncate\n                      ",
                                                          staticStyle: {
                                                            color:
                                                              "#fff !important"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "ConferenceCardActions",
                                                            {
                                                              attrs: {
                                                                conference: conference,
                                                                editConference:
                                                                  _vm.editConference,
                                                                deleteConference:
                                                                  _vm.deleteConferenceHall,
                                                                checkSendMessage:
                                                                  _vm.checkSendMessage,
                                                                existingConference:
                                                                  _vm.existingConference,
                                                                duplicateConference:
                                                                  _vm.duplicateConference,
                                                                callEveryone:
                                                                  _vm.callEveryone,
                                                                reminderConference:
                                                                  _vm.reminderConference,
                                                                amInACall:
                                                                  _vm.amInACall
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(" "),
                                _c("ConferenceInfoTooltip", {
                                  attrs: { conference: conference }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "infinite-loading",
                    {
                      attrs: { distance: 5 },
                      on: { infinite: _vm.infiniteHandler }
                    },
                    [
                      _c("div", {
                        attrs: { slot: "no-results" },
                        slot: "no-results"
                      }),
                      _vm._v(" "),
                      _c("div", { attrs: { slot: "no-more" }, slot: "no-more" })
                    ]
                  )
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.viewingConferences
        ? _c(
            "v-content",
            {
              class:
                "px-0 py-0 " +
                (_vm.removeGeneralWrapper ? null : "contentGridWrapper"),
              nativeOn: {
                scroll: function($event) {
                  return _vm.handleScroll.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "div",
                {
                  class: _vm.isMobile
                    ? "participantsButMobile"
                    : "participantsBut"
                },
                [
                  _vm.showJoinConferenceButton && !_vm.amInACall
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { top: "", "max-width": "210" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "mr-1 mb-2 white--text",
                                          attrs: {
                                            depressed: "",
                                            color: "#FFA500"
                                          },
                                          on: { click: _vm.joinToConference }
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.conferenceForm.joinConference"
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2541446751
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.conferenceForm.joinConference"
                                )
                              ) + " "
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1 mb-2",
                      attrs: { depressed: "", color: "primary", disabled: true }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("components.conferenceForm.inviteeTotal")
                        ) +
                          ":\n        " +
                          _vm._s(_vm.getTotalInvited)
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  !_vm.isPreviewingEmail
                    ? _c(
                        "v-col",
                        {
                          staticClass: "mx-0 py-0",
                          attrs: {
                            cols: _vm.isMobile ? 12 : 7,
                            md: _vm.isMobile ? 12 : 5
                          }
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "mx-0" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pl-0 pb-0",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c(
                                    "v-combobox",
                                    {
                                      ref: "templatesDropDown",
                                      class: {
                                        borderError: _vm.conferenceName == ""
                                      },
                                      attrs: {
                                        disabled: !_vm.canEditEvent,
                                        items: _vm.getUserGroups,
                                        label: _vm.$t(
                                          "components.conferenceForm.conferenceName"
                                        ),
                                        dense: "",
                                        outlined: "",
                                        "item-text": "groupName",
                                        "item-value": "groupId",
                                        "return-object": "",
                                        maxlength: "100",
                                        counter: 100
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.changeGroupCombo($event)
                                        }
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "item",
                                            fn: function(data) {
                                              return [
                                                _c("v-list-item-title", [
                                                  _vm._v(
                                                    _vm._s(
                                                      data.item.groupName
                                                    ) +
                                                      " -\n                  " +
                                                      _vm._s(
                                                        _vm
                                                          .moment(
                                                            data.item
                                                              .groupCreated
                                                          )
                                                          .format(
                                                            "DD.MM.YYYY HH:mm"
                                                          )
                                                      )
                                                  )
                                                ])
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        1002808176
                                      ),
                                      model: {
                                        value: _vm.conferenceName,
                                        callback: function($$v) {
                                          _vm.conferenceName = $$v
                                        },
                                        expression: "conferenceName"
                                      }
                                    },
                                    [
                                      _vm.canEditEvent
                                        ? _c(
                                            "template",
                                            { slot: "append-outer" },
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    icon: "",
                                                                    small: "",
                                                                    disabled: !_vm.canSaveGroup
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.savingTemplate(
                                                                        $event
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "font-awesome-icon",
                                                                  {
                                                                    staticClass:
                                                                      "cursorPointer mb-1",
                                                                    style: {
                                                                      fontSize:
                                                                        "26px"
                                                                    },
                                                                    attrs: {
                                                                      icon: [
                                                                        "fal",
                                                                        "save"
                                                                      ],
                                                                      color: _vm.isEditingTemplate
                                                                        ? "red"
                                                                        : ""
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    1723295436
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.isTemplate &&
                                                          !_vm.isEditingTemplate
                                                          ? _vm.$t(
                                                              "components.conferenceForm.editTemplate"
                                                            )
                                                          : _vm.isTemplate &&
                                                            _vm.isEditingTemplate
                                                          ? _vm.$t(
                                                              "generics.cancel"
                                                            )
                                                          : _vm.$t(
                                                              "components.conferenceForm.saveAsTemplate"
                                                            )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.isEditingTemplate ||
                                              typeof _vm.conferenceName ===
                                                "object"
                                                ? _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    {
                                                                      attrs: {
                                                                        icon:
                                                                          "",
                                                                        small:
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.deleteTemplate(
                                                                            $event
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "font-awesome-icon",
                                                                      {
                                                                        staticClass:
                                                                          "cursorPointer",
                                                                        style: {
                                                                          fontSize:
                                                                            "23px"
                                                                        },
                                                                        attrs: {
                                                                          icon: [
                                                                            "fal",
                                                                            "trash"
                                                                          ],
                                                                          color: !_vm.deleteTemplateActive
                                                                            ? ""
                                                                            : "red"
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        3333591805
                                                      )
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.conferenceForm.deleteTemplate"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.canEditEvent
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "pl-0 pb-0 pt-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          rows: "2",
                                          counter: 150,
                                          maxlength: "150",
                                          label: _vm.$t(
                                            "components.conferenceForm.personalizedSalutation"
                                          ),
                                          "no-resize": ""
                                        },
                                        model: {
                                          value: _vm.personalizedGreeting,
                                          callback: function($$v) {
                                            _vm.personalizedGreeting = $$v
                                          },
                                          expression: "personalizedGreeting"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.canEditEvent
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "pl-0 pt-0",
                                      attrs: { cols: _vm.isMobile ? 8 : 10 }
                                    },
                                    [
                                      _c("v-autocomplete", {
                                        staticClass: "mb-0",
                                        attrs: {
                                          items: _vm.fillDropDown,
                                          "item-disabled": _vm.disabledItem,
                                          outlined: "",
                                          dense: "",
                                          color: "blue-grey lighten-2",
                                          label: _vm.$t(
                                            "components.conferenceForm.selectParticipants"
                                          ),
                                          "item-text": "name",
                                          "item-value": "uuid",
                                          multiple: "",
                                          "hide-selected": "",
                                          "hide-details": "",
                                          disabled: _vm.isConferenceFull,
                                          "search-input": _vm.searchInput,
                                          "no-data-text": !_vm.isConferenceFull
                                            ? _vm.$vuetify.noDataText
                                            : _vm.$t(
                                                "components.conferenceForm.limitUsers"
                                              )
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.handleSelection($event)
                                          },
                                          input: function($event) {
                                            _vm.searchInput = null
                                          },
                                          "update:searchInput": function(
                                            $event
                                          ) {
                                            _vm.searchInput = $event
                                          },
                                          "update:search-input": function(
                                            $event
                                          ) {
                                            _vm.searchInput = $event
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "selection",
                                              fn: function(data) {
                                                return [
                                                  _vm._v(_vm._s(data.item.name))
                                                ]
                                              }
                                            },
                                            {
                                              key: "item",
                                              fn: function(data) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "selectorBack d-flex"
                                                    },
                                                    [
                                                      [
                                                        _c(
                                                          "v-list-item-avatar",
                                                          {
                                                            staticClass:
                                                              "borderRadius-4"
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src:
                                                                  data.item
                                                                    .avatar
                                                              }
                                                            })
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        data.item.group !==
                                                        "Teams"
                                                          ? _c(
                                                              "v-list-item-content",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  {
                                                                    domProps: {
                                                                      innerHTML: _vm._s(
                                                                        _vm.$sanitize(
                                                                          !data
                                                                            .item
                                                                            .isGroup
                                                                            ? data
                                                                                .item
                                                                                .name
                                                                            : data
                                                                                .item
                                                                                .groupName
                                                                        )
                                                                      )
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "v-list-item-subtitle",
                                                                  {
                                                                    domProps: {
                                                                      innerHTML: _vm._s(
                                                                        !data
                                                                          .item
                                                                          .isGroup
                                                                          ? _vm.getTypeOfParticipant(
                                                                              data
                                                                                .item
                                                                                .group
                                                                            )
                                                                          : _vm.$t(
                                                                              "components.addConferenceModal.group"
                                                                            ) +
                                                                              " - " +
                                                                              data
                                                                                .item
                                                                                .groupMembers
                                                                                .length +
                                                                              " " +
                                                                              _vm.$t(
                                                                                "components.addConferenceModal.members"
                                                                              )
                                                                      )
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "v-list-item-content",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  {
                                                                    domProps: {
                                                                      innerHTML: _vm._s(
                                                                        _vm.$sanitize(
                                                                          data
                                                                            .item
                                                                            .name
                                                                        )
                                                                      )
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "v-list-item-subtitle",
                                                                  {
                                                                    domProps: {
                                                                      innerHTML: _vm._s(
                                                                        _vm.$t(
                                                                          "components.addConferenceModal.team"
                                                                        ) +
                                                                          " - " +
                                                                          data
                                                                            .item
                                                                            .members
                                                                            .length +
                                                                          " " +
                                                                          _vm.$t(
                                                                            "components.addConferenceModal.members"
                                                                          )
                                                                      )
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                      ]
                                                    ],
                                                    2
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          1538833078
                                        ),
                                        model: {
                                          value: _vm.selectedDropDowItem,
                                          callback: function($$v) {
                                            _vm.selectedDropDowItem = $$v
                                          },
                                          expression: "selectedDropDowItem"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.canEditEvent
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "pl-0 pt-0 text-center",
                                      staticStyle: {
                                        "align-items": "center",
                                        display: "flex"
                                      },
                                      attrs: { cols: _vm.isMobile ? 4 : 2 }
                                    },
                                    [
                                      _c("span", { staticClass: "caption" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.conferenceForm.guest"
                                            )
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "font-awesome-icon",
                                                      _vm._g(
                                                        {
                                                          staticClass:
                                                            "primary--text cursorPointer ml-3",
                                                          style: {
                                                            fontSize: "26px"
                                                          },
                                                          attrs: {
                                                            icon: [
                                                              "fal",
                                                              "plus-circle"
                                                            ],
                                                            disabled:
                                                              _vm.isConferenceFull
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.isConferenceFull
                                                                ? null
                                                                : (_vm.showAddGuestModal = true)
                                                            }
                                                          }
                                                        },
                                                        on
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            95237744
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.conferenceForm.ttAddNewGuest"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pl-0 pt-0",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              ref: "startDateMenu",
                                              attrs: {
                                                id: "startDateMenu",
                                                "close-on-content-click": false,
                                                transition: "scale-transition",
                                                "offset-y": "",
                                                "min-width": "290px",
                                                disabled:
                                                  !_vm.canEditEvent ||
                                                  _vm.isNotMyConference
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          "v-text-field",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm.canEditEvent ||
                                                                    _vm.isNotMyConference,
                                                                  readonly: "",
                                                                  dense: "",
                                                                  "hide-details":
                                                                    "",
                                                                  outlined: "",
                                                                  label: _vm.$t(
                                                                    "components.conferenceForm.startDate"
                                                                  )
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.formatedStart,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.formatedStart = $$v
                                                                  },
                                                                  expression:
                                                                    "formatedStart"
                                                                }
                                                              },
                                                              "v-text-field",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm.formatedStart !=
                                                              null ||
                                                            _vm.isNotMyConference
                                                              ? _c(
                                                                  "template",
                                                                  {
                                                                    slot:
                                                                      "append"
                                                                  },
                                                                  [
                                                                    _vm.canEditEvent
                                                                      ? _c(
                                                                          "DeleteBasket",
                                                                          {
                                                                            attrs: {
                                                                              indexData:
                                                                                "",
                                                                              delFunction:
                                                                                _vm.cleanDate,
                                                                              extraClasses:
                                                                                "cursorPointer",
                                                                              fontSize:
                                                                                "18"
                                                                            }
                                                                          }
                                                                        )
                                                                      : _vm._e()
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          2
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                2141229800
                                              ),
                                              model: {
                                                value: _vm.startDateMenu,
                                                callback: function($$v) {
                                                  _vm.startDateMenu = $$v
                                                },
                                                expression: "startDateMenu"
                                              }
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("v-date-picker", {
                                                attrs: {
                                                  min: _vm
                                                    .moment(Date.now())
                                                    .format("YYYY-MM-DD"),
                                                  "no-title": "",
                                                  scrollable: "",
                                                  locale: this.$locale.current(),
                                                  "first-day-of-week": 1
                                                },
                                                on: {
                                                  "click:date": function(
                                                    $event
                                                  ) {
                                                    return _vm.$refs.startDateMenu.save(
                                                      _vm.startEvent
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: _vm.startEvent,
                                                  callback: function($$v) {
                                                    _vm.startEvent = $$v
                                                  },
                                                  expression: "startEvent"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              ref: "startTimeMenu",
                                              attrs: {
                                                disabled: !_vm.canEditEvent,
                                                "close-on-content-click": false,
                                                "nudge-right": 40,
                                                "return-value": _vm.time2,
                                                transition: "scale-transition",
                                                "offset-y": "",
                                                "max-width": "290px",
                                                "min-width": "290px"
                                              },
                                              on: {
                                                "update:returnValue": function(
                                                  $event
                                                ) {
                                                  _vm.time2 = $event
                                                },
                                                "update:return-value": function(
                                                  $event
                                                ) {
                                                  _vm.time2 = $event
                                                }
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-text-field",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "timePicker",
                                                              attrs: {
                                                                type: "time",
                                                                dense: "",
                                                                "hide-details":
                                                                  "",
                                                                outlined: "",
                                                                label: _vm.$t(
                                                                  "components.conferenceForm.from"
                                                                ),
                                                                disabled:
                                                                  !_vm.canEditEvent ||
                                                                  !_vm.formatedStart
                                                              },
                                                              on: {
                                                                keydown: function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "space",
                                                                      32,
                                                                      $event.key,
                                                                      [
                                                                        " ",
                                                                        "Spacebar"
                                                                      ]
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  return function(
                                                                    event
                                                                  ) {
                                                                    return event.preventDefault()
                                                                  }.apply(
                                                                    null,
                                                                    arguments
                                                                  )
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.timeStart,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.timeStart = $$v
                                                                },
                                                                expression:
                                                                  "timeStart"
                                                              }
                                                            },
                                                            on
                                                          )
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                2350793649
                                              ),
                                              model: {
                                                value: _vm.startTimeMenu,
                                                callback: function($$v) {
                                                  _vm.startTimeMenu = $$v
                                                },
                                                expression: "startTimeMenu"
                                              }
                                            },
                                            [
                                              _vm._v(" "),
                                              _vm.startTimeMenu
                                                ? _c("v-time-picker", {
                                                    staticClass: "font16",
                                                    attrs: {
                                                      disabled: !_vm.canEditEvent,
                                                      "full-width": "",
                                                      format: "24hr",
                                                      min: "00:00"
                                                    },
                                                    on: {
                                                      "click:minute": function(
                                                        $event
                                                      ) {
                                                        return _vm.$refs.startTimeMenu.save(
                                                          _vm.timeStart
                                                        )
                                                      },
                                                      "click:hour":
                                                        _vm.autocompleteMinutes
                                                    },
                                                    model: {
                                                      value: _vm.timeStart,
                                                      callback: function($$v) {
                                                        _vm.timeStart = $$v
                                                      },
                                                      expression: "timeStart"
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pb-0",
                                          attrs: { cols: "4" }
                                        },
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              ref: "endTimeMenu",
                                              attrs: {
                                                disabled: !_vm.canEditEvent,
                                                "close-on-content-click": false,
                                                "nudge-right": 40,
                                                "return-value": _vm.time1,
                                                transition: "scale-transition",
                                                "offset-y": "",
                                                "max-width": "290px",
                                                "min-width": "290px"
                                              },
                                              on: {
                                                "update:returnValue": function(
                                                  $event
                                                ) {
                                                  _vm.time1 = $event
                                                },
                                                "update:return-value": function(
                                                  $event
                                                ) {
                                                  _vm.time1 = $event
                                                }
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-text-field",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "timePicker",
                                                              attrs: {
                                                                type: "time",
                                                                dense: "",
                                                                outlined: "",
                                                                disabled:
                                                                  !_vm.canEditEvent ||
                                                                  !_vm.timeStart ||
                                                                  !_vm.startEvent,
                                                                "hide-details":
                                                                  "",
                                                                label: _vm.$t(
                                                                  "components.conferenceForm.to"
                                                                )
                                                              },
                                                              on: {
                                                                keydown: function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "space",
                                                                      32,
                                                                      $event.key,
                                                                      [
                                                                        " ",
                                                                        "Spacebar"
                                                                      ]
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  return function(
                                                                    event
                                                                  ) {
                                                                    return event.preventDefault()
                                                                  }.apply(
                                                                    null,
                                                                    arguments
                                                                  )
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.timeEnd,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.timeEnd = $$v
                                                                },
                                                                expression:
                                                                  "timeEnd"
                                                              }
                                                            },
                                                            on
                                                          )
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                1317575369
                                              ),
                                              model: {
                                                value: _vm.endTimeMenu,
                                                callback: function($$v) {
                                                  _vm.endTimeMenu = $$v
                                                },
                                                expression: "endTimeMenu"
                                              }
                                            },
                                            [
                                              _vm._v(" "),
                                              _vm.endTimeMenu
                                                ? _c("v-time-picker", {
                                                    staticClass: "font16",
                                                    attrs: {
                                                      disabled: !_vm.canEditEvent,
                                                      "full-width": "",
                                                      format: "24hr",
                                                      min: _vm.timeStart
                                                    },
                                                    on: {
                                                      "click:minute": function(
                                                        $event
                                                      ) {
                                                        return _vm.$refs.endTimeMenu.save(
                                                          _vm.timeEnd
                                                        )
                                                      },
                                                      "click:hour":
                                                        _vm.autocompleteMinutesEnd
                                                    },
                                                    model: {
                                                      value: _vm.timeEnd,
                                                      callback: function($$v) {
                                                        _vm.timeEnd = $$v
                                                      },
                                                      expression: "timeEnd"
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pl-0 pt-0",
                                  staticStyle: { "max-height": "75px" },
                                  attrs: { cols: _vm.isMobile ? 12 : 8 }
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-textarea",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        disabled: !_vm.canEditEvent,
                                                        outlined: "",
                                                        dense: "",
                                                        rows: "2",
                                                        counter: 150,
                                                        maxlength: "150",
                                                        label: _vm.$t(
                                                          "components.conferenceForm.description"
                                                        ),
                                                        "no-resize": ""
                                                      },
                                                      on: {
                                                        blur: function($event) {
                                                          _vm.isEditingTemplate
                                                            ? _vm.updateTemplate()
                                                            : null
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.conferenceDescription,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.conferenceDescription = $$v
                                                        },
                                                        expression:
                                                          "conferenceDescription"
                                                      }
                                                    },
                                                    on
                                                  )
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2770244722
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.conferenceForm.ttDescription"
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  class:
                                    (_vm.isMobile ? "pb-4" : "pb-0") +
                                    " pt-0 text-center",
                                  attrs: { cols: _vm.isMobile ? 12 : 4 }
                                },
                                [
                                  _c("span", { staticClass: "caption" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.conferenceForm.conferenceLogo"
                                        )
                                      )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0",
                                          attrs: { col: "12" }
                                        },
                                        [
                                          _c(
                                            "v-img",
                                            {
                                              staticClass:
                                                "confImage borderRadius-4 center",
                                              attrs: {
                                                src: _vm.conferenceImage,
                                                width: "80px",
                                                height: "80px",
                                                "max-height": "80px",
                                                "max-width": "80px"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "mx-2 borderRadius-4 uploadGroupImage",
                                                                  attrs: {
                                                                    depressed:
                                                                      "",
                                                                    small: "",
                                                                    fab: "",
                                                                    dark: "",
                                                                    color:
                                                                      "primary",
                                                                    disabled: !_vm.canEditEvent
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.setConferenceImage
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "font-awesome-icon",
                                                                  {
                                                                    style: {
                                                                      fontSize:
                                                                        "18px"
                                                                    },
                                                                    attrs: {
                                                                      icon: [
                                                                        "fal",
                                                                        "upload"
                                                                      ]
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    2006776043
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "components.groups.uploadConferenceImage"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            ref: "conferenceImageInput",
                                            staticClass: "d-none",
                                            attrs: {
                                              type: "file",
                                              accept:
                                                "image/x-png, image/gif, image/jpeg"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.saveConferenceImage(
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              !_vm.canEditEvent && !_vm.confHasEnded
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0",
                                      staticStyle: { "max-height": "75px" },
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-radio-group",
                                        {
                                          staticClass: "ml-2",
                                          attrs: { row: "" },
                                          on: { change: _vm.conferenceRSVP },
                                          model: {
                                            value: _vm.rsvpValue,
                                            callback: function($$v) {
                                              _vm.rsvpValue = $$v
                                            },
                                            expression: "rsvpValue"
                                          }
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              value: true,
                                              label: _vm.$t(
                                                "components.meetingTime.accept"
                                              )
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-radio", {
                                            attrs: {
                                              value: false,
                                              label: _vm.$t(
                                                "components.meetingTime.decline"
                                              )
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _vm.isMod && _vm.confHasEnded
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0",
                                      staticStyle: { "max-height": "75px" },
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            top: "",
                                            "max-width": "210"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          staticClass: "ml-2",
                                                          attrs: {
                                                            depressed: "",
                                                            color: "primary"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.duplicateConference(
                                                                _vm
                                                                  .conferenceToEdit
                                                                  .confId
                                                              )
                                                            }
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.addConferenceModal.dupConfNewDate"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            369001832
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.addConferenceModal.dupConfNewDate"
                                                )
                                              ) + "\n              "
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.canEditEvent
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "12", md: "6" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "mr-2 mt-0 pt-0",
                                        attrs: {
                                          label: _vm.$t(
                                            "components.inviteVisitor.pinAccess"
                                          ),
                                          outlined: "",
                                          autocomplete: "off",
                                          dense: "",
                                          maxlength: 6
                                        },
                                        on: {
                                          keydown: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "space",
                                                32,
                                                $event.key,
                                                [" ", "Spacebar"]
                                              )
                                            ) {
                                              return null
                                            }
                                            $event.preventDefault()
                                          },
                                          blur: function($event) {
                                            _vm.pinCode != ""
                                              ? _vm.checkPin
                                              : null
                                          }
                                        },
                                        model: {
                                          value: _vm.pinCode,
                                          callback: function($$v) {
                                            _vm.pinCode = $$v
                                          },
                                          expression: "pinCode"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "12", md: "6" }
                                    },
                                    [
                                      _vm.pinCode
                                        ? _c(
                                            "p",
                                            { staticClass: "body-2 pt-1" },
                                            [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "components.inviteGuestPanel.pinGuestSenden"
                                                    )
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.isDevelopment
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "pb-1 pt-0",
                                          attrs: { cols: _vm.isMobile ? 12 : 6 }
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            { staticClass: "py-0" },
                                            [
                                              _c(
                                                "v-list-group",
                                                {
                                                  staticClass: "scheculedConf",
                                                  attrs: {
                                                    "append-icon": null,
                                                    "no-action": ""
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "v-list-item-content",
                                                              {
                                                                staticClass:
                                                                  "py-0"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  [
                                                                    _c(
                                                                      "v-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          top:
                                                                            ""
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "activator",
                                                                              fn: function(
                                                                                ref
                                                                              ) {
                                                                                var on =
                                                                                  ref.on
                                                                                return [
                                                                                  _c(
                                                                                    "v-btn",
                                                                                    _vm._g(
                                                                                      {
                                                                                        staticClass:
                                                                                          "justStart no-bg list",
                                                                                        attrs: {
                                                                                          disabled: !_vm.canEditEvent,
                                                                                          depressed:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _c(
                                                                                        "font-awesome-icon",
                                                                                        {
                                                                                          style: {
                                                                                            fontSize:
                                                                                              "18px"
                                                                                          },
                                                                                          attrs: {
                                                                                            icon: [
                                                                                              "fal",
                                                                                              "copy"
                                                                                            ]
                                                                                          }
                                                                                        }
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "ml-3"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "components.addConferenceModal.confFrequency"
                                                                                              )
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ]
                                                                              }
                                                                            }
                                                                          ],
                                                                          null,
                                                                          false,
                                                                          472412669
                                                                        )
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "components.conferenceForm.ttReoccurring"
                                                                                )
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    134012204
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      staticClass: "pl-0 w-90"
                                                    },
                                                    [
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-radio-group",
                                                            {
                                                              attrs: {
                                                                row: ""
                                                              },
                                                              on: {
                                                                change: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.confFrequencyUpdated()
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.scheduledInterval,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.scheduledInterval = $$v
                                                                },
                                                                expression:
                                                                  "scheduledInterval"
                                                              }
                                                            },
                                                            [
                                                              _c("v-radio", {
                                                                staticClass:
                                                                  "ml-3",
                                                                attrs: {
                                                                  label: _vm.$t(
                                                                    "components.addConferenceModal.confDaily"
                                                                  ),
                                                                  value: "d"
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _c("v-radio", {
                                                                staticClass:
                                                                  "ml-3",
                                                                attrs: {
                                                                  label: _vm.$t(
                                                                    "components.addConferenceModal.confWeekly"
                                                                  ),
                                                                  value: "w"
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _c("v-radio", {
                                                                staticClass:
                                                                  "ml-3",
                                                                attrs: {
                                                                  label: _vm.$t(
                                                                    "components.addConferenceModal.confMonthly"
                                                                  ),
                                                                  value: "m"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _vm.scheduledInterval &&
                                                          _vm.scheduledInterval !==
                                                            ""
                                                            ? _c(
                                                                "v-text-field",
                                                                {
                                                                  staticClass:
                                                                    "frequencyInput mx-2",
                                                                  attrs: {
                                                                    dense: "",
                                                                    disabled: !_vm.canEditEvent,
                                                                    type:
                                                                      "number",
                                                                    min: "0",
                                                                    max: "30",
                                                                    step: "1",
                                                                    "hide-details":
                                                                      ""
                                                                  },
                                                                  on: {
                                                                    keypress: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.checkCodeEntered(
                                                                        $event
                                                                      )
                                                                    },
                                                                    keyup: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.checkFrequencyValue(
                                                                        $event
                                                                      )
                                                                    }
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.confFrequency,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.confFrequency = $$v
                                                                    },
                                                                    expression:
                                                                      "confFrequency"
                                                                  }
                                                                }
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _vm.scheduledInterval &&
                                                          _vm.scheduledInterval !==
                                                            ""
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "frequencyDenominator"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.getFrequencyText
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _vm.scheduledInterval &&
                                                          _vm.scheduledInterval !==
                                                            ""
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "ml-2 borderRadius-4 frequencyButton",
                                                                  staticStyle: {
                                                                    height:
                                                                      "36px"
                                                                  },
                                                                  attrs: {
                                                                    icon: "",
                                                                    outlined:
                                                                      "",
                                                                    depressed:
                                                                      "",
                                                                    small: "",
                                                                    fab: "",
                                                                    dark: "",
                                                                    color:
                                                                      "primary"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeScheduledConferences()
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      style: {
                                                                        fontSize:
                                                                          "18px"
                                                                      },
                                                                      attrs: {
                                                                        icon: [
                                                                          "fal",
                                                                          "times"
                                                                        ]
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.canEditEvent && _vm.isEditingConference
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-1 pt-0",
                                      attrs: { cols: "6" }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "justStart no-bg list",
                                          attrs: {
                                            disabled: !_vm.canEditEvent,
                                            depressed: ""
                                          },
                                          on: { click: _vm.doResendInviteToAll }
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            style: { fontSize: "18px" },
                                            attrs: { icon: ["fal", "redo"] }
                                          }),
                                          _vm._v(" "),
                                          _c("span", { staticClass: "ml-3" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.conferenceForm.resendInviteAll"
                                                )
                                              )
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-1 pt-0",
                                      attrs: { cols: "6" }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "justStart no-bg list",
                                          attrs: {
                                            depressed: "",
                                            disabled: false
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.doSendMessageToAll(
                                                _vm.conferenceToEdit.confId
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            style: { fontSize: "18px" },
                                            attrs: {
                                              icon: ["fal", "paper-plane"]
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("span", { staticClass: "ml-3" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.conferenceForm.sendMessageAll"
                                                )
                                              )
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-1 pt-0",
                                      attrs: { cols: "6" }
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            top: "",
                                            "max-width": "210"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          staticClass:
                                                            "justStart no-bg list",
                                                          attrs: {
                                                            depressed: "",
                                                            disabled: !_vm.canEditEvent
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.callEveryone(
                                                                true,
                                                                null
                                                              )
                                                            }
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            style: {
                                                              fontSize: "18px"
                                                            },
                                                            attrs: {
                                                              icon: [
                                                                "fal",
                                                                "loveseat"
                                                              ]
                                                            }
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "ml-3"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "components.conferenceForm.sendConfToWaitingRoom"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            4110325337
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.conferenceForm.sendConfToWaitingRoomTT"
                                                )
                                              ) + "\n              "
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-1 pt-0",
                                      attrs: { cols: "6" }
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          staticClass:
                                                            "justStart no-bg list",
                                                          class: !_vm.deleteActive
                                                            ? "deleteBasketButtonNormal"
                                                            : "deleteBasketButtonActive",
                                                          attrs: {
                                                            depressed: "",
                                                            disabled: !_vm.canEditEvent
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.deleteConferenceSafely(
                                                                $event,
                                                                _vm
                                                                  .conferenceToEdit
                                                                  .confId
                                                              )
                                                            }
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            style: {
                                                              fontSize: "18px"
                                                            },
                                                            attrs: {
                                                              icon: [
                                                                "fal",
                                                                "trash"
                                                              ]
                                                            }
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass: "ml-3"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "components.conferenceForm.deleteConference"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            3699486131
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.conferenceForm.confirmRemove"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isPkg && _vm.canEditEvent
                            ? _c(
                                "v-row",
                                [
                                  _c("v-switch", {
                                    staticClass: "my-0 py-3 mr-2",
                                    attrs: {
                                      label: _vm.$t(
                                        "components.conferenceForm.enableYouTubeCall"
                                      )
                                    },
                                    model: {
                                      value: _vm.enableYouTubeCall,
                                      callback: function($$v) {
                                        _vm.enableYouTubeCall = $$v
                                      },
                                      expression: "enableYouTubeCall"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.areAPIKeysNeeded &&
                          _vm.canEditEvent &&
                          _vm.isYouTubeCall
                            ? _c(
                                "v-row",
                                [
                                  !_vm.isGoogleApiLoaded && _vm.isGapiError
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "pb-1 pt-0 mt-2 mb-1",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "mt-10 mb-6 px-5 redSpan"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.conferenceForm.namespaceNotAllowed"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.isGoogleApiLoaded && _vm.isYouTubeCall
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "pb-1 pt-0 mt-2 mb-1",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          !_vm.gLoggedUser
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "div",
                                                              _vm._g({}, on),
                                                              [
                                                                _c(
                                                                  "google-signin-btn",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "Sign In",
                                                                      customClass:
                                                                        "my-button",
                                                                      depressed:
                                                                        ""
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.signin
                                                                    }
                                                                  }
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "mt-10 mb-6 px-5 redSpan"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "components.conferenceForm.loginYTRequired"
                                                                        )
                                                                      ) +
                                                                        "\n                  "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    195092375
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "components.conferenceForm.needToLoginYTUser"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.gLoggedUser
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex"
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  _vm._g(
                                                                    {},
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "google-signin-btn",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "Sign Out",
                                                                          customClass:
                                                                            "my-button"
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            _vm.signout
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "px-5 align-self-center"
                                                                  },
                                                                  [
                                                                    !_vm.canUserStreamInYT
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "mt-10 mb-6 redSpan"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                      " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "components.conferenceForm.notAbleToStream"
                                                                                  )
                                                                                ) +
                                                                                "\n                    "
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    _vm.canUserStreamInYT
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "primary--text mt-10 mb-6 px-5"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                      " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "components.conferenceForm.ableToStream"
                                                                                  )
                                                                                ) +
                                                                                "\n                    "
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e()
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    696626557
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  !_vm.canUserStreamInYT
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.conferenceForm.needLivestreamEnabled"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.canUserStreamInYT
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "components.conferenceForm.yourUserIsOk"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.areAPIKeysNeeded && _vm.isYouTubeCall
                            ? _c("v-row", [
                                _c(
                                  "span",
                                  { staticClass: "mt-10 mb-6 redSpan" },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.$t(
                                            "components.conferenceForm.noAPIKeys"
                                          )
                                        ) +
                                        "\n          "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isPreviewingEmail
                    ? _c(
                        "v-col",
                        {
                          staticClass: "py-0 userList",
                          attrs: {
                            cols: _vm.isMobile ? 12 : 5,
                            md: _vm.isMobile ? 12 : 7
                          }
                        },
                        [
                          _c(
                            "v-tabs",
                            {
                              staticClass: "tabInvite ",
                              attrs: { centered: _vm.isMobile ? true : false },
                              on: {
                                change: function($event) {
                                  return _vm.setTypeParticipants($event)
                                }
                              }
                            },
                            [
                              _c("v-tab", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("components.conferenceForm.podium")
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("v-tab", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.conferenceForm.listeners"
                                    )
                                  )
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tabs-items",
                            {
                              class: _vm.isMobile ? null : "participantsScroll",
                              model: {
                                value: _vm.participantsType,
                                callback: function($$v) {
                                  _vm.participantsType = $$v
                                },
                                expression: "participantsType"
                              }
                            },
                            [
                              _c("v-tab-item", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "no-gutters flex-fill d-flex rowUsersConference",
                                    class: {
                                      justifyRight:
                                        _vm.getPodiumParticipants &&
                                        _vm.getPodiumParticipants.length <= 4
                                    }
                                  },
                                  [
                                    _vm.getPodiumParticipants.length <= 4
                                      ? _vm._l(
                                          _vm.getPodiumParticipants,
                                          function(user) {
                                            return _c(
                                              "v-col",
                                              {
                                                key: user.uuid,
                                                staticClass: "pr-2 pt-2",
                                                class:
                                                  (_vm.isMobile
                                                    ? null
                                                    : _vm.getWidth("podium")) +
                                                  " " +
                                                  (_vm.getPodiumParticipants
                                                    .length == 3
                                                    ? "mx-auto"
                                                    : ""),
                                                attrs: {
                                                  cols: _vm.getCols("podium")
                                                }
                                              },
                                              [
                                                _c("ListConferencesUsers", {
                                                  attrs: {
                                                    isEditingConference:
                                                      _vm.isEditingConference,
                                                    deleteUser: _vm.deleteUser,
                                                    user: user,
                                                    toggleModerator:
                                                      _vm.toggleModerator,
                                                    groupModerator:
                                                      _vm.conferenceModerator,
                                                    canEditEvent:
                                                      _vm.canEditEvent,
                                                    conference:
                                                      _vm.conferenceToEdit,
                                                    isConferenceUser: true,
                                                    isCardStyle: true,
                                                    createGuestInvite:
                                                      _vm.createGuestInvite,
                                                    createUserInvite:
                                                      _vm.createUserInvite,
                                                    createUserReminderInvite:
                                                      _vm.createUserReminderInvite,
                                                    createGuestReminderInvite:
                                                      _vm.createGuestReminderInvite
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          }
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.getPodiumParticipants.length > 4
                                      ? _c(
                                          "div",
                                          { staticClass: "w-100" },
                                          _vm._l(
                                            _vm.getPodiumParticipants,
                                            function(user) {
                                              return _c(
                                                "div",
                                                {
                                                  key: user.uuid,
                                                  staticClass: "userDiv"
                                                },
                                                [
                                                  _c("ListConferencesUsers", {
                                                    attrs: {
                                                      isEditingConference:
                                                        _vm.isEditingConference,
                                                      deleteUser:
                                                        _vm.deleteUser,
                                                      user: user,
                                                      toggleModerator:
                                                        _vm.toggleModerator,
                                                      groupModerator:
                                                        _vm.conferenceModerator,
                                                      canEditEvent:
                                                        _vm.canEditEvent,
                                                      conference:
                                                        _vm.conferenceToEdit,
                                                      isConferenceUser: true,
                                                      isCardStyle: false,
                                                      createGuestInvite:
                                                        _vm.createGuestInvite,
                                                      createUserInvite:
                                                        _vm.createUserInvite,
                                                      createUserReminderInvite:
                                                        _vm.createUserReminderInvite,
                                                      createGuestReminderInvite:
                                                        _vm.createGuestReminderInvite
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-tab-item",
                                [
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "no-gutters flex-fill d-flex rowUsersConference",
                                        class: {
                                          justifyRight:
                                            _vm.getListenerParticipants &&
                                            _vm.getListenerParticipants
                                              .length <= 4
                                        }
                                      },
                                      [
                                        _vm.getListenerParticipants.length <= 4
                                          ? _vm._l(
                                              _vm.getListenerParticipants,
                                              function(user) {
                                                return _c(
                                                  "v-col",
                                                  {
                                                    key: user.uuid,
                                                    staticClass: "pr-2 pt-2",
                                                    class:
                                                      (_vm.isMobile
                                                        ? null
                                                        : _vm.getWidth(
                                                            "listeners"
                                                          )) +
                                                      " " +
                                                      (_vm
                                                        .getListenerParticipants
                                                        .length == 3
                                                        ? "mx-auto"
                                                        : ""),
                                                    attrs: {
                                                      cols: _vm.getCols(
                                                        "listeners"
                                                      )
                                                    }
                                                  },
                                                  [
                                                    _c("ListConferencesUsers", {
                                                      attrs: {
                                                        isEditingConference:
                                                          _vm.isEditingConference,
                                                        deleteUser:
                                                          _vm.deleteUser,
                                                        user: user,
                                                        groupModerator:
                                                          _vm.conferenceModerator,
                                                        canEditEvent:
                                                          _vm.canEditEvent,
                                                        conference:
                                                          _vm.conferenceToEdit,
                                                        isConferenceUser: true,
                                                        isCardStyle: true,
                                                        createGuestInvite:
                                                          _vm.createGuestInvite,
                                                        createUserInvite:
                                                          _vm.createUserInvite,
                                                        createUserReminderInvite:
                                                          _vm.createUserReminderInvite,
                                                        createGuestReminderInvite:
                                                          _vm.createGuestReminderInvite
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              }
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.getListenerParticipants.length > 4
                                          ? _c(
                                              "div",
                                              { staticClass: "w-100" },
                                              _vm._l(
                                                _vm.getListenerParticipants,
                                                function(user) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: user.uuid,
                                                      staticClass: "userDiv"
                                                    },
                                                    [
                                                      _c(
                                                        "ListConferencesUsers",
                                                        {
                                                          attrs: {
                                                            isEditingConference:
                                                              _vm.isEditingConference,
                                                            deleteUser:
                                                              _vm.deleteUser,
                                                            user: user,
                                                            groupModerator:
                                                              _vm.conferenceModerator,
                                                            canEditEvent:
                                                              _vm.canEditEvent,
                                                            conference:
                                                              _vm.conferenceToEdit,
                                                            isConferenceUser: true,
                                                            isCardStyle: false,
                                                            createGuestInvite:
                                                              _vm.createGuestInvite,
                                                            createUserInvite:
                                                              _vm.createUserInvite,
                                                            createUserReminderInvite:
                                                              _vm.createUserReminderInvite,
                                                            createGuestReminderInvite:
                                                              _vm.createGuestReminderInvite
                                                          }
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ]
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isPreviewingEmail
                    ? _c(
                        "v-col",
                        {
                          staticClass: "mx-0 py-0 my-12",
                          attrs: { cols: "12", md: "12" }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "primary--text mt-10 mb-6 px-4",
                              staticStyle: {
                                "text-transform": "uppercase",
                                "font-weight": "400"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.conferenceForm.emailPreview"
                                  )
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "pb-3 mt-3" }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "primary--text mt-10 mb-6 px-4",
                              staticStyle: {
                                "font-size": "13px",
                                "text-transform": "uppercase",
                                "font-weight": "400"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.invitePreview.invitePreviewForUser"
                                  )
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card",
                            { attrs: { elevation: "2", outlined: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c("v-col", {
                                        staticClass: "grey--text ml-4 cols",
                                        attrs: { cols: "12", md: "12" },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$sanitize(_vm.userInvText)
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "pb-3 mt-3" }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "primary--text mt-10 mb-6 px-4",
                              staticStyle: {
                                "font-size": "13px",
                                "text-transform": "uppercase",
                                "font-weight": "400"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.invitePreview.invitePreviewForGuest"
                                  )
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card",
                            { attrs: { elevation: "2", outlined: "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mx-0",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c("v-col", {
                                        staticClass: "grey--text ml-4 cols",
                                        attrs: { cols: "12", md: "12" },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$sanitize(_vm.guestInvText)
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mx-0" },
                [
                  _c(
                    "v-col",
                    {
                      class:
                        "pl-0 " + (_vm.isMobile ? "text-center" : "text-right"),
                      attrs: { cols: "12" }
                    },
                    [
                      _c("v-divider", { staticClass: "pb-4" }),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "", "max-width": "210" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.isPreviewingEmail &&
                                                _vm.canEditEvent &&
                                                _vm.isNameValid,
                                              expression:
                                                "\n                isPreviewingEmail &&\n                canEditEvent &&\n                isNameValid\n              "
                                            }
                                          ],
                                          staticClass: "ml-2",
                                          attrs: {
                                            depressed: "",
                                            color: "primary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.toggleEmailPreview()
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.conferenceForm.back"
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1314717992
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.conferenceForm.backTT")
                              ) + " "
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.canEditEvent
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { top: "", "max-width": "210" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "ml-2",
                                              attrs: {
                                                disabled:
                                                  _vm.startEvent == "" ||
                                                  _vm.timeStart === null ||
                                                  !_vm.isNameValid ||
                                                  _vm.amInACall ||
                                                  (_vm.isYouTubeCall &&
                                                    (!_vm.isGoogleApiLoaded ||
                                                      !_vm.gLoggedUser ||
                                                      !_vm.canUserStreamInYT)) ||
                                                  _vm.isLoadingCall,
                                                loading: _vm.isLoadingCall,
                                                depressed: "",
                                                color: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.callNow()
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.conferenceForm.startConference"
                                                  )
                                                ) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2793841985
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.conferenceForm.startConferenceTT"
                                      )
                                    ) +
                                    "\n          "
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "", "max-width": "210" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                !_vm.isPreviewingEmail &&
                                                _vm.canEditEvent &&
                                                _vm.isNameValid,
                                              expression:
                                                "\n                !isPreviewingEmail &&\n                canEditEvent &&\n                isNameValid\n              "
                                            }
                                          ],
                                          staticClass: "ml-2",
                                          attrs: {
                                            depressed: "",
                                            color: "primary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.toggleEmailPreview()
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.conferenceForm.emailPreview"
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1557276709
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.conferenceForm.emailPreview")
                              ) + " "
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      !_vm.isEditingConference
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "ml-2",
                              attrs: {
                                depressed: "",
                                color: "primary",
                                disabled: _vm.disableSaveButton
                              },
                              on: {
                                click: function($event) {
                                  return _vm.saveConference()
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("components.conferenceForm.groupSave")
                                ) + "\n        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isEditingConference && _vm.canEditEvent
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { top: "", "max-width": "210" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "ml-2",
                                              attrs: {
                                                disabled:
                                                  !_vm.canEditEvent ||
                                                  _vm.disableSaveButton,
                                                depressed: "",
                                                color: "primary"
                                              },
                                              on: {
                                                click: _vm.doUpdateConference
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.footer.doUpdate"
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2815270915
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("components.footer.doUpdateTT")
                                  ) + " "
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isDevelopment && _vm.state.nerd
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              class:
                                                (_vm.isMobile ? "mb-2" : null) +
                                                " ml-2",
                                              attrs: {
                                                depressed: "",
                                                color: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.showJoinConferenceButton
                                                    ? _vm.joinToConference
                                                    : _vm.callEveryone(
                                                        false,
                                                        null
                                                      )
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c("font-awesome-icon", {
                                              style: { fontSize: "20px" },
                                              attrs: { icon: ["fal", "phone"] }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2861137685
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.conferenceForm.startConferencePlanetTT"
                                    )
                                  ) + "\n          "
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "", "max-width": "210" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "ml-2 borderRadius-4",
                                          staticStyle: { height: "36px" },
                                          attrs: {
                                            icon: "",
                                            outlined: "",
                                            depressed: "",
                                            small: "",
                                            fab: "",
                                            dark: "",
                                            color: "primary"
                                          },
                                          on: { click: _vm.goBackToGrid }
                                        },
                                        on
                                      ),
                                      [
                                        _c("font-awesome-icon", {
                                          style: { fontSize: "20px" },
                                          attrs: { icon: ["fal", "times"] }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            4119114962
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("generics.close")) + " ")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.showAddGuestModal
                ? [
                    _c("AddGuestModal", {
                      attrs: {
                        addMember: _vm.addMember,
                        members: _vm.conferenceMembers,
                        isConference: true,
                        showModal: _vm.showAddGuestModal,
                        closeModal: _vm.closeGuestModal,
                        conferenceId: _vm.getConferenceId
                      }
                    })
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.showCroppie
                ? [
                    _c("UploadGroupConfImg", {
                      attrs: {
                        confOrGroupId: _vm.conferenceId,
                        uploadConferenceImage: _vm.uploadConferenceImage,
                        showCroppie: _vm.showCroppie,
                        temporalImage: _vm.temporalImage,
                        resetInputImg: _vm.resetConferenceImageInput,
                        closeCroppie: _vm.openCloseCroppie,
                        isConference: true
                      }
                    })
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.showConferenceDocumentsModal
                ? _c("DocumentsManager", {
                    attrs: {
                      eventType: "conference",
                      documentsStore: _vm.conferenceStore,
                      showModal: _vm.showConferenceDocumentsModal,
                      amIModerator: _vm.canEditEvent
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showConfrimModal
                ? _c("ConfirmLeave", {
                    attrs: {
                      getDiff: _vm.getDiff,
                      showConfrimModal: _vm.showConfrimModal,
                      stayInPage: _vm.stayInPage,
                      leavePage: _vm.leavePage
                    }
                  })
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }