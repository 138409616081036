var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lateralPanel", class: { active: _vm.showLateralPanel } },
    [
      _vm.showLateralPanel
        ? _c("div", { staticClass: "panelContent" }, [
            _c(
              "div",
              { staticClass: "listUsers" },
              [
                _c(
                  "div",
                  { staticClass: "header" },
                  [
                    _c(
                      "v-text-field",
                      {
                        staticClass: "px-2 py-2",
                        class: { hidden: _vm.dataType == "messages" },
                        attrs: {
                          dense: "",
                          "single-line": "",
                          "hide-details": "",
                          label: _vm.$t("generics.search"),
                          outlined: "",
                          clearable: ""
                        },
                        on: {
                          "click:clear": function($event) {
                            _vm.searchUsers = ""
                          }
                        },
                        model: {
                          value: _vm.searchUsers,
                          callback: function($$v) {
                            _vm.searchUsers = $$v
                          },
                          expression: "searchUsers"
                        }
                      },
                      [
                        _c("font-awesome-icon", {
                          style: { fontSize: "18px" },
                          attrs: { slot: "append", icon: ["fal", "search"] },
                          slot: "append"
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mr-2",
                        attrs: { icon: "" },
                        on: { click: _vm.closePanel }
                      },
                      [
                        _c("font-awesome-icon", {
                          style: { fontSize: "18px", color: "#ffffff" },
                          attrs: { icon: ["fal", "bars"] }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.dataType == "messages"
                  ? _c("ReceivedAndSentMessagesDropDownEffector", {
                      attrs: {
                        notificationType: "message",
                        closeMenu: _vm.closePanel,
                        menuOpen: _vm.showLateralPanel,
                        isLateralPanel: true
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.dataType == "users" || _vm.dataType == "favorites"
                  ? _c(
                      "v-row",
                      { staticClass: "mx-auto rowUsers" },
                      [
                        _c(
                          "v-list",
                          { staticClass: "py-0" },
                          [
                            _vm._l(_vm.slicedPersons, function(person, index) {
                              return _c(
                                "v-list-item",
                                { key: index, staticClass: "customheightList" },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    {
                                      staticClass: "cursorPointer",
                                      style: _vm.getBorderByStatusFull(person),
                                      on: {
                                        click: function($event) {
                                          !_vm.disableCallBtnFunction(
                                            _vm.state.group[person.uuid]
                                          )
                                            ? _vm.handlerCallUser(person)
                                            : null
                                        }
                                      }
                                    },
                                    [
                                      _c("v-img", {
                                        staticClass: "white--text align-end",
                                        attrs: {
                                          contain: "",
                                          height: "80",
                                          src: _vm.getAvatarForUuid(person.uuid)
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "text-truncate" },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(person.name) +
                                              "\n              "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "buttonMessage",
                                                              attrs: {
                                                                icon: "",
                                                                color: "white"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.setShowModalSenMsg(
                                                                    person.uuid,
                                                                    "message"
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "font-awesome-icon",
                                                              {
                                                                style: {
                                                                  fontSize:
                                                                    "16px"
                                                                },
                                                                attrs: {
                                                                  icon: [
                                                                    "fal",
                                                                    "paper-plane"
                                                                  ]
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "components.userProfile.sendAMessage"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          !_vm.isInCallWithUs(person)
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "buttonCall",
                                                                  attrs: {
                                                                    icon: "",
                                                                    disabled: _vm.disableCallBtnFunction(
                                                                      _vm.state
                                                                        .group[
                                                                        person
                                                                          .uuid
                                                                      ]
                                                                    )
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.handlerCallUser(
                                                                        person
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "font-awesome-icon",
                                                                  {
                                                                    style: {
                                                                      fontSize:
                                                                        "16px"
                                                                    },
                                                                    attrs: {
                                                                      color: _vm.disableCallBtnFunction(
                                                                        _vm
                                                                          .state
                                                                          .group[
                                                                          person
                                                                            .uuid
                                                                        ]
                                                                      )
                                                                        ? "gray"
                                                                        : "white",
                                                                      icon: [
                                                                        "fal",
                                                                        "phone"
                                                                      ]
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "components.userListItem.videoCallStart"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "infinite-loading",
                              {
                                attrs: { distance: 10 },
                                on: { infinite: _vm.infiniteHandler }
                              },
                              [
                                _c("div", {
                                  attrs: { slot: "no-results" },
                                  slot: "no-results"
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  attrs: { slot: "no-more" },
                                  slot: "no-more"
                                })
                              ]
                            )
                          ],
                          2
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.dataType == "myWR" || _vm.dataType == "wrFor"
                  ? _c(
                      "v-row",
                      { staticClass: "mx-auto rowUsers" },
                      [
                        _c(
                          "v-list",
                          { staticClass: "py-0" },
                          [
                            _c(
                              "v-list-item",
                              [
                                _c(
                                  "v-list-item-title",
                                  { staticClass: "text-center" },
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click:
                                                            _vm.handlerPlusWR
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          fontSize: "30px",
                                                          color: "#ffffff"
                                                        },
                                                        attrs: {
                                                          icon: ["fal", "plus"]
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          410979184
                                        )
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "components.sidebar.guestTT"
                                              )
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.peopleWaitingRoom, function(
                              person,
                              index
                            ) {
                              return _c(
                                "v-list-item",
                                { key: index, staticClass: "customheightList" },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    {
                                      staticClass: "cursorPointer",
                                      style: _vm.getBorderByStatusFull(person),
                                      on: {
                                        click: function($event) {
                                          !_vm.disableCallBtnFunction(
                                            _vm.state.group[person.uuid]
                                          )
                                            ? _vm.handlerCallUser(person)
                                            : null
                                        }
                                      }
                                    },
                                    [
                                      _c("v-img", {
                                        staticClass: "white--text align-end",
                                        attrs: {
                                          contain: "",
                                          height: "80",
                                          src: _vm.getAvatarForUuid(person.uuid)
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "text-truncate" },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(person.name) +
                                              "\n              "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "buttonMessage",
                                                              attrs: {
                                                                icon: "",
                                                                color: "white"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.setShowModalSenMsg(
                                                                    person.uuid,
                                                                    "message"
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "font-awesome-icon",
                                                              {
                                                                style: {
                                                                  fontSize:
                                                                    "16px"
                                                                },
                                                                attrs: {
                                                                  icon: [
                                                                    "fal",
                                                                    "paper-plane"
                                                                  ]
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "components.userProfile.sendAMessage"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          !_vm.isInCallWithUs(person)
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "buttonCall",
                                                                  attrs: {
                                                                    icon: "",
                                                                    disabled: _vm.disableCallBtnFunction(
                                                                      _vm.state
                                                                        .group[
                                                                        person
                                                                          .uuid
                                                                      ]
                                                                    )
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.handlerCallUser(
                                                                        person
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "font-awesome-icon",
                                                                  {
                                                                    style: {
                                                                      fontSize:
                                                                        "16px"
                                                                    },
                                                                    attrs: {
                                                                      color: _vm.disableCallBtnFunction(
                                                                        _vm
                                                                          .state
                                                                          .group[
                                                                          person
                                                                            .uuid
                                                                        ]
                                                                      )
                                                                        ? "gray"
                                                                        : "white",
                                                                      icon: [
                                                                        "fal",
                                                                        "phone"
                                                                      ]
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "components.userListItem.videoCallStart"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showAddVisitorConference
        ? _c("AddVisitorsConference", {
            attrs: {
              isConferenceCall: _vm.isConferenceCall,
              showModal: _vm.showAddVisitorConference,
              closeModal: _vm.closeVisitorConference,
              dataConference: _vm.getDataConference,
              attachTo: "videos"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showAddVisitorCall
        ? _c("AddVisitorsCall", {
            attrs: {
              showModal: _vm.showAddVisitorCall,
              closeModal: _vm.closeVisitorCall,
              attachTo: "videos"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }