var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { position: "absolute", width: "100%" },
      attrs: { id: "counter" }
    },
    [
      _c(
        "div",
        {
          staticClass: "backImage",
          style: { backgroundImage: "url(" + _vm.getWaitingRoomBg + ")" }
        },
        [
          _vm.showLogoCompany
            ? _c("img", {
                class: _vm.getClassTemplate,
                attrs: { id: "over", src: _vm.showCompanyLogo, alt: "1" }
              })
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }