import { render, staticRenderFns } from "./userActionBar.vue?vue&type=template&id=25bd57ac&scoped=true"
import script from "./userActionBar.vue?vue&type=script&lang=js"
export * from "./userActionBar.vue?vue&type=script&lang=js"
import style0 from "./userActionBar.vue?vue&type=style&index=0&id=25bd57ac&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25bd57ac",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/voffice-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('25bd57ac')) {
      api.createRecord('25bd57ac', component.options)
    } else {
      api.reload('25bd57ac', component.options)
    }
    module.hot.accept("./userActionBar.vue?vue&type=template&id=25bd57ac&scoped=true", function () {
      api.rerender('25bd57ac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "websrc/components/sidebar/userActionBar/userActionBar.vue"
export default component.exports