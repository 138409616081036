var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      (!_vm.mini && _vm.isMobile) || !_vm.isMobile
        ? _c(
            "v-card",
            {
              staticClass: "mx-auto drawerContentCard",
              style: _vm.pushDrawerContentCard ? "z-index: 1" : "",
              attrs: {
                "min-width": "320",
                "max-width": "320",
                id: "drawerContentCard"
              }
            },
            [
              !_vm.hasUserAvatar && !_vm.amIGuest && _vm.showPictureModal
                ? _c("UploadUserPictureModal", {
                    attrs: {
                      showUploadUserPictureModal:
                        _vm.showUploadUserPictureModal,
                      toggleUploadUserPictureModal:
                        _vm.toggleUploadUserPictureModal
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-list-item",
                { attrs: { "three-line": "" } },
                [
                  _vm.hasPrivilege
                    ? _c(
                        "v-list-item-avatar",
                        {
                          staticClass:
                            "customAvatar borderRadius10 cursorPointer",
                          attrs: { tile: "", size: "60" }
                        },
                        [
                          _vm.state.user.avatar ===
                          "img/default_profile_picture.png"
                            ? _c("DefaultAvatar", {
                                attrs: {
                                  size: 60,
                                  userData: _vm.state.user,
                                  setUserInModal: _vm.handleOpenModal
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.showCamera &&
                          _vm.state.user.avatar !==
                            "img/default_profile_picture.png"
                            ? _c("v-img", {
                                staticClass: "mr-2 borderRadius10",
                                attrs: {
                                  "max-height": "60",
                                  "max-width": "60",
                                  contain: _vm.getPictureSize,
                                  src: _vm.state.user.avatar,
                                  "lazy-src":
                                    "assets/default_profile_picture.png"
                                },
                                on: { click: _vm.handleOpenModal }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showCamera
                            ? _c("CameraPreview", { attrs: { width: 80 } })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.hasPrivilege
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-list-item-avatar",
                                      _vm._g(
                                        {
                                          staticClass:
                                            "customAvatar borderRadius10 cursorPointer",
                                          attrs: { tile: "", size: "60" }
                                        },
                                        on
                                      ),
                                      [
                                        _vm.state.user.avatar ===
                                        "img/default_profile_picture.png"
                                          ? _c("DefaultAvatar", {
                                              attrs: {
                                                size: 60,
                                                userData: _vm.state.user,
                                                setUserInModal:
                                                  _vm.handleOpenModal
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.showCamera &&
                                        _vm.state.user.avatar !==
                                          "img/default_profile_picture.png"
                                          ? _c("v-img", {
                                              staticClass:
                                                "mx-2 borderRadius10",
                                              attrs: {
                                                "max-height": "60",
                                                "max-width": "60",
                                                contain: _vm.getPictureSize,
                                                src: _vm.state.user.avatar,
                                                "lazy-src":
                                                  "assets/default_profile_picture.png"
                                              },
                                              on: { click: _vm.handleOpenModal }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.showCamera
                                          ? _c("CameraPreview", {
                                              attrs: { width: 80 }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            951284971
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.drawerContentCard.videoTestTT"
                                )
                              )
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showCustomTT
                    ? _c("CustomTT", {
                        attrs: {
                          text:
                            _vm.$t("components.hotkeys.controlKey") + " + P",
                          extraclass: "ctrP",
                          position: "bottom"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    { class: !_vm.hasPrivilege ? "pt-0" : null },
                    [
                      !_vm.hasPrivilege
                        ? _c(
                            "div",
                            {
                              staticClass: "font-weight-bold text-truncate",
                              staticStyle: {
                                "max-width": "178px",
                                "min-width": "178px"
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.drawerContentCard.visitorLabel"
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "div",
                            { staticClass: "font-weight-bold text-truncate" },
                            [
                              _c(
                                "span",
                                { staticClass: "spanUserName text-truncate" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.titel) +
                                      " " +
                                      _vm._s(_vm.state.user.name)
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.hasPrivilege
                                ? [
                                    _c(
                                      "v-list-item-subtitle",
                                      {
                                        staticClass:
                                          "d-flex statusSection locationSection my-0 pt-1"
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "div",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "d-flex"
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-menu",
                                                            {
                                                              attrs: {
                                                                "open-on-click": _vm.hideUserLocation
                                                                  ? false
                                                                  : true,
                                                                "offset-y": "",
                                                                "close-on-click": true,
                                                                "max-height":
                                                                  "450",
                                                                "max-width":
                                                                  "350"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "div",
                                                                          _vm._g(
                                                                            {
                                                                              staticClass:
                                                                                "mx-1 cursorPointer statusLabel"
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            !_vm.hideUserLocation
                                                                              ? [
                                                                                  _vm
                                                                                    .state
                                                                                    .user
                                                                                    .userLocation ===
                                                                                  "homeOffice"
                                                                                    ? _c(
                                                                                        "font-awesome-icon",
                                                                                        {
                                                                                          style: {
                                                                                            fontSize:
                                                                                              "16px"
                                                                                          },
                                                                                          attrs: {
                                                                                            icon: [
                                                                                              "fal",
                                                                                              "house-user"
                                                                                            ],
                                                                                            color:
                                                                                              "#02889d"
                                                                                          }
                                                                                        }
                                                                                      )
                                                                                    : _vm
                                                                                        .state
                                                                                        .user
                                                                                        .userLocation ===
                                                                                      "outOfHouse"
                                                                                    ? _c(
                                                                                        "font-awesome-icon",
                                                                                        {
                                                                                          attrs: {
                                                                                            icon: [
                                                                                              "fal",
                                                                                              "car-building"
                                                                                            ],
                                                                                            color:
                                                                                              "#02889d"
                                                                                          }
                                                                                        }
                                                                                      )
                                                                                    : _vm
                                                                                        .state
                                                                                        .user
                                                                                        .userLocation ===
                                                                                      "realoOffice"
                                                                                    ? _c(
                                                                                        "font-awesome-icon",
                                                                                        {
                                                                                          attrs: {
                                                                                            icon: [
                                                                                              "fal",
                                                                                              "building"
                                                                                            ],
                                                                                            color:
                                                                                              "#02889d"
                                                                                          }
                                                                                        }
                                                                                      )
                                                                                    : _vm
                                                                                        .state
                                                                                        .user
                                                                                        .userLocation ===
                                                                                      "notSpecified"
                                                                                    ? _c(
                                                                                        "span",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "--"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    : _c(
                                                                                        "font-awesome-icon",
                                                                                        {
                                                                                          attrs: {
                                                                                            icon: [
                                                                                              "fal",
                                                                                              "location-arrow"
                                                                                            ]
                                                                                          }
                                                                                        }
                                                                                      )
                                                                                ]
                                                                              : _vm._e(),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _vm.hideUserLocation
                                                                              ? [
                                                                                  _c(
                                                                                    "p",
                                                                                    {
                                                                                      staticClass:
                                                                                        "statusText primary--text"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "-"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              : _vm._e()
                                                                          ],
                                                                          2
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              ),
                                                              model: {
                                                                value:
                                                                  _vm.showMenuLocation,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.showMenuLocation = $$v
                                                                },
                                                                expression:
                                                                  "showMenuLocation"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(" "),
                                                              _c("vue-scroll", [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle: {
                                                                      "max-height":
                                                                        "425px"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-list",
                                                                      {
                                                                        attrs: {
                                                                          dense:
                                                                            ""
                                                                        }
                                                                      },
                                                                      _vm._l(
                                                                        _vm.getLocations,
                                                                        function(
                                                                          locationOption,
                                                                          i
                                                                        ) {
                                                                          return _c(
                                                                            "v-list-item",
                                                                            {
                                                                              key: i,
                                                                              attrs: {
                                                                                id:
                                                                                  "locationItem_" +
                                                                                  i
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setLocation(
                                                                                    locationOption
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-list-item-title",
                                                                                {
                                                                                  staticClass:
                                                                                    "statusItem primary--text"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.locationListTranslated(
                                                                                        locationOption
                                                                                      )
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              1124261443
                                            )
                                          },
                                          [
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.drawerContentCard.myLocation"
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.hasPrivilege
                            ? [
                                _vm.openHolidaysCalendar
                                  ? [
                                      _c("HolidaysCalendarModal", {
                                        attrs: {
                                          userUUID: _vm.ownUUID,
                                          closeModal:
                                            _vm.toggleShowHolidaysModal,
                                          showModal: _vm.openHolidaysCalendar
                                        }
                                      })
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.show2faSettings
                                  ? _c("TwofaModalContainer", {
                                      attrs: {
                                        showModalStatus: _vm.show2faSettings,
                                        toggle2Modal2faSetting:
                                          _vm.toggle2Modal2faSetting
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showAdminSettings
                                  ? _c("AdminSettingModal", {
                                      attrs: {
                                        openCloseSidebar: _vm.openCloseSidebar,
                                        showAdminSettings:
                                          _vm.showAdminSettings,
                                        toggleModalAdminSettings:
                                          _vm.toggleModalAdminSettings
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showErrorRemoteStorage
                                  ? _c("ErrorRemoteStorage", {
                                      attrs: {
                                        showErrorRemoteStorage:
                                          _vm.showErrorRemoteStorage,
                                        closeModalErrorRemoteStorage:
                                          _vm.closeModalErrorRemoteStorage
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("StatusColorModal", {
                                  attrs: {
                                    showStatusColorSettings:
                                      _vm.showStatusColorSettings,
                                    toggleStatusColorSettings:
                                      _vm.toggleStatusColorSettings
                                  }
                                }),
                                _vm._v(" "),
                                _c("ProtocolModal", {
                                  attrs: {
                                    showProtocol: _vm.showProtocol,
                                    protocol: _vm.protocol
                                  }
                                }),
                                _vm._v(" "),
                                _c("DialUpTimeModal", {
                                  attrs: {
                                    showDialUpTime: _vm.showDialUpTime,
                                    dialUpTime: _vm.dialUpTime
                                  }
                                }),
                                _vm._v(" "),
                                _vm.startView
                                  ? _c("StartViewModal", {
                                      attrs: {
                                        showStartView: _vm.showStartView,
                                        startView: _vm.startView
                                      }
                                    })
                                  : _vm._e()
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showSetupDevice || _vm.showDeviceGuest
                            ? _c("DeviceSetupModal", {
                                attrs: {
                                  showSetupDevice: _vm.hasPrivilege
                                    ? _vm.showSetupDevice
                                    : _vm.showDeviceGuest,
                                  toggleModalSettings: _vm.toggleModalSettings
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("SpeechliveModal", {
                            attrs: {
                              showSpeechlive: _vm.showSpeechlive,
                              speechlive: _vm.speechlive
                            }
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    !_vm.hasPrivilege && _vm.getVisitorDate
                                      ? _c(
                                          "div",
                                          _vm._g(
                                            {
                                              staticClass: "text-truncate",
                                              staticStyle: {
                                                "max-width": "178px",
                                                "min-width": "178px"
                                              },
                                              style: { fontSize: "14px" }
                                            },
                                            on
                                          ),
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.drawerContentCard.available"
                                                  )
                                                ) + " "
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3566702091
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.drawerContentCard.availableSince"
                                )
                              ) +
                                "\n            " +
                                _vm._s(_vm.getVisitorDate)
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("ExternalServices"),
                      _vm._v(" "),
                      _vm.hasPrivilege
                        ? _c(
                            "v-row",
                            { staticClass: "mx-0" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "py-0 px-0",
                                  attrs: { cols: "6" }
                                },
                                [
                                  [
                                    _c(
                                      "v-list-item-subtitle",
                                      {
                                        staticClass:
                                          "d-flex statusSection my-0 pt-1"
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "div",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "d-flex"
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-menu",
                                                            {
                                                              attrs: {
                                                                "offset-y": "",
                                                                "close-on-click": true,
                                                                "max-height":
                                                                  "456",
                                                                "max-width":
                                                                  "350"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      return [
                                                                        _c(
                                                                          "div",
                                                                          _vm._g(
                                                                            {
                                                                              staticClass:
                                                                                "cursorPointer statusLabel"
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            _c(
                                                                              "p",
                                                                              {
                                                                                class:
                                                                                  _vm.statusTextClass,
                                                                                style:
                                                                                  "" +
                                                                                  (_vm.isDarkMode &&
                                                                                  _vm
                                                                                    .state
                                                                                    .user
                                                                                    .activity ==
                                                                                    "No status"
                                                                                    ? "color:white !important"
                                                                                    : "color:" +
                                                                                      _vm.borderClass +
                                                                                      "!important")
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      _vm.userActivityTranslated
                                                                                    ) +
                                                                                    "\n                            "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "font-awesome-icon",
                                                                              {
                                                                                staticClass:
                                                                                  "primary--text statusIcon",
                                                                                attrs: {
                                                                                  icon: [
                                                                                    "fas",
                                                                                    "chevron-down"
                                                                                  ]
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              ),
                                                              model: {
                                                                value:
                                                                  _vm.showMenuStatus,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.showMenuStatus = $$v
                                                                },
                                                                expression:
                                                                  "showMenuStatus"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(" "),
                                                              _c("vue-scroll", [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle: {
                                                                      "max-height":
                                                                        "456px"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-list",
                                                                      {
                                                                        ref:
                                                                          "statusContainer",
                                                                        attrs: {
                                                                          dense:
                                                                            ""
                                                                        }
                                                                      },
                                                                      _vm._l(
                                                                        _vm.getStatus,
                                                                        function(
                                                                          activityOption,
                                                                          i
                                                                        ) {
                                                                          return _c(
                                                                            "v-list-item",
                                                                            {
                                                                              key: i,
                                                                              attrs: {
                                                                                tabindex: i,
                                                                                id:
                                                                                  "statusItem_" +
                                                                                  i
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setStatus(
                                                                                    activityOption
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-list-item-title",
                                                                                {
                                                                                  staticClass:
                                                                                    "statusItem",
                                                                                  style:
                                                                                    (_vm.isDarkMode &&
                                                                                    activityOption ==
                                                                                      "No status"
                                                                                      ? "color:white !important"
                                                                                      : "color:" +
                                                                                        _vm.getStatusColor(
                                                                                          activityOption
                                                                                        ) +
                                                                                        "!important") +
                                                                                    " "
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "status." +
                                                                                          activityOption
                                                                                      )
                                                                                    ) +
                                                                                      " "
                                                                                  ),
                                                                                  activityOption !=
                                                                                  "No status"
                                                                                    ? _c(
                                                                                        "v-tooltip",
                                                                                        {
                                                                                          attrs: {
                                                                                            top:
                                                                                              ""
                                                                                          },
                                                                                          scopedSlots: _vm._u(
                                                                                            [
                                                                                              {
                                                                                                key:
                                                                                                  "activator",
                                                                                                fn: function(
                                                                                                  ref
                                                                                                ) {
                                                                                                  var on =
                                                                                                    ref.on
                                                                                                  return [
                                                                                                    _c(
                                                                                                      "font-awesome-icon",
                                                                                                      _vm._g(
                                                                                                        {
                                                                                                          style: {
                                                                                                            fontSize:
                                                                                                              "17px"
                                                                                                          },
                                                                                                          attrs: {
                                                                                                            color:
                                                                                                              (_vm.isDarkMode &&
                                                                                                              activityOption ==
                                                                                                                "No status"
                                                                                                                ? "color:white !important"
                                                                                                                : "color:" +
                                                                                                                  _vm.getStatusColor(
                                                                                                                    activityOption
                                                                                                                  ) +
                                                                                                                  "!important") +
                                                                                                              " ",
                                                                                                            icon: [
                                                                                                              "fal",
                                                                                                              "info-circle"
                                                                                                            ]
                                                                                                          }
                                                                                                        },
                                                                                                        on
                                                                                                      )
                                                                                                    )
                                                                                                  ]
                                                                                                }
                                                                                              }
                                                                                            ],
                                                                                            null,
                                                                                            true
                                                                                          )
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                            " +
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "status-tooltips." +
                                                                                                        activityOption
                                                                                                    )
                                                                                                  ) +
                                                                                                  "\n                                          "
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e()
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              3966279646
                                            )
                                          },
                                          [
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.drawerContentCard.myStatus"
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.showCustomTT
                                      ? _c("CustomTT", {
                                          attrs: {
                                            text:
                                              _vm.$t(
                                                "components.hotkeys.controlKey"
                                              ) + " + A",
                                            position: "top",
                                            extraclass: "ctra"
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                ],
                                2
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                {
                  staticClass: "px-2 noBoxShadow",
                  staticStyle: { height: "36px" },
                  style: {
                    borderWidth: 1,
                    borderColor: _vm.borderClass,
                    borderStyle: "solid"
                  },
                  attrs: { color: _vm.badgetClassObj }
                },
                [
                  _c("UserActionBar", {
                    attrs: {
                      setTotalNotificationCall: _vm.setTotalNotificationCall,
                      totalNotificationCall: _vm.totalNotificationCall,
                      phoneColorColor: _vm.phoneColorColor,
                      totalNotificationTicket: _vm.totalNotificationTicket,
                      amIGuest: _vm.amIGuest,
                      totalNotificationMessage: _vm.totalNotificationMessage,
                      setTotalNotificationMessage:
                        _vm.setTotalNotificationMessage,
                      totalNotificationFile: _vm.totalNotificationFile,
                      setTotalNotificationFile: _vm.setTotalNotificationFile,
                      totalNotificationInvite: _vm.totalNotificationInvite,
                      resetInviteUserNotification:
                        _vm.resetInviteUserNotification,
                      iconsBlack: !this.wholeBarWithColors(),
                      logout: _vm.logout,
                      isMobile: _vm.isMobile,
                      setUserInModal: _vm.setUserInModal,
                      toggleShowHolidaysModal: _vm.toggleShowHolidaysModal,
                      showCamera: _vm.showCamera,
                      cameraPreview: _vm.cameraPreview,
                      handleStatusClick: _vm.handleStatusClick
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.showModalAditionalInfoStatus
                ? _c("AditionalStatusInfoModal", {
                    attrs: {
                      showModal: _vm.showModalAditionalInfoStatus,
                      closeModal: _vm.showCloseAditionalStatusInfoModal
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showForwardUsers
                ? _c("RepresentativeModal", {
                    attrs: {
                      showModal: _vm.showForwardUsers,
                      closeModal: _vm.showCloseForwardUsers
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showUserSettingsNewModal
                ? _c("UserSettingsNewModal", {
                    attrs: {
                      showModal: _vm.showUserSettingsNewModal,
                      toggle2faSettings: _vm.toggle2Modal2faSetting,
                      showProtocol: _vm.showProtocol,
                      showDialUpTime: _vm.showDialUpTime,
                      showStartView: _vm.showStartView,
                      showSpeechlive: _vm.showSpeechlive,
                      toggleShowHolidaysModal: _vm.toggleShowHolidaysModal
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.mini && _vm.isMobile
        ? _c(
            "v-tooltip",
            {
              attrs: { right: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-list-item",
                          _vm._g({ staticClass: "cursorPointer px-3" }, on),
                          [
                            _c(
                              "v-list-item-icon",
                              [
                                _vm.state.user.avatar ===
                                "img/default_profile_picture.png"
                                  ? _c("DefaultAvatar", {
                                      attrs: {
                                        size: 30,
                                        userData: _vm.state.user,
                                        setUserInModal: _vm.handleOpenModal
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.showCamera &&
                                _vm.state.user.avatar !==
                                  "img/default_profile_picture.png"
                                  ? _c("v-img", {
                                      staticClass: "borderRadius50",
                                      attrs: {
                                        "max-height": "30",
                                        "max-width": "30",
                                        contain: _vm.getPictureSize,
                                        src: _vm.state.user.avatar,
                                        "lazy-src":
                                          "assets/default_profile_picture.png"
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.clickUserImageMini.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                914468164
              )
            },
            [
              _vm._v(" "),
              _c("span", [
                _vm._v(_vm._s(_vm.$t("components.sidebar.conferencesTT")))
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }