var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "570" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.titleModal,
              closeModalFunction: _vm.closeModal
            }
          }),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mx-0 pt-4 pb-3 px-4" },
            [
              _c("v-col", [
                _c("p", { staticClass: "font-weight-medium" }, [
                  _vm._v(_vm._s(_vm.$t("generics.attention")))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.isSuperVisorOrganisation
                        ? _vm.$t(
                            "components.manageOrganisation.hasAmanagementFunction"
                          )
                        : _vm.$t("generics.deleteUser")
                    )
                  )
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "FooterModal",
            { attrs: { closeModalFunction: _vm.closeModal, showCancel: true } },
            [
              _c(
                "v-btn",
                {
                  staticStyle: { color: "red" },
                  attrs: {
                    disabled: _vm.isSuperVisorOrganisation,
                    depressed: "",
                    color: "white"
                  },
                  on: {
                    click: function($event) {
                      return _vm.revokeInvite()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("generics.delete")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }