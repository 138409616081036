var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pa-0 col-12 cursorPointer" },
    [
      _c(
        "v-progress-linear",
        {
          class: _vm.getClassProgressLinear,
          attrs: {
            "background-color": "#6dbac6",
            height: "24",
            color:
              _vm.getPercentage <= 50
                ? "barColorGreen"
                : _vm.getPercentage > 50
                ? "barColorOrange"
                : "barColorGreen",
            value: _vm.getPercentage
          },
          on: {
            click: function($event) {
              return _vm.addMoreTime()
            }
          }
        },
        [
          [
            _c("strong", { staticClass: "white--text startCall" }, [
              _vm._v(_vm._s(_vm.getCallStart))
            ]),
            _vm._v(" "),
            _vm.originalCallDuration != 31536000000 &&
            _vm.originalCallDuration != 86400000
              ? _c(
                  "strong",
                  {
                    staticClass: "black--text",
                    staticStyle: { "margin-right": "25px" }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("generics.planned")) +
                        " " +
                        _vm._s(_vm.originalCallTime) +
                        " Min"
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.originalCallDuration != 31536000000 &&
            _vm.originalCallDuration != 86400000
              ? _c(
                  "strong",
                  {
                    staticClass: "customRed",
                    staticStyle: { "margin-left": "25px" }
                  },
                  [_vm._v("(" + _vm._s(_vm.timerCall) + ")")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("strong", { staticClass: "white--text endCall" }, [
              _vm._v(_vm._s(_vm.getCallEnd))
            ])
          ]
        ],
        2
      ),
      _vm._v(" "),
      _vm.showEndingModal
        ? [
            _c("BeforeEndingCallBridgeModal", {
              attrs: {
                showModal: _vm.showEndingModal,
                closeModal: _vm.openCloseBeforeEndingModal,
                hangUpBridgeCall: _vm.hangUpBridgeCall,
                addMoreTime: _vm.addMoreTime,
                callUUID: _vm.getCallUuid,
                amIModerator: _vm.amIModerator
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }