var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.hideWaitingRoom
    ? _c(
        "div",
        { staticClass: "mx-0" },
        [
          !_vm.isWaitingRoom
            ? [
                _c(
                  "v-card",
                  { staticClass: "px-0 py-0", attrs: { flat: "" } },
                  [
                    _c(
                      "v-row",
                      { staticClass: "ml-0" },
                      [
                        _c("WaitingRoomBackground"),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "pt-10 pl-5 col-xl-12 col-lg-12 col-md-12"
                          },
                          [_c("RegularGridWaitingRoomNew")],
                          1
                        ),
                        _vm._v(" "),
                        !_vm.selectedConference
                          ? _c("div", { staticClass: "text-center" }, [
                              _vm.hasSocialMedia
                                ? _c(
                                    "div",
                                    { staticClass: "divSocialMedia" },
                                    [
                                      _vm.getFacebookLink
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                icon: "",
                                                href: _vm.getFacebookLink,
                                                color: "#0076FB",
                                                target: "_blank",
                                                depressed: ""
                                              }
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                style: { fontSize: "25px" },
                                                attrs: {
                                                  icon: ["fab", "facebook"]
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.getTwitterLink
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                icon: "",
                                                href: _vm.getTwitterLink,
                                                color: "#1da1f2",
                                                target: "_blank",
                                                depressed: ""
                                              }
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                style: { fontSize: "25px" },
                                                attrs: {
                                                  icon: ["fab", "twitter"]
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.getYoutubeLink
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                icon: "",
                                                href: _vm.getYoutubeLink,
                                                color: "red",
                                                target: "_blank",
                                                depressed: ""
                                              }
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                style: { fontSize: "25px" },
                                                attrs: {
                                                  icon: ["fab", "youtube"]
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.getLinkedinLink
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                icon: "",
                                                href: _vm.getLinkedinLink,
                                                color: "#2962ff",
                                                target: "_blank",
                                                depressed: ""
                                              }
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                style: { fontSize: "25px" },
                                                attrs: {
                                                  icon: ["fab", "linkedin"]
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.getInstagramLink
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                href: _vm.getInstagramLink,
                                                icon: "",
                                                target: "_blank",
                                                depressed: ""
                                              }
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  attrs: {
                                                    "enable-background":
                                                      "new 0 0 24 24",
                                                    height: "24",
                                                    viewBox: "0 0 24 24",
                                                    width: "24",
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    "xmlns:xlink":
                                                      "http://www.w3.org/1999/xlink"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "linearGradient",
                                                    {
                                                      attrs: {
                                                        id: "SVGID_1_",
                                                        gradientTransform:
                                                          "matrix(0 -1.982 -1.844 0 -132.522 -51.077)",
                                                        gradientUnits:
                                                          "userSpaceOnUse",
                                                        x1: "-37.106",
                                                        x2: "-26.555",
                                                        y1: "-72.705",
                                                        y2: "-84.047"
                                                      }
                                                    },
                                                    [
                                                      _c("stop", {
                                                        attrs: {
                                                          offset: "0",
                                                          "stop-color": "#fd5"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("stop", {
                                                        attrs: {
                                                          offset: ".5",
                                                          "stop-color":
                                                            "#ff543e"
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("stop", {
                                                        attrs: {
                                                          offset: "1",
                                                          "stop-color":
                                                            "#c837ab"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("path", {
                                                    attrs: {
                                                      d:
                                                        "m1.5 1.633c-1.886 1.959-1.5 4.04-1.5 10.362 0 5.25-.916 10.513 3.878 11.752 1.497.385 14.761.385 16.256-.002 1.996-.515 3.62-2.134 3.842-4.957.031-.394.031-13.185-.001-13.587-.236-3.007-2.087-4.74-4.526-5.091-.559-.081-.671-.105-3.539-.11-10.173.005-12.403-.448-14.41 1.633z",
                                                      fill: "url(#SVGID_1_)"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("path", {
                                                    attrs: {
                                                      d:
                                                        "m11.998 3.139c-3.631 0-7.079-.323-8.396 3.057-.544 1.396-.465 3.209-.465 5.805 0 2.278-.073 4.419.465 5.804 1.314 3.382 4.79 3.058 8.394 3.058 3.477 0 7.062.362 8.395-3.058.545-1.41.465-3.196.465-5.804 0-3.462.191-5.697-1.488-7.375-1.7-1.7-3.999-1.487-7.374-1.487zm-.794 1.597c7.574-.012 8.538-.854 8.006 10.843-.189 4.137-3.339 3.683-7.211 3.683-7.06 0-7.263-.202-7.263-7.265 0-7.145.56-7.257 6.468-7.263zm5.524 1.471c-.587 0-1.063.476-1.063 1.063s.476 1.063 1.063 1.063 1.063-.476 1.063-1.063-.476-1.063-1.063-1.063zm-4.73 1.243c-2.513 0-4.55 2.038-4.55 4.551s2.037 4.55 4.55 4.55 4.549-2.037 4.549-4.55-2.036-4.551-4.549-4.551zm0 1.597c3.905 0 3.91 5.908 0 5.908-3.904 0-3.91-5.908 0-5.908z",
                                                      fill: "#fff"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.getWebLink
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticStyle: {
                                                height: "32px",
                                                "min-width": "32px !important",
                                                width: "32px !important"
                                              },
                                              attrs: {
                                                href: _vm.getWebLink,
                                                target: "_blank",
                                                color: "primary",
                                                dense: "",
                                                depressed: ""
                                              }
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                style: { fontSize: "20px" },
                                                attrs: {
                                                  icon: ["fal", "globe"]
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isWaitingRoom
            ? [
                _c(
                  "v-card",
                  { attrs: { flat: "" } },
                  [
                    _c(
                      "v-row",
                      { staticClass: "mx-0" },
                      [
                        _c("WaitingRoomBackground", {
                          staticClass: "backGuest"
                        }),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          {
                            class:
                              "backGuestBox px-0 py-0  " +
                              (_vm.isMobile
                                ? "col-12 backGuestBoxMobile"
                                : "col-xl-3 col-lg-3 col-md-5 mt-6 mb-6 ml-6")
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c("v-col", {
                                  staticClass:
                                    "col-xl-12 col-lg-12 col-md-12 py-0"
                                }),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pt-5 pb-3 px-7 pb-0 py-0",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "namePersonStyle font-weight-medium mb-3 black--text"
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.titelPerson(
                                                _vm.getInviterPerson
                                              )
                                            ) +
                                            "\n                " +
                                            _vm._s(
                                              _vm.namePerson(
                                                _vm.getInviterPerson
                                              )
                                            ) +
                                            "\n                " +
                                            _vm._s(
                                              _vm.$t(
                                                "components.waitingRoom.userInform",
                                                [_vm.userConecctedAt]
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("v-divider"),
                                    _vm._v(" "),
                                    !_vm.isMobile &&
                                    _vm.getWaitingRoomExpectedTime !==
                                      undefined &&
                                    _vm.getWaitingRoomExpectedTime.length > 0
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "pt-3 mb-0 pb-0 black--text"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.waitingRoom.timeExpectedText",
                                                    [
                                                      _vm.getWaitingRoomExpectedTime
                                                    ]
                                                  )
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.hasSocialMedia && _vm.isMobile
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "divSocialMediaGuestMobile"
                                      },
                                      [
                                        _vm.getFacebookLink
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  href: _vm.getFacebookLink,
                                                  icon: "",
                                                  color: "#0076FB",
                                                  target: "_blank",
                                                  depressed: ""
                                                }
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  style: { fontSize: "25px" },
                                                  attrs: {
                                                    icon: ["fab", "facebook"]
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.getTwitterLink
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  href: _vm.getTwitterLink,
                                                  icon: "",
                                                  color: "#1da1f2",
                                                  target: "_blank",
                                                  depressed: ""
                                                }
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  style: { fontSize: "25px" },
                                                  attrs: {
                                                    icon: ["fab", "twitter"]
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.getYoutubeLink
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  href: _vm.getYoutubeLink,
                                                  icon: "",
                                                  color: "red",
                                                  target: "_blank",
                                                  depressed: ""
                                                }
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  style: { fontSize: "25px" },
                                                  attrs: {
                                                    icon: ["fab", "youtube"]
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.getLinkedinLink
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  icon: "",
                                                  href: _vm.getLinkedinLink,
                                                  color: "#2962ff",
                                                  target: "_blank",
                                                  depressed: ""
                                                }
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  style: { fontSize: "25px" },
                                                  attrs: {
                                                    icon: ["fab", "linkedin"]
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.getInstagramLink
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  href: _vm.getInstagramLink,
                                                  icon: "",
                                                  target: "_blank",
                                                  depressed: ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    attrs: {
                                                      "enable-background":
                                                        "new 0 0 24 24",
                                                      height: "24",
                                                      viewBox: "0 0 24 24",
                                                      width: "24",
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      "xmlns:xlink":
                                                        "http://www.w3.org/1999/xlink"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "linearGradient",
                                                      {
                                                        attrs: {
                                                          id: "SVGID_1_",
                                                          gradientTransform:
                                                            "matrix(0 -1.982 -1.844 0 -132.522 -51.077)",
                                                          gradientUnits:
                                                            "userSpaceOnUse",
                                                          x1: "-37.106",
                                                          x2: "-26.555",
                                                          y1: "-72.705",
                                                          y2: "-84.047"
                                                        }
                                                      },
                                                      [
                                                        _c("stop", {
                                                          attrs: {
                                                            offset: "0",
                                                            "stop-color": "#fd5"
                                                          }
                                                        }),
                                                        _vm._v(" "),
                                                        _c("stop", {
                                                          attrs: {
                                                            offset: ".5",
                                                            "stop-color":
                                                              "#ff543e"
                                                          }
                                                        }),
                                                        _vm._v(" "),
                                                        _c("stop", {
                                                          attrs: {
                                                            offset: "1",
                                                            "stop-color":
                                                              "#c837ab"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c("path", {
                                                      attrs: {
                                                        d:
                                                          "m1.5 1.633c-1.886 1.959-1.5 4.04-1.5 10.362 0 5.25-.916 10.513 3.878 11.752 1.497.385 14.761.385 16.256-.002 1.996-.515 3.62-2.134 3.842-4.957.031-.394.031-13.185-.001-13.587-.236-3.007-2.087-4.74-4.526-5.091-.559-.081-.671-.105-3.539-.11-10.173.005-12.403-.448-14.41 1.633z",
                                                        fill: "url(#SVGID_1_)"
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c("path", {
                                                      attrs: {
                                                        d:
                                                          "m11.998 3.139c-3.631 0-7.079-.323-8.396 3.057-.544 1.396-.465 3.209-.465 5.805 0 2.278-.073 4.419.465 5.804 1.314 3.382 4.79 3.058 8.394 3.058 3.477 0 7.062.362 8.395-3.058.545-1.41.465-3.196.465-5.804 0-3.462.191-5.697-1.488-7.375-1.7-1.7-3.999-1.487-7.374-1.487zm-.794 1.597c7.574-.012 8.538-.854 8.006 10.843-.189 4.137-3.339 3.683-7.211 3.683-7.06 0-7.263-.202-7.263-7.265 0-7.145.56-7.257 6.468-7.263zm5.524 1.471c-.587 0-1.063.476-1.063 1.063s.476 1.063 1.063 1.063 1.063-.476 1.063-1.063-.476-1.063-1.063-1.063zm-4.73 1.243c-2.513 0-4.55 2.038-4.55 4.551s2.037 4.55 4.55 4.55 4.549-2.037 4.549-4.55-2.036-4.551-4.549-4.551zm0 1.597c3.905 0 3.91 5.908 0 5.908-3.904 0-3.91-5.908 0-5.908z",
                                                        fill: "#fff"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.getWebLink
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticStyle: {
                                                  height: "32px",
                                                  "min-width":
                                                    "32px !important",
                                                  width: "32px !important"
                                                },
                                                attrs: {
                                                  href: _vm.getWebLink,
                                                  color: "primary",
                                                  target: "_blank",
                                                  dense: "",
                                                  depressed: ""
                                                }
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  style: { fontSize: "20px" },
                                                  attrs: {
                                                    icon: ["fal", "globe"]
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !_vm.isMobile
                          ? _c(
                              "v-col",
                              {
                                staticClass:
                                  "col-xl-9 col-lg-9 col-md-7 my-0 py-0 px-2"
                              },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "mx-0" },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "text-center my-0 py-0",
                                        attrs: { cols: "12" }
                                      },
                                      [
                                        _vm.hasSocialMedia && !_vm.isMobile
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "divSocialMediaGuest mt-2"
                                              },
                                              [
                                                _vm.getFacebookLink
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          href:
                                                            _vm.getFacebookLink,
                                                          icon: "",
                                                          color: "#0076FB",
                                                          target: "_blank",
                                                          depressed: ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            style: {
                                                              fontSize: "25px"
                                                            },
                                                            attrs: {
                                                              icon: [
                                                                "fab",
                                                                "facebook"
                                                              ]
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.getTwitterLink
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          href:
                                                            _vm.getTwitterLink,
                                                          icon: "",
                                                          color: "#1da1f2",
                                                          target: "_blank",
                                                          depressed: ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            style: {
                                                              fontSize: "25px"
                                                            },
                                                            attrs: {
                                                              icon: [
                                                                "fab",
                                                                "twitter"
                                                              ]
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.getYoutubeLink
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          href:
                                                            _vm.getYoutubeLink,
                                                          icon: "",
                                                          color: "red",
                                                          target: "_blank",
                                                          depressed: ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            style: {
                                                              fontSize: "25px"
                                                            },
                                                            attrs: {
                                                              icon: [
                                                                "fab",
                                                                "youtube"
                                                              ]
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.getLinkedinLink
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          icon: "",
                                                          href:
                                                            _vm.getLinkedinLink,
                                                          color: "#2962ff",
                                                          target: "_blank",
                                                          depressed: ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            style: {
                                                              fontSize: "25px"
                                                            },
                                                            attrs: {
                                                              icon: [
                                                                "fab",
                                                                "linkedin"
                                                              ]
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.getInstagramLink
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "mr-2",
                                                        attrs: {
                                                          href:
                                                            _vm.getInstagramLink,
                                                          icon: "",
                                                          target: "_blank",
                                                          depressed: ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "svg",
                                                          {
                                                            attrs: {
                                                              "enable-background":
                                                                "new 0 0 24 24",
                                                              height: "24",
                                                              viewBox:
                                                                "0 0 24 24",
                                                              width: "24",
                                                              xmlns:
                                                                "http://www.w3.org/2000/svg",
                                                              "xmlns:xlink":
                                                                "http://www.w3.org/1999/xlink"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "linearGradient",
                                                              {
                                                                attrs: {
                                                                  id:
                                                                    "SVGID_1_",
                                                                  gradientTransform:
                                                                    "matrix(0 -1.982 -1.844 0 -132.522 -51.077)",
                                                                  gradientUnits:
                                                                    "userSpaceOnUse",
                                                                  x1: "-37.106",
                                                                  x2: "-26.555",
                                                                  y1: "-72.705",
                                                                  y2: "-84.047"
                                                                }
                                                              },
                                                              [
                                                                _c("stop", {
                                                                  attrs: {
                                                                    offset: "0",
                                                                    "stop-color":
                                                                      "#fd5"
                                                                  }
                                                                }),
                                                                _vm._v(" "),
                                                                _c("stop", {
                                                                  attrs: {
                                                                    offset:
                                                                      ".5",
                                                                    "stop-color":
                                                                      "#ff543e"
                                                                  }
                                                                }),
                                                                _vm._v(" "),
                                                                _c("stop", {
                                                                  attrs: {
                                                                    offset: "1",
                                                                    "stop-color":
                                                                      "#c837ab"
                                                                  }
                                                                })
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c("path", {
                                                              attrs: {
                                                                d:
                                                                  "m1.5 1.633c-1.886 1.959-1.5 4.04-1.5 10.362 0 5.25-.916 10.513 3.878 11.752 1.497.385 14.761.385 16.256-.002 1.996-.515 3.62-2.134 3.842-4.957.031-.394.031-13.185-.001-13.587-.236-3.007-2.087-4.74-4.526-5.091-.559-.081-.671-.105-3.539-.11-10.173.005-12.403-.448-14.41 1.633z",
                                                                fill:
                                                                  "url(#SVGID_1_)"
                                                              }
                                                            }),
                                                            _vm._v(" "),
                                                            _c("path", {
                                                              attrs: {
                                                                d:
                                                                  "m11.998 3.139c-3.631 0-7.079-.323-8.396 3.057-.544 1.396-.465 3.209-.465 5.805 0 2.278-.073 4.419.465 5.804 1.314 3.382 4.79 3.058 8.394 3.058 3.477 0 7.062.362 8.395-3.058.545-1.41.465-3.196.465-5.804 0-3.462.191-5.697-1.488-7.375-1.7-1.7-3.999-1.487-7.374-1.487zm-.794 1.597c7.574-.012 8.538-.854 8.006 10.843-.189 4.137-3.339 3.683-7.211 3.683-7.06 0-7.263-.202-7.263-7.265 0-7.145.56-7.257 6.468-7.263zm5.524 1.471c-.587 0-1.063.476-1.063 1.063s.476 1.063 1.063 1.063 1.063-.476 1.063-1.063-.476-1.063-1.063-1.063zm-4.73 1.243c-2.513 0-4.55 2.038-4.55 4.551s2.037 4.55 4.55 4.55 4.549-2.037 4.549-4.55-2.036-4.551-4.549-4.551zm0 1.597c3.905 0 3.91 5.908 0 5.908-3.904 0-3.91-5.908 0-5.908z",
                                                                fill: "#fff"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.getWebLink
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        staticStyle: {
                                                          height: "32px",
                                                          "min-width":
                                                            "32px !important",
                                                          width:
                                                            "32px !important"
                                                        },
                                                        attrs: {
                                                          href: _vm.getWebLink,
                                                          color: "primary",
                                                          target: "_blank",
                                                          dense: "",
                                                          depressed: ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "font-awesome-icon",
                                                          {
                                                            style: {
                                                              fontSize: "20px"
                                                            },
                                                            attrs: {
                                                              icon: [
                                                                "fal",
                                                                "globe"
                                                              ]
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }