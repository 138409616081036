var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      on: {
        click: function($event) {
          return _vm.doMakeSelection(_vm.confTemplate.groupId)
        }
      }
    },
    [
      _c(
        "v-tooltip",
        {
          attrs: { top: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-card-text",
                    _vm._g({ staticStyle: { cursor: "pointer" } }, on),
                    [
                      _c("v-img", {
                        staticClass: "mr-1",
                        attrs: {
                          src: _vm.confTemplate.groupImage,
                          "max-height": "30",
                          "max-width": "30",
                          contain: ""
                        }
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "templateNameHeader" }, [
                        _vm._v(
                          _vm._s(_vm.$t("components.groups.templateName")) + ":"
                        )
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "templateName" }, [
                        _vm._v(_vm._s(_vm.confTemplate.groupName))
                      ])
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c(
            "span",
            [
              _c("TemplateGeneralDetails", {
                attrs: { confTemplate: _vm.confTemplate }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }