var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: { top: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "font-awesome-icon",
                _vm._g(
                  {
                    class:
                      (!_vm.deleteActive ? "deleteBasketNormal" : "") +
                      " " +
                      (_vm.deleteActive ? "deleteBasketActive" : "") +
                      " " +
                      _vm.extraClasses,
                    style: { fontSize: "" + (parseInt(_vm.fontSize) + "px") },
                    attrs: { icon: ["fal", "trash"], disabled: _vm.isDeleting },
                    on: {
                      click: function($event) {
                        return _vm.handleClick($event)
                      }
                    }
                  },
                  on
                )
              )
            ]
          }
        }
      ])
    },
    [_vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.text))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }