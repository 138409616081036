import { setEventStateModalEvent, resetEventStateModalEvent } from './timelineEventModal/events';
import { eventModalStateStore } from './timelineEventModal/state';

import { setAgreementStateModalEvent, resetAgreementStateModalEvent } from './globalAgreementModal/events';
import { agreementModalStateStore } from './globalAgreementModal/state';

import { setQualityVotingModalEvent, resetQualityVotingModalEvent, setBadQualityMessageModalEvent, resetBadQualityMessageModalEvent, setRateMessageModalEvent, resetRateMessageModalEvent } from './qualityVotingModal/events';
import { qualityVotingModalStore, badQualityMessageModalStore, rateMessageModalStore } from './qualityVotingModal/state';

import { setReminderModalEvent, resetReminderModalEvent } from './reminderModal/events';
import { reminderModalStore } from './reminderModal/state';

import { setDirectCalLInviteModalEvent, resetDirectCallInviteModalEvent } from './directCallInviteModal/events';
import { directCallInvitationModalStore } from './directCallInviteModal/state';

import { setChangelogModalEvent, resetChangelogModalEvent } from './changelogModal/events';
import { changelogModalStore } from './changelogModal/state';

import { setNotificationModalEvent, resetNotificationModalEvent } from './notificationModal/events';
import { notificationModalStore } from './notificationModal/state';

import { setErrorInCallModalEvent, resetErrorInCallModalEvent } from './errorInCallModal/events';
import { errorInCallModalStore } from './errorInCallModal/state';

import { setBeforeEndingCallModalEvent, resetBeforeEndingCallModallEvent } from './beforeEndingCallModal/events';
import { beforeEndingCallModalStore } from './beforeEndingCallModal/state';

import { setCallBridgeIsFullEvent, resetCallBridgeIsFullEvent } from './callBridgeIsFull/events';
import { callBridgeIsFullStore } from './callBridgeIsFull/state';

import { setNewVersionAvailableModalEvent, resetNewVersionAvailableModalEvent } from './newVersionAvailable/events';
import { newVersionAvailableModalStore } from './newVersionAvailable/state';

import { setQrCodeModalEvent, resetQrCodeModalEvent } from './qrCodeModal/events';
import { qrCodeModalStore } from './qrCodeModal/state';

import { setVideoTestModalEvent, resetVideoTestModalEvent } from './videoTestModal/events';
import { videoTestModalStore } from './videoTestModal/state';

import { setPaypalInfoModalEvent, resetPaypalInfoModalEvent } from './paypalInfoModal/events';
import { paypalInfoModalStore } from './paypalInfoModal/state';

import { setExternalServicesModalEvent, resetExternalServicesModalEvent } from './externalServices/events';
import { externalServicesModalStore } from './externalServices/state';

import { setvOfficeProVersionModalEvent, resetvOfficeProVersionModallEvent } from './vOfficeProVersion/events';
import { vOfficeProVersionModalStore } from './vOfficeProVersion/state';

import { setRequestPaymentModal, setRequestPaymentEventModal, setPaymentSuccessEventModal, setPaymentInProgressModal, setPaymentInProgressEventModal, setSuccessPayment, setRequestedPaymentEventModal } from './paymentModals/events';
import { paymentModalStore, paymentEventModalStore, successPaymentEventModalStore, paymentInProgressModalStore, successPaymentStore } from './paymentModals/state';

import { setJoinBridgeCallModalEvent, resetJoinBridgeCallModalEvent } from './joinBridgeCallModal/events';
import { joinBridgeCallStore } from './joinBridgeCallModal/state';

import { setSendMessageInCallModalEvent, resetSendMessageInCallModalEvent } from './sendMessageInCallModal/events';
import { sendMessageInCallStore } from './sendMessageInCallModal/state';

import { setNewMessageFromCallEvent, resetNewMessageFromCallEvent } from './newMessageFromCall/events';
import { newMessageFromCallStore } from './newMessageFromCall/state';

import { setFullBridgeCallModalEvent, resetFullBridgeCallModalEvent } from './fullBridgeCallModal/events';
import { fullBridgeCallStore } from './fullBridgeCallModal/state';

import { setInfoConferenceModalEvent, resetInfoConferenceModalEvent } from './infoConferenceModal/events';
import { infoConferenceStore } from './infoConferenceModal/state';

import { setUserSettingsEvent, resetUserSettingsEvent } from './userSettingsModal/events';
import { userSettingsStore } from './userSettingsModal/state';

import { setHotkesysvent, resetHotkeysEvent } from './hotkeysModal/events';
import { hotkeysStore } from './hotkeysModal/state';

import { setUserProfileEvent, resetUserProfileEvent } from './userProfileModal/events';
import { userProfileStore } from './userProfileModal/state';

import { setMailFooterEvent, resetMailFooterEvent } from './legalMailFooterModal/events';
import { mailFooterStore } from './legalMailFooterModal/state';

import { setConferenceDocumentsEvent, resetConferenceDocumentsEvent } from './conferenceDocumentModal/events';
import { conferenceDocumentsStore } from './conferenceDocumentModal/state';

import { setCanJoinConferenceEvent, resetCanJoinConferenceEvent } from './canJoinConferenceModal/events';
import { canJoinConferenceStore } from './canJoinConferenceModal/state';

import { setJoinConferenceEvent, resetJoinConferenceEvent, deleteUserJoinConferenceEvent } from './joinConferenceModal/events';
import { joinConferenceStore } from './joinConferenceModal/state';

import { setSendMessageBeforeConferenceEvent, resetSendMessageBeforeConferenceEvent } from './sendMessageBeforeConferenceModal/events';
import { sendMessageBeforeConferenceStore } from './sendMessageBeforeConferenceModal/state';

import { setSendMessageModalEvent, resetSendMessageModalEvent } from './sendMessageModal/events';
import { sendMessageModalStore } from './sendMessageModal/state';

import { setLanguageModalEvent, resetLanguageModalEvent } from './languageModal/events';
import { languageModalStore } from './languageModal/state';

import { setMobileInsideCallModalEvent, resetMobileInsideCallModalEvent, setMobileCallModalEvent, resetMobileCallModalEvent } from './mobileModal/events';
import { insideMobileModalStore, callMobileModalStore } from './mobileModal/state'

import { setlogoutModalEvent, resetlogoutModalEvent } from './logoutModal/events';
import { logoutModalStore } from './logoutModal/state'

import { setHistoryMessagesModalEvent, resetHistoryMessagesModalEvent } from './historyMessagesModal/events';
import { historyMessagesModalStore } from './historyMessagesModal/state'

import { setUploadAvatarModalEvent, resetUploadAvatarModalEvent } from './uploadAvatarModal/events';
import { uploadAvatarModalStore } from './uploadAvatarModal/state';

import { setErrorCameraPermissionsModalEvent, resetErrorCameraPermissionsModalEvent } from './errorCameraPermissionsModal/events';
import { errorCameraPermissionsModalStore } from './errorCameraPermissionsModal/state';

import { setDeleteVisitorPaylinkModalEvent, resetDeleteVisitorPaylinkModalEvent } from './deleteVisitorPaylinkModal/events';
import { deleteVisitorPaylinkModalStore } from './deleteVisitorPaylinkModal/state';

import { setCallUserInCallModalEvent, resetCallUserInCallModalEvent } from './callUserInCallModal/events';
import { callUserInCallModalStore } from './callUserInCallModal/state';

import { setVisitorNameModalEvent, resetVisitorNameModalEvent } from './visitorNameModal/events';
import { visitorNameModalStore } from './visitorNameModal/state';

import { setCallPersonConferenceHallModalEvent, resetCallPersonConferenceHallModalEvent } from './callPersonConferenceHallModal/events';
import { callPersonConferenceHallModalStore } from './callPersonConferenceHallModal/state';

import { setCallEndedInfoModalEvent, resetCallEndedInfoModalEvent } from './callEndedInfoModal/events';
import { callEndedInfoModalStore } from './callEndedInfoModal/state';

import { setBreakStatusModalEvent, resetBreakStatusModalEvent } from './breakStatusModal/events';
import { breakStatusModalStore } from './breakStatusModal/state';

import { setDailyReportModalEvent, resetDailyReportModalEvent } from './dailyReportModal/events';
import { dailyReportModalStore } from './dailyReportModal/state';

import { setDailyReportSettingsModalEvent, resetDailyReportSettingsModalEvent } from './dailyReportSettingsModal/events';
import { dailyReportSettingsModalStore } from './dailyReportSettingsModal/state';

import { setBeaconDomainWhitelistEvent, resetBeaconDomainWhitelistEvent } from './beaconDomainWhitelist/events';
import { beaconDomainWhitelistStore } from './beaconDomainWhitelist/state';

export {
  // = timelineEventModal Start =
  // Events
  setEventStateModalEvent,
  resetEventStateModalEvent,
  setAgreementStateModalEvent,
  resetAgreementStateModalEvent,
  setChangelogModalEvent,
  resetChangelogModalEvent,
  setErrorInCallModalEvent,
  resetErrorInCallModalEvent,
  setBeforeEndingCallModalEvent,
  resetBeforeEndingCallModallEvent,
  setCallBridgeIsFullEvent,
  resetCallBridgeIsFullEvent,
  setNewVersionAvailableModalEvent,
  resetNewVersionAvailableModalEvent,
  setNotificationModalEvent,
  resetNotificationModalEvent,
  setQrCodeModalEvent,
  resetQrCodeModalEvent,
  setVideoTestModalEvent,
  resetVideoTestModalEvent,
  setPaypalInfoModalEvent,
  resetPaypalInfoModalEvent,
  setExternalServicesModalEvent,
  resetExternalServicesModalEvent,
  setvOfficeProVersionModalEvent,
  resetvOfficeProVersionModallEvent,
  setJoinBridgeCallModalEvent,
  resetJoinBridgeCallModalEvent,
  setSendMessageInCallModalEvent,
  resetSendMessageInCallModalEvent,
  setNewMessageFromCallEvent,
  resetNewMessageFromCallEvent,
  setFullBridgeCallModalEvent,
  resetFullBridgeCallModalEvent,
  setInfoConferenceModalEvent,
  resetInfoConferenceModalEvent,
  setUserSettingsEvent,
  resetUserSettingsEvent,
  setHotkesysvent,
  resetHotkeysEvent,
  setUserProfileEvent,
  resetUserProfileEvent,
  setMailFooterEvent,
  resetMailFooterEvent,
  setConferenceDocumentsEvent,
  resetConferenceDocumentsEvent,
  setCanJoinConferenceEvent,
  resetCanJoinConferenceEvent,
  setSendMessageBeforeConferenceEvent,
  resetSendMessageBeforeConferenceEvent,
  setSendMessageModalEvent,
  resetSendMessageModalEvent,
  setLanguageModalEvent,
  resetLanguageModalEvent,
  setMobileInsideCallModalEvent,
  resetMobileInsideCallModalEvent,
  setMobileCallModalEvent,
  resetMobileCallModalEvent,
  setlogoutModalEvent,
  resetlogoutModalEvent,
  setHistoryMessagesModalEvent,
  resetHistoryMessagesModalEvent,
  setUploadAvatarModalEvent,
  resetUploadAvatarModalEvent,
  setErrorCameraPermissionsModalEvent,
  resetErrorCameraPermissionsModalEvent,
  setDeleteVisitorPaylinkModalEvent,
  resetDeleteVisitorPaylinkModalEvent,
  setJoinConferenceEvent,
  resetJoinConferenceEvent,
  deleteUserJoinConferenceEvent,
  setCallUserInCallModalEvent,
  resetCallUserInCallModalEvent,
  setVisitorNameModalEvent,
  resetVisitorNameModalEvent,
  setCallPersonConferenceHallModalEvent,
  resetCallPersonConferenceHallModalEvent,
  setDailyReportModalEvent,
  resetDailyReportModalEvent,
  setDailyReportSettingsModalEvent,
  resetDailyReportSettingsModalEvent,
  setBeaconDomainWhitelistEvent,
  resetBeaconDomainWhitelistEvent,

  // Stores
  callUserInCallModalStore,
  eventModalStateStore,
  agreementModalStateStore,
  callBridgeIsFullStore,
  uploadAvatarModalStore,

  // = timelineEventModal End =

  // = qualityVotingModal Start =
  // Events
  setQualityVotingModalEvent,
  resetQualityVotingModalEvent,
  setBadQualityMessageModalEvent,
  resetBadQualityMessageModalEvent,
  setRateMessageModalEvent,
  resetRateMessageModalEvent,

  // = reminderModal Start =
  // Events
  setReminderModalEvent,
  resetReminderModalEvent,

  // = DirectCallInvitationModal start =
  //Events
  setDirectCalLInviteModalEvent,
  resetDirectCallInviteModalEvent,

  // Stores
  directCallInvitationModalStore,
  reminderModalStore,
  qualityVotingModalStore,
  badQualityMessageModalStore,
  qrCodeModalStore,
  rateMessageModalStore,
  videoTestModalStore,
  paypalInfoModalStore,
  externalServicesModalStore,
  vOfficeProVersionModalStore,
  joinBridgeCallStore,
  sendMessageInCallStore,
  newMessageFromCallStore,
  fullBridgeCallStore,
  infoConferenceStore,
  userSettingsStore,
  hotkeysStore,
  userProfileStore,
  mailFooterStore,
  conferenceDocumentsStore,
  canJoinConferenceStore,
  sendMessageBeforeConferenceStore,
  sendMessageModalStore,
  logoutModalStore,
  historyMessagesModalStore,
  dailyReportModalStore,
  dailyReportSettingsModalStore,

  // Payment modals Events
  setRequestPaymentModal,
  setRequestPaymentEventModal,
  setPaymentInProgressEventModal,
  setRequestedPaymentEventModal,
  setPaymentSuccessEventModal,
  setPaymentInProgressModal,
  setSuccessPayment,
  languageModalStore,
  insideMobileModalStore,
  callMobileModalStore,
  errorCameraPermissionsModalStore,
  deleteVisitorPaylinkModalStore,
  joinConferenceStore,
  visitorNameModalStore,
  callPersonConferenceHallModalStore,
  beaconDomainWhitelistStore,

  // Payment modals store
  paymentModalStore,
  successPaymentStore,
  paymentInProgressModalStore,
  paymentEventModalStore,
  successPaymentEventModalStore,
  changelogModalStore,
  errorInCallModalStore,
  beforeEndingCallModalStore,
  newVersionAvailableModalStore,
  notificationModalStore,

  // show call ended info modal event and store
  setCallEndedInfoModalEvent,
  resetCallEndedInfoModalEvent,
  callEndedInfoModalStore,
  // break status modal
  setBreakStatusModalEvent,
  resetBreakStatusModalEvent,
  breakStatusModalStore,
};
