import { render, staticRenderFns } from "./videoBridgeManager.vue?vue&type=template&id=860b3732&scoped=true"
import script from "./videoBridgeManager.vue?vue&type=script&lang=js"
export * from "./videoBridgeManager.vue?vue&type=script&lang=js"
import style0 from "./videoBridgeManager.vue?vue&type=style&index=0&id=860b3732&lang=scss"
import style1 from "./videoBridgeManager.vue?vue&type=style&index=1&id=860b3732&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "860b3732",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/voffice-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('860b3732')) {
      api.createRecord('860b3732', component.options)
    } else {
      api.reload('860b3732', component.options)
    }
    module.hot.accept("./videoBridgeManager.vue?vue&type=template&id=860b3732&scoped=true", function () {
      api.rerender('860b3732', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "websrc/components/content/videoBridgeManager.vue"
export default component.exports