var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "650" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t("components.holidays.setYourHolidays"),
              closeModalFunction: _vm.closeModal,
              showInfoButton: "holidays"
            }
          }),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "mx-auto" },
            [
              _c(
                "v-row",
                { staticClass: "mx-auto", attrs: { flex: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: _vm.isMobile ? "12" : "6" } },
                    [
                      _c("div", { staticClass: "mb-2 text-center" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("components.holidays.start")) +
                            "\n          " +
                            _vm._s(_vm.getStartHolidaysFormatted)
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-date-picker", {
                        attrs: {
                          "no-title": "",
                          "show-current": _vm.startHolidays,
                          pickTime: true,
                          format: "DD.MM.YYYY H:mm",
                          max: _vm.endHolidays,
                          locale: this.$locale.current(),
                          "first-day-of-week": "1",
                          width: "100%"
                        },
                        model: {
                          value: _vm.startHolidays,
                          callback: function($$v) {
                            _vm.startHolidays = $$v
                          },
                          expression: "startHolidays"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: _vm.isMobile ? "12" : "6" } },
                    [
                      _c("div", { staticClass: "mb-2 text-center" }, [
                        _vm._v(
                          _vm._s(_vm.$t("components.holidays.end")) +
                            "\n          " +
                            _vm._s(_vm.getEndHolidaysFormatted)
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-date-picker", {
                        attrs: {
                          min: _vm.startHolidays,
                          "no-title": "",
                          "show-current": _vm.startHolidays,
                          pickTime: true,
                          format: "DD.MM.YYYY H:mm",
                          locale: this.$locale.current(),
                          "first-day-of-week": "1",
                          width: "100%"
                        },
                        model: {
                          value: _vm.endHolidays,
                          callback: function($$v) {
                            _vm.endHolidays = $$v
                          },
                          expression: "endHolidays"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mx-auto", attrs: { flex: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: 12 } },
                    [
                      _c("v-combobox", {
                        staticClass: "mb-5",
                        attrs: {
                          outlined: "",
                          items: _vm.dataComboboxAllUsers,
                          "search-input": _vm.searchUsers,
                          select: "",
                          label: _vm.$t("excel.representative"),
                          "hide-details": "",
                          dense: "",
                          "item-value": "value",
                          "item-text": "text",
                          "return-object": true
                        },
                        on: {
                          "update:searchInput": function($event) {
                            _vm.searchUsers = $event
                          },
                          "update:search-input": function($event) {
                            _vm.searchUsers = $event
                          },
                          change: function($event) {
                            _vm.searchUsers = ""
                          },
                          blur: _vm.selectItemOnBlur
                        },
                        model: {
                          value: _vm.representative,
                          callback: function($$v) {
                            _vm.representative = $$v
                          },
                          expression: "representative"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "FooterModal",
            { attrs: { showCancel: true, closeModalFunction: _vm.closeModal } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ml-2",
                  attrs: { disabled: _vm.disabledSave, color: "primary" },
                  on: { click: _vm.deleteHolidays }
                },
                [_vm._v(_vm._s(_vm.$t("components.holidays.deleteHolidays")))]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-2",
                  attrs: { color: "primary", disabled: _vm.disabledSave },
                  on: { click: _vm.saveHolidays }
                },
                [_vm._v(_vm._s(_vm.$t("generics.save")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }