var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "px-4" },
    [
      _c(
        "v-col",
        { attrs: { cols: "2" } },
        [
          _c(
            "v-list",
            { attrs: { dense: "" } },
            [
              _c("v-list-item-title", { staticClass: "mb-3 primary--text" }, [
                _vm._v(" " + _vm._s(_vm.$t("generics.sections")))
              ]),
              _vm._v(" "),
              _c(
                "v-list-item-group",
                {
                  staticClass: "listWrapper",
                  attrs: { color: "primary" },
                  model: {
                    value: _vm.selectedSection,
                    callback: function($$v) {
                      _vm.selectedSection = $$v
                    },
                    expression: "selectedSection"
                  }
                },
                [
                  _c(
                    "v-list-item",
                    { key: "allSections", attrs: { value: "allSections" } },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("generics.allSections")
                              )
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.sectionsData, function(name, index) {
                    return _c(
                      "v-list-item",
                      { key: index, attrs: { value: index } },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", {
                              domProps: { textContent: _vm._s(name) }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    { attrs: { value: "intern" } },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("components.userManagement.intern")
                              )
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "10" } },
        [
          _vm.selectedSection !== "intern"
            ? [
                _c("StatusTable", {
                  attrs: {
                    headers: _vm.headers,
                    users: _vm.loadDataTable,
                    itemsPerPage: _vm.itemsPerPage,
                    statusType: _vm.getStatusType,
                    getItemPerPage: _vm.getItemPerPage
                  }
                })
              ]
            : [
                _c("InternTable", {
                  attrs: {
                    internUsers: _vm.loadDataTable,
                    statusType: _vm.getStatusType
                  }
                })
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }