var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "v-tour-navBar" } },
    [
      _c("v-card", {
        staticClass: "d-flex justify-center flex-wrap py-1",
        attrs: {
          height: "30px",
          color: _vm.getColornavbarIcon,
          flat: "",
          tile: ""
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }