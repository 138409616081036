var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto", attrs: { flat: "", width: "100%" } },
    [
      _c("UserListItem", {
        attrs: { flat: "", person: _vm.getInviterPerson, section: "Users" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }