var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _vm.isVisitor(_vm.call.uuid)
        ? _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c("v-img", {
                staticClass: "mr-1",
                attrs: {
                  src: "assets/icon.png",
                  "max-height": "30",
                  "max-width": "30",
                  contain: ""
                }
              }),
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("components.incomingCall.incomingOneCall")) +
                  "\n  "
              )
            ],
            1
          )
        : _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c("v-img", {
                staticClass: "mr-1",
                attrs: {
                  src: "assets/icon.png",
                  "max-height": "30",
                  "max-width": "30",
                  contain: ""
                }
              }),
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("components.incomingCall.incomingCall")) +
                  "\n  "
              )
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "w-100 mx-0 my-0", attrs: { flex: "" } },
        _vm._l(2, function(n) {
          return _c(
            "v-col",
            {
              key: n,
              attrs: {
                lg: _vm.cols[n - 1],
                md: _vm.cols[n - 1],
                sm: _vm.cols[n - 1],
                xl: _vm.cols[n - 1]
              }
            },
            [
              n == 1
                ? _c(
                    "div",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "v-badge",
                        {
                          attrs: {
                            bordered: "",
                            overlay: "",
                            height: "60",
                            width: "60",
                            bottom: "",
                            color: "#008000",
                            dot: "",
                            "offset-x": "22",
                            "offset-y": "15"
                          }
                        },
                        [
                          _c(
                            "v-list-item-avatar",
                            {
                              staticClass: "borderRadius10",
                              attrs: { tile: "", size: "150" }
                            },
                            [
                              _c("v-img", {
                                staticClass: "borderRadius10",
                                attrs: {
                                  "max-height": "150",
                                  "max-width": "150",
                                  contain: "",
                                  src: _vm.getAvatarForUuid(_vm.call.uuid),
                                  "lazy-src":
                                    "assets/default_profile_picture.png"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              n == 2
                ? _c(
                    "div",
                    { class: { "text-center": _vm.isMobile } },
                    [
                      _c("p", { staticClass: "userNameCard my-0" }, [
                        _vm._v(_vm._s(_vm.getNameForUuid(_vm.call.uuid)))
                      ]),
                      _vm._v(" "),
                      _vm.state.group[_vm.call.uuid].user.position
                        ? _c("p", { staticClass: "dataUserCard my-0" }, [
                            _vm._v(
                              _vm._s(
                                _vm.state.group[_vm.call.uuid].user.position
                              )
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.state.group[_vm.call.uuid].user.email
                        ? _c("p", { staticClass: "dataUserCard my-0" }, [
                            _vm._v(
                              _vm._s(
                                _vm.state.group[_vm.call.uuid].user.email
                              ) +
                                " | " +
                                _vm._s(
                                  _vm.state.group[_vm.call.uuid].user.phone
                                )
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("v-divider", { staticClass: "mt-4" }),
                      _vm._v(" "),
                      _vm.isCallProtected
                        ? _c(
                            "v-row",
                            {
                              staticClass: "px-2 btns w-100 mx-0 my-0",
                              class: {
                                centerRow: _vm.isMobile,
                                "mt-2": _vm.isMobile
                              },
                              staticStyle: { "text-align": "center" }
                            },
                            [
                              _c(
                                "v-col",
                                { staticClass: "col-4 pb-0 col-12" },
                                [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "components.callsContent.protectedCall"
                                        )
                                      )
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "col-9 pt-0",
                                  staticStyle: { margin: "0 auto" }
                                },
                                [
                                  [
                                    _c("v-text-field", {
                                      staticClass: "whiteControl",
                                      style: { width: 290, color: _vm.white },
                                      attrs: {
                                        outlined: "",
                                        dense: "",
                                        "hide-details": "",
                                        label: _vm.$t(
                                          "components.callsContent.insertPin"
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.checkIt($event)
                                        }
                                      },
                                      model: {
                                        value: _vm.userPin,
                                        callback: function($$v) {
                                          _vm.userPin = $$v
                                        },
                                        expression: "userPin"
                                      }
                                    })
                                  ]
                                ],
                                2
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", [
                        _vm.state.group[_vm.call.uuid].user.sections &&
                        _vm.state.group[_vm.call.uuid].user.sections.length >
                          0 &&
                        _vm.state.group[_vm.call.uuid].user.locations &&
                        _vm.state.group[_vm.call.uuid].user.locations.length > 0
                          ? _c("span", { staticClass: "dataUserCard my-0" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.state.group[_vm.call.uuid].user
                                    .sections[0]
                                ) +
                                  " | " +
                                  _vm._s(
                                    _vm.state.group[_vm.call.uuid].user
                                      .locations[0]
                                  )
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _vm.call &&
                      _vm.call.dialGroup &&
                      _vm.call.dialGroup.length
                        ? _c("div", [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "components.inviteVisitor.participants"
                                  )
                                ) + ":"
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(_vm.call.dialGroup, function(uuid) {
                                return _c("li", { key: uuid }, [
                                  _vm._v(_vm._s(_vm.getNameForUuid(uuid)))
                                ])
                              }),
                              0
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        {
          staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
          attrs: { color: "primary" }
        },
        [
          !_vm.isVisitor(_vm.ownUUID)
            ? [
                _vm.state.persisted.tmpMessages.showInfoIncomingCallModal
                  ? _c(
                      "v-row",
                      {
                        staticClass: "px-2 w-100 mx-0 my-0",
                        class: { "text-center": _vm.isMobile }
                      },
                      [
                        _c("small", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("components.incomingCall.enableCamMic")
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("small", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("components.incomingCall.answerSpace")
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("v-checkbox", {
                          staticClass: "mt-0 white--text",
                          attrs: {
                            dark: "",
                            label: _vm.$t(
                              "components.meetingTime.dontShowAgain"
                            )
                          },
                          on: {
                            change: function($event) {
                              return _vm.setShowInfoIncomingCallModal(false)
                            }
                          },
                          model: {
                            value: _vm.checkedMessage,
                            callback: function($$v) {
                              _vm.checkedMessage = $$v
                            },
                            expression: "checkedMessage"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "v-row",
                  {
                    staticClass: "px-2 btns w-100 mx-0 my-0 mb-2",
                    class: { centerRow: _vm.isMobile, "mt-2": _vm.isMobile }
                  },
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        ref: "cancelCall",
                                        staticClass: "ml-2",
                                        attrs: {
                                          outlined: "",
                                          "x-small": "",
                                          color: "white"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.call.resolve(false)
                                            _vm.declineDefaultMessage(
                                              _vm.call.uuid,
                                              _vm.$t(
                                                "components.incomingCall.callLater"
                                              )
                                            )
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "components.incomingCall.callLater"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2648016552
                        )
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("components.incomingCall.callLater"))
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        ref: "cancelCall",
                                        staticClass: "ml-2",
                                        attrs: {
                                          outlined: "",
                                          "x-small": "",
                                          color: "white"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.call.resolve(false)
                                            _vm.declineDefaultMessage(
                                              _vm.call.uuid,
                                              _vm.$t(
                                                "components.incomingCall.callTomorrow"
                                              )
                                            )
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "components.incomingCall.callTomorrow"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          614689608
                        )
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("components.incomingCall.callTomorrow")
                            )
                          )
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  {
                    staticClass: "px-2 btns w-100 mx-0 my-0 mb-2",
                    class: { centerRow: _vm.isMobile, "mt-2": _vm.isMobile }
                  },
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        ref: "cancelCall",
                                        staticClass: "ml-2",
                                        attrs: {
                                          outlined: "",
                                          "x-small": "",
                                          color: "white"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.call.resolve(false)
                                            _vm.declineDefaultMessage(
                                              _vm.call.uuid,
                                              _vm.$t(
                                                "components.incomingCall.callYouBack"
                                              )
                                            )
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "components.incomingCall.callYouBack"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3202452616
                        )
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("components.incomingCall.callYouBack")
                            )
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        ref: "cancelCall",
                                        staticClass: "ml-2",
                                        attrs: {
                                          outlined: "",
                                          "x-small": "",
                                          color: "white"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.call.resolve(false)
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "components.incomingCall.dontAnswer"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          339668934
                        )
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("components.incomingCall.dontAnswer"))
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "px-2 btns w-100 mx-0 my-0",
              class: { centerRow: _vm.isMobile, "mt-2": _vm.isMobile }
            },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u(
                    [
                      _vm.call.typeCall !== "audio"
                        ? {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      ref: "acceptCall",
                                      staticClass: "mr-4",
                                      attrs: {
                                        outlined: "",
                                        color: "white",
                                        disabled: _vm.isCallProtected
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.resolveCall(
                                            _vm.call,
                                            "video"
                                          )
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "mr-3",
                                      style: { fontSize: "20px" },
                                      attrs: { icon: ["fal", "video"] }
                                    }),
                                    _vm._v("Video\n          ")
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        : null
                    ],
                    null,
                    true
                  )
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("components.incomingCall.hookOff")))
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  outlined: "",
                                  color: "white",
                                  disabled: _vm.isCallProtected
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.resolveCall(_vm.call, "audio")
                                  }
                                }
                              },
                              on
                            ),
                            [
                              _c("font-awesome-icon", {
                                staticClass: "mr-3",
                                style: { fontSize: "20px" },
                                attrs: { icon: ["fal", "microphone"] }
                              }),
                              _vm._v("Audio\n          ")
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("components.incomingCall.hookOff")))
                  ])
                ]
              ),
              _vm._v(" "),
              !_vm.isVisitor(_vm.ownUUID)
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      ref: "cancelCall",
                                      attrs: { icon: "", color: "white" },
                                      on: {
                                        click: function($event) {
                                          _vm.setShowModalSenMsg(_vm.call.uuid)
                                          _vm.call.resolve(false)
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("font-awesome-icon", {
                                      style: { fontSize: "20px" },
                                      attrs: { icon: ["fal", "comment-times"] }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        122423786
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("components.incomingCall.declineWithMessage")
                          )
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                ref: "cancelCall",
                                attrs: { icon: "", color: "white" },
                                on: {
                                  click: function($event) {
                                    return _vm.call.resolve(false)
                                  }
                                }
                              },
                              on
                            ),
                            [
                              _c("font-awesome-icon", {
                                staticClass: "faPhoneRotate",
                                style: { fontSize: "20px" },
                                attrs: { icon: ["fal", "phone"] }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("generics.hangUp")))])
                ]
              )
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _c("v-spacer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }