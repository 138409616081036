import { render, staticRenderFns } from "./paypal.vue?vue&type=template&id=213f2edd&scoped=true"
import script from "./paypal.vue?vue&type=script&lang=js"
export * from "./paypal.vue?vue&type=script&lang=js"
import style0 from "./paypal.vue?vue&type=style&index=0&id=213f2edd&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "213f2edd",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/voffice-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('213f2edd')) {
      api.createRecord('213f2edd', component.options)
    } else {
      api.reload('213f2edd', component.options)
    }
    module.hot.accept("./paypal.vue?vue&type=template&id=213f2edd&scoped=true", function () {
      api.rerender('213f2edd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "websrc/components/firstSetup/paypal.vue"
export default component.exports