var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "560" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModalMoveItem,
        callback: function($$v) {
          _vm.showModalMoveItem = $$v
        },
        expression: "showModalMoveItem"
      }
    },
    [
      [
        _c(
          "v-card",
          [
            _c("HeaderModal", {
              attrs: {
                titleModal: _vm.$t("components.manageOrganisation.headline"),
                closeModalFunction: _vm.closeModal
              }
            }),
            _vm._v(" "),
            _c(
              "v-row",
              { staticClass: "px-5 mx-0" },
              [
                _c("v-col", { staticClass: "px-0", attrs: { cols: "12" } }, [
                  _c("p", {
                    staticClass: "mb-0",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$sanitize(
                          _vm.$t("components.organisationGrid.whereMove", [
                            _vm.itemToMoveSanitized
                          ])
                        )
                      )
                    }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "v-col",
                  { staticClass: "px-0", attrs: { cols: "12" } },
                  [
                    _c("v-select", {
                      attrs: {
                        items: _vm.getListItem,
                        select: "",
                        "return-object": "",
                        "item-text": "name",
                        "item-value": "name",
                        dense: "",
                        outlined: "",
                        "hide-details": "",
                        autofocus: "",
                        label:
                          _vm.destination == "sections"
                            ? _vm.$t("components.organisationCarousel.sections")
                            : _vm.destination == "departments"
                            ? _vm.$t(
                                "components.organisationCarousel.departments"
                              )
                            : _vm.destination == "teams"
                            ? _vm.$t("components.addConferenceModal.teams")
                            : "default"
                      },
                      model: {
                        value: _vm.selectedItem,
                        callback: function($$v) {
                          _vm.selectedItem = $$v
                        },
                        expression: "selectedItem"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "FooterModal",
              {
                attrs: { closeModalFunction: _vm.closeModal, showFooter: true }
              },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { disabled: !_vm.selectedItem, color: "primary" },
                    on: {
                      click: function($event) {
                        _vm.moveItem(_vm.selectedItem.name)
                        _vm.resetSelected()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("generics.save")) + "\n        ")]
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }