var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list",
    [
      _c(
        "v-tooltip",
        {
          attrs: { top: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-list-item",
                    _vm._g(
                      {
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.handleWaitingRoom()
                          }
                        }
                      },
                      on
                    ),
                    [
                      _c(
                        "v-list-item-icon",
                        {
                          staticClass: "primary--text",
                          class: _vm.activeBlueClass ? " primary--text" : ""
                        },
                        [
                          _vm.currentPage(
                            "/waitingroomfor/" + _vm.waitingRoomFor
                          )
                            ? _c("font-awesome-icon", {
                                staticClass: "primary--text",
                                class: _vm.activeBlueClass
                                  ? "primary--text"
                                  : "",
                                attrs: { icon: ["fas", "users-class"] }
                              })
                            : _c("font-awesome-icon", {
                                staticClass: "primary--text",
                                class: _vm.activeBlueClass
                                  ? "primary--text"
                                  : "",
                                attrs: { icon: ["fal", "users-class"] }
                              })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.getconnecetedWaitingrooms > 0
                        ? _c("v-badge", {
                            staticClass: "waitingRoomBadge",
                            attrs: {
                              right: "",
                              color: "error",
                              content: _vm.getconnecetedWaitingrooms,
                              value: _vm.getconnecetedWaitingrooms,
                              bordered: "",
                              overlap: "",
                              "offset-x": "7",
                              "offset-y": "7"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.handleWaitingRoom()
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-list-item-title",
                        {
                          class: _vm.activeBlueClass ? "font-weight-bold " : ""
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.getTitleWaitingROOM) +
                              "\n          " +
                              _vm._s(
                                "" +
                                  (_vm.assistantWaitingRoomList.length > 0
                                    ? "(" +
                                      _vm.assistantWaitingRoomList.length +
                                      ")"
                                    : "")
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.$t("components.waitingRoom.myWaitinRoomTT", [
                  this.getTitelForUuid(this.waitingRoomFor) +
                    " " +
                    this.getNameForUuid(this.waitingRoomFor)
                ])
              )
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }