var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.currentVideoDevice, staticClass: "containerPreview" },
    [
      _vm.videoCameraCallOn
        ? [
            _c("WebCam", {
              ref: "webcam",
              attrs: { "device-id": _vm.deviceId },
              on: {
                cameras: _vm.onCameras,
                "camera-change": _vm.onCameraChange
              }
            })
          ]
        : [
            _c(
              "div",
              { staticClass: "avatarContainer" },
              [
                _c("v-img", {
                  staticClass: "borderRadius4",
                  attrs: {
                    "max-height": "120",
                    "max-width": "120",
                    src:
                      _vm.getAvatarForUuid(_vm.ownUUID) ||
                      "img/default_profile_picture.png",
                    "lazy-src": "assets/default_profile_picture.png"
                  }
                })
              ],
              1
            )
          ],
      _vm._v(" "),
      _c("div", { staticClass: "iconSetting" }, [
        _c(
          "div",
          { staticClass: "buttosContainer" },
          [
            _c(
              "v-tooltip",
              {
                attrs: { top: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "buttonsCamera",
                                attrs: { icon: "", color: "white" },
                                on: { click: _vm.toggleCamMic }
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c("font-awesome-icon", {
                              style: { fontSize: "20px" },
                              attrs: { icon: ["fal", "cog"] }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              },
              [
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.$t("generics.settings")))])
              ]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "deviceIcons" }, [
        _c(
          "div",
          { staticClass: "buttosContainer d-flex" },
          [
            _c(
              "v-tooltip",
              {
                attrs: { top: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-switch",
                          _vm._g(
                            {
                              staticClass: "px-2",
                              attrs: {
                                disabled: _vm.hasVideoDevices.length === 0
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.changeCallSetting("video")
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "prepend",
                                    fn: function() {
                                      return [
                                        _vm.videoCameraCallOn
                                          ? _c("font-awesome-icon", {
                                              style: { fontSize: "20px" },
                                              attrs: {
                                                color: "white",
                                                icon: ["fal", "video"]
                                              }
                                            })
                                          : _c("img", {
                                              staticClass: "video-slash",
                                              attrs: {
                                                src:
                                                  "/img/video-slash-red-white.svg"
                                              }
                                            })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.videoCameraCallOn,
                                callback: function($$v) {
                                  _vm.videoCameraCallOn = $$v
                                },
                                expression: "videoCameraCallOn"
                              }
                            },
                            on
                          )
                        )
                      ]
                    }
                  }
                ])
              },
              [
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.videoCameraCallOn
                        ? _vm.$t("generics.cameraOff")
                        : _vm.$t("generics.cameraOn")
                    )
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "v-tooltip",
              {
                attrs: { top: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-switch",
                          _vm._g(
                            {
                              staticClass: "px-2",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.changeCallSetting("audio")
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "prepend",
                                    fn: function() {
                                      return [
                                        _vm.audioCallOn
                                          ? _c("font-awesome-icon", {
                                              style: { fontSize: "20px" },
                                              attrs: {
                                                color: "white",
                                                icon: ["fal", "microphone"]
                                              }
                                            })
                                          : _c("img", {
                                              staticClass: "microphone-slash",
                                              attrs: {
                                                src:
                                                  "/img/microphone-slash-red-white.svg"
                                              }
                                            })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.audioCallOn,
                                callback: function($$v) {
                                  _vm.audioCallOn = $$v
                                },
                                expression: "audioCallOn"
                              }
                            },
                            on
                          )
                        )
                      ]
                    }
                  }
                ])
              },
              [
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.audioCallOn
                        ? _vm.$t("generics.micOff")
                        : _vm.$t("generics.micOn")
                    )
                  )
                ])
              ]
            )
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }