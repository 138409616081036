var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "pb-0", attrs: { fluid: "" } },
        [
          _c(
            "v-layout",
            [
              _c(
                "v-row",
                { staticClass: "pt-0 px-3 Width100" },
                _vm._l(3, function(n) {
                  return _c(
                    "v-col",
                    {
                      key: n,
                      staticClass: "col-12",
                      attrs: {
                        lg: _vm.cols[n - 1],
                        md: _vm.cols[n - 1],
                        sm: _vm.cols[n - 1],
                        xl: _vm.cols[n - 1],
                        xs: "12"
                      }
                    },
                    [
                      _c("v-card", { attrs: { flat: "" } }, [
                        n == 1
                          ? _c("div", [
                              _c("video", {
                                ref: "video",
                                refInFor: true,
                                attrs: {
                                  id: "video",
                                  playsinline: "",
                                  autoplay: ""
                                },
                                domProps: { srcObject: _vm.videoSourceObject }
                              })
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        n == 2
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "mx-0 px-0 cameraSelection" },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c("v-select", {
                                          ref: "videoSource",
                                          refInFor: true,
                                          staticClass: "pt-1",
                                          attrs: {
                                            "hide-details": !_vm.showUpdateAdvise,
                                            items: _vm.optionsVideoSource,
                                            label: _vm.$t(
                                              "components.camAndMic.video"
                                            ),
                                            outlined: "",
                                            dense: "",
                                            id: "videoSource"
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.handleChange(
                                                "video",
                                                _vm.videoOptionSelected
                                              )
                                            }
                                          },
                                          model: {
                                            value: _vm.videoOptionSelected,
                                            callback: function($$v) {
                                              _vm.videoOptionSelected = $$v
                                            },
                                            expression: "videoOptionSelected"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _vm.showUpdateAdvise
                                          ? _c(
                                              "span",
                                              { staticClass: "pt-5" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "components.camAndMic.updateAdvise"
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        n == 3
                          ? _c(
                              "div",
                              { attrs: { cols: "12 px-0 py-0" } },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "mx-0 m y-0" },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12 pt-2 px-0 py-0" } },
                                      [
                                        _c("v-select", {
                                          ref: "audioSource",
                                          refInFor: true,
                                          staticClass: "selAudio pt-1",
                                          attrs: {
                                            items: _vm.optionsAudioSource,
                                            label: _vm.$t(
                                              "components.camAndMic.audioInput"
                                            ),
                                            outlined: "",
                                            dense: "",
                                            id: "audioSource"
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.handleChange(
                                                "audioInput",
                                                _vm.audioOptionSelected
                                              )
                                            }
                                          },
                                          model: {
                                            value: _vm.audioOptionSelected,
                                            callback: function($$v) {
                                              _vm.audioOptionSelected = $$v
                                            },
                                            expression: "audioOptionSelected"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("canvas", {
                                          ref: "canvasAudio",
                                          refInFor: true,
                                          attrs: { id: "canvasAudio" }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12 px-0 pt-5" } },
                                      [
                                        _c("v-switch", {
                                          staticClass: "my-0 py-2",
                                          attrs: {
                                            outlined: "",
                                            dense: "",
                                            label: _vm.$t(
                                              "components.camAndMic.autoDeviceChanging"
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.changeMicSetting()
                                            }
                                          },
                                          model: {
                                            value: _vm.automaticMic,
                                            callback: function($$v) {
                                              _vm.automaticMic = $$v
                                            },
                                            expression: "automaticMic"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12 px-0 py-0" } },
                                      [
                                        _c(
                                          "v-subheader",
                                          {
                                            staticClass:
                                              "pl-0 mb-1 font-weight-light setupSubHeader"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.camAndMic.ringerVolume"
                                                )
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("v-slider", {
                                          staticClass: "micSlider",
                                          attrs: { "thumb-label": "always" },
                                          model: {
                                            value: _vm.volumeCallToneModel,
                                            callback: function($$v) {
                                              _vm.volumeCallToneModel = $$v
                                            },
                                            expression: "volumeCallToneModel"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12 px-0 py-0" } },
                                      [
                                        _c("v-select", {
                                          ref: "audioOutput",
                                          refInFor: true,
                                          attrs: {
                                            items: _vm.optionsAudioOutput,
                                            label: _vm.$t(
                                              "components.camAndMic.audioOutput"
                                            ),
                                            outlined: "",
                                            dense: "",
                                            id: "audioOutput"
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.handleChange(
                                                "audioOutput",
                                                _vm.audioOutputOptionSelected
                                              )
                                            }
                                          },
                                          model: {
                                            value:
                                              _vm.audioOutputOptionSelected,
                                            callback: function($$v) {
                                              _vm.audioOutputOptionSelected = $$v
                                            },
                                            expression:
                                              "audioOutputOptionSelected"
                                          }
                                        }),
                                        _vm._v(" "),
                                        !!_vm.audioOutputError
                                          ? _c(
                                              "v-alert",
                                              {
                                                attrs: {
                                                  type: "warning",
                                                  dismissible: false
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    Output: " +
                                                    _vm._s(
                                                      _vm.audioOutputError
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.mediaDevicesError
        ? _c("v-alert", { attrs: { type: "error", dismissible: true } }, [
            _vm._v(_vm._s(_vm.mediaDevicesError))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "FooterModal",
        { attrs: { showCancel: true, closeModalFunction: _vm.closeModal } },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "primary" },
              on: {
                click: function($event) {
                  return _vm.saveMediaSetup()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("generics.save")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }