var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "py-0", attrs: { cols: "6" } },
        [
          _c("v-text-field", {
            attrs: {
              label: _vm.$t("components.companyRegister.lastName") + "*",
              rules: _vm.contactNameRules,
              required: "",
              outlined: "",
              dense: ""
            },
            model: {
              value: _vm.companyRegisterObject.lastName,
              callback: function($$v) {
                _vm.$set(_vm.companyRegisterObject, "lastName", $$v)
              },
              expression: "companyRegisterObject.lastName"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { staticClass: "py-0", attrs: { cols: "6" } },
        [
          _c("v-text-field", {
            attrs: {
              label: _vm.$t("components.companyRegister.firstName") + "*",
              rules: _vm.contactNameRules,
              required: "",
              outlined: "",
              dense: ""
            },
            model: {
              value: _vm.companyRegisterObject.firstName,
              callback: function($$v) {
                _vm.$set(_vm.companyRegisterObject, "firstName", $$v)
              },
              expression: "companyRegisterObject.firstName"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { staticClass: "py-0", attrs: { cols: "6" } },
        [
          _c("v-text-field", {
            attrs: {
              label: _vm.isGermanLawyer
                ? _vm.$t("components.companyRegister.firmenameOption") + "*"
                : _vm.$t("components.companyRegister.companyName") + "*",
              rules: _vm.companyNameRules,
              required: "",
              outlined: "",
              dense: ""
            },
            model: {
              value: _vm.companyRegisterObject.company_name,
              callback: function($$v) {
                _vm.$set(_vm.companyRegisterObject, "company_name", $$v)
              },
              expression: "companyRegisterObject.company_name"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("v-col", { staticClass: "py-0 pRelative", attrs: { cols: "6" } }, [
        _c(
          "div",
          [
            _c(
              "p",
              { staticClass: "pContact px-1" },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("components.companyRegister.contactNumber")) +
                    "*\n        "
                ),
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "", "max-width": "400" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "font-awesome-icon",
                              _vm._g(
                                {
                                  style: {
                                    fontSize: "17px",
                                    cursor: "pointer"
                                  },
                                  attrs: {
                                    color: "#02889d",
                                    icon: ["fal", "info-circle"]
                                  }
                                },
                                on
                              )
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("components.companyRegister.phoneTT"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("vue-tel-input", {
              ref: "countryInput",
              staticClass: "form-control text-center input-sm",
              class: { borderError: !_vm.phoneValid },
              attrs: {
                enabledCountryCode: "",
                name: "number",
                id: "numDisplay",
                placeholder: _vm.$t("components.companyRegister.example"),
                autocomplete: "off",
                defaultCountry: _vm.countryCodeInput,
                disabledFetchingCountry: ""
              },
              on: { onInput: _vm.onTelInput }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-col",
        { staticClass: "py-0", attrs: { cols: "12" } },
        [
          _c("v-text-field", {
            attrs: {
              label: _vm.$t("components.companyRegister.email") + "*",
              rules: _vm.emailRules,
              required: "",
              outlined: "",
              dense: ""
            },
            model: {
              value: _vm.companyRegisterObject.email,
              callback: function($$v) {
                _vm.$set(_vm.companyRegisterObject, "email", $$v)
              },
              expression: "companyRegisterObject.email"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }