var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "690" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c("v-img", {
                staticClass: "mr-1",
                attrs: {
                  src: "assets/icon.png",
                  "max-height": "30",
                  "max-width": "30",
                  contain: ""
                }
              }),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("components.2faOtp.title")) +
                  "\n    "
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-card-subtitle", { staticClass: "pt-2" }, [
            _c("div", { staticClass: "py-2" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("components.2faOtp.scanSentence")))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "d-flex justify-center text-center" },
              [
                [
                  _c(
                    "div",
                    [
                      _vm.loadingQR || !!!_vm.otpKeyQR
                        ? [
                            _c("v-progress-circular", {
                              attrs: {
                                size: 50,
                                color: "primary",
                                indeterminate: ""
                              }
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "mt-3" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t("components.2faSms.loadingQR")
                                  ) +
                                  " ...\n              "
                              )
                            ])
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      !!_vm.otpKeyQR && !_vm.loadingQR
                        ? _c("v-img", { attrs: { src: _vm.otpKeyQR } })
                        : _vm._e()
                    ],
                    2
                  )
                ]
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-3" },
              [
                _c("v-text-field", {
                  attrs: {
                    autocomplete: "off",
                    placeholder: " ",
                    outlined: "",
                    dense: "",
                    required: "",
                    autofocus: ""
                  },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      _vm.otpCode && _vm.showModalActivation()
                    },
                    keydown: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "space", 32, $event.key, [
                          " ",
                          "Spacebar"
                        ])
                      ) {
                        return null
                      }
                      $event.preventDefault()
                    }
                  },
                  model: {
                    value: _vm.otpCode,
                    callback: function($$v) {
                      _vm.otpCode = $$v
                    },
                    expression: "otpCode"
                  }
                }),
                _vm._v(" "),
                _vm.errMsg
                  ? _c(
                      "v-alert",
                      { attrs: { type: "error", dismissible: true } },
                      [_vm._v(_vm._s(_vm.errMsg))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex justify-end mt-3" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "primary",
                          "hide-details": "",
                          small: "",
                          disabled: !_vm.otpCode
                        },
                        on: {
                          click: function($event) {
                            return _vm.showModalActivation()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("components.2faSms.btnEnable")))]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "v-card",
            {
              staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
              attrs: { color: "primary" }
            },
            [
              _c(
                "v-row",
                { staticClass: "px-2 btns mx-0", attrs: { flex: "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "px-0 py-0",
                      attrs: { cols: "8", align: "right" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticStyle: {
                            "border-radius": "4px",
                            "min-width": "45px"
                          },
                          attrs: {
                            icon: "",
                            tile: "",
                            outlined: "",
                            color: "white"
                          },
                          on: {
                            click: function($event) {
                              return _vm.closeModal()
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            style: { fontSize: "20px" },
                            attrs: { icon: ["fal", "times"] }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer")
        ],
        1
      ),
      _vm._v(" "),
      _vm.showActivation
        ? _c("TwoFactorActivationModal", {
            attrs: {
              showActivation: _vm.showActivation,
              toggle2faActivationModal: _vm.toggle2faActivationModal,
              saveEntryNoPhone: _vm.saveEntryNoPhone
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }