var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "max-width": "650",
        attach: _vm.getAttach,
        "hide-overlay": _vm.getAttach ? true : false
      },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showModal,
        callback: function($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t("components.conferenceForm.titleModalPDF"),
              closeModalFunction: _vm.closeModal
            }
          }),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mx-0" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-list",
                    _vm._l(_vm.getDataConference.pdfDocuments, function(
                      document
                    ) {
                      return _c(
                        "v-list-item",
                        { key: document.documentId },
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "py-0" },
                            [
                              _c("v-list-item-title", [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.fixDocPath(
                                        document.documentURL
                                      ),
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v(_vm._s(document.documentName))]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.amIModerator
                            ? _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-btn",
                                    { attrs: { icon: "" } },
                                    [
                                      _c("DeleteBasket", {
                                        attrs: {
                                          indexData: document,
                                          delFunction: _vm.deleteDocumentBtn,
                                          extraClasses: "",
                                          fontSize: "17",
                                          prevent: "false"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.showAddNewDocument
            ? _c(
                "v-row",
                { staticClass: "mx-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "hide-details": "",
                          dense: "",
                          solo: "",
                          label: _vm.$t("components.conferenceForm.pdfSubject")
                        },
                        model: {
                          value: _vm.documentName,
                          callback: function($$v) {
                            _vm.documentName = $$v
                          },
                          expression: "documentName"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "hide-details": "",
                          dense: "",
                          solo: "",
                          label: "URL"
                        },
                        model: {
                          value: _vm.documentURL,
                          callback: function($$v) {
                            _vm.documentURL = $$v
                          },
                          expression: "documentURL"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.amIModerator
            ? _c(
                "v-row",
                { staticClass: "mx-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "textRight", attrs: { cols: "12" } },
                    [
                      _vm.showAddNewDocument
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: {
                                                color: "primary",
                                                disabled:
                                                  _vm.documentName == "" ||
                                                  _vm.documentURL == ""
                                              },
                                              on: { click: _vm.saveNewDocument }
                                            },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.$t("generics.save")
                                                ) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                195607014
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("generics.save")))
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.showAddNewDocument
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: { color: "primary" },
                                              on: {
                                                click: function($event) {
                                                  _vm.showAddNewDocument = true
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.conferenceForm.addpdfConference"
                                                  )
                                                ) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                328197149
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.conferenceForm.addpdfConference"
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showAddNewDocument
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: { color: "primary" },
                                              on: {
                                                click: function($event) {
                                                  _vm.showAddNewDocument = false
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(
                                                  _vm.$t("generics.cancel")
                                                ) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1728264293
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("generics.cancel")))
                              ])
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("FooterModal", { attrs: { closeModalFunction: _vm.closeModal } })
        ],
        1
      ),
      _vm._v(" "),
      _c("v-spacer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }