var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "560" },
      model: {
        value: _vm.showVotingModal,
        callback: function($$v) {
          _vm.showVotingModal = $$v
        },
        expression: "showVotingModal"
      }
    },
    [
      [
        _c(
          "v-card",
          { staticClass: "mx-auto" },
          [
            _c("HeaderModal", {
              attrs: { titleModal: "VideoCall Qualitätsbewertung" }
            }),
            _vm._v(" "),
            _c("v-card-text", [
              _vm._v(
                "\n        Bewerten Sie die Qualität der Verbindung\n        "
              ),
              _c(
                "div",
                { staticClass: "mt-8 d-flex justify-center" },
                [
                  _c("v-rating", {
                    attrs: {
                      color: "yellow darken-3",
                      "background-color": "grey darken-1",
                      "empty-icon": "$ratingFull",
                      hover: ""
                    },
                    on: {
                      input: function($event) {
                        return _vm.rate()
                      }
                    },
                    model: {
                      value: _vm.rating,
                      callback: function($$v) {
                        _vm.rating = $$v
                      },
                      expression: "rating"
                    }
                  })
                ],
                1
              )
            ])
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }