var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "560" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showcanJoinConferencesModal.show,
        callback: function($$v) {
          _vm.$set(_vm.showcanJoinConferencesModal, "show", $$v)
        },
        expression: "showcanJoinConferencesModal.show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("HeaderModal", {
            attrs: {
              titleModal: _vm.$t("generics.info"),
              closeModalFunction: _vm.closeModal
            }
          }),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mx-0" },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("components.callsContent.likeJoinConference")
                    ) +
                    "\n    "
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          [
            _c(
              "FooterModal",
              {
                attrs: { closeModalFunction: _vm.closeModal, showCancel: true }
              },
              [
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "mr-4",
                                  attrs: { outlined: "", color: "white" },
                                  on: { click: _vm.tryJoin }
                                },
                                on
                              ),
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("generics.accept")) +
                                    "\n              "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("generics.accept")))])
                  ]
                )
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }