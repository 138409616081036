var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "drawerBorder",
      class:
        (_vm.hasPrivilege(_vm.ownUUID) && !_vm.isMobile
          ? "custom-navigation-drawer"
          : _vm.hasPrivilege(_vm.ownUUID) && _vm.isMobile
          ? "custom-navigation-drawer-mobile"
          : "custom-navigation-drawerWaitingVisitor") +
        " " +
        (_vm.moveToTop && !_vm.isMobile
          ? "sidebarAdjust"
          : _vm.moveToTop && _vm.isMobile
          ? "sidebarAdjustMobile"
          : "sidebarAdjust") +
        " " +
        (0 && _vm.getRoute === "/newconference" ? "zindex-1" : "") +
        " " +
        (_vm.amInACall ? "zindex-0" : ""),
      attrs: {
        width: "320",
        id: "v-tour-drawer",
        "mini-variant": _vm.mini,
        clipped: "",
        absolute: true,
        temporary: false,
        touchless: _vm.isMobile ? true : false,
        permanent: ""
      },
      on: {
        "update:miniVariant": function($event) {
          _vm.mini = $event
        },
        "update:mini-variant": [
          function($event) {
            _vm.mini = $event
          },
          function($event) {
            return _vm.openCloseSidebar()
          }
        ]
      },
      model: {
        value: _vm.primaryDrawer.model,
        callback: function($$v) {
          _vm.$set(_vm.primaryDrawer, "model", $$v)
        },
        expression: "primaryDrawer.model"
      }
    },
    [
      _vm.hasPrivilege(_vm.ownUUID) && _vm.isMobile
        ? _c("DrawerContentCard", {
            attrs: {
              primaryDrawer: _vm.primaryDrawer,
              openCloseSidebar: _vm.openCloseSidebar,
              mini: _vm.mini
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: _vm.mini ? "width60" : null,
          on: {
            click: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
            }
          }
        },
        [
          _vm.hasPrivilege(_vm.ownUUID)
            ? _c("DrawerContentExpansion", {
                attrs: {
                  active: _vm.active,
                  setList: _vm.setList,
                  mini: _vm.mini
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        {
          staticClass: "mx-auto borderRadius0",
          attrs: { flat: "", width: "100%" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
            }
          }
        },
        [
          !_vm.amIGuest ||
          _vm.amIAdmin ||
          ((_vm.canISeeUser ||
            _vm.oneCallAndWaitingUser(_vm.ownUUID) ||
            _vm.hasPrivilege(_vm.ownUUID)) &&
            _vm.displayAllUsers)
            ? _c("Users", {
                attrs: {
                  active: _vm.active,
                  setList: _vm.setList,
                  mini: _vm.mini,
                  isGuest: _vm.amIGuest,
                  openSidebar: _vm.openSidebar
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      !_vm.searchFoundSomethingPerson
        ? _c(
            "div",
            {
              class: _vm.mini ? "width60" : "divDrawerContentUser",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "mx-auto borderRadius0",
                  attrs: { flat: "", width: "100%" }
                },
                [
                  _vm.hasPrivilege(_vm.ownUUID)
                    ? _c("InstaConference", {
                        attrs: { mini: _vm.mini, sidebar: true }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("Stats", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.state.nerd,
                        expression: "state.nerd"
                      }
                    ],
                    attrs: { setList: _vm.setList, active: _vm.active }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.isGuest && !_vm.isWaitingRoom
                ? _c("DrawerContentGuest", {
                    attrs: {
                      active: _vm.active,
                      setList: _vm.setList,
                      mini: _vm.mini
                    }
                  })
                : _c("DrawerContentUser", {
                    attrs: {
                      active: _vm.active,
                      setList: _vm.setList,
                      mini: _vm.mini,
                      openSidebar: _vm.openSidebar
                    }
                  })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.searchFoundSomethingPerson
        ? _c(
            "div",
            { staticClass: "divDrawerContentUser" },
            [_c("DrawerContentUserSearch")],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("Footer", { attrs: { mini: _vm.mini } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }