var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "595" },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeModal.apply(null, arguments)
        }
      },
      model: {
        value: _vm.showActivation,
        callback: function($$v) {
          _vm.showActivation = $$v
        },
        expression: "showActivation"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c("v-img", {
                staticClass: "mr-1",
                attrs: {
                  src: "assets/icon.png",
                  "max-height": "30",
                  "max-width": "30",
                  contain: ""
                }
              }),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("components.2faActivation.title")) +
                  "\n    "
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-card-subtitle", { staticClass: "pt-2" }, [
            _c("div", { staticClass: "py-2" }, [
              _c("span", [
                _vm._v(_vm._s(_vm.$t("components.2faActivation.sentence1")))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "py-2" }, [
              _c("div", { staticClass: "py-1 px-2 redBordered" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("components.2faActivation.sentence2")))
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "py-2" },
              [
                _c(
                  "v-row",
                  { staticClass: "mx-0 my-3" },
                  [
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0 positionRelative m-0a",
                          attrs: { cols: "7" }
                        },
                        [
                          _c("vue-tel-input", {
                            staticClass:
                              "form-control text-center input-sm inputPhone2FA",
                            attrs: {
                              required: "",
                              defaultCountry: "DE",
                              enabledCountryCode: "",
                              name: "number",
                              id: "numDisplay",
                              value: "",
                              placeholder: "",
                              autocomplete: "off"
                            },
                            on: { onInput: _vm.onTelInput },
                            model: {
                              value: _vm.telInputModel,
                              callback: function($$v) {
                                _vm.telInputModel = $$v
                              },
                              expression: "telInputModel"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "whatsAppIcon" },
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "20px" },
                                attrs: { icon: ["fab", "whatsapp"] }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ],
                  2
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "py-2 greenBordered" }, [
              _c(
                "div",
                { staticClass: "py-1", staticStyle: { "max-height": "50px" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "spanCheckBox",
                      attrs: { slot: "label" },
                      slot: "label"
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("components.2faActivation.checkBoxText"))
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-checkbox", {
                    staticClass: "optOutCheckbox",
                    attrs: {
                      disabled: _vm.disabledoptOutCheck,
                      value: _vm.optOut
                    },
                    on: {
                      change: function($event) {
                        return _vm.optOutChanged($event)
                      }
                    },
                    model: {
                      value: _vm.optOut,
                      callback: function($$v) {
                        _vm.optOut = $$v
                      },
                      expression: "optOut"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "v-card",
            {
              staticClass: "py-2 pl-4 mt-0 pr-2 footerModal",
              attrs: { color: "primary" }
            },
            [
              _c(
                "v-row",
                { staticClass: "px-2 btns mx-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "px-0 py-0",
                      attrs: { cols: "12", align: "right" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            disabled: _vm.saveDisabled
                          },
                          on: {
                            click: function($event) {
                              return _vm.saveEntryNoTel()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("generics.save")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showLastChance
        ? _c("TwoFactorLastChancePhone", {
            attrs: {
              toggle2faLastChangeModal: _vm.toggle2faLastChangeModal,
              showLastChance: _vm.showLastChance,
              saveEntryNoTel: _vm.saveEntryNoTel
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }