import { render, staticRenderFns } from "./returnToCall.vue?vue&type=template&id=53c9b030&scoped=true"
import script from "./returnToCall.vue?vue&type=script&lang=js"
export * from "./returnToCall.vue?vue&type=script&lang=js"
import style0 from "./returnToCall.vue?vue&type=style&index=0&id=53c9b030&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53c9b030",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/voffice-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('53c9b030')) {
      api.createRecord('53c9b030', component.options)
    } else {
      api.reload('53c9b030', component.options)
    }
    module.hot.accept("./returnToCall.vue?vue&type=template&id=53c9b030&scoped=true", function () {
      api.rerender('53c9b030', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "websrc/components/navbar/returnToCall.vue"
export default component.exports