var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "test", attrs: { id: "test" } },
    [
      _c(
        "v-content",
        {
          ref: "organisationGridContainer",
          staticClass: "px-0 py-0 contentGridWrapper",
          attrs: { id: "organisationGridContainer" }
        },
        [
          _c(
            "v-row",
            { staticClass: "mx-0" },
            [
              _c(
                "v-col",
                {
                  staticClass: "py-0 px-0",
                  attrs: { cols: _vm.amIAdmin && _vm.isEditableView ? 10 : 12 }
                },
                [
                  [
                    _c(
                      "v-row",
                      { staticClass: "row d-flex mx-auto" },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex justify-start py-0",
                            attrs: { cols: "7" }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "apartTitle text-white subtitle-1 font-weight-bold mb-0"
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.getApartLabel("sections")) +
                                    "\n\n                "
                                ),
                                _vm.amIAdmin &&
                                _vm.currentApart === "sections" &&
                                _vm.isEditableView
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "font-awesome-icon",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "cursorPointer primary--text",
                                                        style: {
                                                          fontSize: "18px"
                                                        },
                                                        attrs: {
                                                          icon: [
                                                            "fal",
                                                            _vm.getIsEditing
                                                              ? "times"
                                                              : "pen"
                                                          ]
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.getIsEditing
                                                              ? _vm.cleanEditing()
                                                              : _vm.setEditing(
                                                                  "section"
                                                                )
                                                          }
                                                        }
                                                      },
                                                      on
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          818897983
                                        )
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getIsEditing
                                                ? _vm.$t(
                                                    "components.organisationGrid.cancelEdit"
                                                  )
                                                : _vm.$t(
                                                    "components.organisationGrid.editTT"
                                                  )
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.isEditableView &&
                        (_vm.currentApart === "departments" ||
                          _vm.currentApart === "sections")
                          ? _c(
                              "v-col",
                              {
                                staticClass: "d-flex justify-end py-0",
                                attrs: { cols: "5" }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "apartTitle text-white subtitle-1 font-weight-bold mb-0"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.getApartLabel("assistants")
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticClass: "mx-auto rowHeight" },
                      [
                        _vm.amIAdmin &&
                        _vm.showPlusSection &&
                        _vm.isEditableView
                          ? _c(
                              "v-col",
                              {
                                class: "custom6cols py-1 px-2",
                                attrs: { cols: "6" }
                              },
                              [
                                _c(
                                  "draggable",
                                  {
                                    staticClass: "list-group",
                                    attrs: {
                                      "ghost-class": "ghost",
                                      handle: ".handle",
                                      list: [_vm.sections],
                                      group: "people"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.log($event, "addSection")
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-card",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "cardWidth cursorPointer",
                                                        style: {
                                                          border:
                                                            "1px solid #efefef"
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "divCardAdd"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "divAdd"
                                                            },
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  staticClass:
                                                                    "buttonAdd",
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "20px"
                                                                  },
                                                                  attrs: {
                                                                    icon: [
                                                                      "fal",
                                                                      "plus-circle"
                                                                    ]
                                                                  }
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "mb-0 mt-2 primary--text px-2"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "components.organisationGrid.addField"
                                                                        )
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("v-text-field", {
                                                        staticClass: "py-2",
                                                        attrs: {
                                                          outlined: "",
                                                          label: _vm.$t(
                                                            "components.organisationCarousel.headOfDepartment"
                                                          ),
                                                          dense: "",
                                                          "hide-details": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newSectionName,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.newSectionName = $$v
                                                          },
                                                          expression:
                                                            "newSectionName"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          1951442462
                                        )
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "components.organisationGrid.dragTT"
                                                )
                                              ) +
                                              "\n                  "
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.sections, function(section, name, index) {
                          return _c(
                            "v-col",
                            {
                              key: name,
                              class:
                                ((_vm.currentApart === "sections" ||
                                  _vm.currentApart === "departments" ||
                                  _vm.currentApart === "teams") &&
                                _vm.isEditableView
                                  ? "mr-auto"
                                  : null) + " custom6cols py-1 px-2",
                              attrs: { cols: "6" },
                              on: {
                                mouseenter: function($event) {
                                  return _vm.selectIndex("section-" + index)
                                },
                                mouseleave: function($event) {
                                  _vm.ItemIndex = null
                                }
                              }
                            },
                            [
                              _vm.getIsEditing &&
                              _vm.getEditingType == "section"
                                ? _c(
                                    "draggable",
                                    {
                                      staticClass: "list-group",
                                      attrs: {
                                        "ghost-class": "ghost",
                                        handle: ".handle",
                                        list: [_vm.sections],
                                        group: "people"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.editingItemOrga($event)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-card",
                                                      _vm._g(
                                                        {
                                                          staticClass:
                                                            "cardWidth cursorPointer",
                                                          style: {
                                                            border:
                                                              "1px solid #efefef"
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "divCardAdd d-flex align-center"
                                                          },
                                                          _vm._l(
                                                            section.supervisors,
                                                            function(
                                                              supervisor
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key:
                                                                    supervisor.uuid,
                                                                  staticClass:
                                                                    "editSupervisorCard",
                                                                  style: {
                                                                    backgroundImage:
                                                                      "url(" +
                                                                      _vm.getAvatarByType(
                                                                        supervisor.uuid
                                                                      ) +
                                                                      ")"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "h100 gradientBg justify-center align-center d-flex flex-column text-center"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "px-1 pb-2 w100 my-0 white--text text-truncate"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.getNameForUuid(
                                                                                supervisor.uuid
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      section
                                                                        .supervisors
                                                                        .length >
                                                                      1
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "deleteBasketSupervisor d-flex align-center justify-center text-center"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "DeleteBasket",
                                                                                {
                                                                                  attrs: {
                                                                                    indexData: [
                                                                                      supervisor.uuid,
                                                                                      true
                                                                                    ],
                                                                                    delFunction:
                                                                                      _vm.editingItemOrga,
                                                                                    extraClasses:
                                                                                      "",
                                                                                    fontSize:
                                                                                      "16",
                                                                                    prevent:
                                                                                      "false",
                                                                                    organigramTT: _vm.$t(
                                                                                      "components.organisationGrid.deleteBasketTT"
                                                                                    )
                                                                                  }
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                        _vm._v(" "),
                                                        _c("v-text-field", {
                                                          staticClass: "py-2",
                                                          attrs: {
                                                            outlined: "",
                                                            label: _vm.$t(
                                                              "components.organisationCarousel.section"
                                                            ),
                                                            dense: "",
                                                            "hide-details": ""
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "append",
                                                                fn: function() {
                                                                  return [
                                                                    _c(
                                                                      "font-awesome-icon",
                                                                      {
                                                                        staticClass:
                                                                          "mt-1 cursorPointer",
                                                                        style: {
                                                                          fontSize:
                                                                            "15px"
                                                                        },
                                                                        attrs: {
                                                                          icon: [
                                                                            "fal",
                                                                            "save"
                                                                          ]
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.editingItemOrga()
                                                                          }
                                                                        }
                                                                      }
                                                                    )
                                                                  ]
                                                                },
                                                                proxy: true
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                          model: {
                                                            value:
                                                              _vm.isEditing
                                                                .name,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.isEditing,
                                                                "name",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "isEditing.name"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "components.organisationGrid.editCardTT"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.getIsEditing ||
                              (_vm.getIsEditing &&
                                _vm.getEditingType !== "section")
                                ? _c(
                                    "draggable",
                                    {
                                      staticClass: "list-group sectionsDrag",
                                      attrs: {
                                        "ghost-class": "ghost",
                                        handle: ".handle",
                                        list: [_vm.sections],
                                        group: "people"
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "cardWidth" }, [
                                        _c(
                                          "div",
                                          { staticClass: "d-flex" },
                                          [
                                            _vm.amIAdmin &&
                                            _vm.isEditableView &&
                                            _vm.ItemIndex === "section-" + index
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "deleteBasketOrga d-flex align-center justify-center text-center"
                                                  },
                                                  [
                                                    _c("DeleteBasket", {
                                                      attrs: {
                                                        indexData: [
                                                          {
                                                            type: "section",
                                                            name: name
                                                          }
                                                        ],
                                                        delFunction:
                                                          _vm.removeStructure,
                                                        extraClasses: "",
                                                        fontSize: "16",
                                                        prevent: "false",
                                                        organigramTT: _vm.$t(
                                                          "components.organisationGrid.deleteBasketTT"
                                                        )
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !section.supervisors.length
                                              ? _c(
                                                  "div",
                                                  { staticClass: "w100" },
                                                  [
                                                    _c(
                                                      "v-card",
                                                      {
                                                        staticStyle: {
                                                          border:
                                                            "4px solid rgb(128, 128, 128)"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "v-img",
                                                              {
                                                                staticClass:
                                                                  "white--text align-end",
                                                                attrs: {
                                                                  height:
                                                                    "180px"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "gradientBg"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card-subtitle",
                                                                      {
                                                                        staticClass:
                                                                          "titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate",
                                                                        staticStyle: {
                                                                          color:
                                                                            "#fff !important"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                  " +
                                                                            _vm._s(
                                                                              name
                                                                            ) +
                                                                            "\n                                "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-card-subtitle",
                                                                      {
                                                                        staticClass:
                                                                          "titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate subTitleCard",
                                                                        staticStyle: {
                                                                          color:
                                                                            "#fff !important"
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm._l(
                                              section.supervisors,
                                              function(supervisor, i) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: supervisor.uuid,
                                                    class:
                                                      section.supervisors
                                                        .length > 1
                                                        ? "w50"
                                                        : "w100"
                                                  },
                                                  [
                                                    _c(
                                                      "v-card",
                                                      {
                                                        style: _vm.getBorderCard(
                                                          section.supervisors,
                                                          supervisor.uuid,
                                                          i
                                                        ),
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            !_vm.disableCallBtn(
                                                              _vm.getPersonByUuid(
                                                                supervisor.uuid
                                                              )
                                                            )
                                                              ? _vm.callUser(
                                                                  supervisor.uuid
                                                                )
                                                              : ""
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: { top: "" },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        _vm._g(
                                                                          {},
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-img",
                                                                            {
                                                                              staticClass:
                                                                                "white--text align-end",
                                                                              attrs: {
                                                                                height:
                                                                                  "180px",
                                                                                src: _vm.getAvatarForUuid(
                                                                                  supervisor.uuid
                                                                                )
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "gradientBg"
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-card-subtitle",
                                                                                    {
                                                                                      staticClass:
                                                                                        "titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate",
                                                                                      staticStyle: {
                                                                                        color:
                                                                                          "#fff !important"
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                    " +
                                                                                          _vm._s(
                                                                                            name
                                                                                          ) +
                                                                                          "\n                                  "
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "v-card-subtitle",
                                                                                    {
                                                                                      staticClass:
                                                                                        "titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate subTitleCard",
                                                                                      staticStyle: {
                                                                                        color:
                                                                                          "#fff !important"
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                    " +
                                                                                          _vm._s(
                                                                                            _vm.getTitelForUuid(
                                                                                              supervisor.uuid
                                                                                            ) +
                                                                                              " " +
                                                                                              _vm.getNameForUuid(
                                                                                                supervisor.uuid
                                                                                              )
                                                                                          ) +
                                                                                          "\n                                  "
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _vm.hasRepresentative(
                                                                                    supervisor.uuid
                                                                                  )
                                                                                    ? _c(
                                                                                        "v-card-subtitle",
                                                                                        {
                                                                                          staticClass:
                                                                                            "d-flex titleCard colorWhite py-0 px-0 text-truncate justify-space-between",
                                                                                          style: _vm.getBorderByStatusFull(
                                                                                            _vm.getPersonByUuid(
                                                                                              _vm.getRepresentativeId(
                                                                                                supervisor.uuid
                                                                                              )
                                                                                            )
                                                                                          ),
                                                                                          on: {
                                                                                            click: function(
                                                                                              $event
                                                                                            ) {
                                                                                              $event.stopPropagation()
                                                                                              $event.preventDefault()
                                                                                            }
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "representative-subtitle text-truncate"
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "CallIcon",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "mr-1",
                                                                                                  attrs: {
                                                                                                    person: _vm.getPersonByUuid(
                                                                                                      _vm.getRepresentativeId(
                                                                                                        supervisor.uuid
                                                                                                      )
                                                                                                    ),
                                                                                                    isRegularGrid: true
                                                                                                  }
                                                                                                }
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "colorWhite"
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                        " +
                                                                                                      _vm._s(
                                                                                                        _vm.getRepresentativeName(
                                                                                                          supervisor.uuid
                                                                                                        )
                                                                                                      ) +
                                                                                                      "\n                                      "
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "ButtonsFrontCard",
                                                                                            {
                                                                                              staticClass:
                                                                                                "pt-1",
                                                                                              staticStyle: {
                                                                                                position:
                                                                                                  "inherit !important"
                                                                                              },
                                                                                              attrs: {
                                                                                                isOrga: true,
                                                                                                person: _vm.getPersonByUuid(
                                                                                                  supervisor.uuid
                                                                                                ),
                                                                                                uuid:
                                                                                                  supervisor.uuid,
                                                                                                sectionData: {
                                                                                                  type:
                                                                                                    "sections",
                                                                                                  name: name
                                                                                                },
                                                                                                isRegularGrid: true
                                                                                              },
                                                                                              on: {
                                                                                                click: function(
                                                                                                  $event
                                                                                                ) {
                                                                                                  $event.stopPropagation()
                                                                                                  $event.preventDefault()
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  !_vm.hasRepresentative(
                                                                                    supervisor.uuid
                                                                                  )
                                                                                    ? _c(
                                                                                        "ButtonsFrontCard",
                                                                                        {
                                                                                          attrs: {
                                                                                            isOrga: true,
                                                                                            person: _vm.getPersonByUuid(
                                                                                              supervisor.uuid
                                                                                            ),
                                                                                            uuid:
                                                                                              supervisor.uuid,
                                                                                            sectionData: {
                                                                                              type:
                                                                                                "sections",
                                                                                              name: name
                                                                                            },
                                                                                            isRegularGrid: true
                                                                                          },
                                                                                          on: {
                                                                                            click: function(
                                                                                              $event
                                                                                            ) {
                                                                                              $event.stopPropagation()
                                                                                              $event.preventDefault()
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      )
                                                                                    : _vm._e()
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "ButtonsCardWrapper",
                                                                                {
                                                                                  attrs: {
                                                                                    showMultiUserCallIcon: true,
                                                                                    isOrganigram: true,
                                                                                    person: _vm.getPersonByUuid(
                                                                                      supervisor.uuid
                                                                                    ),
                                                                                    isRegularGrid: true
                                                                                  }
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _c(
                                                              "UserInfoTooltip",
                                                              {
                                                                attrs: {
                                                                  organisationData: {
                                                                    name: name
                                                                  },
                                                                  person: _vm.getPersonByUuid(
                                                                    supervisor.uuid
                                                                  )
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              }
                                            )
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "d-flex justify-center"
                                          },
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "font-awesome-icon",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "cursorPointer arrowIcon",
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "40px"
                                                                },
                                                                attrs: {
                                                                  icon: !_vm.isSectionSelected
                                                                    ? [
                                                                        "fal",
                                                                        "angle-down"
                                                                      ]
                                                                    : [
                                                                        "fal",
                                                                        "angle-up"
                                                                      ]
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleClicks(
                                                                      $event,
                                                                      name,
                                                                      "sections",
                                                                      "flipSection-" +
                                                                        index
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              on
                                                            )
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "components.organisationGrid.arrowIconTT"
                                                        )
                                                      ) +
                                                      "\n                      "
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        }),
                        _vm._v(" "),
                        _vm.amIAdmin &&
                        _vm.currentApart === "sections" &&
                        _vm.isEditableView
                          ? _c(
                              "v-col",
                              {
                                staticClass: "d-flex custom6cols py-1 px-2",
                                attrs: { cols: "6" }
                              },
                              [
                                _c(
                                  "draggable",
                                  {
                                    staticClass: "list-group",
                                    attrs: {
                                      handle: ".handle",
                                      "ghost-class": "ghost",
                                      list: [_vm.sections],
                                      group: "people"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.log($event, "addAssistant")
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass: "cardWidth cursorPointer",
                                        style: { border: "1px solid #efefef" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "divCardAdd assitants"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "divAdd" },
                                              [
                                                _c("font-awesome-icon", {
                                                  staticClass: "buttonAdd",
                                                  staticStyle: {
                                                    "font-size": "20px"
                                                  },
                                                  attrs: {
                                                    icon: ["fal", "plus-circle"]
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "mb-0 mt-2 primary--text px-2"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "components.organisationGrid.addField"
                                                          )
                                                        ) +
                                                        "\n                      "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(
                          _vm.specificApartAssistants("sections"),
                          function(section, name, index) {
                            return _c(
                              "v-col",
                              {
                                key: name,
                                staticClass: "py-1 px-2",
                                class: {
                                  "assistantsViewMode custom6colsViewing": !_vm.isEditableView,
                                  custom6cols: _vm.isEditableView
                                },
                                attrs: { cols: "6" },
                                on: {
                                  mouseenter: function($event) {
                                    return _vm.selectIndex(
                                      "sectionAssistant-" + index
                                    )
                                  },
                                  mouseleave: function($event) {
                                    _vm.ItemIndex = null
                                  }
                                }
                              },
                              [
                                !_vm.isEditableView && index == 0
                                  ? _c(
                                      "p",
                                      {
                                        staticClass:
                                          "apartTitleViewMode apartTitle text-white subtitle-1 font-weight-bold mb-0"
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.getApartLabel("assistants")
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "v-card",
                                  {
                                    class: " cardWidth",
                                    style: _vm.getBorderByStatusFull(section),
                                    on: {
                                      click: function($event) {
                                        return _vm.handleClicks(
                                          $event,
                                          null,
                                          null,
                                          "flipSectionAssistant-" + index
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "div",
                                                    _vm._g({}, on),
                                                    [
                                                      _c(
                                                        "v-img",
                                                        {
                                                          staticClass:
                                                            "white--text align-end",
                                                          attrs: {
                                                            height: !_vm.isEditableView
                                                              ? "100px"
                                                              : "180px",
                                                            src: _vm.getSupervisorAvatar(
                                                              section,
                                                              "members"
                                                            )
                                                          }
                                                        },
                                                        [
                                                          _vm.amIAdmin &&
                                                          _vm.isEditableView &&
                                                          _vm.ItemIndex ===
                                                            "sectionAssistant-" +
                                                              index
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "deleteBasketOrga"
                                                                },
                                                                [
                                                                  _c(
                                                                    "DeleteBasket",
                                                                    {
                                                                      attrs: {
                                                                        indexData: [
                                                                          {
                                                                            user: section,
                                                                            type:
                                                                              "sections"
                                                                          }
                                                                        ],
                                                                        delFunction:
                                                                          _vm.removeAssistant,
                                                                        extraClasses:
                                                                          "",
                                                                        fontSize:
                                                                          "16",
                                                                        prevent:
                                                                          "false",
                                                                        organigramTT: _vm.$t(
                                                                          "components.organisationGrid.deleteBasketTT"
                                                                        )
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "gradientBg"
                                                            },
                                                            [
                                                              _vm.isEditableView
                                                                ? _c(
                                                                    "v-card-subtitle",
                                                                    {
                                                                      staticClass:
                                                                        "titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate",
                                                                      staticStyle: {
                                                                        color:
                                                                          "#fff !important"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "components.organisationCarousel.assistant"
                                                                            )
                                                                          ) +
                                                                          "\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-card-subtitle",
                                                                {
                                                                  staticClass:
                                                                    "subTitleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate",
                                                                  staticStyle: {
                                                                    color:
                                                                      "#fff !important"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        _vm.getSupervisorTitel(
                                                                          section
                                                                        ) +
                                                                          " " +
                                                                          _vm.getUserName(
                                                                            section
                                                                          )
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              section.user &&
                                                              _vm.hasRepresentative(
                                                                section.uuid
                                                              )
                                                                ? _c(
                                                                    "v-card-subtitle",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex titleCard colorWhite py-0 px-0 text-truncate justify-space-between",
                                                                      style: _vm.getBorderByStatusFull(
                                                                        _vm.getPersonByUuid(
                                                                          section
                                                                            .user
                                                                            .representative
                                                                            .value
                                                                        )
                                                                      ),
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          $event.preventDefault()
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "representative-subtitle text-truncate"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "CallIcon",
                                                                            {
                                                                              staticClass:
                                                                                "mr-1",
                                                                              attrs: {
                                                                                person: _vm.getPersonByUuid(
                                                                                  section
                                                                                    .user
                                                                                    .representative
                                                                                    .value
                                                                                ),
                                                                                isRegularGrid: true
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "colorWhite"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                " +
                                                                                  _vm._s(
                                                                                    _vm.getRepresentativeName(
                                                                                      section.uuid
                                                                                    )
                                                                                  ) +
                                                                                  "\n                              "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "ButtonsFrontCard",
                                                                        {
                                                                          staticClass:
                                                                            "pt-1",
                                                                          staticStyle: {
                                                                            position:
                                                                              "inherit !important"
                                                                          },
                                                                          attrs: {
                                                                            isOrga: true,
                                                                            person: section,
                                                                            uuid:
                                                                              section.uuid,
                                                                            sectionName:
                                                                              "members",
                                                                            isRegularGrid: true
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                              $event.preventDefault()
                                                                            }
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              !section.user ||
                                                              !_vm.hasRepresentative(
                                                                section.uuid
                                                              )
                                                                ? _c(
                                                                    "ButtonsFrontCard",
                                                                    {
                                                                      attrs: {
                                                                        isOrga: true,
                                                                        person: section,
                                                                        uuid:
                                                                          section.uuid,
                                                                        sectionName:
                                                                          "members",
                                                                        isRegularGrid: true
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          $event.preventDefault()
                                                                        }
                                                                      }
                                                                    }
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "ButtonsCardWrapper",
                                                            {
                                                              attrs: {
                                                                showMultiUserCallIcon: true,
                                                                isOrganigram: true,
                                                                person: section,
                                                                isRegularGrid: true
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("UserInfoTooltip", {
                                          attrs: { person: section }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }
                        )
                      ],
                      2
                    )
                  ],
                  _vm._v(" "),
                  _vm.showDepartment || _vm.showEmptyDepartments
                    ? [
                        _vm.showDepartment
                          ? _c(
                              "v-row",
                              { staticClass: "row d-flex mx-auto" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "d-flex justify-start py-0",
                                    attrs: { cols: "7" }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "apartTitle" },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.getApartLabel("departments")
                                            ) +
                                            "\n\n                "
                                        ),
                                        _vm.amIAdmin &&
                                        _vm.currentApart === "departments" &&
                                        _vm.isEditableView
                                          ? _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "font-awesome-icon",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "cursorPointer primary--text",
                                                                style: {
                                                                  fontSize:
                                                                    "18px"
                                                                },
                                                                attrs: {
                                                                  icon: [
                                                                    "fal",
                                                                    _vm.getIsEditing
                                                                      ? "times"
                                                                      : "pen"
                                                                  ]
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.getIsEditing
                                                                      ? _vm.cleanEditing()
                                                                      : _vm.setEditing(
                                                                          "department"
                                                                        )
                                                                  }
                                                                }
                                                              },
                                                              on
                                                            )
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  7522002
                                                )
                                              },
                                              [
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getIsEditing
                                                        ? _vm.$t(
                                                            "components.organisationGrid.cancelEdit"
                                                          )
                                                        : _vm.$t(
                                                            "components.organisationGrid.editTT"
                                                          )
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.isEditableView &&
                                _vm.currentApart === "departments"
                                  ? _c(
                                      "v-col",
                                      {
                                        staticClass: "d-flex justify-end py-0",
                                        attrs: { cols: "5" }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "apartTitle text-white subtitle-1 font-weight-bold mb-0"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.organisationCarousel.assistant"
                                                  )
                                                ) +
                                                "\n\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "v-row",
                          { staticClass: "mx-auto rowHeight" },
                          [
                            _vm.amIAdmin &&
                            _vm.showPlusDepartment &&
                            _vm.isEditableView
                              ? _c(
                                  "v-col",
                                  {
                                    staticClass: "custom6cols py-1 px-2",
                                    attrs: { cols: "6" }
                                  },
                                  [
                                    _c(
                                      "draggable",
                                      {
                                        staticClass: "list-group",
                                        attrs: {
                                          handle: ".handle",
                                          "ghost-class": "ghost",
                                          list: [_vm.sections],
                                          group: "people"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.log(
                                              $event,
                                              "addDepartment"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-card",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "cardWidth cursorPointer",
                                                            style: {
                                                              border:
                                                                "1px solid #efefef"
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "divCardAdd"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "divAdd"
                                                                },
                                                                [
                                                                  _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      staticClass:
                                                                        "buttonAdd",
                                                                      staticStyle: {
                                                                        "font-size":
                                                                          "20px"
                                                                      },
                                                                      attrs: {
                                                                        icon: [
                                                                          "fal",
                                                                          "plus-circle"
                                                                        ]
                                                                      }
                                                                    }
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "mb-0 mt-2 primary--text px-2"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "components.organisationGrid.addField"
                                                                            )
                                                                          ) +
                                                                          "\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("v-text-field", {
                                                            staticClass: "py-2",
                                                            attrs: {
                                                              outlined: "",
                                                              label: _vm.$t(
                                                                "components.organisationCarousel.department"
                                                              ),
                                                              dense: "",
                                                              "hide-details": ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.newDepartmentName,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.newDepartmentName = $$v
                                                              },
                                                              expression:
                                                                "newDepartmentName"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              1374861490
                                            )
                                          },
                                          [
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "components.organisationGrid.dragTT"
                                                    )
                                                  ) +
                                                  "\n                  "
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(_vm.departments, function(
                              section,
                              name,
                              index
                            ) {
                              return _c(
                                "v-col",
                                {
                                  key: name,
                                  staticClass: "custom6cols py-1 px-2`",
                                  class: {
                                    "mr-auto":
                                      (_vm.currentApart === "departments" ||
                                        _vm.currentApart === "teams") &&
                                      _vm.isEditableView &&
                                      _vm.isDepartmentSelected
                                  },
                                  attrs: { cols: "6" },
                                  on: {
                                    mouseenter: function($event) {
                                      return _vm.selectIndex(
                                        "department-" + index
                                      )
                                    },
                                    mouseleave: function($event) {
                                      _vm.ItemIndex = null
                                    }
                                  }
                                },
                                [
                                  _vm.getIsEditing &&
                                  _vm.getEditingType == "department"
                                    ? _c(
                                        "draggable",
                                        {
                                          staticClass: "list-group",
                                          attrs: {
                                            "ghost-class": "ghost",
                                            handle: ".handle",
                                            list: [_vm.sections],
                                            group: "people"
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.editingItemOrga($event)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-card",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "cardWidth cursorPointer",
                                                              style: {
                                                                border:
                                                                  "1px solid #efefef"
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "divCardAdd d-flex align-center"
                                                              },
                                                              _vm._l(
                                                                section.supervisors,
                                                                function(
                                                                  supervisor
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key:
                                                                        supervisor.uuid,
                                                                      staticClass:
                                                                        "editSupervisorCard",
                                                                      style: {
                                                                        backgroundImage:
                                                                          "url(" +
                                                                          _vm.getAvatarByType(
                                                                            supervisor.uuid
                                                                          ) +
                                                                          ")"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "h100 gradientBg justify-center align-center d-flex flex-column text-center"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "px-1 pb-2 w100 my-0 white--text text-truncate"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.getNameForUuid(
                                                                                    supervisor.uuid
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          section
                                                                            .supervisors
                                                                            .length >
                                                                          1
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "deleteBasketSupervisor d-flex align-center justify-center text-center"
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "DeleteBasket",
                                                                                    {
                                                                                      attrs: {
                                                                                        indexData: [
                                                                                          supervisor.uuid,
                                                                                          true
                                                                                        ],
                                                                                        delFunction:
                                                                                          _vm.editingItemOrga,
                                                                                        extraClasses:
                                                                                          "",
                                                                                        fontSize:
                                                                                          "16",
                                                                                        prevent:
                                                                                          "false",
                                                                                        organigramTT: _vm.$t(
                                                                                          "components.organisationGrid.deleteBasketTT"
                                                                                        )
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e()
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            ),
                                                            _vm._v(" "),
                                                            _c("v-text-field", {
                                                              staticClass:
                                                                "py-2",
                                                              attrs: {
                                                                outlined: "",
                                                                label: _vm.$t(
                                                                  "components.organisationCarousel.department"
                                                                ),
                                                                dense: "",
                                                                "hide-details":
                                                                  ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "append",
                                                                    fn: function() {
                                                                      return [
                                                                        _c(
                                                                          "font-awesome-icon",
                                                                          {
                                                                            staticClass:
                                                                              "mt-1 cursorPointer",
                                                                            style: {
                                                                              fontSize:
                                                                                "15px"
                                                                            },
                                                                            attrs: {
                                                                              icon: [
                                                                                "fal",
                                                                                "save"
                                                                              ]
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.editingItemOrga()
                                                                              }
                                                                            }
                                                                          }
                                                                        )
                                                                      ]
                                                                    },
                                                                    proxy: true
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              ),
                                                              model: {
                                                                value:
                                                                  _vm.isEditing
                                                                    .name,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.isEditing,
                                                                    "name",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "isEditing.name"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "components.organisationGrid.editCardTT"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.getIsEditing ||
                                  (_vm.getIsEditing &&
                                    _vm.getEditingType !== "department")
                                    ? _c(
                                        "draggable",
                                        {
                                          staticClass:
                                            "list-group departmentsDrag",
                                          attrs: {
                                            "ghost-class": "ghost",
                                            list: [_vm.sections],
                                            group: "people"
                                          },
                                          on: {
                                            end: function($event) {
                                              return _vm.checkMove(
                                                $event,
                                                name,
                                                section.section,
                                                "moveDepartment"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "cardWidth" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "d-flex" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "gradientTop"
                                                    },
                                                    [
                                                      _c(
                                                        "v-card-subtitle",
                                                        {
                                                          staticClass:
                                                            "titleCard colorWhite pt-1 pb-1 px-2 text-truncate",
                                                          staticStyle: {
                                                            color:
                                                              "#fff !important"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                section.section
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.amIAdmin &&
                                                  _vm.isEditableView &&
                                                  _vm.ItemIndex ===
                                                    "department-" + index
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "deleteBasketOrga d-flex align-center justify-center text-center"
                                                        },
                                                        [
                                                          _c("DeleteBasket", {
                                                            attrs: {
                                                              indexData: [
                                                                {
                                                                  type:
                                                                    "department",
                                                                  name: name,
                                                                  parent:
                                                                    section.section
                                                                }
                                                              ],
                                                              delFunction:
                                                                _vm.removeStructure,
                                                              extraClasses: "",
                                                              fontSize: "16",
                                                              prevent: "false",
                                                              organigramTT: _vm.$t(
                                                                "components.organisationGrid.deleteBasketTT"
                                                              )
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  !section.supervisors.length
                                                    ? _c(
                                                        "div",
                                                        { staticClass: "w100" },
                                                        [
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticStyle: {
                                                                border:
                                                                  "4px solid rgb(128, 128, 128)"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "v-img",
                                                                    {
                                                                      staticClass:
                                                                        "white--text align-end",
                                                                      attrs: {
                                                                        height:
                                                                          "180px"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "gradientBg"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-card-subtitle",
                                                                            {
                                                                              staticClass:
                                                                                "titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate",
                                                                              staticStyle: {
                                                                                color:
                                                                                  "#fff !important"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                " +
                                                                                  _vm._s(
                                                                                    !section.uuid
                                                                                      ? name
                                                                                      : _vm.$t(
                                                                                          "components.organisationCarousel.assistant"
                                                                                        ) +
                                                                                          " - " +
                                                                                          _vm.getAssistantSection
                                                                                  ) +
                                                                                  "\n                              "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    section.supervisors,
                                                    function(supervisor, i) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: supervisor.uuid,
                                                          class:
                                                            section.supervisors
                                                              .length > 1
                                                              ? "w50"
                                                              : "w100"
                                                        },
                                                        [
                                                          _c(
                                                            "v-card",
                                                            {
                                                              style: _vm.getBorderCard(
                                                                section.supervisors,
                                                                supervisor.uuid,
                                                                i
                                                              ),
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  !_vm.disableCallBtn(
                                                                    _vm.getPersonByUuid(
                                                                      supervisor.uuid
                                                                    )
                                                                  )
                                                                    ? _vm.callUser(
                                                                        supervisor.uuid
                                                                      )
                                                                    : ""
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    top: ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              _vm._g(
                                                                                {},
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-img",
                                                                                  {
                                                                                    staticClass:
                                                                                      "white--text align-end",
                                                                                    class: {
                                                                                      repeateadImage: _vm.isUserRepeated(
                                                                                        section,
                                                                                        "departments"
                                                                                      )
                                                                                    },
                                                                                    attrs: {
                                                                                      height:
                                                                                        "180px",
                                                                                      src: supervisor.uuid
                                                                                        ? _vm.getAvatarForUuid(
                                                                                            supervisor.uuid
                                                                                          )
                                                                                        : null
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "gradientBg"
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-card-subtitle",
                                                                                          {
                                                                                            staticClass:
                                                                                              "titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate",
                                                                                            staticStyle: {
                                                                                              color:
                                                                                                "#fff !important"
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                    " +
                                                                                                _vm._s(
                                                                                                  !section.uuid
                                                                                                    ? name
                                                                                                    : _vm.$t(
                                                                                                        "components.organisationCarousel.assistant"
                                                                                                      ) +
                                                                                                        " - " +
                                                                                                        _vm.getAssistantSection
                                                                                                ) +
                                                                                                "\n                                  "
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "v-card-subtitle",
                                                                                          {
                                                                                            staticClass:
                                                                                              "subTitleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate",
                                                                                            staticStyle: {
                                                                                              color:
                                                                                                "#fff !important"
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                    " +
                                                                                                _vm._s(
                                                                                                  section.uuid
                                                                                                    ? _vm.getTitelForUuid(
                                                                                                        supervisor.uuid
                                                                                                      ) +
                                                                                                        " " +
                                                                                                        _vm.getNameForUuid(
                                                                                                          supervisor.uuid
                                                                                                        )
                                                                                                    : _vm.getTitelForUuid(
                                                                                                        supervisor.uuid
                                                                                                      ) +
                                                                                                        " " +
                                                                                                        _vm.getNameForUuid(
                                                                                                          supervisor.uuid
                                                                                                        )
                                                                                                ) +
                                                                                                "\n                                  "
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _vm.hasRepresentative(
                                                                                          supervisor.uuid
                                                                                        )
                                                                                          ? _c(
                                                                                              "v-card-subtitle",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "d-flex titleCard colorWhite py-0 px-0 text-truncate justify-space-between",
                                                                                                style: _vm.getBorderByStatusFull(
                                                                                                  _vm.getPersonByUuid(
                                                                                                    _vm.getRepresentativeId(
                                                                                                      supervisor.uuid
                                                                                                    )
                                                                                                  )
                                                                                                ),
                                                                                                on: {
                                                                                                  click: function(
                                                                                                    $event
                                                                                                  ) {
                                                                                                    $event.stopPropagation()
                                                                                                    $event.preventDefault()
                                                                                                  }
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "representative-subtitle text-truncate"
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "CallIcon",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "mr-1",
                                                                                                        attrs: {
                                                                                                          person: _vm.getPersonByUuid(
                                                                                                            _vm.getRepresentativeId(
                                                                                                              supervisor.uuid
                                                                                                            )
                                                                                                          ),
                                                                                                          isRegularGrid: true
                                                                                                        }
                                                                                                      }
                                                                                                    ),
                                                                                                    _vm._v(
                                                                                                      " "
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "span",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "colorWhite"
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "\n                                        " +
                                                                                                            _vm._s(
                                                                                                              _vm.getRepresentativeName(
                                                                                                                supervisor.uuid
                                                                                                              )
                                                                                                            ) +
                                                                                                            "\n                                      "
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                ),
                                                                                                _vm._v(
                                                                                                  " "
                                                                                                ),
                                                                                                (section.uuid
                                                                                                ? true
                                                                                                : !_vm.isUserRepeated(
                                                                                                    section,
                                                                                                    "departments"
                                                                                                  ))
                                                                                                  ? _c(
                                                                                                      "ButtonsFrontCard",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "pt-1",
                                                                                                        staticStyle: {
                                                                                                          position:
                                                                                                            "inherit !important"
                                                                                                        },
                                                                                                        attrs: {
                                                                                                          checkMove:
                                                                                                            _vm.checkMove,
                                                                                                          isMovingDepa: true,
                                                                                                          isMovingTeam: false,
                                                                                                          isEditableView:
                                                                                                            _vm.isEditableView,
                                                                                                          nameSectionToMove: name,
                                                                                                          parentToMove:
                                                                                                            section.section,
                                                                                                          moveType:
                                                                                                            "moveDepartment",
                                                                                                          isOrga: true,
                                                                                                          person: section.uuid
                                                                                                            ? section
                                                                                                            : _vm.getPersonByUuid(
                                                                                                                supervisor.uuid
                                                                                                              ),
                                                                                                          uuid: section.uuid
                                                                                                            ? name
                                                                                                            : supervisor.uuid,
                                                                                                          sectionData: section.uuid
                                                                                                            ? undefined
                                                                                                            : {
                                                                                                                type:
                                                                                                                  "departments",
                                                                                                                name: name
                                                                                                              },
                                                                                                          isRegularGrid: true
                                                                                                        },
                                                                                                        on: {
                                                                                                          click: function(
                                                                                                            $event
                                                                                                          ) {
                                                                                                            $event.stopPropagation()
                                                                                                            $event.preventDefault()
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    )
                                                                                                  : _vm._e()
                                                                                              ],
                                                                                              1
                                                                                            )
                                                                                          : _vm._e(),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        !_vm.hasRepresentative(
                                                                                          supervisor.uuid
                                                                                        ) &&
                                                                                        (section.uuid
                                                                                          ? true
                                                                                          : !_vm.isUserRepeated(
                                                                                              section,
                                                                                              "departments"
                                                                                            ))
                                                                                          ? _c(
                                                                                              "ButtonsFrontCard",
                                                                                              {
                                                                                                attrs: {
                                                                                                  checkMove:
                                                                                                    _vm.checkMove,
                                                                                                  isMovingDepa: true,
                                                                                                  isMovingTeam: false,
                                                                                                  isEditableView:
                                                                                                    _vm.isEditableView,
                                                                                                  nameSectionToMove: name,
                                                                                                  parentToMove:
                                                                                                    section.section,
                                                                                                  moveType:
                                                                                                    "moveDepartment",
                                                                                                  isOrga: true,
                                                                                                  person: section.uuid
                                                                                                    ? section
                                                                                                    : _vm.getPersonByUuid(
                                                                                                        supervisor.uuid
                                                                                                      ),
                                                                                                  uuid: section.uuid
                                                                                                    ? name
                                                                                                    : supervisor.uuid,
                                                                                                  sectionData: section.uuid
                                                                                                    ? undefined
                                                                                                    : {
                                                                                                        type:
                                                                                                          "departments",
                                                                                                        name: name
                                                                                                      },
                                                                                                  isRegularGrid: true
                                                                                                },
                                                                                                on: {
                                                                                                  click: function(
                                                                                                    $event
                                                                                                  ) {
                                                                                                    $event.stopPropagation()
                                                                                                    $event.preventDefault()
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            )
                                                                                          : _vm._e()
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "ButtonsCardWrapper",
                                                                                      {
                                                                                        attrs: {
                                                                                          showMultiUserCallIcon: true,
                                                                                          customTop: true,
                                                                                          isOrganigram: true,
                                                                                          person: _vm.getPersonByUuid(
                                                                                            supervisor.uuid
                                                                                          ),
                                                                                          isRegularGrid: true
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                },
                                                                [
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "UserInfoTooltip",
                                                                    {
                                                                      attrs: {
                                                                        organisationData: {
                                                                          name: name
                                                                        },
                                                                        person: section.uuid
                                                                          ? section
                                                                          : _vm.getPersonByUuid(
                                                                              supervisor.uuid
                                                                            )
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-center"
                                                },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "font-awesome-icon",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "cursorPointer arrowIcon",
                                                                      staticStyle: {
                                                                        "font-size":
                                                                          "40px"
                                                                      },
                                                                      attrs: {
                                                                        icon: !_vm.isDepartmentSelected
                                                                          ? [
                                                                              "fal",
                                                                              "angle-down"
                                                                            ]
                                                                          : [
                                                                              "fal",
                                                                              "angle-up"
                                                                            ]
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleClicks(
                                                                            $event,
                                                                            section.uuid
                                                                              ? null
                                                                              : name,
                                                                            section.uuid
                                                                              ? null
                                                                              : "departments",
                                                                            "flipDepartment-" +
                                                                              index
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    on
                                                                  )
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "components.organisationGrid.arrowIconTT"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            }),
                            _vm._v(" "),
                            (_vm.currentApart == "departments" ||
                              _vm.currentApart == "teams") &&
                            (_vm.isDepartmentSelected || _vm.isTeamSelected)
                              ? [
                                  _vm.amIAdmin &&
                                  _vm.showPlusDepartmentAssistant &&
                                  _vm.isEditableView
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass: "custom6cols py-1 px-2",
                                          attrs: { cols: "6" }
                                        },
                                        [
                                          _c(
                                            "draggable",
                                            {
                                              staticClass: "list-group",
                                              attrs: {
                                                "ghost-class": "ghost",
                                                handle: ".handle",
                                                list: [_vm.sections],
                                                group: "people"
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.log(
                                                    $event,
                                                    "addDepartmentAssistant"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  staticClass:
                                                    "cardWidth cursorPointer",
                                                  style: {
                                                    border: "1px solid #efefef"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "divCardAdd assitants"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "divAdd"
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              staticClass:
                                                                "buttonAdd",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "20px"
                                                              },
                                                              attrs: {
                                                                icon: [
                                                                  "fal",
                                                                  "plus-circle"
                                                                ]
                                                              }
                                                            }
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "mb-0 mt-2 primary--text px-2"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "components.organisationGrid.addField"
                                                                    )
                                                                  ) +
                                                                  "\n                        "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.specificApartAssistants("departments"),
                                    function(section, name, index) {
                                      return _c(
                                        "v-col",
                                        {
                                          key: name,
                                          staticClass: "py-1 px-2",
                                          class: {
                                            "assistantsViewMode custom6colsViewing": !_vm.isEditableView,
                                            custom6cols: _vm.isEditableView
                                          },
                                          attrs: { cols: "6" },
                                          on: {
                                            mouseenter: function($event) {
                                              return _vm.selectIndex(
                                                "depaAssistant-" + index
                                              )
                                            },
                                            mouseleave: function($event) {
                                              _vm.ItemIndex = null
                                            }
                                          }
                                        },
                                        [
                                          !_vm.isEditableView && index == 0
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "apartTitleViewMode apartTitle text-white subtitle-1 font-weight-bold mb-0"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.getApartLabel(
                                                          "assistants"
                                                        )
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "v-card",
                                            {
                                              class: " cardWidth",
                                              style: _vm.getBorderByStatusFull(
                                                section
                                              ),
                                              on: {
                                                click: function($event) {
                                                  return _vm.handleClicks(
                                                    $event,
                                                    null,
                                                    null,
                                                    "flipDepartmentAssistant-" +
                                                      index
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "div",
                                                              _vm._g({}, on),
                                                              [
                                                                _c(
                                                                  "v-img",
                                                                  {
                                                                    staticClass:
                                                                      "white--text align-end",
                                                                    attrs: {
                                                                      height: !_vm.isEditableView
                                                                        ? "100px"
                                                                        : "180px",
                                                                      src: _vm.getSupervisorAvatar(
                                                                        section,
                                                                        "members"
                                                                      )
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm.amIAdmin &&
                                                                    _vm.isEditableView &&
                                                                    _vm.ItemIndex ===
                                                                      "depaAssistant-" +
                                                                        index
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "deleteBasketOrga"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "DeleteBasket",
                                                                              {
                                                                                attrs: {
                                                                                  indexData: [
                                                                                    {
                                                                                      user: section,
                                                                                      type:
                                                                                        "department"
                                                                                    }
                                                                                  ],
                                                                                  delFunction:
                                                                                    _vm.removeAssistant,
                                                                                  extraClasses:
                                                                                    "",
                                                                                  fontSize:
                                                                                    "16",
                                                                                  prevent:
                                                                                    "false",
                                                                                  organigramTT: _vm.$t(
                                                                                    "components.organisationGrid.deleteBasketTT"
                                                                                  )
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "gradientBg"
                                                                      },
                                                                      [
                                                                        _vm.isEditableView
                                                                          ? _c(
                                                                              "v-card-subtitle",
                                                                              {
                                                                                staticClass:
                                                                                  "titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate",
                                                                                staticStyle: {
                                                                                  color:
                                                                                    "#fff !important"
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      _vm.getApartLabel(
                                                                                        "assistants"
                                                                                      )
                                                                                    ) +
                                                                                    "\n\n                            "
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-card-subtitle",
                                                                          {
                                                                            staticClass:
                                                                              "subTitleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate",
                                                                            staticStyle: {
                                                                              color:
                                                                                "#fff !important"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              " +
                                                                                _vm._s(
                                                                                  _vm.getSupervisorTitel(
                                                                                    section
                                                                                  ) +
                                                                                    " " +
                                                                                    _vm.getUserName(
                                                                                      section
                                                                                    )
                                                                                ) +
                                                                                "\n                            "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        section.user &&
                                                                        _vm.hasRepresentative(
                                                                          section.uuid
                                                                        )
                                                                          ? _c(
                                                                              "v-card-subtitle",
                                                                              {
                                                                                staticClass:
                                                                                  "d-flex titleCard colorWhite py-0 px-0 text-truncate justify-space-between",
                                                                                style: _vm.getBorderByStatusFull(
                                                                                  _vm.getPersonByUuid(
                                                                                    section
                                                                                      .user
                                                                                      .representative
                                                                                      .value
                                                                                  )
                                                                                ),
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    $event.stopPropagation()
                                                                                    $event.preventDefault()
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "representative-subtitle text-truncate"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "CallIcon",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mr-1",
                                                                                        attrs: {
                                                                                          person: _vm.getPersonByUuid(
                                                                                            section
                                                                                              .user
                                                                                              .representative
                                                                                              .value
                                                                                          ),
                                                                                          isRegularGrid: true
                                                                                        }
                                                                                      }
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "colorWhite"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              _vm.getRepresentativeName(
                                                                                                section.uuid
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "ButtonsFrontCard",
                                                                                  {
                                                                                    staticClass:
                                                                                      "pt-1",
                                                                                    staticStyle: {
                                                                                      position:
                                                                                        "inherit !important"
                                                                                    },
                                                                                    attrs: {
                                                                                      isOrga: true,
                                                                                      person: section,
                                                                                      uuid:
                                                                                        section.uuid,
                                                                                      isRegularGrid: true
                                                                                    },
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        $event.stopPropagation()
                                                                                        $event.preventDefault()
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        !section.user ||
                                                                        !_vm.hasRepresentative(
                                                                          section.uuid
                                                                        )
                                                                          ? _c(
                                                                              "ButtonsFrontCard",
                                                                              {
                                                                                attrs: {
                                                                                  isOrga: true,
                                                                                  person: section,
                                                                                  uuid:
                                                                                    section.uuid,
                                                                                  isRegularGrid: true
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    $event.stopPropagation()
                                                                                    $event.preventDefault()
                                                                                  }
                                                                                }
                                                                              }
                                                                            )
                                                                          : _vm._e()
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "ButtonsCardWrapper",
                                                                      {
                                                                        attrs: {
                                                                          showMultiUserCallIcon: true,
                                                                          isOrganigram: true,
                                                                          person: section,
                                                                          isRegularGrid: true
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("UserInfoTooltip", {
                                                    attrs: { person: section }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showTeam || _vm.showEmptyTeams
                    ? [
                        _vm.showTeam
                          ? _c(
                              "div",
                              { staticClass: "mx-auto titleWrapper px-2" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "apartTitle" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.getApartLabel("teams")) +
                                        "\n\n              "
                                    ),
                                    _vm.amIAdmin &&
                                    _vm.currentApart === "teams" &&
                                    _vm.isEditableView
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "font-awesome-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "cursorPointer primary--text",
                                                            style: {
                                                              fontSize: "18px"
                                                            },
                                                            attrs: {
                                                              icon: [
                                                                "fal",
                                                                _vm.getIsEditing
                                                                  ? "times"
                                                                  : "pen"
                                                              ]
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.getIsEditing
                                                                  ? _vm.cleanEditing()
                                                                  : _vm.setEditing(
                                                                      "team"
                                                                    )
                                                              }
                                                            }
                                                          },
                                                          on
                                                        )
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              206868875
                                            )
                                          },
                                          [
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getIsEditing
                                                    ? _vm.$t(
                                                        "components.organisationGrid.cancelEdit"
                                                      )
                                                    : _vm.$t(
                                                        "components.organisationGrid.editTT"
                                                      )
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "v-row",
                          { staticClass: "mx-auto rowHeight" },
                          [
                            _vm.amIAdmin &&
                            _vm.showPlusTeam &&
                            _vm.isEditableView
                              ? _c(
                                  "v-col",
                                  {
                                    class: "custom6cols py-1 px-2",
                                    attrs: { cols: "6" }
                                  },
                                  [
                                    _c(
                                      "draggable",
                                      {
                                        staticClass: "list-group",
                                        attrs: {
                                          "ghost-class": "ghost",
                                          handle: ".handle",
                                          list: [_vm.sections],
                                          group: "people"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.log($event, "addTeam")
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-card",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "cardWidth cursorPointer",
                                                            style: {
                                                              border:
                                                                "1px solid #efefef"
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "divCardAdd"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "divAdd"
                                                                },
                                                                [
                                                                  _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      staticClass:
                                                                        "buttonAdd",
                                                                      staticStyle: {
                                                                        "font-size":
                                                                          "20px"
                                                                      },
                                                                      attrs: {
                                                                        icon: [
                                                                          "fal",
                                                                          "plus-circle"
                                                                        ]
                                                                      }
                                                                    }
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "mb-0 mt-2 primary--text px-2"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "components.organisationGrid.addField"
                                                                            )
                                                                          ) +
                                                                          "\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("v-text-field", {
                                                            staticClass: "py-2",
                                                            attrs: {
                                                              outlined: "",
                                                              label: _vm.$t(
                                                                "components.organisationCarousel.team"
                                                              ),
                                                              dense: "",
                                                              "hide-details": ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.newTeamName,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.newTeamName = $$v
                                                              },
                                                              expression:
                                                                "newTeamName"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              2216828370
                                            )
                                          },
                                          [
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "components.organisationGrid.dragTT"
                                                    )
                                                  ) +
                                                  "\n                  "
                                              )
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(_vm.teams, function(section, name, index) {
                              return _c(
                                "v-col",
                                {
                                  key: name,
                                  staticClass: "custom6cols py-1 px-2",
                                  attrs: { cols: "6" },
                                  on: {
                                    mouseenter: function($event) {
                                      return _vm.selectIndex("team-" + index)
                                    },
                                    mouseleave: function($event) {
                                      _vm.ItemIndex = null
                                    }
                                  }
                                },
                                [
                                  _vm.getIsEditing &&
                                  _vm.getEditingType == "team"
                                    ? _c(
                                        "draggable",
                                        {
                                          staticClass: "list-group",
                                          attrs: {
                                            "ghost-class": "ghost",
                                            handle: ".handle",
                                            list: [_vm.sections],
                                            group: "people"
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.editingItemOrga($event)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-card",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "cardWidth cursorPointer",
                                                              style: {
                                                                border:
                                                                  "1px solid #efefef"
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "divCardAdd d-flex align-center"
                                                              },
                                                              _vm._l(
                                                                section.supervisors,
                                                                function(
                                                                  supervisor
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key:
                                                                        supervisor.uuid,
                                                                      staticClass:
                                                                        "editSupervisorCard",
                                                                      style: {
                                                                        backgroundImage:
                                                                          "url(" +
                                                                          _vm.getAvatarByType(
                                                                            supervisor.uuid
                                                                          ) +
                                                                          ")"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "h100 gradientBg justify-center align-center d-flex flex-column text-center"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "px-1 pb-2 w100 my-0 white--text text-truncate"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.getNameForUuid(
                                                                                    supervisor.uuid
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          section
                                                                            .supervisors
                                                                            .length >
                                                                          1
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "deleteBasketSupervisor d-flex align-center justify-center text-center"
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "DeleteBasket",
                                                                                    {
                                                                                      attrs: {
                                                                                        indexData: [
                                                                                          supervisor.uuid,
                                                                                          true
                                                                                        ],
                                                                                        delFunction:
                                                                                          _vm.editingItemOrga,
                                                                                        extraClasses:
                                                                                          "",
                                                                                        fontSize:
                                                                                          "16",
                                                                                        prevent:
                                                                                          "false",
                                                                                        organigramTT: _vm.$t(
                                                                                          "components.organisationGrid.deleteBasketTT"
                                                                                        )
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e()
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            ),
                                                            _vm._v(" "),
                                                            _c("v-text-field", {
                                                              staticClass:
                                                                "py-2",
                                                              attrs: {
                                                                outlined: "",
                                                                label: _vm.$t(
                                                                  "components.organisationCarousel.team"
                                                                ),
                                                                dense: "",
                                                                "hide-details":
                                                                  ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "append",
                                                                    fn: function() {
                                                                      return [
                                                                        _c(
                                                                          "font-awesome-icon",
                                                                          {
                                                                            staticClass:
                                                                              "mt-1 cursorPointer",
                                                                            style: {
                                                                              fontSize:
                                                                                "15px"
                                                                            },
                                                                            attrs: {
                                                                              icon: [
                                                                                "fal",
                                                                                "save"
                                                                              ]
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.editingItemOrga()
                                                                              }
                                                                            }
                                                                          }
                                                                        )
                                                                      ]
                                                                    },
                                                                    proxy: true
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              ),
                                                              model: {
                                                                value:
                                                                  _vm.isEditing
                                                                    .name,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.isEditing,
                                                                    "name",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "isEditing.name"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "components.organisationGrid.editCardTT"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.getIsEditing ||
                                  (_vm.getIsEditing &&
                                    _vm.getEditingType !== "team")
                                    ? _c(
                                        "draggable",
                                        {
                                          staticClass: "list-group teamsDrag",
                                          attrs: {
                                            list: [_vm.sections],
                                            "ghost-class": "ghost",
                                            group: "people"
                                          },
                                          on: {
                                            end: function($event) {
                                              _vm.checkMove(
                                                $event,
                                                name,
                                                _vm.getDepartmentName(
                                                  section,
                                                  "teams"
                                                ),
                                                "moveTeam"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "cardWidth" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "d-flex" },
                                                [
                                                  section.department &&
                                                  section.department !== ""
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "gradientTop"
                                                        },
                                                        [
                                                          _c(
                                                            "v-card-subtitle",
                                                            {
                                                              staticClass:
                                                                "titleCard colorWhite pt-1 pb-1 px-2 text-truncate",
                                                              staticStyle: {
                                                                color:
                                                                  "#fff !important"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    _vm.getDepartmentName(
                                                                      section,
                                                                      "teams"
                                                                    )
                                                                  ) +
                                                                  "\n                    "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.amIAdmin &&
                                                  _vm.isEditableView &&
                                                  _vm.ItemIndex ===
                                                    "team-" + index
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "deleteBasketOrga d-flex align-center justify-center text-center"
                                                        },
                                                        [
                                                          _c("DeleteBasket", {
                                                            attrs: {
                                                              indexData: [
                                                                {
                                                                  type: "team",
                                                                  name: name,
                                                                  parent: _vm.getDepartmentName(
                                                                    section,
                                                                    "teams"
                                                                  )
                                                                }
                                                              ],
                                                              delFunction:
                                                                _vm.removeStructure,
                                                              extraClasses: "",
                                                              fontSize: "16",
                                                              prevent: "false",
                                                              organigramTT: _vm.$t(
                                                                "components.organisationGrid.deleteBasketTT"
                                                              )
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  !section.supervisors.length
                                                    ? _c(
                                                        "div",
                                                        { staticClass: "w100" },
                                                        [
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticStyle: {
                                                                border:
                                                                  "4px solid rgb(128, 128, 128)"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "v-img",
                                                                    {
                                                                      staticClass:
                                                                        "white--text align-end",
                                                                      attrs: {
                                                                        height:
                                                                          "180px"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "gradientBg"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-card-subtitle",
                                                                            {
                                                                              staticClass:
                                                                                "titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate",
                                                                              staticStyle: {
                                                                                color:
                                                                                  "#fff !important"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                  " +
                                                                                  _vm._s(
                                                                                    name
                                                                                  ) +
                                                                                  "\n                              "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    section.supervisors,
                                                    function(supervisor, i) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: supervisor.uuid,
                                                          class:
                                                            section.supervisors
                                                              .length > 1
                                                              ? "w50"
                                                              : "w100"
                                                        },
                                                        [
                                                          _c(
                                                            "v-card",
                                                            {
                                                              style: _vm.getBorderCard(
                                                                section.supervisors,
                                                                supervisor.uuid,
                                                                i
                                                              ),
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  !_vm.disableCallBtn(
                                                                    _vm.getPersonByUuid(
                                                                      supervisor.uuid
                                                                    )
                                                                  )
                                                                    ? _vm.callUser(
                                                                        supervisor.uuid
                                                                      )
                                                                    : ""
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    top: ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              _vm._g(
                                                                                {},
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-img",
                                                                                  {
                                                                                    staticClass:
                                                                                      "white--text align-end",
                                                                                    class: {
                                                                                      repeateadImage: _vm.isUserRepeated(
                                                                                        section,
                                                                                        "teams"
                                                                                      )
                                                                                    },
                                                                                    attrs: {
                                                                                      height:
                                                                                        "180px",
                                                                                      src: _vm.getAvatarForUuid(
                                                                                        supervisor.uuid
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "gradientBg"
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-card-subtitle",
                                                                                          {
                                                                                            staticClass:
                                                                                              "titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate",
                                                                                            staticStyle: {
                                                                                              color:
                                                                                                "#fff !important"
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                  " +
                                                                                                _vm._s(
                                                                                                  name
                                                                                                ) +
                                                                                                "\n                                "
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "v-card-subtitle",
                                                                                          {
                                                                                            staticClass:
                                                                                              "subTitleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate",
                                                                                            staticStyle: {
                                                                                              color:
                                                                                                "#fff !important"
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                  " +
                                                                                                _vm._s(
                                                                                                  _vm.getTitelForUuid(
                                                                                                    supervisor.uuid
                                                                                                  ) +
                                                                                                    " " +
                                                                                                    _vm.getNameForUuid(
                                                                                                      supervisor.uuid
                                                                                                    )
                                                                                                ) +
                                                                                                "\n                                "
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _vm.hasRepresentative(
                                                                                          supervisor.uuid
                                                                                        )
                                                                                          ? _c(
                                                                                              "v-card-subtitle",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "d-flex titleCard colorWhite py-0 px-0 text-truncate justify-space-between",
                                                                                                style: _vm.getBorderByStatusFull(
                                                                                                  _vm.getPersonByUuid(
                                                                                                    _vm.getRepresentativeId(
                                                                                                      supervisor.uuid
                                                                                                    )
                                                                                                  )
                                                                                                ),
                                                                                                on: {
                                                                                                  click: function(
                                                                                                    $event
                                                                                                  ) {
                                                                                                    $event.stopPropagation()
                                                                                                    $event.preventDefault()
                                                                                                  }
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "representative-subtitle text-truncate"
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "CallIcon",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "mr-1",
                                                                                                        attrs: {
                                                                                                          person: _vm.getPersonByUuid(
                                                                                                            _vm.getRepresentativeId(
                                                                                                              supervisor.uuid
                                                                                                            )
                                                                                                          ),
                                                                                                          isRegularGrid: true
                                                                                                        }
                                                                                                      }
                                                                                                    ),
                                                                                                    _vm._v(
                                                                                                      " "
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "span",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "colorWhite"
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "\n                                      " +
                                                                                                            _vm._s(
                                                                                                              _vm.getRepresentativeName(
                                                                                                                supervisor.uuid
                                                                                                              )
                                                                                                            ) +
                                                                                                            "\n                                    "
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                ),
                                                                                                _vm._v(
                                                                                                  " "
                                                                                                ),
                                                                                                _c(
                                                                                                  "ButtonsFrontCard",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "pt-1",
                                                                                                    staticStyle: {
                                                                                                      position:
                                                                                                        "inherit !important"
                                                                                                    },
                                                                                                    attrs: {
                                                                                                      checkMove:
                                                                                                        _vm.checkMove,
                                                                                                      isMovingDepa: false,
                                                                                                      isMovingTeam: true,
                                                                                                      nameSectionToMove: name,
                                                                                                      parentToMove: _vm.getDepartmentName(
                                                                                                        section,
                                                                                                        "teams"
                                                                                                      ),
                                                                                                      moveType:
                                                                                                        "moveTeam",
                                                                                                      isEditableView:
                                                                                                        _vm.isEditableView,
                                                                                                      isOrga: true,
                                                                                                      person: _vm.getPersonByUuid(
                                                                                                        supervisor.uuid
                                                                                                      ),
                                                                                                      uuid:
                                                                                                        supervisor.uuid,
                                                                                                      sectionData: {
                                                                                                        type:
                                                                                                          "teams",
                                                                                                        name: name,
                                                                                                        data: section
                                                                                                      },
                                                                                                      isRegularGrid: true
                                                                                                    },
                                                                                                    on: {
                                                                                                      click: function(
                                                                                                        $event
                                                                                                      ) {
                                                                                                        $event.stopPropagation()
                                                                                                        $event.preventDefault()
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                )
                                                                                              ],
                                                                                              1
                                                                                            )
                                                                                          : _vm._e(),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        !_vm.hasRepresentative(
                                                                                          supervisor.uuid
                                                                                        )
                                                                                          ? _c(
                                                                                              "ButtonsFrontCard",
                                                                                              {
                                                                                                attrs: {
                                                                                                  checkMove:
                                                                                                    _vm.checkMove,
                                                                                                  isMovingDepa: false,
                                                                                                  isMovingTeam: true,
                                                                                                  nameSectionToMove: name,
                                                                                                  parentToMove: _vm.getDepartmentName(
                                                                                                    section,
                                                                                                    "teams"
                                                                                                  ),
                                                                                                  moveType:
                                                                                                    "moveTeam",
                                                                                                  isEditableView:
                                                                                                    _vm.isEditableView,
                                                                                                  isOrga: true,
                                                                                                  person: _vm.getPersonByUuid(
                                                                                                    supervisor.uuid
                                                                                                  ),
                                                                                                  uuid:
                                                                                                    supervisor.uuid,
                                                                                                  sectionData: {
                                                                                                    type:
                                                                                                      "teams",
                                                                                                    name: name,
                                                                                                    data: section
                                                                                                  },
                                                                                                  isRegularGrid: true
                                                                                                },
                                                                                                on: {
                                                                                                  click: function(
                                                                                                    $event
                                                                                                  ) {
                                                                                                    $event.stopPropagation()
                                                                                                    $event.preventDefault()
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            )
                                                                                          : _vm._e()
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "ButtonsCardWrapper",
                                                                                      {
                                                                                        attrs: {
                                                                                          showMultiUserCallIcon: true,
                                                                                          customTop: true,
                                                                                          isOrganigram: true,
                                                                                          person: _vm.getPersonByUuid(
                                                                                            supervisor.uuid
                                                                                          ),
                                                                                          isRegularGrid: true
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                },
                                                                [
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "UserInfoTooltip",
                                                                    {
                                                                      attrs: {
                                                                        organisationData: {
                                                                          name: name
                                                                        },
                                                                        person: _vm.getPersonByUuid(
                                                                          supervisor.uuid
                                                                        )
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-center"
                                                },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "font-awesome-icon",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "cursorPointer arrowIcon",
                                                                      staticStyle: {
                                                                        "font-size":
                                                                          "40px"
                                                                      },
                                                                      attrs: {
                                                                        icon: !_vm.isTeamSelected
                                                                          ? [
                                                                              "fal",
                                                                              "angle-down"
                                                                            ]
                                                                          : [
                                                                              "fal",
                                                                              "angle-up"
                                                                            ]
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleClicks(
                                                                            $event,
                                                                            name,
                                                                            "teams",
                                                                            "flipTeams-" +
                                                                              index
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    on
                                                                  )
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "components.organisationGrid.arrowIconTT"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            })
                          ],
                          2
                        )
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showMembers
                    ? [
                        _vm.showMembers
                          ? _c(
                              "div",
                              { staticClass: "mx-auto titleWrapper px-2" },
                              [
                                _c("span", { staticClass: "apartTitle" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.getApartLabel("members")) +
                                      "\n\n            "
                                  )
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "v-row",
                          { staticClass: "mx-auto rowHeight" },
                          [
                            _vm.amIAdmin &&
                            _vm.showPlusMember &&
                            _vm.isEditableView
                              ? _c(
                                  "v-col",
                                  {
                                    staticClass: "custom6cols py-1 px-2",
                                    attrs: { cols: "6" }
                                  },
                                  [
                                    _c(
                                      "draggable",
                                      {
                                        staticClass: "list-group",
                                        attrs: {
                                          "ghost-class": "ghost",
                                          handle: ".handle",
                                          list: [_vm.sections],
                                          group: "people"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.log($event, "addMember")
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "cardWidth cursorPointer",
                                            style: {
                                              border: "1px solid #efefef"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "divCardAdd assitants"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "divAdd" },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      staticClass: "buttonAdd",
                                                      staticStyle: {
                                                        "font-size": "20px"
                                                      },
                                                      attrs: {
                                                        icon: [
                                                          "fal",
                                                          "plus-circle"
                                                        ]
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "mb-0 mt-2 primary--text px-2"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "components.organisationGrid.addField"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(_vm.members, function(section, name, index) {
                              return _c(
                                "v-col",
                                {
                                  directives: [
                                    {
                                      name: "click-outside",
                                      rawName: "v-click-outside",
                                      value: _vm.closeLargeUser,
                                      expression: "closeLargeUser"
                                    }
                                  ],
                                  key: name,
                                  staticClass: "custom6cols py-1 px-2",
                                  attrs: { cols: "6" },
                                  on: {
                                    mouseenter: function($event) {
                                      return _vm.selectIndex("members-" + index)
                                    },
                                    mouseleave: function($event) {
                                      _vm.ItemIndex = null
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "draggable",
                                    {
                                      staticClass: "list-group memberDrag",
                                      attrs: {
                                        list: [_vm.sections],
                                        group: {
                                          name: "people",
                                          pull: "clone",
                                          put: false
                                        }
                                      },
                                      on: {
                                        end: function($event) {
                                          return _vm.checkMove(
                                            $event,
                                            section,
                                            "",
                                            "moveMember"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          class:
                                            "" +
                                            (_vm.showLargeUser == section.uuid
                                              ? "cardWidthLargeUser"
                                              : "cardWidth"),
                                          style: _vm.getBorderByStatusFull(
                                            section
                                          ),
                                          on: {
                                            click: function($event) {
                                              _vm.handleClicks(
                                                $event,
                                                null,
                                                null,
                                                "flipMembers-" + index
                                              )
                                              !_vm.disableCallBtn(section)
                                                ? _vm.callUser(section.uuid)
                                                : ""
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "div",
                                                          _vm._g({}, on),
                                                          [
                                                            _c(
                                                              "v-img",
                                                              {
                                                                staticClass:
                                                                  "white--text align-end",
                                                                attrs: {
                                                                  height:
                                                                    "180px",
                                                                  src: _vm.getSupervisorAvatar(
                                                                    section,
                                                                    "members"
                                                                  )
                                                                }
                                                              },
                                                              [
                                                                _vm.amIAdmin &&
                                                                _vm.isEditableView &&
                                                                _vm.ItemIndex ===
                                                                  "members-" +
                                                                    index
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "deleteBasketOrga"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "DeleteBasket",
                                                                          {
                                                                            attrs: {
                                                                              indexData: section,
                                                                              delFunction:
                                                                                _vm.deleteMember,
                                                                              extraClasses:
                                                                                "",
                                                                              fontSize:
                                                                                "16",
                                                                              prevent:
                                                                                "false",
                                                                              organigramTT: _vm.$t(
                                                                                "components.organisationGrid.deleteBasketTT"
                                                                              )
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "gradientBg"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card-subtitle",
                                                                      {
                                                                        staticClass:
                                                                          "titleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate",
                                                                        staticStyle: {
                                                                          color:
                                                                            "#fff !important"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                              " +
                                                                            _vm._s(
                                                                              _vm.getMemberPosition(
                                                                                section
                                                                              )
                                                                            ) +
                                                                            "\n                            "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-card-subtitle",
                                                                      {
                                                                        staticClass:
                                                                          "subTitleCard colorWhite pt-1 pb-1 px-2 customFontSize text-truncate",
                                                                        staticStyle: {
                                                                          color:
                                                                            "#fff !important"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                              " +
                                                                            _vm._s(
                                                                              _vm.getSupervisorTitel(
                                                                                section
                                                                              ) +
                                                                                " " +
                                                                                _vm.getUserName(
                                                                                  section
                                                                                )
                                                                            ) +
                                                                            "\n                            "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    section.user &&
                                                                    _vm.hasRepresentative(
                                                                      section.uuid
                                                                    )
                                                                      ? _c(
                                                                          "v-card-subtitle",
                                                                          {
                                                                            staticClass:
                                                                              "d-flex titleCard colorWhite py-0 px-0 text-truncate justify-space-between",
                                                                            style: _vm.getBorderByStatusFull(
                                                                              _vm.getPersonByUuid(
                                                                                section
                                                                                  .user
                                                                                  .representative
                                                                                  .value
                                                                              )
                                                                            ),
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                                $event.preventDefault()
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "representative-subtitle text-truncate"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "CallIcon",
                                                                                  {
                                                                                    staticClass:
                                                                                      "mr-1",
                                                                                    attrs: {
                                                                                      person: _vm.getPersonByUuid(
                                                                                        section
                                                                                          .user
                                                                                          .representative
                                                                                          .value
                                                                                      ),
                                                                                      isRegularGrid: true
                                                                                    }
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "colorWhite"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                  " +
                                                                                        _vm._s(
                                                                                          _vm.getRepresentativeName(
                                                                                            section.uuid
                                                                                          )
                                                                                        ) +
                                                                                        "\n                                "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "ButtonsFrontCard",
                                                                              {
                                                                                staticClass:
                                                                                  "pt-1",
                                                                                staticStyle: {
                                                                                  position:
                                                                                    "inherit !important"
                                                                                },
                                                                                attrs: {
                                                                                  teamInfo:
                                                                                    _vm.currentCardName,
                                                                                  isOrga: true,
                                                                                  person: section,
                                                                                  uuid:
                                                                                    section.uuid,
                                                                                  sectionName:
                                                                                    "members",
                                                                                  sectionData: {
                                                                                    type:
                                                                                      "members",
                                                                                    name: name,
                                                                                    data: section
                                                                                  },
                                                                                  isRegularGrid: true
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    $event.stopPropagation()
                                                                                    $event.preventDefault()
                                                                                  }
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    !_vm.hasRepresentative(
                                                                      section.uuid
                                                                    )
                                                                      ? _c(
                                                                          "ButtonsFrontCard",
                                                                          {
                                                                            attrs: {
                                                                              teamInfo:
                                                                                _vm.currentCardName,
                                                                              isOrga: true,
                                                                              person: section,
                                                                              uuid:
                                                                                section.uuid,
                                                                              sectionName:
                                                                                "members",
                                                                              sectionData: {
                                                                                type:
                                                                                  "members",
                                                                                name: name,
                                                                                data: section
                                                                              },
                                                                              isRegularGrid: true
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                                $event.preventDefault()
                                                                              }
                                                                            }
                                                                          }
                                                                        )
                                                                      : _vm._e()
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "ButtonsCardWrapper",
                                                                  {
                                                                    attrs: {
                                                                      showMultiUserCallIcon: true,
                                                                      isOrganigram: true,
                                                                      person: section,
                                                                      isRegularGrid: true
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("UserInfoTooltip", {
                                                attrs: { person: section }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            })
                          ],
                          2
                        )
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _vm.amIAdmin && _vm.isEditableView
                ? _c(
                    "v-col",
                    {
                      staticClass: "py-0 px-0 listUsers",
                      attrs: { cols: "2" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "header" },
                        [
                          _c(
                            "v-text-field",
                            {
                              staticClass: "py-2",
                              attrs: {
                                dense: "",
                                "single-line": "",
                                "hide-details": "",
                                label: _vm.$t("generics.search"),
                                outlined: "",
                                clearable: ""
                              },
                              on: {
                                "click:clear": function($event) {
                                  _vm.searchUsers = ""
                                }
                              },
                              model: {
                                value: _vm.searchUsers,
                                callback: function($$v) {
                                  _vm.searchUsers = $$v
                                },
                                expression: "searchUsers"
                              }
                            },
                            [
                              _c("font-awesome-icon", {
                                style: { fontSize: "18px" },
                                attrs: {
                                  slot: "append",
                                  icon: ["fal", "search"]
                                },
                                slot: "append"
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "mx-auto rowUsers pa-3" },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "draggable",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    list: _vm.usersSearchF,
                                    group: {
                                      name: "people",
                                      pull: "clone",
                                      put: false
                                    }
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.log($event, "userList")
                                    }
                                  }
                                },
                                _vm._l(_vm.usersSearchF, function(
                                  person,
                                  index
                                ) {
                                  return _c(
                                    "v-list-item",
                                    { key: index, staticClass: "my-1" },
                                    [
                                      _c(
                                        "v-list-item-avatar",
                                        [
                                          _c("v-img", {
                                            staticClass:
                                              "white--text align-end",
                                            attrs: {
                                              contain: "",
                                              height: "80",
                                              src: _vm.getAvatarForUuid(
                                                person.uuid
                                              )
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-list-item-title", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(person.name) +
                                            "\n                "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.amIAdmin && _vm.isEditableView
            ? _c("MoveOrgaItemModal", {
                attrs: {
                  closeModal: _vm.closeModalMove,
                  destination: _vm.destination,
                  moveItem: _vm.moveItemF,
                  itemToMove: _vm.moveItem.name,
                  parent: _vm.moveItem.parent,
                  parentSection: _vm.moveItem.parentSection,
                  showModalMoveItem: _vm.showModalMoveItem
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: {
                value: _vm.structureToDeleteType != null,
                "max-width": "550px"
              }
            },
            [
              _c(
                "v-card",
                [
                  _vm.structureToDeleteType
                    ? _c(
                        "v-card-title",
                        { staticClass: "headline" },
                        [
                          _c("v-img", {
                            staticClass: "mr-1",
                            attrs: {
                              src: "assets/icon.png",
                              "max-height": "30",
                              "max-width": "30",
                              contain: ""
                            }
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "components.manageOrganisation." +
                                    _vm.structureToDeleteType +
                                    ".delete"
                                )
                              ) +
                              "\n        "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.structureToDeleteType
                    ? _c("v-card-text", [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "components.manageOrganisation.modalDelete",
                                [
                                  _vm.structureToDelete != null
                                    ? _vm.structureToDelete[
                                        _vm.structureToDeleteType
                                      ]
                                    : ""
                                ]
                              )
                            ) + " "
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "components.manageOrganisation.confirmDelete"
                              )
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              position: "relative",
                              width: "100%",
                              "padding-top": "5px"
                            }
                          },
                          [
                            _c("v-text-field", {
                              ref: "repeatStructureName",
                              attrs: {
                                placeholder:
                                  _vm.structureToDelete != null
                                    ? _vm.structureToDelete[
                                        _vm.structureToDeleteType
                                      ]
                                    : ""
                              },
                              on: {
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.tryDeletion.apply(null, arguments)
                                }
                              },
                              model: {
                                value: _vm.repeatStructureName,
                                callback: function($$v) {
                                  _vm.repeatStructureName = $$v
                                },
                                expression: "repeatStructureName"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    {
                      staticClass: "py-2 pl-4 mt-4 pr-2 footerModal",
                      attrs: { color: "red" }
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "px-2 btns mx-0", attrs: { flex: "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "px-0 py-0",
                              attrs: { cols: "8", align: "right" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { disabled: _vm.isDeleteDisabled },
                                  on: { click: _vm.deteleStructureAndSub }
                                },
                                [_vm._v(_vm._s(_vm.$t("generics.delete")))]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.structureToDeleteType = null
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "components.manageOrganisation.abort"
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }